import {
  Alignment,
  FillType,
  VerticalAlignment,
} from "~~/models/widgets/widget-controls.model";
import { State } from "~~/models/widgets/widget-controls.model";

import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFontFamily,
  getDefaultSize,
} from "../common/widget-initial";

export const SPORTS_MENU_WIDGET_INITIAL = () => {
  return {
    options: {
      fillImageColor: {
        fillType: FillType.COLOR,
        value: {
          color: "#000000",
          opacity: 100,
        },
      },
    },
  };
};

const MENU_ITEM_STATE = () => {
  return {
    fill: {
      color: "",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: null,
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    color: {
      color: "#ffffff",
      opacity: 100,
    },
    decoration: [],

    itemIcon: {
      _active: true,
      color: {
        color: "#ffffff",
        opacity: 100,
      },
    },

    arrowSettings: {
      _active: true,
      textColor: {
        color: "#ffffff",
        opacity: 100,
      },
    },
  };
};

export const MENU_ITEM_INITIAL = () => {
  return {
    options: {
      fill: {
        color: "",
        opacity: 100,
      },
      border: getDefaultBorderValue(),
      shadow: {
        fill: {
          color: null,
          opacity: 100,
        },
        x: 0,
        y: 0,
        blur: 0,
        spread: 0,
      },
      cornerRadius: getDefaultCornerRadiusValue(),
      padding: {
        top: 15,
        bottom: 15,
        left: 20,
        right: 20,
      },
      verticalSpace: 4,
      color: {
        color: "#ffffff",
        opacity: 100,
      },
      theme: 13,
      fontFamily: getDefaultFontFamily(),
      decoration: [],
      alignment: Alignment.LEFT,
      size: getDefaultSize(),
      baseDisplaySettings: {
        alignment: VerticalAlignment.TOP,
      },

      itemIcon: {
        _active: true,
        size: 14,
        spaceBetween: 8,
        color: {
          color: "#ffffff",
          opacity: 100,
        },
      },
      arrowSettings: {
        _active: true,
        value: "Down",
        default_value: "ant-design:arrow-down-outlined",
        rotate: true,
        textColor: {
          color: "#ffffff",
          opacity: 100,
        },
      },
      states: {
        [State.HOVER]: MENU_ITEM_STATE(),
        [State.ACTIVE]: MENU_ITEM_STATE(),
      },
    },
  };
};
