import { useI18n } from "vue-i18n";

import {
  Alignment,
  ContainerMode,
  Grid,
  ResizingType,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import {
  ELEMENTS_INITIAL,
  HOVER_CONTENT_INITIAL,
  ICON_INITIAL,
  TAG_INITIAL,
} from "~~/constants/configs/casino/initial-constants";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();
  const { t } = useI18n();

  const DEFAULT_STATES = {
    header_button: State.DEFAULT,
    games_button: State.DEFAULT,
    games_status: State.WITH_ACTIVE_GAMES,
    game_cover: State.DEFAULT,
    promo_tag: State.DEFAULT,
    play_money_button: State.DEFAULT,
    play_free_button: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions([], {
    display: {
      distance: 12,
    },
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions(
      "title",
      {
        data: TITLE_INITIAL(),
        exclude: ["spacing", "link"],
        values: {
          color: getDefaultFillValue("#000000", 90),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
        additionalData: {
          options: {
            icon: {
              _active: true,
              default_value: "solar:crown-bold",
              textColor: getDefaultFillValue("#000000", 90),
              iconSize: 32,
              gap: 10,
            },
          },
        },
      },
      { value: "Top Games" }
    );

    addFieldInitialOptions("header_button", {
      data: BUTTON_INITIAL(),
      values: {
        fill: getDefaultFillValue(),
        buttonDisplaySettings: {
          resizing: ResizingType.HUG,
          alignment: Alignment.LEFT,
        },
        buttonTextSettings: {
          _active: true,
          textColor: getDefaultFillValue("#000000", 90),
          theme: getDefaultTheme(16),
          fontFamily: getDefaultFontFamily(),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
        iconSettings: {
          _active: true,
          default_value: "ant-design:right-outlined",
          textColor: getDefaultFillValue("#000000"),
          iconSize: 20,
          position: Alignment.RIGHT,
          gap: 8,
        },
        padding: getDefaultSpacing(),
        margins: getDefaultSpacing(),
      },
    });

    addFieldInitialOptions("header", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          distance: 16,
        },
      },
    });

    addFieldInitialOptions(
      "search_result",
      {
        data: TITLE_INITIAL(),
        exclude: ["spacing", "link"],
        values: {
          color: getDefaultFillValue("#000000", 90),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
      },
      { value: "Search Result" }
    );

    addFieldInitialOptions("games_list", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          mode: ContainerMode.GRID,
          gridSettings: {
            grid: Grid.FIXED,
            minCardWidth: 228,
            gridFixedType: Grid.FIT,
            maxCardsInRow: 2,
            minCardsInRow: 1,
            gap: {
              row: 12,
              column: 12,
            },
          },
        },
      },
    });

    addFieldInitialOptions("game_name", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        _active: false,
        theme: getDefaultTheme(),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        color: getDefaultFillValue("#000000", 90),
      },
    });

    addFieldInitialOptions(
      "nogames_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["padding"],
        values: {
          theme: getDefaultTheme(16),
          decoration: getDefaultDecoration([TextStyle.BOLD]),
        },
        additionalData: {
          options: {
            description: {
              _active: true,
              value: useLocalizedValue().transformToLocalizedValue(
                "Try to change the query"
              ).localizedValue,
              textColor: getDefaultFillValue("#000000", 65),
              theme: getDefaultTheme(16),
            },
          },
        },
      },
      { value: "There are no games" }
    );

    addFieldInitialOptions("game_cards", {
      data: DESIGN_INITIAL(),
      exclude: ["spacing"],
      additionalData: {
        options: {
          distance: 4,
          padding: getDefaultSpacing(),
        },
      },
    });

    addFieldInitialOptions("all_games_card", {
      data: DESIGN_INITIAL(),
      exclude: ["spacing"],
      values: {
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
      },
      additionalData: {
        options: {
          alignment: Alignment.CENTER,
          distance: 4,
          padding: getDefaultSpacing(),
        },
      },
    });

    const initialGamesState = (): Record<string, IWidgetOptions> => {
      return {
        fill: getDefaultFillValue("#ffffff"),
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 6),
        }),
        shadow: getDefaultShadow(),
      };
    };

    const initialGamesStateHover = (): Record<string, IWidgetOptions> => {
      return {
        ...initialGamesState(),
        overlay: getDefaultFillValue("#000000", 60),
      };
    };

    addFieldInitialOptions("game_cover", {
      data: {
        options: {
          ...DESIGN_INITIAL().options,
          ...ELEMENTS_INITIAL().options,
        },
      },
      exclude: ["spacing"],
      values: {
        border: getDefaultBorderValue({
          fill: getDefaultFillValue("#000000", 15),
        }),
        cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
        fill: getDefaultFillValue("#FFFFFF"),
        padding: getDefaultSpacing(16),
      },
      additionalData: {
        options: {
          overlay: getDefaultFillValue("#000000", 60),
          image: {
            cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
            padding: getDefaultSpacing(8),
          },
          states: {
            [State.HOVER]: initialGamesStateHover(),
            [State.ACTIVE]: initialGamesState(),
          },
        },
      },
    });

    addFieldInitialOptions("hover_content", {
      data: HOVER_CONTENT_INITIAL(),
    });

    addFieldInitialOptions("favorite_icon", {
      data: ICON_INITIAL(),
      additionalData: {
        options: {
          default_value: "ant-design:star-outlined",
        },
      },
    });

    addFieldInitialOptions("promo_tag", {
      data: TAG_INITIAL(),
    });

    addFieldInitialOptions(
      "game_title",
      {
        data: TITLE_INITIAL(),
        values: {
          color: getDefaultFillValue("#FFFFFF"),
          theme: getDefaultTheme(20),
          alignment: Alignment.CENTER,
        },
      },
      { value: t("widgets.CasinoGamesListWidget.game_title") }
    );

    addFieldInitialOptions(
      "play_money_button",
      {
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 8,
            right: 8,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.CENTER,
          },
          iconSettings: {
            ...BUTTON_INITIAL().options.iconSettings,
            _active: false,
          },
        },
      },
      { value: t("widgets.CasinoGamesListWidget.play_for_money") }
    );

    addFieldInitialOptions(
      "play_free_button",
      {
        data: BUTTON_INITIAL(),
        exclude: ["link"],
        values: {
          cornerRadius: getDefaultCornerRadiusValue({ value: 4 }),
          fill: getDefaultFillValue("#ffffff"),
          padding: getDefaultSpacing({
            top: 4,
            bottom: 4,
            left: 8,
            right: 8,
          }),
          buttonDisplaySettings: {
            resizing: ResizingType.HUG,
            alignment: Alignment.CENTER,
          },
          buttonTextSettings: {
            _active: true,
            textColor: getDefaultFillValue("#000000"),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration(),
          },
          iconSettings: {
            ...BUTTON_INITIAL().options.iconSettings,
            _active: false,
          },
        },
      },
      { value: t("widgets.CasinoGamesListWidget.play_for_free") }
    );

    addFieldInitialOptions(
      "games_button",
      {
        data: BUTTON_INITIAL(),
        values: {
          fill: getDefaultFillValue(),
          buttonDisplaySettings: {
            resizing: ResizingType.FILL,
            alignment: Alignment.CENTER,
          },
          buttonTextSettings: {
            _active: true,
            textColor: getDefaultFillValue("#000000", 90),
            theme: getDefaultTheme(16),
            fontFamily: getDefaultFontFamily(),
            decoration: getDefaultDecoration([TextStyle.BOLD]),
          },
          iconSettings: {
            _active: false,
            default_value: "ant-design:right-outlined",
            textColor: getDefaultFillValue("#000000"),
            iconSize: 20,
            position: Alignment.RIGHT,
            gap: 8,
          },
          padding: getDefaultSpacing(),
          margins: getDefaultSpacing(),
        },
      },
      { value: "All games" }
    );

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
