import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import {
  Alignment,
  ContainerMode,
  DisplayOrientation,
  ResizingType,
  State,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import {
  MAX_ICON_SIZE_INPUT_VALUE,
  MIN_ICON_SIZE_INPUT_VALUE,
} from "~~/constants/input-validation";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

import { useAllowDisableDependentFieldContentItems } from "../../useAllowDisableDependentFieldContentItems";
import { useButtonControls } from "../../card/useButtonControls";

import { useItems } from "./useCasinoSmartGamesItems";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useItems(widget, fields);

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.BUTTON_TEXT_SETTINGS, FieldContentItem.ICON_SETTINGS]
  );

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const selectedElementPath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: widget.value,
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  // header_button
  const {
    buttonDesignControls: headerButtonDesignControls,
    buttonContentControls: headerButtonContentControls,
    buttonStatesControls: headerButtonStatesControls,
  } = useButtonControls({
    state: toRef(() => states.value.header_button),
    stateHandler: (state: State) => {
      emit("update-state", {
        state: selectedField.value?.name,
        value: state,
      });
    },
    exclude: {
      content: ["content/ConfigTextInputWithToggle"],
    },
  });

  const {
    buttonDesignControls: gamesButtonDesignControls,
    buttonContentControls: gamesButtonContentControls,
    buttonStatesControls: gamesButtonStatesControls,
  } = useButtonControls({
    state: toRef(() => states.value.games_button),
    stateHandler: (state: State) => {
      emit("update-state", {
        state: selectedField.value?.name,
        value: state,
      });
    },
    exclude: {
      content: ["content/ConfigTextInputWithToggle"],
    },
  });

  // header
  const headerDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const headerContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "title",
              label: "Title",
              isActiveValueSource: fields.value.title.field.options,
            },
            {
              value: "header_button",
              label: "Button",
              isActiveValueSource: fields.value.header_button.field.options,
            },
          ],
        },
      },
    ];
  });

  // title
  const titleControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.icon.gap",
            valueSource: selectedField.value,
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      typography({ source: selectedField.value }, "Text"),
      {
        section: generateContentSectionName("Icon"),
        toggleable: true,
        valueSource: selectedField.value?.options.icon,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.textColor",
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.iconSize",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: selectedField.value,
            options: {
              label: "Size",
              minValue: MIN_ICON_SIZE_INPUT_VALUE,
              maxValue: MAX_ICON_SIZE_INPUT_VALUE,
            },
          },
        ],
      },
    ];
  });

  // search_result

  // games_list
  const gamesListDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const gamesListContentControls = computed(() => {
    const sharedControls = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.games_status,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.with_active_games,
              value: State.WITH_ACTIVE_GAMES,
            },
            {
              label: STATE_KEY_LABELS.no_active_games,
              value: State.NO_ACTIVE_GAMES,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "games_status", value });
        },
      },
    ];

    if (states.value.games_status === State.WITH_ACTIVE_GAMES) {
      return [
        ...sharedControls,
        {
          section: generateDesignSectionName("Display mode"),
          controls: [
            {
              componentPath: "common/ConfigRadioTextElements",
              valuePath: "options.mode",
              valueSource: selectedField.value,
              options: {
                items: [
                  { label: "Grid", value: ContainerMode.GRID },
                  { label: "Carousel", value: ContainerMode.CAROUSEL },
                ],
              },
            },
            {
              componentPath: "ConfigDisplayGridSettings",
              valuePath: "options.gridSettings",
              valueSource: selectedField.value,
              visible: selectedField.value?.options.mode === ContainerMode.GRID,
            },
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.gridSettings.minCardWidth",
              valueSource: selectedField.value,
              visible:
                selectedField.value?.options.mode === ContainerMode.CAROUSEL,
              options: {
                label: "Card width",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
            {
              componentPath: "ConfigColumnWidth",
              valuePath: "options.gridSettings.gap.column",
              valueSource: selectedField.value,
              visible:
                selectedField.value?.options.mode === ContainerMode.CAROUSEL,
              options: {
                label: "Gap",
                showIconPrefix: false,
                showSuffix: true,
                suffix: "px",
              },
            },
          ],
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [
              {
                label: "Games cards",
                value: "game_cards",
              },
              {
                label: "All games card",
                value: "all_games_card",
                isActiveValueSource: fields.value.all_games_card.field.options,
              },
            ],
          },
        },
      ];
    } else {
      return [
        ...sharedControls,
        {
          section: generateContentSectionName("Title"),
          controls: [
            {
              componentPath: "content/ConfigTextInput",
              valuePath: "value",
              valueSource: fields.value.nogames_title.field,
            },
            ...(typography({
              source: fields.value.nogames_title.field,
            }).controls ?? []),
          ],
        },
        {
          section: generateContentSectionName("Description"),
          toggleable: true,
          valueSource: fields.value.nogames_title.field.options.description,
          controls: [
            {
              componentPath: "content/ConfigTextInput",
              valuePath: "options.description.value",
              valueSource: fields.value.nogames_title.field,
            },
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: "options.description.color",
              valueSource: fields.value.nogames_title.field,
              options: {
                placeholder: "Enter text",
                label: "Text color",
                type: ColorPickerType.TEXT,
              },
            },
            {
              componentPath: "ConfigThemeSelect",
              valuePath: "options.description.theme",
              valueSource: fields.value.nogames_title.field,
              options: {
                placeholder: "Enter text",
                label: "Font size",
              },
            },
          ],
        },
      ];
    }
  });

  // game_name
  const gameNameControls = computed(() => {
    return [
      typography({ source: selectedField.value }, "Text"),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  // game_cards
  const gameCardsDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const gameCardsContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "game_cover",
              label: "Game Cover",
            },
            {
              value: "game_name",
              label: "Game Name",
              isActiveValueSource: fields.value.game_name.field.options,
            },
          ],
        },
      },
    ];
  });

  // all_game_card
  const allGameCardDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const allGameCardContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.distance",
            valueSource: selectedField.value,
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.alignment",
            valueSource: selectedField.value,
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "games_button",
              label: "All games button",
            },
          ],
        },
      },
    ];
  });

  // game_cover
  const gameCoverDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const gameCoverContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Image"),
        controls: [
          {
            componentPath: "ConfigCornerRadius",
            valuePath: "options.image.cornerRadius",
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Corner radius",
              isBold: true,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Cover elements layout"),
        controls: [
          {
            componentPath: "common/ConfigCommonRadio",
            valuePath: "options.display.position",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              label: "Position",
              firstVariant: "",
              firstVariantIcon: {
                icon: "custom:unordered-list-outlined",
              },
              firstValue: Alignment.LEFT,
              secondaryVariant: "",
              secondaryVariantIcon: {
                className: "r-180",
                icon: "custom:unordered-list-outlined",
              },
              secondaryValue: Alignment.RIGHT,
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.display.alignment",
            valueSource: selectedField.value,
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.image.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "promo_tag",
              label: "Promo Tag",
              isActiveValueSource: fields.value.promo_tag.field.options,
            },
            {
              value: "favorite_icon",
              label: "Favourite Icon",
              isActiveValueSource: fields.value.favorite_icon.field.options,
            },
          ],
        },
      },
    ];
  });

  const gameCoverStatesControls = computed(() => {
    const sharedControls = [
      {
        componentPath: "common/ConfigStatesList",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
    ];

    if (states.value[selectedField.value!.name] === State.HOVER) {
      return [
        ...sharedControls,
        {
          section: generateDesignSectionName("Fill"),
          controls: [
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: `${selectedElementPath.value}.fill`,
              valueSource: selectedField.value,
              options: {
                label: "Card",
                type: ColorPickerType.BACKGROUND,
              },
            },
            {
              componentPath: "ConfigColorPickerInput",
              valuePath: `${selectedElementPath.value}.overlay`,
              valueSource: selectedField.value,
              options: {
                label: "Blackout",
                type: ColorPickerType.BACKGROUND,
              },
            },
          ],
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${selectedElementPath.value}.border`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Border",
            isBold: true,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${selectedElementPath.value}.shadow`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Shadow",
            isBold: true,
          },
        },
        {
          componentPath: "form/ConfigTabs",
          options: {
            items: [{ label: "Hover content", value: "hover_content" }],
          },
        },
      ];
    } else {
      return [
        ...sharedControls,
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${selectedElementPath.value}.fill`,
          valueSource: selectedField.value,
          options: {
            label: "Fill",
            isBold: true,
            type: ColorPickerType.BACKGROUND,
          },
        },
        {
          componentPath: "ConfigBorder",
          valuePath: `${selectedElementPath.value}.border`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Border",
            isBold: true,
          },
        },
        {
          componentPath: "ConfigBoxShadow",
          valuePath: `${selectedElementPath.value}.shadow`,
          valueSource: selectedField.value,
          options: {
            placeholder: "None",
            label: "Shadow",
            isBold: true,
          },
        },
      ];
    }
  });

  const casinoListFavIconControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioIcon",
        valuePath: "options.default_value",
        valueSource: selectedField.value,
        className: "event-cards-radio border-bottom-none p-t-0",
        options: {
          items: [
            {
              icon: "custom:casino-list-star",
              value: "ant-design:star-outlined",
            },
            {
              icon: "custom:casino-list-heart",
              value: "ant-design:heart-outlined",
            },
            {
              icon: "custom:casino-list-tag",
              value: "ant-design:tag-outlined",
            },
          ],
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.color",
        valueSource: selectedField.value,
        className: "border-bottom-none p-t-0",
        options: {
          label: "Icon color",
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.iconSize",
        valueSource: selectedField.value,
        className: "border-bottom-none p-l-16 p-r-16",
        options: {
          label: "Size",
          minValue: MIN_ICON_SIZE_INPUT_VALUE,
          maxValue: MAX_ICON_SIZE_INPUT_VALUE,
        },
      },
    ];
  });

  // promo_tag
  const casinoListGameTagButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs", "ConfigBoxShadow"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const casinoListGameTagButtonContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            valueSource: selectedField.value,
          },
        ],
      },
    ];
  });

  const casinoListGameTagButtonStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.tag_promo,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.tag_jackpot,
              value: State.JACKPOT,
            },
            {
              label: STATE_KEY_LABELS.tag_new,
              value: State.NEW,
            },
            {
              label: STATE_KEY_LABELS.tag_hot,
              value: State.HOT,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  // hover_content
  const casinoListHoverContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.position",
            valueSource: selectedField.value,
            options: {
              label: "Title position",
              items: [
                {
                  label: "Top",
                  value: TextPosition.TOP,
                },
                {
                  label: "Bottom",
                  value: TextPosition.BOTTOM,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.layout",
            valueSource: selectedField.value,
            options: {
              label: "Buttons layout",
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
        ],
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Game Name Hover",
              value: "game_title",
              isActiveValueSource: fields.value.game_title.field.options,
            },
            {
              label: "Play for money button",
              value: "play_money_button",
              isActiveValueSource: fields.value.play_money_button.field.options,
            },
            {
              label: "Play for free button",
              value: "play_free_button",
              isActiveValueSource: fields.value.play_free_button.field.options,
            },
          ],
        },
      },
    ];
  });

  // game_title
  const casinoListGameTitleControls = computed(() => {
    return [
      typography({ source: selectedField.value }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  // play_money_button
  const casinoListPlayMoneyButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const casinoListPlayMoneyButtonContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            className: "m-t-8",
            valueSource: selectedField.value,
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        withoutSectionLabel: true,
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.buttonDisplaySettings.resizing",
            valueSource: selectedField.value,
            options: {
              label: "Display settings",
              isBold: true,
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
    ];
  });

  const casinoListPlayMoneyButtonStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${selectedElementPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter icon",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  // play_free_button
  const casinoListPlayFreeButtonDesignControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.margins",
            valueSource: selectedField.value,
            options: {
              label: "Margin",
              showTooltip: true,
              isAllSideSetting: true,
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const casinoListPlayFreeButtonContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Text"),
        toggleable: true,
        valueSource: selectedField.value?.options.buttonTextSettings,
        allowDisable: allowDisableFieldsItem(
          FieldContentItem.BUTTON_TEXT_SETTINGS
        ).value,
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            className: "m-t-8",
            valueSource: selectedField.value,
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
        options: {
          isStatesTab: false,
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON_SETTINGS)
            .value,
        },
      },
      {
        withoutSectionLabel: true,
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.buttonDisplaySettings.resizing",
            valueSource: selectedField.value,
            options: {
              label: "Display settings",
              isBold: true,
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            className: "group-control-element--child",
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
    ];
  });

  const casinoListPlayFreeButtonStateControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: selectedField.value!.name, value });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${selectedElementPath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${selectedElementPath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${selectedElementPath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.buttonTextSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${selectedElementPath.value}.iconSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter icon",
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    headerDesignControls,
    headerContentControls,

    titleControls,

    headerButtonDesignControls,
    headerButtonContentControls,
    headerButtonStatesControls,

    gamesListDesignControls,
    gamesListContentControls,

    gameNameControls,

    gameCardsDesignControls,
    gameCardsContentControls,

    allGameCardDesignControls,
    allGameCardContentControls,

    gamesButtonDesignControls,
    gamesButtonContentControls,
    gamesButtonStatesControls,

    gameCoverDesignControls,
    gameCoverContentControls,
    gameCoverStatesControls,

    casinoListFavIconControls,

    casinoListGameTagButtonDesignControls,
    casinoListGameTagButtonContentControls,
    casinoListGameTagButtonStateControls,

    casinoListHoverContentControls,

    casinoListGameTitleControls,

    casinoListPlayMoneyButtonDesignControls,
    casinoListPlayMoneyButtonContentControls,
    casinoListPlayMoneyButtonStateControls,

    casinoListPlayFreeButtonDesignControls,
    casinoListPlayFreeButtonContentControls,
    casinoListPlayFreeButtonStateControls,
  };
};
