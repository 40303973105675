import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { ICellDimensionValue, CellSizeType } from "~~/models/grid.interface";

export enum ControlSizing {
  FIXED = "fixed",
  ADAPTIVE = "adaptive",
}

export enum SliderColumns {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

export enum SliderTransition {
  ALL = "All",
  BY_ONE = "ByOne",
}

export enum SnapAlignment {
  START = "start",
  CENTER = "center",
  END = "end",
}

export enum Overflow {
  INITIAL = "initial",
  AUTO = "auto",
  HIDDEN = "hidden",
  VISIBLE = "visible",
  SCROLL = "scroll",
  CLIP = "clip",
  ARROWS = "arrows",
}

export enum SliderItemsPosition {
  INSIDE = "inside",
  OUTSIDE = "outside",
}

export enum SliderArrowIcon {
  OUTLINED = "ant-design:left-outlined",
  ARROW = "ant-design:arrow-left-outlined",
  CARET = "ant-design:caret-left-outlined",
}

export enum Align {
  START = "flex-start",
  MIDDLE = "center",
  END = "flex-end",
}

export enum Alignment {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export enum VerticalPosition {
  TOP = "top",
  BOTTOM = "bottom",
  CENTER = "center",
}

export enum TextPosition {
  TOP = "top",
  LEFT = "left",
  RIGHT = "right",
  BOTTOM = "bottom",
}

export enum TextStyle {
  BOLD = "bold",
  ITALIC = "italic",
  UNDERLINE = "underline",
  STRIKE = "strike",
  LINE_THROUGH = "line-through",
}

export enum SpacingInput {
  LEFT = "left",
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
}

export interface ISpacingInput {
  left: string | number;
  top: string | number;
  right: string | number;
  bottom: string | number;
}

export interface IOverflow {
  overflowX: Overflow;
  overflowY: Overflow;
}

export interface ISpacingControl {
  label: string;
  value: SpacingInput;
  icon: string;
  iconClassName?: string;
}

export interface IVerticalSpacingInput {
  top: string | number;
  bottom: string | number;
}

export enum VerticalAlignment {
  TOP = "flex-start",
  MIDDLE = "center",
  BOTTOM = "flex-end",
}

export enum LinkType {
  EXTERNAL = "external",
  MODAL = "modal",
  PHONE = "phone",
  EMAIL = "email",
  TRIGGER = "trigger",
}

export enum TriggerValue {
  COEFFICIENT_CHANGE_ACCEPTING = "coefficient_change_accepting",
}

export type TextPositionComplex = {
  position: TextPosition | null;
  scale: string | number | null;
  alignment: VerticalAlignment | null;
  horizontalAlignment?: string;
};

export type LayoutPositionComplex = {
  position: TextPosition | null;
  alignment: VerticalAlignment | null;
  horizontalAlignment?: Alignment;
};

export enum SpacingKeyName {
  MARGIN = "margin",
  PADDING = "padding",
}

export enum ResizingType {
  FIXED = "fixed",
  FILL = "fill",
  FIT = "fit",
  HUG = "hug",
  WRAP = "wrap",
}

export enum Display {
  NONE = "none",
  BLOCK = "block",
  INLINE = "inline",
  INLINE_BLOCK = "inline-block",
  FLEX = "flex",
  INLINE_FLEX = "inline-flex",
  GRID = "grid",
  INLINE_GRID = "inline-grid",
  FLOW_ROOT = "flow-root",
  TABLE = "table",
  TABLE_ROW = "table-row",
  TABLE_CELL = "table-cell",
  TABLE_CAPTION = "table-caption",
  TABLE_COLUMN = "table-column",
  TABLE_COLUMN_GROUP = "table-column-group",
  TABLE_FOOTER_GROUP = "table-footer-group",
  TABLE_HEADER_GROUP = "table-header-group",
  TABLE_ROW_GROUP = "table-row-group",
  LIST_ITEM = "list-item",
  RUN_IN = "run-in",
  RUBY = "ruby",
  RUBY_BASE = "ruby-base",
  RUBY_TEXT = "ruby-text",
  RUBY_BASE_CONTAINER = "ruby-base-container",
  RUBY_TEXT_CONTAINER = "ruby-text-container",
  CONTENTS = "contents",
  INITIAL = "initial",
  INHERIT = "inherit",
  UNSET = "unset",
  REVERT = "revert",
}

export enum DisplayOrientation {
  WRAP = "wrap",
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export enum ContainerMode {
  FLEX = "flex",
  GRID = "grid",
  CAROUSEL = "carousel",
}

export enum FlexDirection {
  ROW = "row",
  ROW_REVERSE = "row-reverse",
  COLUMN = "column",
  COLUMN_REVERSE = "column-reverse",
}

export enum BorderStyle {
  SOLID = "solid",
  DOTTED = "dotted",
}

export enum BorderStrokeType {
  DEPENDENT = "dependent",
  CUSTOM = "custom",
}

export interface IFillControl {
  color: string | null;
  opacity: number | string;
}

export interface IBorderWidthComplex {
  left: number | string | null;
  right: number | string | null;
  bottom: number | string | null;
  top: number | string | null;
}

export interface ICornerValueComplex {
  topLeft: number | string | null;
  topRight: number | string | null;
  bottomLeft: number | string | null;
  bottomRight: number | string | null;
}

export interface IBorderStroke {
  width: IBorderWidthComplex | number | string | null;
  type: BorderStrokeType;
}
export interface IBorderControl {
  fill: IFillControl;
  style: BorderStyle | null;
  stroke: IBorderStroke;
}

export interface ICornerRadiusControl {
  type: BorderStrokeType;
  value: ICornerValueComplex | number | string | null;
}

export interface IShadowControl {
  fill: IFillControl;
  x: number | string | null;
  y: number | string | null;
  blur: number | string | null;
  spread: number | string | null;
}

export interface ITextShadowControl {
  fill: IFillControl;
  x: number | string | null;
  y: number | string | null;
  blur: number | string | null;
}

export interface IImagePosition {
  x: string | null;
  y: string | null;
  value: string | null;
}

export interface Link {
  type: LinkType | null;
  value: Record<string, string> | null;
  authorizeValue: Record<string, string> | null;
  hasAuthorizeValue: boolean;
  isNewTabOpen: boolean | null;
  states?: { [key in State]: IWidgetOptions };
}

export enum ListStyle {
  NONE = "none",
  LIST = "list",
  ORDERED = "ordered",
}

export enum TipTapListType {
  BULLET = "bulletList",
  ORDERED = "orderedList",
}

export type SimpleControlValue = string | number | undefined;
export type SimpleStringControlValue = string | undefined;

export enum GlobalFieldControl {
  DISPLAY_SETTINGS = "displaySettings",
  TYPOGRAPHY = "typography",
}

export enum CustomLinkTypeValue {
  EMAIL = "mailto:",
  PHONE = "tel:",
  HTTPS = "https://",
  HTTP = "http://",
}

export enum State {
  DEFAULT = "default",
  HOVER = "hover",
  ACTIVE = "active",
  ACTIVE_CURRENT_PAGE = "active_current_page",
  DISABLED = "disabled",
  INACTIVE = "inactive",
  MODAL = "modal",

  AUTHORIZED = "authorized",
  UNAUTHORIZED = "unauthorized",

  FORM_DEFAULT = "form",
  FORM_SUCCESS = "success",
  FORM_EXPIRED = "expired",

  SELECTED_DEFAULT = "selected_default",
  SELECTED_HOVER = "selected_hover",
  SELECTED_DISABLED = "selected_disabled",
  UNSELECTED_DEFAULT = "unselected_default",
  UNSELECTED_HOVER = "unselected_hover",
  UNSELECTED_DISABLED = "unselected_disabled",

  BETSLIP = "betslip",
  MY_BETS = "my_bets",
  BETS_RESULT = "bets_result",
  NO_BETS = "NO_BETS",
  BOOKING_CODE = "booking_code",
  LOADER = "LOADER",

  ACTIVE_BETS = "active_bets",
  INACTIVE_BETS = "inactive_bets",

  CASINO_LIST = "casino_list",
  CASINO_SEARCH = "casino_search",

  TRANSACTION_HISTORY = "transaction_history",
  BETTING_HISTORY = "betting_history",
  PENDING_WITHDRAWALS = "pending_withdrawals",

  UP = "up",
  DOWN = "down",

  SINGLE_BETS = "single_bets",
  MULTI_BETS = "multi_bets",
  SYSTEM_BETS = "system_bets",

  ACTIVE_EVENTS = "active_events",
  NO_ACTIVE = "no_active",

  WITH_ACTIVE_EVENTS = "with_active_events",
  NO_ACTIVE_EVENTS = "no_active_events",

  PROMO = "tag_promo",
  JACKPOT = "tag_jackpot",
  NEW = "tag_new",
  HOT = "tag_hot",

  WITH_ACTIVE_GAMES = "with_active_games",
  NO_ACTIVE_GAMES = "no_active_games",

  WITH_FAVORITE_BETS = "with_favorite_bets",
  NO_FAVORITE_BETS = "no_favorite_bets",

  WITH_POPULAR_LEAGUES = "with_popular_leagues",
  NO_POPULAR_LEAGUES = "no_popular_leagues",

  SPORTSBOOK_SPORT = "sport",
  SPORTSBOOK_COUNTRY = "country",
  SPORTSBOOK_LEAGUE = "league",
  SPORTSBOOK_TOP_LEAGUES = "top_leagues",
  SPORTSBOOK_ALL_LEAGUES = "all_leagues",
  SPORTSBOOK_TABS_EVENTS = "events",
  SPORTSBOOK_TABS_LEAGUE_WINNER = "leagueWinner",

  PROVIDERS_EXIST = "providers_exist",
  NO_PROVIDERS = "no_providers",
  INSUFFICIENT_PERSONAL_DATA = "insufficient_personal_data",

  PROMOTIONS_ALL_GROUPS = "all_groups",
  PROMOTIONS_ONE_GROUP = "one_group",

  PROMO_EXIST = "promo_exist",
  PROMO_NOT_EXIST = "promo_not_exist",

  SMALL_SIZE = "small_size",
  BIG_SIZE = "big_size",
  WINNER = "winner",

  NOT_RUNNER = "not_runner",

  COEFFICIENT_CHANGE = "coefficient_change",
  FILTERED = "filtered",
}

export enum StateTarget {
  BUTTON = "button",
  WIDGET = "widget",
  LINK = "link",
}

export enum StateControlType {
  TEXT_COLOR = "textColor",
  ICON_COLOR = "iconColor",
  FILL = "fill",
  FILL_IMAGE_COLOR = "fillImageColor",
  BORDER = "border",
  SHADOW = "shadow",
  DECORATION = "decoration",
}

export interface IFormSettingsSpacing {
  margin: IVerticalSpacingInput;
  padding: ISpacingInput;
}

export type ConfigTabItem = {
  label: string;
  value: string;
  isActiveValueSource?: any;
  states?: any;
  customDropdownValue?: string;
  allowDisable?: boolean;
  hideSettingsIcon?: boolean;
};

export enum FillType {
  COLOR = "color",
  IMAGE = "image",
}

export interface IGridSettingsControl {
  cardsSize: string | number;
  alignment: string;
  minCardsGap: string | number;
  rowGap: string | number;
}

export type MinMaxMode = "min" | "max" | "min_max";

export type MinMaxDimension = {
  _active: boolean;
  min: ICellDimensionValue | null;
  max: ICellDimensionValue | null;
  mode: MinMaxMode | null;
};

export type WidgetDimension = {
  value?: ICellDimensionValue;
  type?: CellSizeType | ResizingType;
  minMax?: MinMaxDimension;
};

export type WidgetSize = {
  width?: WidgetDimension;
  height?: WidgetDimension;
};

export type HeadingType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export interface IHeadingControl {
  type: HeadingType | string | undefined;
  value: SimpleControlValue;
}

export enum SportMenuLiveBet9CardLayoutMode {
  FIRST = "first",
  SECOND = "second",
}

export enum Grid {
  FIT = "auto-fit",
  FILL = "auto-fill",
  FIXED = "fixed",
}

export enum JustifyPosition {
  STRETCH = "stretch",
  START = "start",
  END = "end",
  CENTER = "center",
  SPACE_BETWEEN = "space-between",
  SPACE_AROUND = "space-around",
  SPACE_EVENLY = "space-evenly",
}

export enum JustifyContent {
  CENTER = "center",
  START = "start", // Grid-specific
  END = "end", // Grid-specific
  FLEX_START = "flex-start", // Flex-specific
  FLEX_END = "flex-end", // Flex-specific
  LEFT = "left", // Grid-specific
  RIGHT = "right", // Grid-specific

  NORMAL = "normal",

  STRETCH = "stretch",
  SPACE_BETWEEN = "space-between", // Flex-specific
  SPACE_AROUND = "space-around", // Flex-specific
  SPACE_EVENLY = "space-evenly", // Flex-specific
}

export enum JustifyItems {
  NORMAL = "normal",
  STRETCH = "stretch",

  CENTER = "center",
  START = "start",
  END = "end",
  FLEX_START = "flex-start",
  FLEX_END = "flex-end",
  SELF_START = "self-start",
  SELF_END = "self-end",
  LEFT = "left",
  RIGHT = "right",
  ANCHOR_CENTER = "anchor-center",

  BASELINE = "baseline",
}

export enum AlignContent {
  NORMAL = "normal",

  START = "start",
  CENTER = "center",
  END = "end",

  FLEX_START = "flex-start",
  FLEX_END = "flex-end",

  BASELINE = "baseline",

  SPACE_BETWEEN = "space-between",
  SPACE_AROUND = "space-around",
  SPACE_EVENLY = "space-evenly",
  STRETCH = "stretch",
}

export enum AlignItems {
  NORMAL = "normal",
  STRETCH = "stretch",

  CENTER = "center",
  START = "start", // Grid-specific
  END = "end", // Grid-specific

  FLEX_START = "flex-start", // Flex-specific
  FLEX_END = "flex-end", // Flex-specific

  SELF_START = "self-start", // Grid-specific
  SELF_END = "self-end", // Grid-specific
  ANCHOR_CENTER = "anchor-center", // Grid-specific

  BASELINE = "baseline", // Flex-specific
}

export type LayoutAlignment = {
  justifyContent: JustifyContent | null;
  alignContent?: AlignContent | null;
  justifyItems?: JustifyItems | null;
  alignItems: AlignItems | null;
};
