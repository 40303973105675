import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import {
  generateArrows,
  generatePagination,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/promo-bets-fenix-widget-css";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex } from "../helpers";
import { getFlexAlign } from "../../widget-settings";
import { getPxValueFromNumber } from "../..";

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    const isVertical = options.display.layout === DisplayOrientation.VERTICAL;
    const isWrap = options.display.layout === DisplayOrientation.WRAP;
    const alignment = getFlexAlign(
      options.display?.alignment,
      options.display.layout
    );

    const widgetStyles = generateFlex({
      flex: "flex",
      align: alignment["align-items"],
      justify: alignment["justify-content"],
      direction: isVertical ? "column" : "row",
      wrap: isWrap ? "wrap" : "nowrap",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options) +
        `overflow-x: ${options.display.horizontalOverflow};` +
        `overflow-y: ${options.display.verticalOverflow};` +
        "position:relative;",
    });

    cssString += generateCssClassWithContent({
      className: options._contentCssClass,
      content:
        widgetStyles +
        `column-gap: ${getPxValueFromNumber(options.display.horizontalGap)};` +
        `row-gap: ${getPxValueFromNumber(options.display.horizontalGap)};`,
    });

    cssString += generateCssClassWithContent({
      className: options._wrapperCssClass,
      content:
        `column-gap: ${getPxValueFromNumber(options.display.horizontalGap)};` +
        `row-gap: ${getPxValueFromNumber(options.display.horizontalGap)};`,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .ssr-carousel-mask",
      content: generateFlex({
        flex: "flex",
        align: alignment["align-items"],
        justify: alignment["justify-content"],
      }),
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .ssr-carousel-track",
      content: "height: 100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .base-carousel-slide",
      content: generateFlex({
        flex: "flex",
        align: alignment["align-items"],
        justify: alignment["justify-content"],
      }),
    });

    return cssString;
  };

export const generateCarouselCssString =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .ssr-carousel",
      content: "height: 100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .ssr-carousel-slides",
      content: "height: 100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .ssr-carousel-mask",
      content: "height: 100%;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .base-ssr-carousel2",
      content: "position: static;",
    });

    return cssString;
  };

export const generateContainerWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  widget.options._contentCssClass = generateClassName(`${widget.name}Content`);
  widget.options._wrapperCssClass = generateClassName(
    `${widget.name}ContentWrapper`
  );

  const arrows = widget.content["arrows"];
  arrows.options._cssClass = generateClassName("arrows");
  arrows.options._containerCssClass = generateClassName("arrowsContainer");
  arrows.options.button._cssClass = generateClassName("arrowsButton");

  const pagination = widget.content["pagination"];
  pagination.options._cssClass = generateClassName("pagination");
  pagination.options._dotsCssClass = generateClassName("paginationDots");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateCarouselCssString(widget.options),
    generateArrows(arrows),
    generatePagination(pagination)
  )("");
};
