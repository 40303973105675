<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <PopularLeaguesWidgetSettings
      v-if="selectedWidget?.id === widget.id"
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="popular-leagues-widget">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
        class="popular-leagues-widget__title-field"
      >
        <BasicTitleField :field="fields.title.field" />
      </WidgetField>

      <div v-if="states.widget === State.DEFAULT">
        <WidgetField
          :widget="widget"
          :field="fields.menu_level_1.field"
          class="popular-leagues-widget__menu-level-1-field"
        >
          <div
            class="popular-leagues-widget__menu-level-1-item"
            :style="itemsStyles"
          >
            <div
              v-for="item in 3"
              :key="item"
              :style="elementsStyles"
              class="popular-leagues-widget__menu-level-1-element"
            >
              <CommonIcon
                v-if="fields.menu_level_1.field.options.itemIcon._active"
                :style="itemIconStyles"
                name="custom:sport-icon"
              />
              <span class="popular-leagues-widget__menu-level-1-title"
                >Sport</span
              >
            </div>
          </div>
        </WidgetField>
      </div>

      <BasicTitleField
        v-else
        :field="fields.no_events_message.field"
      />
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useStates } from "~~/composables/widgets/common/useStates";
import { usePopularLeaguesWidgetStyles } from "~~/composables/widgets/popular-leagues/usePopularLeaguesWidgetStyles";
import { usePopularLeaguesWidgetInit } from "~~/composables/widgets/popular-leagues/usePopularLeaguesWidgetInit";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { fields, states, DEFAULT_STATES, init } = usePopularLeaguesWidgetInit(
  ref(props.widget)
);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const { itemsStyles, elementsStyles, itemIconStyles } =
  usePopularLeaguesWidgetStyles(props.widget, states);

const widgetSettingsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetSettingsStore);

init();
</script>

<style lang="scss">
.popular-leagues-widget {
  svg {
    width: 100%;
    height: 100%;
  }

  &__menu-level-1-title {
    width: 100%;
  }
}
</style>
