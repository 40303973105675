import { CSSProperties } from "vue";

import {
  clearObject,
  useDesignInitialStyles,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  Display,
  FlexDirection,
} from "~~/models/widgets/widget-controls.model";

export const useBonusBlockStyles = (
  bonusBlockOptions: any = {}
): CSSProperties => {
  const styles = {
    ...useDesignInitialStyles(bonusBlockOptions),

    display: Display.FLEX,
    flexDirection: FlexDirection.COLUMN,
    gap: bonusBlockOptions.gap
      ? getPxValueFromNumber(bonusBlockOptions.gap)
      : undefined,
  };

  return clearObject(styles);
};
