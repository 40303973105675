<template>
  <DNDContainer
    id="drag-n-drop-container"
    :container-id="DndContainerId.GRID"
    show-divider
    :divider-position="dividerPosition"
    @drop="$emit('drop', $event)"
    @over="$emit('over', $event)"
    @enter="$emit('enter', $event)"
    @update-container-position="$emit('update-container-position', $event)"
  >
    <section class="workspace">
      <div
        id="workspace"
        :class="['workspace__inner', `workspace__inner--${activeScreen}`]"
      >
        <div class="workspace__grid">
          <div
            v-for="(cell, index) in currentLayout"
            :key="cell.cellId"
            class="workspace__row"
          >
            <GridCell
              :cell="cell"
              :columns="cell.children.length"
              :container-sequence-index="index"
            />
          </div>
        </div>
      </div>
    </section>
  </DNDContainer>
</template>

<script setup lang="ts">
import { useGridConfig } from "~~/store/grid";
import { IDividerPosition, IClientBoundingRect } from "~~/models/dnd.model";
import { DndContainerId } from "~~/models/common/dropdown.model";
import DNDContainer from "~~/components/drag-n-drop/DNDContainer.vue";
import GridCell from "~~/components/grid/GridCell.vue";
import { useUiStore } from "~~/store/ui";

defineEmits<{
  (e: "drop", event: DragEvent): void;
  (e: "over", event: DragEvent): void;
  (e: "enter", event: DragEvent): void;
  (e: "update-container-position", event: IClientBoundingRect): void;
}>();

const { currentLayout, dividerData } = storeToRefs(useGridConfig());
const { activeScreen } = storeToRefs(useUiStore());

const dividerPosition = computed<IDividerPosition>(() => {
  return {
    top: dividerData.value.top,
    left: dividerData.value.left,
    width: dividerData.value.width,
  };
});
</script>

<style lang="scss">
$padding: 12px;
@mixin maxWidth($value) {
  max-width: calc($value + $padding * 2 - 1px);
}

.workspace {
  display: flex;

  &__inner {
    margin: 0 auto;
    min-height: calc(100vh - 116px);
    padding: 20px $padding $space-l;
    background-color: $c-white;

    &--mob,
    &--mob-app,
    &--bot {
      flex: 0 1 $sm-larger + $padding * 2;
      @include maxWidth($md);
    }

    &--tab {
      flex: 0 1 $md-larger + $padding * 2;
      @include maxWidth($lg);
    }

    &--desktop {
      display: flex;
      flex: 0 1 calc(100vw - 288px - 288px);
      min-width: calc($lg + 24px);
      overflow: auto;
    }
  }

  &__grid {
    flex-grow: 1;
    flex-wrap: wrap;
    @include flex-column(center, flex-start, 20px);
  }

  &__row {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
