import {
  FillType,
  State,
  DisplayOrientation,
  TextStyle,
  Alignment,
  ResizingType,
  Overflow,
} from "~~/models/widgets/widget-controls.model";

import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultFontFamily,
  getDefaultSpacing,
} from "../common/widget-initial";

export const TITLE_INITIAL = () => ({
  value: "Enter value",
  options: {
    _active: true,
    theme: 24,
    fontFamily: getDefaultFontFamily(),
    decoration: [],
    padding: getDefaultSpacing(),
    color: {
      color: "#000000",
      opacity: 100,
    },
    link: {
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      type: null,
      isNewTabOpen: false,
      states: {
        [State.HOVER]: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [],
        },
        [State.ACTIVE]: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [],
        },
      },
    },
  },
});

export const ITEMS_GROUP_INITIAL = () => ({
  options: {
    _active: true,
    fillImageColor: {
      fillType: FillType.COLOR,
      value: { color: "", opacity: 100 },
    },
    border: getDefaultBorderValue(),
    overflow: Overflow.AUTO,
    shadow: {
      fill: {
        color: "",
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(0),
    padding: {
      top: 4,
      bottom: 4,
      right: 12,
      left: 12,
    },
    margins: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    spaceBetween: 1,
    itemsText: {
      _active: true,
      color: {
        color: "#000000",
        opacity: 100,
      },
      theme: 13,
      fontFamily: getDefaultFontFamily(),
      decoration: [TextStyle.BOLD],
    },
    itemsIcon: {
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      iconSize: 16,
      gap: 5,
      position: "right",
    },
    displayOrientation: DisplayOrientation.VERTICAL,
    states: {
      [State.HOVER]: {
        fillImageColor: {
          fillType: FillType.COLOR,
          value: { color: "", opacity: 100 },
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        itemsText: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        itemsIcon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
      [State.ACTIVE]: {
        fillImageColor: {
          fillType: FillType.COLOR,
          value: { color: "", opacity: 100 },
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        itemsText: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        itemsIcon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
    },
  },
});

export const COMING_EVENTS_ITEMS_GROUP_INITIAL = () => ({
  options: {
    _active: true,
    fill: {
      color: "#FFFFFF",
      opacity: 100,
    },
    border: getDefaultBorderValue(),
    shadow: {
      fill: {
        color: "",
        opacity: 100,
      },
      x: 0,
      y: 0,
      blur: 0,
      spread: 0,
    },
    cornerRadius: getDefaultCornerRadiusValue(0),
    padding: {
      top: 4,
      bottom: 4,
      right: 12,
      left: 12,
    },
    margins: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    spaceBetween: 1,
    itemsText: {
      _active: true,
      color: {
        color: "#000000",
        opacity: 100,
      },
      theme: 13,
      fontFamily: getDefaultFontFamily(),
      decoration: [TextStyle.BOLD],
    },
    itemsIcon: {
      textColor: {
        color: "#000000",
        opacity: 100,
      },
      iconSize: 16,
      gap: 5,
      position: "right",
    },
    displayOrientation: DisplayOrientation.HORIZONTAL,
    alignment: Alignment.LEFT,
    resizing: ResizingType.HUG,
    states: {
      [State.HOVER]: {
        fill: {
          color: "#FFFFFF",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        itemsText: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        itemsIcon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
      [State.ACTIVE]: {
        fill: {
          color: "#FFFFFF",
          opacity: 100,
        },
        border: getDefaultBorderValue(),
        shadow: {
          fill: {
            color: "",
            opacity: 100,
          },
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        itemsText: {
          color: {
            color: "#000000",
            opacity: 100,
          },
          decoration: [TextStyle.BOLD],
        },
        itemsIcon: {
          textColor: {
            color: "#000000",
            opacity: 100,
          },
        },
      },
    },
  },
});

export const ITEM_INITIAL = () => ({
  options: {
    _active: true,
    text: {
      _active: true,
    },
    icon: {
      _active: false,
      value: "ant-design:arrow-right-outlined",
    },
    link: {
      value: null,
      authorizeValue: null,
      hasAuthorizeValue: false,
      type: null,
      isNewTabOpen: false,
    },
  },
});
