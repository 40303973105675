import { IPageContentWidget } from "~~/models/page.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { createContentWidget } from "~~/assets/utils/widget";

export const useCssGenerator = (
  generator: (widget: IPageContentWidget) => string,
  widget: IWidgetWithFields
) => {
  const generatedStyles = ref<string>("");
  const hasGeneratedStyles = computed(() => {
    return Boolean(generatedStyles.value);
  });

  const key = `widget-${widget.name}-${widget.id}`;

  const generate = () => {
    generatedStyles.value = generator(
      createContentWidget(widget, String(widget.cell_id))
    );
  };

  const append = () => {
    const styleId = `generated-styles-${key}`;

    let style = document.getElementById(styleId);
    if (!style) {
      style = document.createElement("style");
      style.id = styleId;
      document.body.appendChild(style);
    }
    style.textContent = formatCSS(generatedStyles.value);
  };

  (window as any).cssGenerators = {
    [key]: () => {
      generate();
      append();

      return generatedStyles.value;
    },
  };

  function formatCSS(cssString: string) {
    return cssString
      .replace(/\s*{\s*/g, " {\n\t")
      .replace(/;\s*/g, ";\n\t")
      .replace(/\s*}\s*/g, "\n}\n")
      .replace(/\n\t\n/g, "\n")
      .trim();
  }

  return {
    generatedStyles,
    hasGeneratedStyles,
  };
};
