<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
    class="welcome-bonus-fenix-widget"
  >
    <WelcomeBonusFenixWidgetSettings
      :widget="widget"
      :state-controller="stateController"
    />

    <WelcomeBonusFenixWidgetMarkup
      :widget="widget"
      :fields="fields"
      :state-controller="stateController"
      :has-generated-styles="hasGeneratedStyles"
    />
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";

import { useWelcomeBonusFenixWidgetStateController } from "./composables/useWelcomeBonusFenixWidgetStateController";
import { generateWelcomeBonusFenixWidgetCssString } from "./welcome-bonus-fenix-widget-css";
import { useCssGenerator } from "./composables/useCssGenerator";
import { useWelcomeBonusFenixWidgetInit } from "./composables/useWelcomeBonusFenixWidgetInit";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const widget = toRef(props.widget);

const { init, fields } = useWelcomeBonusFenixWidgetInit(widget);
init();

const { stateController } = useWelcomeBonusFenixWidgetStateController();

const { hasGeneratedStyles } = useCssGenerator(
  generateWelcomeBonusFenixWidgetCssString,
  props.widget
);
</script>

<style lang="scss">
.welcome-bonus-fenix-widget {
  position: relative;
}
</style>
