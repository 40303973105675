<template>
  <div
    v-if="hasDesignPermissions"
    class="config-display-grid-settings widget-control-container"
  >
    <ConfigRadioTextElements
      :model-value="modelValue.grid"
      :items="[
        { label: 'Fit', value: Grid.FIT },
        { label: 'Fill', value: Grid.FILL },
        { label: 'Fixed', value: Grid.FIXED },
      ]"
      class=""
      label="Grid type"
      @update:model-value="handleUpdate('grid', $event)"
    />

    <ConfigRadioTextElements
      v-if="modelValue.grid === Grid.FIXED"
      :model-value="modelValue.gridFixedType"
      :items="[
        { label: 'Fit', value: Grid.FIT },
        { label: 'Fill', value: Grid.FILL },
      ]"
      label="Fixed resizing type"
      @update:model-value="handleUpdate('gridFixedType', $event)"
    />

    <ConfigNumberInput
      :model-value="modelValue.minCardWidth"
      label="Minimum card width"
      suffix-content="px"
      @update:model-value="handleUpdate('minCardWidth', $event)"
    />

    <template v-if="modelValue.grid === Grid.FIXED">
      <template v-if="typeof modelValue.gap === 'number'">
        <ConfigNumberInput
          :model-value="modelValue.gap"
          label="Distance between cards"
          suffix-content="px"
          @update:model-value="handleUpdate('gap', $event)"
        />

        <ConfigNumberInput
          :model-value="modelValue.rowGap"
          label="Distance between rows"
          suffix-content="px"
          @update:model-value="handleUpdate('rowGap', $event)"
        />
      </template>

      <template v-else>
        <ConfigGapSpace
          :model-value="modelValue.gap"
          label="Gap"
        />
      </template>

      <ConfigRangeSlider
        :model-value="modelValue.maxCardsInRow"
        :min-value="1"
        :max-value="10"
        label="Max. number of cards in a row"
        class="p-b-8 p-l-16 p-r-16 p-t-16"
        @update:model-value="handleUpdate('maxCardsInRow', $event)"
      />

      <ConfigRangeSlider
        :model-value="modelValue.minCardsInRow"
        :min-value="1"
        :max-value="modelValue.maxCardsInRow"
        label="Min. number of cards in a row"
        class="p-l-16 p-r-16"
        @update:model-value="handleUpdate('minCardsInRow', $event)"
      />
    </template>

    <!-- <ConfigAlignment
      v-if="modelValue.grid !== Grid.FIXED"
      :model-value="modelValue.alignment"
      :is-text-content-icons="false"
      @update:model-value="handleUpdate('alignment', $event)"
    /> -->
  </div>
</template>

<script lang="ts" setup>
import { Alignment, Grid } from "~~/models/widgets/widget-controls.model";
import { usePermissions } from "~~/composables/permissions/usePermissions";

const props = defineProps<{
  modelValue: {
    grid: Grid;
    gridFixedType: Grid;
    minCardWidth: number;
    gap: number | Record<string, string>;
    rowGap?: number;
    maxCardsInRow: number;
    minCardsInRow: number;
    alignment: Alignment;
  };
}>();

const emit = defineEmits<{
  (
    e: "update:modelValue",
    event: {
      [key: string]:
        | { [key: string]: string }
        | string
        | number
        | Alignment
        | Grid;
    }
  ): void;
  (e: "update:active", event: boolean): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdate = (key: string, value: string) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  });
};
</script>

<style lang="scss"></style>
