import { Ref } from "vue";

import {
  AlignItems,
  Alignment,
  JustifyContent,
  ResizingType,
  State,
  TextPosition,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSizeValue,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { DESCRIPTION_INITIAL } from "~~/composables/widgets/text/useTextWidgetConfig";
import { PROMO_BUTTON_INITIAL } from "~~/constants/configs/promotions-list/initial-constants";

import noBonusImage from "../images/no-bonus-image.svg?url";

export const useWelcomeBonusFenixWidgetInit = (
  widget: Ref<IWidgetWithFields>
) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions([], {
    gap: 20,
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions("bonus_block", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          gap: 20,
        },
      },
    });

    addFieldInitialOptions("bonus", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          gap: 0,
        },
      },
    });

    addFieldInitialOptions("bonus_header", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#E6F4FF"),
        spacing: {
          padding: getDefaultSpacing(16),
          margin: getDefaultSpacing(0),
        },
      },
      additionalData: {
        options: {
          layoutPosition: {
            position: TextPosition.RIGHT,
          },
          layoutAlignment: `${AlignItems.CENTER} ${JustifyContent.FLEX_START}`,
          gap: 12,
          states: {
            [State.HOVER]: {
              fill: getDefaultFillValue(),
              border: getDefaultBorderValue(),
              shadow: getDefaultShadow(),
            },
            [State.ACTIVE]: {
              fill: getDefaultFillValue(),
              border: getDefaultBorderValue(),
              shadow: getDefaultShadow(),
            },
          },
        },
      },
    });

    addFieldInitialOptions("bonus_image", {
      data: FLEXIBLE_IMAGE_INITIAL(),
      values: {
        containerSize: {
          width: {
            type: ResizingType.FIXED,
            value: 36,
          },
          height: {
            type: ResizingType.FIXED,
            value: 36,
          },
        },
        resizing: {
          type: ResizingType.FILL,
          position: "center center",
          x: 50,
          y: 50,
        },
      },
    });

    addFieldInitialOptions("bonus_title", {
      data: TITLE_INITIAL(),
      exclude: ["link", "padding"],
      values: {
        theme: getDefaultTheme(18),
        decoration: [TextStyle.BOLD],
      },
      additionalData: {
        options: {
          states: {
            [State.HOVER]: {
              color: getDefaultFillValue(),
            },
            [State.ACTIVE]: {
              color: getDefaultFillValue(),
            },
          },
        },
      },
    });

    addFieldInitialOptions("bonus_content", {
      data: {
        options: {
          ...DESIGN_INITIAL().options,
          ...TITLE_INITIAL().options,
        },
      },
      exclude: ["link", "padding"],
      values: {
        fill: getDefaultFillValue("#E6F4FF"),
        spacing: {
          padding: getDefaultSpacing({
            top: 0,
            right: 16,
            bottom: 16,
            left: 16,
          }),
          margin: getDefaultSpacing(0),
        },
        theme: getDefaultTheme(16),
      },
    });

    addFieldInitialOptions("no_bonus", {
      data: DESIGN_INITIAL(),
      values: {
        fill: getDefaultFillValue("#E6F4FF"),
        spacing: {
          padding: getDefaultSpacing(16),
          margin: getDefaultSpacing(0),
        },
      },
      additionalData: {
        options: {
          layoutPosition: {
            position: TextPosition.RIGHT,
          },
          layoutAlignment: `${AlignItems.CENTER} ${JustifyContent.FLEX_START}`,
          gap: 12,
          states: {
            [State.HOVER]: {
              fill: getDefaultFillValue(),
              border: getDefaultBorderValue(),
              shadow: getDefaultShadow(),
            },
            [State.ACTIVE]: {
              fill: getDefaultFillValue(),
              border: getDefaultBorderValue(),
              shadow: getDefaultShadow(),
            },
          },
        },
      },
    });

    addFieldInitialOptions(
      "no_bonus_image",
      {
        data: FLEXIBLE_IMAGE_INITIAL(),
        values: {
          containerSize: {
            width: {
              type: ResizingType.FIXED,
              value: 36,
            },
            height: {
              type: ResizingType.FIXED,
              value: 36,
            },
          },
          resizing: {
            type: ResizingType.FILL,
            position: "center center",
            x: 50,
            y: 50,
          },
        },
      },
      { value: noBonusImage }
    );

    addFieldInitialOptions(
      "no_bonus_title",
      {
        data: TITLE_INITIAL(),
        exclude: ["link", "padding"],
        values: {
          theme: getDefaultTheme(18),
          decoration: [TextStyle.BOLD],
        },
        additionalData: {
          options: {
            states: {
              [State.HOVER]: {
                color: getDefaultFillValue(),
              },
              [State.ACTIVE]: {
                color: getDefaultFillValue(),
              },
            },
          },
        },
      },
      { value: "No Bonus" }
    );

    addFieldInitialOptions(
      "description",
      {
        data: DESCRIPTION_INITIAL(),
      },
      { value: "Description" }
    );

    addFieldInitialOptions("button", {
      data: PROMO_BUTTON_INITIAL(),
      values: {
        spacing: {
          padding: getDefaultSpacing({
            top: 12,
            right: 32,
            bottom: 12,
            left: 32,
          }),
        },
        buttonDisplaySettings: {
          resizing: getDefaultSizeValue(ResizingType.HUG),
          alignment: Alignment.LEFT,
        },
        buttonTextSettings: {
          _active: true,
          textColor: {
            color: "#ffffff",
            opacity: 100,
          },
          theme: 16,
          fontFamily: getDefaultFontFamily(),
          decoration: [TextStyle.BOLD],
        },
      },
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    init,
  };
};
