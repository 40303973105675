export const BASE_API_URL = () => window?._APP_ENV?.VITE_BASE_API_URL;
export const BASE_MEDIA_URL = () => window?._APP_ENV?.VITE_BASE_MEDIA_URL;
export const PROJECT_ID_DEFAULT = import.meta.env.VITE_PROJECT_ID_LOCAL;
export const CONFIG_APP_BASE_API_URL = () =>
  window?._APP_ENV?.VITE_CONFIG_APP_BASE_API_URL;
export const DEFAULT_ACCEPT_LANG = "en-GB";
export const VITE_DASHBOARD_BASE_URL = () =>
  window?._APP_ENV?.VITE_DASHBOARD_BASE_URL;

export const FRONTEND_APP_CONTAINER_ID = "page-grid";
export const OSG_KEY_START = "[:";

export const TEST_HTML = /<[^>]+>/g;

export const DEFAULT_CONTENT_VALUES = [
  "default",
  "this is default",
  `${BASE_MEDIA_URL()}img.png`,
  "title",
  "description",
  "this is title",
  "label",
  "some default value",
  "tooltip text",
  "item",
  "enter value",
  "request example",
  "placeholder",
  "document upload field",
  "search query",
  "form field",
  "option",
  "custom",
  "widgets.",
  "global.",
];
