<template>
  <div class="top-match-fenix__success-container">
    <WidgetField
      :widget="widget"
      :field="fields.success_message.field"
    >
      <BasicDesignWrapperField
        :widget="widget"
        :field="fields.success_message.field"
        class="top-match-fenix__success-content"
        :class="`top-match-fenix__success-content--${fields.success_message.field.options.display.layout}`"
      >
        <div class="top-match-fenix__success-top">
          <CommonIcon
            :name="fields.success_message.field.options.icon.value"
            :size="fields.success_message.field.options.icon.size"
            :color="
              getColorFromHex(fields.success_message.field.options.icon.color)
            "
          />

          <BasicTitleField :field="fields.success_message.field">
            Success message
          </BasicTitleField>
        </div>

        <BasicTitleField
          :field="fields.success_message.field"
          :options="fields.success_message.field.options.print"
        >
          Print button
        </BasicTitleField>
      </BasicDesignWrapperField>
    </WidgetField>

    <WidgetField
      :widget="widget"
      :field="fields.bet_card.field"
    >
      <BasicDesignWrapperField
        :widget="widget"
        :field="fields.bet_card.field"
        class="top-match-fenix__success-bet-card"
      >
        <div>
          <WidgetField
            :widget="widget"
            :field="fields.bets_type.field"
            class="top-match-fenix__success-single-bets"
          >
            <BasicDesignWrapperField
              :widget="widget"
              :field="fields.bets_type.field"
            >
              <BasicTitleField :field="fields.bets_type.field">
                Single bets
              </BasicTitleField>
            </BasicDesignWrapperField>
          </WidgetField>
        </div>

        <WidgetField
          :widget="widget"
          :field="fields.event_container.field"
        >
          <BasicDesignWrapperField
            :widget="widget"
            :field="fields.event_container.field"
            class="top-match-fenix__success-event-container"
          >
            <WidgetField
              :widget="widget"
              :field="fields.event_name.field"
            >
              <BasicTitleField :field="fields.event_name.field">
                Event name
              </BasicTitleField>
            </WidgetField>

            <WidgetField
              :widget="widget"
              :field="fields.bet_market.field"
            >
              <BasicTitleField :field="fields.bet_market.field">
                Bet market
              </BasicTitleField>
            </WidgetField>

            <div class="top-match-fenix__success-bet">
              <WidgetField
                :widget="widget"
                :field="fields.bet_name.field"
              >
                <BasicTitleField :field="fields.bet_name.field">
                  Bet name
                </BasicTitleField>
              </WidgetField>

              <WidgetField
                :widget="widget"
                :field="fields.coefficent.field"
              >
                <BasicDesignWrapperField
                  :widget="widget"
                  :field="fields.coefficent.field"
                >
                  <BasicTitleField :field="fields.coefficent.field">
                    Coefficient
                  </BasicTitleField>
                </BasicDesignWrapperField>
              </WidgetField>
            </div>
          </BasicDesignWrapperField>
        </WidgetField>

        <WidgetField
          :widget="widget"
          :field="fields.result_bet_amounts.field"
        >
          <BasicDesignWrapperField
            :widget="widget"
            :field="fields.result_bet_amounts.field"
            :class="`top-match-fenix__success-bet-amount--${fields.result_bet_amounts.field.options.display.layout}`"
            class="top-match-fenix__success-bet-amount"
          >
            <div>
              <BasicTitleField
                :field="fields.result_bet_amounts.field"
                :options="fields.result_bet_amounts.field.options.label"
              >
                Bet amount
              </BasicTitleField>

              <BasicTitleField
                :field="fields.result_bet_amounts.field"
                :options="fields.result_bet_amounts.field.options.value"
              >
                0.00
              </BasicTitleField>
            </div>

            <div>
              <BasicTitleField
                :field="fields.result_bet_amounts.field"
                :options="fields.result_bet_amounts.field.options.label"
              >
                Possible refund
              </BasicTitleField>

              <BasicTitleField
                :field="fields.result_bet_amounts.field"
                :options="fields.result_bet_amounts.field.options.value"
              >
                0.00
              </BasicTitleField>
            </div>
          </BasicDesignWrapperField>
        </WidgetField>
      </BasicDesignWrapperField>
    </WidgetField>

    <WidgetField
      :widget="widget"
      :field="fields.continue_button.field"
    >
      <BasicButtonField
        :field="fields.continue_button.field"
        :current-state="states.continue_button"
        custom-value="Continue"
      />
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getColorFromHex,
  getFlexPosition,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
}>();

const fields = useWidgetFields(props.widget);

const gap = computed(() => {
  return getPxValueFromNumber(
    fields.value.success_message.field.options.display.gap
  );
});

const successMessageAlign = computed(() => {
  return getFlexPosition(
    fields.value.success_message.field.options.display.alignment
  );
});

const iconGap = computed(() => {
  return getPxValueFromNumber(
    fields.value.success_message.field.options.icon.gap
  );
});

const betCardGap = computed(() => {
  return getPxValueFromNumber(fields.value.bet_card.field.options.display.gap);
});

const eventCardGap = computed(() => {
  return getPxValueFromNumber(
    fields.value.event_container.field.options.display.gap
  );
});

const betAmountGap = computed(() => {
  return getPxValueFromNumber(
    fields.value.result_bet_amounts.field.options.display.gap
  );
});

const distance = computed(() => {
  return getPxValueFromNumber(props.widget.options.success.gap);
});
</script>

<style lang="scss">
.top-match-fenix {
  &__success-container {
    @include flex-column("stretch", "center", v-bind(distance));
  }

  &__success-content {
    @include flex-column(v-bind(successMessageAlign), center, v-bind(gap));

    &--horizontal {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__success-top {
    @include flex(center, center, v-bind(iconGap));
  }

  &__success-bet-card {
    @include flex-column(stretch, center, v-bind(betCardGap));
  }

  &__success-event-container {
    @include flex-column(stretch, center, v-bind(eventCardGap));
  }

  &__success-bet {
    @include flex(center, space-between, 0);
  }

  &__success-single-bets {
    display: inline-block;
  }

  &__success-bet-amount {
    @include flex(center, flex-start, v-bind(betAmountGap));

    & > div {
      flex: 1;
    }

    &--horizontal {
      flex-direction: column;
      align-items: stretch;

      & > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
