<template>
  <div class="config__header-wrapper">
    <div class="config__header">
      <div class="config__controls">
        <div class="config__resolution">
          <div class="config__devices">
            <!--
              TODO move it back :disabled="!gridLayout[key]?.length &&
              activeStep === Step.WIDGETS"

              For now it allowed for testing purposes
             -->
            <a-button
              v-for="(screen, key) of breakpoints"
              :key="key"
              :class="{
                'config__button': true,
                'config__button--active': activeScreen === key,
              }"
              @click="changeResponsiveScreen(key)"
            >
              <span
                :class="{
                  'anticon': screen.icon.isCustom,
                  'config__button--filled':
                    gridLayout[key]?.length && isLayoutsStep,
                }"
              >
                <CommonIcon :name="screen.icon.name" />
              </span>
            </a-button>
          </div>
          <div class="f-base">
            {{ breakpoints[activeScreen].screenSize }} px
          </div>
        </div>

        <div class="config__meta">
          <slot name="input"></slot>

          <ConfigTemplateStatus
            v-if="isDraft"
            class="m-l-16"
          />
        </div>

        <div class="config__save-options">
          <PageEditors
            v-if="selectedPageId"
            :key="selectedPageId"
          />

          <slot name="buttons">
            <!-- TODO: Move buttons to `/GridConfig.vue` -->
            <a-button
              class="config__action-button config__save-button"
              type="primary"
              :disabled="
                !templateName || isMobileTemplateEmpty || !isTemplateEditable
              "
              @click="handleSaveClick"
            >
              <CommonIcon
                v-if="loading"
                name="ant-design:loading-outlined"
                spin
                class="config__action-spinner"
              />
              <span v-else> Save </span>
            </a-button>

            <a-button
              v-translate:grid="'Save draft'"
              class="config__action-button"
              :disabled="!isTemplateEditable || !templateId"
              @click="handleSaveDraftClick"
            >
              Save draft
            </a-button>

            <a-button
              class="config__action-button"
              :disabled="currentLayout.length === 0 || !isTemplateEditable"
              @click="handleClearClick"
            >
              Reset
            </a-button>

            <a-button
              v-if="!isLayoutsStep"
              v-translate:grid="'Preview'"
              class="config__action-button"
              type="primary"
              ghost
            >
            </a-button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";

import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useGridConfig } from "~~/store/grid";
import { useUiStore } from "~~/store/ui";
import { Breakpoint, breakpoints } from "~~/models/breakpoints.model";
import {
  ITemplateErrorResponse,
  TemplateStatus,
} from "~~/models/stores/grid-store.model";
import { Step } from "~~/models/step.enum";
import { generateErrors } from "~~/assets/utils";
import {
  TEMPLATE_RESET_SUCCES,
  SUCCESSFULLY_UPDATED,
  PAGE_SAVE_SUCCESS,
  PAGE_SAVE_ERROR,
} from "~~/constants/notification-messages";
import { usePagesStore } from "~~/store/pages";
import { handleActionSuccess } from "~~/helpers/handle-action-success";
import { handleActionError } from "~~/helpers/handle-action-error";
import { useIframePostMessage } from "~~/composables/useIframePostMessage";
import { PageEditors } from "~~/entities/pageEditors";

const notification = useNotification();
const gridStore = useGridConfig();
const uiStore = useUiStore();
const pagesStore = usePagesStore();
const widgetSettingsStore = useWidgetSettingsStore();

const route = useRoute();

const {
  gridLayout,
  currentLayout,
  templateId,
  templateName,
  isDraft,
  isMobileTemplateEmpty,
  isTemplateEditable,
  currentWidgetsLayout,
} = storeToRefs(gridStore);
const { activeScreen, activeStep } = storeToRefs(uiStore);
const { setScreen, switchTo } = uiStore;
const { setSelectedCell } = gridStore;
const { selectedPageId } = storeToRefs(pagesStore);

const loading = ref(false);

const changeResponsiveScreen = (key: Breakpoint): void => {
  setSelectedCell(null);
  setScreen(key);
  saveResolution(key);
  widgetSettingsStore.setActiveElement(null, null);
};

const saveMethod = computed<() => Promise<void | void[]>>(() => {
  if (templateId.value) {
    return gridStore.updateAllLayouts;
  }

  return gridStore.createLayout;
});

const handleSaveClick = (): void => {
  if (loading.value) {
    return;
  }

  loading.value = true;

  const draftToClearTemplateId = templateId.value;

  saveMethod
    .value()
    .then(() => {
      notification.notify({
        text: SUCCESSFULLY_UPDATED,
        type: "success",
      });

      gridStore.clearGridLayoutDraft(draftToClearTemplateId);
      gridStore.setTemplateStatus(TemplateStatus.SAVED);
      gridStore.setSelectedCell(null);

      gridStore.changeIsLayoutSavedStatus(true);

      saveResolution(activeScreen.value);

      useIframePostMessage().sendPathChange(`/template/${templateId.value}`);
    })
    .catch((error: ITemplateErrorResponse) => {
      notification.notify({
        text: generateErrors(error.response.data)[0],
        type: "error",
      });
    })
    .finally(() => {
      loading.value = false;
    });
};

const handleClearClick = (): void => {
  if (loading.value) {
    return;
  }

  gridStore.resetCurrentLayout();

  notification.notify({
    text: TEMPLATE_RESET_SUCCES,
  });
};

const handleSaveDraftClick = (): void => {
  if (loading.value) {
    return;
  }

  gridStore.saveGridLayoutDraft();
  gridStore.setTemplateStatus(TemplateStatus.DRAFT);

  if (currentWidgetsLayout.value.length) {
    gridStore
      .saveWidgetsGrid()
      .then(() => {
        handleActionSuccess(PAGE_SAVE_SUCCESS);
      })
      .catch(() => {
        handleActionError(PAGE_SAVE_ERROR);
      });
  }
};

const isLayoutsStep = computed<boolean>(
  () => activeStep.value === Step.LAYOUTS
);

const resolutionLSKey = computed<string>(
  () =>
    `resolution-key-${
      isLayoutsStep.value ? templateId.value : selectedPageId.value
    }`
);

const getSavedResolution = (): string | null =>
  localStorage.getItem(resolutionLSKey.value);

const saveResolution = (value: Breakpoint): void => {
  localStorage.setItem(resolutionLSKey.value, value);
};

onMounted(() => {
  switchTo(route.name as Step);

  nextTick(() => {
    const id = isLayoutsStep.value ? templateId.value : selectedPageId.value;
    // create form
    if (!id) return;

    const savedResolution = getSavedResolution();

    if (savedResolution) {
      setScreen(savedResolution as Breakpoint);
    }
  });
});
</script>

<style lang="scss">
.config {
  &__header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-max;
    width: 100%;
    height: $header-height;
    padding: 0 $space-lg 0 $space-l;
    background: $c-white;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-bottom: 1px solid $c-grey-15;
  }

  &__header {
    @include flex(center, space-between);
    height: 100%;
  }

  &__controls {
    width: 100%;
    min-width: 400px;
    overflow: auto;
    @include flex(center, space-between, 50px);
  }

  &__resolution {
    @include flex(center, space-between);
  }

  &__devices {
    @include flex(center, center, 7px);
    flex-direction: row-reverse;
  }

  &__meta {
    @include flex(center, space-between, 8px);
  }

  &__save-options {
    @include flex(center, center, 15px);
  }

  &__devices {
    background-color: $c-grey-04;
    border-radius: 8px;
    padding: 2px;
  }

  &__button,
  &__button:focus,
  &__button:hover {
    color: $c-black;
    border-color: transparent;
  }
  &__button {
    @include flex(center, center, 0);
    background-color: transparent;
    height: 36px;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;

    &--active {
      border: 1px solid $c-white;
      background-color: $c-white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02),
        0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.03);
    }

    &--filled::after {
      content: "";
      position: absolute;
      top: 8px;
      left: 20px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $c-primary-base;
    }
  }

  &__action-button {
    border-radius: 6px;
  }

  &__save-button {
    min-width: 62px;

    &--is-outdated {
      background-color: #faad14;
      border-color: #faad14;

      &:hover,
      &:active {
        background-color: darken(#faad14, 5%);
        border-color: darken(#faad14, 5%);
      }
    }
  }

  &__action-spinner {
    font-size: 12px;
    color: $c-white;
  }
}

.ant-btn:focus > span,
.ant-btn:active > span {
  position: unset;
}

@include respUp(lg) {
  .config {
    &__meta {
      flex-shrink: 1;
    }

    &__controls {
      flex-grow: 1;
      gap: 10px;
    }
  }
}
</style>
