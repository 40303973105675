import { type Ref } from "vue";

import {
  IWidgetWithFields,
  ColorPickerType,
} from "~~/models/widgets/widget.core/widget.model";
import {
  Display,
  DisplayOrientation,
  FlexDirection,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import {
  EventInfoDirection,
  EventInfoVariant,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetTypes";

export const useEventInfoConfig = (widget: Ref<IWidgetWithFields>) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(useWidgetSettingsStore());

  const eventInfoContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.size.width",
            valueSource: fields.value.event_info.field,
            options: {
              type: ColorPickerType.TEXT,
              label: "Width",
              icon: "ant-design:column-width-outlined",
              isWidth: true,
              exclude: [ResizingType.FIXED],
            },
          },
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.size.height",
            valueSource: fields.value.event_info.field,
            options: {
              type: ColorPickerType.TEXT,
              label: "Height",
              icon: "ant-design:column-height-outlined",
              exclude: [ResizingType.FIXED],
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.event_info.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Display mode",
              noTextWrap: true,
              items: [
                {
                  label: "1",
                  value: EventInfoVariant.Variant1,
                },
                {
                  label: "2",
                  value: EventInfoVariant.Variant2,
                },
                {
                  label: "3",
                  value: EventInfoVariant.Variant3,
                },
                {
                  label: "4",
                  value: EventInfoVariant.Variant4,
                },
                {
                  label: "5",
                  value: EventInfoVariant.Variant5,
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.direction",
            valueSource: fields.value.event_info.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Direction",
              noTextWrap: true,
              items: [
                {
                  label: "Default",
                  value: EventInfoDirection.Default,
                },
                {
                  label: "Reverse",
                  value: EventInfoDirection.Reverse,
                },
              ],
            },
          },

          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.event_info.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "ConfigAlignmentAdvanced",
        valuePath: "options.display.layoutAlignment",
        valueSource: selectedField.value,
        options: {
          label: "Align elements",
          display: Display.GRID,
          isBold: true,
          direction: FlexDirection.ROW,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "team",
              label: "Team / player",
            },
            {
              value: "date",
              label: "Date / time",
            },
          ],
        },
      },
    ];
  });

  const teamControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.size.height",
            valueSource: fields.value.team.field,
            options: {
              type: ColorPickerType.TEXT,
              label: "Height",
              icon: "ant-design:column-height-outlined",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.team.field,
            className: "group-control-element",
            options: {
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: fields.value.team.field,
            className: "border-bottom-none",
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
      typography({
        source: fields.value.team.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        valueSource: fields.value.team.field.options.icon,
        toggleable: true,
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: fields.value.team.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 124,
            },
          },
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.icon.position",
            valueSource: fields.value.team.field,
            className: "group-control-element",
            options: {
              label: "Position",
              row: true,
              items: [
                {
                  icon: "custom:unordered-list-outlined",
                  value: "top",
                  iconClassName: "r-90",
                },
                {
                  icon: "custom:unordered-list-outlined",
                  value: "left",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.gap",
            valueSource: fields.value.team.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Spacing between text and icon",
              minValue: 0,
              maxValue: 12,
            },
          },
        ],
      },
    ];
  });

  const dateControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.date.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.date.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      typography({
        source: fields.value.date.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        valueSource: fields.value.date.field.options.icon,
        toggleable: true,
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.dateValue",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.date.field,
            options: {
              label: "Date icon",
            },
          },
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.timeValue",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.date.field,
            options: {
              label: "Time icon",
            },
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.date.field,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: fields.value.date.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.gap",
            valueSource: fields.value.date.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Spacing between text and icon",
              minValue: 8,
              maxValue: 32,
            },
          },
        ],
      },
    ];
  });

  return {
    eventInfoContentControls,
    teamControls,
    dateControls,
  };
};
