import {
  DynamicFieldsProperties,
  IDynamicFieldProperties,
} from "~~/models/widgets/form.model";
import {
  ConfigTabItem,
  ControlSizing,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetMetaFieldsOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  FormFieldDetails,
  SUCCESS_SCREEN_FIELDS,
  EXPIRED_SCREEN_FIELDS,
} from "~~/models/widgets/form.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { generateWidgetField, createCustomField } from "~~/assets/utils/widget";
import { FORM_STYLING_FIELDS_OPTIONS } from "~~/constants/widget-details/form/form-fields-styling";
import { STYLING_FIELDS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { DYNAMIC_FIELD_OPTIONS } from "~~/constants/widget-details/form/dynamic-fields";
import { FormElementName } from "~~/models/widgets/form.model";
import {
  generateDropdownItem,
  DropdownItem,
} from "~~/helpers/configs/generate-dropdown-item";
import { StyleValue } from "~~/models/common";
import { IPageWidgetDetails, InnerFieldValue } from "~~/models/page.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

export interface IFormTextInputProp {
  _active: boolean;
  value: string;
  mode?: string;
}

export const getStylesAsVars = (
  styles: Record<string, StyleValue>,
  prefix?: string
): Record<string, StyleValue> => {
  const result: Record<string, StyleValue> = {};
  const currPrefix = prefix ? `${prefix}-` : "";

  for (const key in styles) {
    if (!key.startsWith("--")) {
      result[`--${currPrefix}${key}`] = styles[key];
      continue;
    }

    result[`--${currPrefix}${key.slice(2, key.length)}`] = styles[key];
  }

  return result;
};

export const findField = (
  name: string,
  fields: IWidgetField[]
): IWidgetField | undefined => {
  return fields.find(field => field.name === name);
};

export const removeHTMLFromString = (value: string): string => {
  return value.replace(/<\/?[^>]+(>|$)/g, "");
};

export const fetchFieldsWithProperty = (
  fieldsData: DynamicFieldsProperties,
  propertyName: keyof IDynamicFieldProperties
): Record<string, Partial<IDynamicFieldProperties>> => {
  const res = Object.keys(fieldsData).reduce(
    (result: Record<string, Partial<IDynamicFieldProperties>>, fieldName) => {
      const fieldProps = fieldsData[fieldName];
      const fieldsPropertyValue = fieldProps[propertyName];

      if (!fieldsPropertyValue || !Object.keys(fieldsPropertyValue).length) {
        return result;
      }

      result[fieldName] = fieldsPropertyValue;

      return result;
    },
    {}
  );

  return res;
};

export function generateWidgetCustomFieldsTabs(
  widget: IWidgetWithFields,
  metaFieldsOptions: IWidgetMetaFieldsOptions
): ConfigTabItem[] {
  const fields = widget.fields;

  return fields.reduce((result: ConfigTabItem[], field) => {
    const fieldData = metaFieldsOptions[field.name]?._meta;

    if (fieldData?.group != "custom") {
      return result;
    }

    /*
      Filter out duplicates
    */
    if (result.find(item => item.value === field.name)) {
      return result;
    }

    const cb = fieldData?.dropdownTitle;

    const item = {
      label: cb ? cb() : "",
      value: field.name,
    };

    result.push(item);

    return result;
  }, []);
}
export const generateStylingFormFields = (
  formFieldType: string,
  widgetId: string | number,
  initialOptions?: Record<string, Record<string, any>>
): Record<string, IWidgetField> => {
  const result: Record<string, IWidgetField> = {};

  const stylingList =
    FORM_STYLING_FIELDS_OPTIONS[formFieldType as FormElementName];

  if (!stylingList) {
    return {};
  }

  stylingList.forEach(stylingFieldName => {
    const getStylingInitial = STYLING_FIELDS[stylingFieldName];
    const initial = getStylingInitial();

    if (initialOptions?.[stylingFieldName]) {
      initial.options = {
        ...initial.options,
        ...initialOptions[stylingFieldName],
      };
    }

    const stylingField = createCustomField(
      initial.options,
      widgetId,
      stylingFieldName,
      initial.title
    );

    result[stylingFieldName] = stylingField;
  });

  return result;
};

export const generateFormFields = (
  formFieldDetails: FormFieldDetails | undefined,
  widget: IWidgetWithFields,
  customParentName?: string,
  exclude?: string[],
  initialOptions?: Record<string, Record<string, any>>
): IWidgetField[] => {
  if (!formFieldDetails) {
    return [];
  }

  const result: IWidgetField[] = [];
  let stylingFields: Record<string, IWidgetField> = {};

  for (const fieldName in formFieldDetails.value.fields) {
    const fieldDetails = formFieldDetails.value.fields[fieldName];

    const fieldInitialOptionsCallback =
      DYNAMIC_FIELD_OPTIONS[fieldDetails.type];

    if (!fieldInitialOptionsCallback) {
      console.warn(`Missing initial options for ${fieldName} field`);
      continue;
    }

    const fieldInitialOptions = fieldInitialOptionsCallback(
      fieldDetails,
      customParentName
    );

    const field = generateWidgetField(
      fieldName,
      widget.id,
      fieldDetails.type,
      fieldDetails.label,
      fieldDetails.value,
      {
        _parent: customParentName || "form",
        ...fieldInitialOptions.options,
      },
      fieldDetails.validation,
      fieldDetails.items,
      fieldDetails.readonly,
      undefined,
      fieldDetails.custom_rules
    );

    const currStylingFields = generateStylingFormFields(
      fieldDetails.type,
      widget.id,
      initialOptions
    );

    stylingFields = {
      ...stylingFields,
      ...currStylingFields,
    };

    result.push(field);
  }

  /*
    Generate custom styling fields for widget fields(not form fields only)
  */
  widget.fields.forEach(field => {
    if (exclude && exclude.includes(field.name)) {
      return;
    }

    const currStylingFields = generateStylingFormFields(
      field.type,
      widget.id,
      initialOptions
    );

    stylingFields = {
      ...stylingFields,
      ...currStylingFields,
    };
  });

  const fieldStylingFieldsList = Object.keys(stylingFields).map(fieldName => {
    return stylingFields[fieldName];
  });

  return [...result, ...fieldStylingFieldsList];
};

export const generateSpecificFormFields = (data: {
  widget: IWidgetWithFields;
  customParentName?: string;
  formElements: Record<string, string[]>;
  widgetCacheData: Ref<Partial<IPageWidgetDetails | undefined>>;
}): IWidgetField[] => {
  const { widget, customParentName, formElements, widgetCacheData } = data;

  if (!widgetCacheData.value) {
    return [];
  }

  const result: IWidgetField[] = [];
  let stylingFields: Record<string, IWidgetField> = {};
  /*
    Generate inner fields from form value
  */
  for (const fieldName in widgetCacheData.value.fields) {
    const currentField = widgetCacheData.value.fields[fieldName];

    if (currentField.type !== "FormField") {
      continue;
    }

    for (const fieldName in (currentField.value as InnerFieldValue).fields) {
      const fieldDetails = (currentField.value as InnerFieldValue).fields[
        fieldName
      ];

      const fieldInitialOptionsCallback =
        DYNAMIC_FIELD_OPTIONS[fieldDetails.type];

      if (!fieldInitialOptionsCallback) {
        console.warn(`Missing initial options for ${fieldName} field`);
        continue;
      }

      const fieldInitialOptions = fieldInitialOptionsCallback(
        fieldDetails as any,
        currentField.name
      );

      const field = generateWidgetField(
        fieldName,
        widget.id,
        fieldDetails.type,
        fieldDetails.label,
        fieldDetails.value,
        {
          _parent: customParentName || currentField.name,
          ...fieldInitialOptions.options,
        },
        fieldDetails.validation,
        fieldDetails.items,
        fieldDetails.readonly,
        undefined,
        fieldDetails.custom_rules
      );

      const currStylingFields = generateStylingFormFields(
        fieldDetails.type,
        widget.id
      );

      stylingFields = {
        ...stylingFields,
        ...currStylingFields,
      };

      result.push(field);
    }
  }

  for (const formName in formElements) {
    const fieldDetails = widgetCacheData.value.fields![formName];

    /*
      Create form elements fields
    */
    result.push(
      generateWidgetField(
        formName,
        widget.id,
        fieldDetails.type,
        fieldDetails.title,
        fieldDetails.value,
        {},
        fieldDetails.validation,
        undefined,
        undefined,
        true,
        fieldDetails.custom_rules
      )
    );

    const additionalFormElements = formElements[formName];

    /*
      Generate custom styling fields for widget fields(not form fields only)
    */
    additionalFormElements.forEach(elementName => {
      const field = widgetCacheData.value!.fields![elementName];

      const currStylingFields = generateStylingFormFields(
        field.type!,
        widget.id
      );

      stylingFields = {
        ...stylingFields,
        ...currStylingFields,
      };
    });
  }

  const fieldStylingFieldsList = Object.keys(stylingFields).map(fieldName => {
    return stylingFields[fieldName];
  });

  return [...result, ...fieldStylingFieldsList];
};

export const getFormWidth = (widthOptions: {
  type: ControlSizing;
  width: number | null;
}): {
  width: string;
  maxWidth?: string;
} => {
  if (widthOptions && widthOptions.type === ControlSizing.FIXED) {
    return {
      width: `${widthOptions.width}px`,
      maxWidth: "100%",
    };
  }

  return {
    width: "100%",
  };
};

export const generateStyleDropdownItems = (
  fields: IWidgetField[]
): DropdownItem[] => {
  return fields
    .filter(field => field.options._custom)
    .map(field => {
      return generateDropdownItem(field.title!, field.name, "custom");
    });
};

export const generateFormElementsStylesList = (
  fields: IWidgetField[],
  except?: string[]
): ConfigTabItem[] => {
  return fields.reduce<ConfigTabItem[]>((result, currField) => {
    if (!currField.options._custom) {
      return result;
    }

    if (except && except.includes(currField.name)) {
      return result;
    }

    const currItem = {
      value: currField.name,
      label: currField.title,
    } as ConfigTabItem;

    return [...result, currItem];
  }, []);
};

export const isInputField = (field: IWidgetField): boolean => {
  if (
    field.type === FormElementName.TEXT_INPUT ||
    field.type === FormElementName.EMAIL ||
    field.type === FormElementName.DROPDOWN ||
    field.type === FormElementName.PASSWORD ||
    field.type === FormElementName.NUMBER ||
    field.type === FormElementName.CHECKBOX ||
    field.type === FormElementName.RADIO_GROUP ||
    field.type === FormElementName.DATE ||
    field.type === FormElementName.PHONE ||
    field.type === FormElementName.FORM_TEXT_INPUT_FIELD ||
    field.type === FormElementName.UPLOAD
  ) {
    return true;
  }

  return false;
};

export const getFormElements = (fields: IWidgetField[]): IWidgetField[] => {
  return fields.filter(field => {
    return (
      /*
        "_custom" key is responsible for styling fields(they needed only to store styles,
        without rendering)
      */
      field.name !== "form" &&
      !field.options._custom &&
      !SUCCESS_SCREEN_FIELDS.includes(field.name) &&
      !EXPIRED_SCREEN_FIELDS.includes(field.name)
    );
  });
};

export const prefillCustomFields = (
  widgetOptions: IWidgetOptions,
  widgetId: string | number
): IWidgetField[] => {
  if (!widgetOptions || !widgetOptions._customFields) {
    return [];
  }

  type FieldDetails = { name: string; options: IWidgetOptions };
  const result: IWidgetField[] = Object.values<FieldDetails>(
    widgetOptions._customFields
  )
    .filter(field => {
      if (STYLING_FIELDS[field.name]) {
        return true;
      }

      return false;
    })
    .map(field => {
      const getStylingInitial = STYLING_FIELDS[field.name];

      const initial = getStylingInitial();

      const stylingField = createCustomField(
        {
          ...initial.options,
          ...field.options,
        },
        widgetId,
        field.name,
        initial.title
      );

      return stylingField;
    });

  return result;
};

export const generateFormContentDropdownItems = (
  fields: IWidgetField[],
  customFormElements: Record<string, string[]>,
  customLabels?: Record<string, string>
): DropdownItem[] => {
  const mergedElements: string[] = [];

  for (const formName in customFormElements) {
    const els = customFormElements[formName];
    mergedElements.push(...els);
  }

  const { getLocalizedValue } = useLocalizedValue();

  return fields
    .filter(
      field => mergedElements.includes(field.name) || field.options._parent
    )
    .map(field => {
      let label;
      const isInput = isInputField(field);

      if (customLabels?.[field.name]) {
        label = customLabels?.[field.name];
      } else if (isInput) {
        label = getLocalizedValue.value(field.options?.label?.value);
      } else if (field.type === FormElementName.DESCRIPTION) {
        label = removeHTMLFromString(getLocalizedValue.value(field.value));
      } else {
        label = getLocalizedValue.value(field.value);
      }

      return generateDropdownItem(label, field.type, "fields", {
        fieldId: field.id,
      });
    });
};
