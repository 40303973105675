import type { Ref } from "vue";

import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { TabItem, generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { useFlexibleImageControls } from "~~/composables/widgets/common/useFlexibleCardWidgetControls";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Page Title", "page_title", "title"),
    generateDropdownItem("Providers List", "providers_list", "providers"),
    generateDropdownItem("Provider image", "provider_image", "providers"),
    generateDropdownItem(
      "No providers title",
      "no_providers_title",
      "providers"
    ),
    generateDropdownItem("Provider cards", "provider_card", "provider_card"),
    generateDropdownItem("Provider name", "provider_name", "provider_card"),
    generateDropdownItem(
      "Provider description",
      "provider_description",
      "provider_card"
    ),
    generateDropdownItem("Provider button", "provider_button", "provider_card"),
    generateDropdownItem("Provider tooltip", "tooltip", "provider_card"),
    generateDropdownItem(
      "Information message",
      "information_message",
      "information_message"
    ),
    generateDropdownItem(
      "Information description",
      "information_description",
      "information_message"
    ),
    generateDropdownItem(
      "Information button",
      "information_button",
      "information_message"
    ),
    generateDropdownItem(
      "Information title",
      "information_title",
      "information_message"
    ),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    providers_list: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    provider_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    provider_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    information_message: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    information_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    no_providers_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    tooltip: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
  } as Record<string, TabItem[]>;

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const configItems = computed<ConfigTabItem[]>(() => [
    {
      label: "Page Title",
      value: "page_title",
    },
    {
      label: "Providers list",
      value: "providers_list",
    },
  ]);

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: configItems.value,
        },
      },
    ];
  });

  const titleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter text",
        },
      },
      typography({ source: selectedField.value! }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const providerListDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigFillImageColor",
        valuePath: "options.fillImageColor",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const PROVIDERS_EXIST_CONTROLS = [
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "ConfigDisplayGridSettings",
          valuePath: "options.gridSettings",
          valueSource: fields.value.providers_list.field,
        },
      ],
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            label: "Provider cards",
            value: "provider_card",
          },
        ],
      },
    },
  ];

  const PROVIDERS_LIST_NO_PROVIDERS_CONTROLS = [
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            label: "No Providers Message",
            value: "no_providers_title",
          },
        ],
      },
    },
  ];

  const PROVIDERS_LIST_INSUFFICIENT_CONTROLS = [
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            label: "Information Message",
            value: "information_message",
          },
        ],
      },
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            label: "Providers list",
            value: "providers_list",
            isActiveValueSource: fields.value.providers_list.field.options,
            hideSettingsIcon: true,
          },
        ],
      },
    },
    {
      componentPath: "content/ConfigNumberInput",
      valuePath: "options.insufficientOpacity",
      valueSource: fields.value.providers_list.field,
      options: {
        label: "Opacity",
        suffixContent: "%",
        maxValue: 100,
      },
    },
  ];

  const providerListContentControls = computed(() => {
    const result: Array<Record<string, any>> = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.providers_list,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.providers_exist,
              value: State.PROVIDERS_EXIST,
            },
            {
              label: STATE_KEY_LABELS.no_providers,
              value: State.NO_PROVIDERS,
            },
            {
              label: STATE_KEY_LABELS.insufficient_personal_data,
              value: State.INSUFFICIENT_PERSONAL_DATA,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.providers_list = value;
        },
      },
    ];

    if (states.value.providers_list === State.PROVIDERS_EXIST) {
      result.push(...PROVIDERS_EXIST_CONTROLS);

      return result;
    }

    if (states.value.providers_list === State.NO_PROVIDERS) {
      result.push(...PROVIDERS_LIST_NO_PROVIDERS_CONTROLS);

      return result;
    }

    if (states.value.providers_list === State.INSUFFICIENT_PERSONAL_DATA) {
      result.push(...PROVIDERS_LIST_INSUFFICIENT_CONTROLS);

      return result;
    }

    return result;
  });

  const providerCardDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
        },
      },
    ];
  });

  const cardsListContentConfigItems: ConfigTabItem[] = [
    {
      label: "Provider image",
      value: "provider_image",
    },
    {
      label: "Provider name",
      value: "provider_name",
    },
    {
      label: "Provider description",
      value: "provider_description",
    },
    {
      label: "Provider button",
      value: "provider_button",
      isActiveValueSource: fields.value.provider_button.field.options,
    },
    {
      label: "Tooltips",
      value: "tooltip",
    },
  ];

  const providerCardContentControls = computed(() => {
    return [
      {
        componentPath: "ConfigLayoutPosition",
        valuePath: "options.layoutPosition",
        valueSource: selectedField.value,
        options: {},
      },
      // {
      //   componentPath: "ConfigAlignment",
      //   valuePath: "options.layoutPosition.alignment",
      //   valueSource: selectedField.value,
      //   options: {
      //     label: "Alignment",
      //     isTextContentIcons: false,
      //   },
      // },
      {
        componentPath: "content/ConfigNumberInput",
        valuePath: "options.gap",
        valueSource: selectedField.value,
        options: {
          label: "Distance between elements",
          suffixContent: "px",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: cardsListContentConfigItems,
        },
      },
    ];
  });

  const cardsListStateValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const providerCardStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.provider_card = value;
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${cardsListStateValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${cardsListStateValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${cardsListStateValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
    ];
  });

  const { flexibleImageControls: providerImageControls } =
    useFlexibleImageControls(toRef(fields.value.provider_image.field));

  const providerTextControls = computed(() => [
    typography({ source: selectedField.value! }),
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const providerButtonDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigSpacingInputs",
      valuePath: "options.spacing",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Spacing",
      },
    },
  ]);

  const providerButtonContentControls = computed(() => [
    {
      componentPath: "ConfigButtonDisplaySettings",
      valuePath: "options.buttonDisplaySettings",
      valueSource: selectedField.value,
      options: {
        showAlignmentOnFillOnly: false,
      },
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigButtonTextSettings",
          valuePath: "options.buttonTextSettings",
          className: "p-t-16",
          valueSource: selectedField.value,
          options: {
            label: "Text",
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigIconSettings",
          valuePath: "options.iconSettings",
          valueSource: selectedField.value,
          options: {
            isStatesTab: false,
          },
        },
      ],
    },
  ]);

  const informationButtonContentControls = computed(() => [
    {
      componentPath: "ConfigButtonDisplaySettings",
      valuePath: "options.buttonDisplaySettings",
      valueSource: selectedField.value,
      options: {
        showAlignmentOnFillOnly: false,
      },
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigButtonTextSettings",
          valuePath: "options.buttonTextSettings",
          className: "p-t-16",
          valueSource: selectedField.value,
          options: {
            label: "Text",
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigIconSettings",
          valuePath: "options.iconSettings",
          valueSource: selectedField.value,
          options: {
            isStatesTab: false,
          },
        },
      ],
    },
    {
      componentPath: "ConfigLinkType",
      valuePath: "options.link",
      valueSource: selectedField.value,
      options: {
        label: "Link",
        shouldShowStyleSettings: false,
      },
    },
  ]);

  const buttonSettingsValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const providerButtonStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        states.value[selectedField.value!.name] = value;
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${buttonSettingsValuePath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${buttonSettingsValuePath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${buttonSettingsValuePath.value}.shadow`,
      valueSource: selectedField.value,
    },
    {
      section: generateDesignSectionName("Text"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${buttonSettingsValuePath.value}.buttonTextSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Text color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
    {
      section: generateDesignSectionName("Icon"),
      controls: [
        {
          componentPath: "ConfigColorPickerInput",
          valuePath: `${buttonSettingsValuePath.value}.iconSettings.textColor`,
          valueSource: selectedField.value,
          options: {
            label: "Icon color",
            type: ColorPickerType.TEXT,
          },
        },
      ],
    },
  ]);

  const informationDesignControls = computed(() => [
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.fill",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Fill",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: "options.border",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Border",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: "options.shadow",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Shadow",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigCornerRadius",
      valuePath: "options.cornerRadius",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Corner radius",
        isBold: true,
      },
    },
    {
      componentPath: "ConfigSpacingInputs",
      valuePath: "options.spacing",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        label: "Spacing",
      },
    },
  ]);

  const informationContentConfigItems: ConfigTabItem[] = [
    {
      label: "Title",
      value: "information_title",
    },
    {
      label: "Description",
      value: "information_description",
    },
    {
      label: "Button",
      value: "information_button",
    },
  ];

  const informationContentControls = computed(() => [
    {
      componentPath: "common/ConfigRadioTextElements",
      valuePath: "options.layout",
      valueSource: selectedField.value,
      className: "border-bottom-none p-b-0",
      options: {
        label: "Layout",
        items: [
          {
            label: "Horizontal",
            value: "horizontal",
          },
          {
            label: "Vertical",
            value: "vertical",
          },
        ],
      },
    },
    {
      componentPath: "ConfigVerticalAlignment",
      valuePath: "options.alignment",
      valueSource: selectedField.value,
      className: "group-control-element--child",
      visible:
        selectedField.value?.options.layout === DisplayOrientation.HORIZONTAL,
      options: {
        label: "Alignment",
      },
    },
    {
      componentPath: "content/ConfigNumberInput",
      valuePath: "options.gap",
      valueSource: selectedField.value,
      options: {
        label: "Distance between elements",
        suffixContent: "px",
      },
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: informationContentConfigItems,
      },
    },
  ]);

  const informationSettingsValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const informationStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        states.value.information_message = value;
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${informationSettingsValuePath.value}.fill`,
      valueSource: selectedField.value,
      options: {
        label: "Fill",
        type: ColorPickerType.BACKGROUND,
      },
    },
    {
      componentPath: "ConfigBorder",
      valuePath: `${informationSettingsValuePath.value}.border`,
      valueSource: selectedField.value,
    },
    {
      componentPath: "ConfigBoxShadow",
      valuePath: `${informationSettingsValuePath.value}.shadow`,
      valueSource: selectedField.value,
    },
  ]);

  const informationTitleControls = computed(() => [
    {
      componentPath: "content/ConfigTextInput",
      valuePath: "value",
      valueSource: selectedField.value,
      options: {
        placeholder: "None",
        showOptions: false,
      },
    },
    typography({ source: selectedField.value! }, "Text"),
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const informationDescriptionControls = computed(() => [
    typography({ source: selectedField.value! }, "Text"),
    {
      componentPath: "ConfigPaddingInputs",
      valuePath: "options.padding",
      valueSource: selectedField.value,
      options: {
        label: "Padding",
        meta: {
          isBold: true,
        },
      },
    },
  ]);

  const tooltipDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: fields.value.tooltip.field,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: fields.value.tooltip.field,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: fields.value.tooltip.field,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: fields.value.tooltip.field,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.tooltip.field,
        options: {
          isBold: true,
        },
      },
    ];
  });

  const tooltipContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.mode",
        valueSource: fields.value.tooltip.field,
        options: {
          label: "Mode (visible on click at)",
          isBold: true,
          items: [
            {
              label: "Icon",
              value: "icon",
            },
            {
              label: "Field",
              value: "field",
            },
          ],
        },
      },
      {
        componentPath: "common/ConfigChooseIcon",
        valuePath: "options.textChooseIcon",
        valueSource: fields.value.tooltip.field,
        visible: fields.value.tooltip.field.options.mode === "icon",
        options: {
          placeholder: "None",
          label: "Icon",
          isBold: true,
          icons: {},
          options: [
            {
              label: "Question",
              value: "ant-design:question-circle-outlined",
            },
          ],
        },
      },
      typography(
        {
          source: fields.value.tooltip.field,
          except: ["ConfigAlignment"],
        },
        "Tooltips text"
      ),
    ];
  });

  const noProvidersDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          isBold: true,
        },
      },
    ];
  });

  const noProvidersContentControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter text",
        },
      },
      typography({ source: selectedField.value! }),
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigIconSettings",
            valuePath: "options.iconSettings",
            valueSource: selectedField.value,
            options: {
              isStatesTab: false,
            },
          },
        ],
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    titleControls,

    providerListDesignControls,
    providerListContentControls,

    providerCardDesignControls,
    providerCardContentControls,
    providerCardStatesControls,

    providerImageControls,
    providerTextControls,

    providerButtonDesignControls,
    providerButtonContentControls,
    informationButtonContentControls,
    providerButtonStatesControls,

    informationDesignControls,
    informationContentControls,
    informationStatesControls,
    informationTitleControls,
    informationDescriptionControls,

    tooltipDesignControls,
    tooltipContentControls,

    noProvidersDesignControls,
    noProvidersContentControls,
  };
};
