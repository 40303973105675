import type { Ref } from "vue";

import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { TabItem, generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("League items", "menu_level_1"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    menu_level_1: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  } as Record<string, TabItem[]>;

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput", "ConfigCornerRadius"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.title?.field,
        visible: !!fields.value.title,
        options: {
          placeholder: "Enter button text",
          label: "Text",
          allowDisable: true,
          active: fields.value.title?.field.options._active,
          showOptions: fields.value.title?.field.options._active,
          "onUpdate:active": (value: boolean) => {
            widgetsStore.updateFieldOptionValue(
              value,
              "_active",
              fields.value.title?.field.id
            );
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.title?.field
            );
          },
        },
      },
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.widget,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS[State.WITH_POPULAR_LEAGUES],
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS[State.NO_POPULAR_LEAGUES],
              value: State.NO_ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", {
            state: "widget",
            value,
          });
        },
      },
      ...(states.value.widget === State.DEFAULT
        ? [
            {
              componentPath: "form/ConfigTabs",
              options: {
                items: [
                  {
                    label: "League items",
                    value: "menu_level_1",
                  },
                ],
              },
            },
          ]
        : [
            {
              section: generateContentSectionName("Text"),
              controls: [
                {
                  componentPath: "content/ConfigTextInput",
                  valuePath: "value",
                  valueSource: fields.value.no_events_message.field,
                  options: {
                    placeholder: "Enter text",
                    active: true,
                  },
                },
              ],
            },
            typography({
              source: fields.value.no_events_message.field,
            }),
            {
              componentPath: "ConfigPaddingInputs",
              valuePath: "options.padding",
              valueSource: fields.value.no_events_message.field,
              options: {
                label: "Padding",
                meta: {
                  isBold: true,
                },
              },
            },
          ]),
    ];
  });

  const titleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.title.field,
        options: {
          placeholder: "Enter text",
        },
      },
      typography({ source: fields.value.title.field! }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.title.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const menuItemDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const menuItemContentControls = computed(() => [
    ...getInitialContentControlsList({
      source: selectedField.value,
    }),
    {
      section: generateDesignSectionName("Display mode"),
      controls: [
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options.items.displayOrientation",
          valueSource: selectedField.value,
          options: {
            items: [
              {
                label: "Vertical",
                value: DisplayOrientation.VERTICAL,
              },
              {
                label: "Horizontal",
                value: DisplayOrientation.HORIZONTAL,
              },
            ],
          },
        },
        {
          componentPath: "common/ConfigSpacing",
          valuePath: "options.items.gap",
          valueSource: selectedField.value,
          options: {
            label: "Distance between items",
          },
        },
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options.elements.displayOrientation",
          valueSource: selectedField.value,
          options: {
            items: [
              {
                label: "Vertical",
                value: DisplayOrientation.VERTICAL,
              },
              {
                label: "Horizontal",
                value: DisplayOrientation.HORIZONTAL,
              },
            ],
          },
        },
        {
          componentPath: "common/ConfigSpacing",
          valuePath: "options.elements.gap",
          valueSource: selectedField.value,
          options: {
            label: "Distance between item elements",
          },
        },
      ],
    },
    typography(
      {
        source: selectedField.value,
      },
      "Text"
    ),
    {
      componentPath: "common/ConfigToggle",
      valuePath: "options.itemIcon._active",
      valueSource: selectedField.value,
      className: "p-t-16 p-r-16 p-b-16 p-l-16",
      options: {
        placeholder: "None",
        label: "Icon",
        size: "small",
        isBold: true,
        type: ColorPickerType.BACKGROUND,
        showOptions: false,
        allowDisable: true,
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: "options.itemIcon.color",
      valueSource: selectedField.value,
      className: "p-t-0",
      visible: selectedField.value?.options.itemIcon._active,
      options: {
        placeholder: "None",
        label: "Color",
        type: ColorPickerType.TEXT,
      },
    },
    {
      componentPath: "common/ConfigRangeSlider",
      valuePath: "options.itemIcon.size",
      valueSource: selectedField.value,
      className: "p-t-16 p-r-16 p-b-16 p-l-16",
      visible: selectedField.value?.options.itemIcon._active,
      options: {
        label: "Size",
        minValue: 12,
        maxValue: 32,
      },
    },
  ]);

  const currentStatePath = computed(() => {
    if (!selectedField.value) {
      return "";
    }

    const currState = states.value?.[selectedField.value.name];

    if (currState === State.DEFAULT) {
      return `options`;
    }

    return `options.states.${currState}`;
  });

  const stateValue = computed<State | null>(() => {
    if (!selectedField.value) {
      return null;
    }

    return states.value[selectedField.value.name];
  });

  const menuItemStatesControls = computed(() => {
    const result: Array<Record<string, any>> = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateValue.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          states.value[selectedField.value!.name] = value;
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentStatePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentStatePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentStatePath.value}.shadow`,
        valueSource: selectedField.value,
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.color`,
            valueSource: selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentStatePath.value}.decoration`,
            valueSource: selectedField.value,
            options: {
              label: "Decoration",
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.itemIcon.color`,
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];

    return result;
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    titleControls,

    menuItemDesignControls,
    menuItemContentControls,
    menuItemStatesControls,
  };
};
