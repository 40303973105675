<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <CasinoGamesListFenixSettings
      :widget="widget"
      :states="states"
      :state-controller="stateController"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="casino-list-fenix">
      <WidgetField
        v-if="fields.back_button.active"
        :widget="widget"
        :field="fields.back_button.field"
      >
        <BasicButtonField
          :field="fields.back_button.field"
          custom-value="Back Button"
          :current-state="states.back_button"
        />
      </WidgetField>

      <CasinoHeader
        v-if="fields.header.field.options._active"
        :widget="widget"
        :field="fields.header.field"
        :page-title-field="fields.page_title.field"
        :search-result-field="fields.search_result.field"
        :search-field="fields.search.field"
        :sorting-field="fields.sorting.field"
        :search-state="states.search"
        :page-type-state="states.page_type"
        :sorting-state="states.sorting"
      />

      <WidgetField
        :widget="widget"
        :field="fields.games_list.field"
      >
        <PromoGroup
          :widget="widget"
          :fields="fields"
          :group-list-field="fields.promo_group_list.field"
          :group-cards-field="fields.promo_cards.field"
          :card-image-field="fields.promo_image.field"
          :card-title-field="fields.promo_title.field"
          :card-description-field="fields.promo_description.field"
          :card-button-group-field="fields.promo_button_group.field"
          :card-button1-field="fields.promo_button_1.field"
          :card-button2-field="fields.promo_button_2.field"
          :states="states"
          :state-controller="stateController"
          :cards-count="3"
          image-value-path="/promotions-list/second-group/"
          image-format="png"
        />

        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.games_list.field"
        >
          <div
            class="casino-list-fenix__list"
            :style="gamesListWrapper"
          >
            <template v-if="states.page_type === State.CASINO_LIST">
              <WidgetField
                v-for="index in 2"
                :key="index"
                :widget="widget"
                :field="fields.games_category.field"
              >
                <BasicDesignWrapperField
                  :widget="widget"
                  :field="fields.games_category.field"
                  :state="states.games_category"
                  :style="gamesCategoryWrapper"
                >
                  <SportsbookLiveSportsTitle
                    :widget="widget"
                    :field="fields.games_category_title.field"
                    :button-field="fields.games_category_button.field"
                    :hover-button-field="
                      fields.games_category_hover_button.field
                    "
                    :state="states.games_category_title"
                    :sports-state="states.games_category"
                    :button-field-state="states.games_category_button"
                    :hover-button-field-state="
                      states.games_category_hover_button
                    "
                  >
                    <span>Category / Subcategory Name</span>
                  </SportsbookLiveSportsTitle>

                  <WidgetField
                    v-if="
                      states.games_status_category === State.WITH_ACTIVE_GAMES
                    "
                    :widget="widget"
                    :field="fields.games_category_list.field"
                    :style="categoryListWrapper"
                  >
                    <WidgetField
                      v-for="idx in gamesInRowCategory"
                      :key="idx"
                      :widget="widget"
                      :field="fields.game_cards.field"
                    >
                      <BasicDesignWrapperField
                        :widget="widget"
                        :field="fields.game_cards.field"
                        :state="states.game_cards"
                        :style="cardsWrapperStyles"
                      >
                        <CasinoGameCard
                          :photo-id="idx"
                          :widget="widget"
                          :field="fields.game_cover.field"
                          :state="states.game_cover"
                          :photo-field="fields.game_cover.field"
                          :hover-content-field="fields.hover_content.field"
                          :game-title-field="fields.game_title.field"
                          :play-money-button-field="
                            fields.play_money_button.field
                          "
                          :play-money-button-state="states.play_money_button"
                          :play-free-button-field="
                            fields.play_free_button.field
                          "
                          :play-free-button-state="states.play_free_button"
                          :cards-elements-field="fields.game_cover.field"
                          :promo-tag-field="fields.promo_tag.field"
                          :promo-tag-state="states.promo_tag"
                          :favorite-icon-field="fields.favorite_icon.field"
                          :is-hover-content="isHoverContent"
                        />

                        <WidgetField
                          v-if="fields.game_name.active"
                          :widget="widget"
                          :field="fields.game_name.field"
                        >
                          <BasicTitleField
                            :field="fields.game_name.field"
                            value="Game Name"
                          />
                        </WidgetField>
                      </BasicDesignWrapperField>
                    </WidgetField>
                  </WidgetField>
                  <WidgetField
                    v-else
                    :widget="widget"
                    :field="fields.games_category_list.field"
                    :style="cardsWrapperStyles"
                  >
                    <BasicTitleField
                      :field="fields.games_category_nogames_title.field"
                    />

                    <BasicTitleField
                      v-if="
                        fields.games_category_nogames_title.field.options
                          .description._active
                      "
                      :field="fields.games_category_nogames_title.field"
                      :value="
                        fields.games_category_nogames_title.field.options
                          .description.value
                      "
                      :options="
                        fields.games_category_nogames_title.field.options
                          .description
                      "
                    />
                  </WidgetField>
                </BasicDesignWrapperField>
              </WidgetField>
            </template>

            <WidgetField
              :widget="widget"
              :field="fields.all_games.field"
            >
              <BasicDesignWrapperField
                :widget="widget"
                :field="fields.all_games.field"
                :state="states.all_games"
                :style="allGamesWrapper"
              >
                <WidgetField
                  :widget="widget"
                  :field="fields.all_games_title.field"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.all_games_title.field"
                  >
                    <BasicTitleField
                      v-if="fields.all_games_title.active"
                      :field="fields.all_games_title.field"
                      :options="{
                        ...fields.all_games_title.field.options,
                        padding: null,
                      }"
                    />
                  </BasicDesignWrapperField>
                </WidgetField>

                <WidgetField
                  v-if="
                    states.games_status_allgames === State.WITH_ACTIVE_GAMES
                  "
                  :widget="widget"
                  :field="fields.all_games_list.field"
                  :style="allGamesListWrapper"
                >
                  <WidgetField
                    v-for="idx in fields.show_more_games_card.field.options
                      ._active
                      ? gamesInRowAllGames - 1
                      : gamesInRowAllGames"
                    :key="idx"
                    :widget="widget"
                    :field="fields.game_cards.field"
                  >
                    <BasicDesignWrapperField
                      :widget="widget"
                      :field="fields.game_cards.field"
                      :state="states.game_cards"
                      :style="cardsWrapperStyles"
                    >
                      <CasinoGameCard
                        :photo-id="idx"
                        :widget="widget"
                        :field="fields.game_cover.field"
                        :state="states.game_cover"
                        :photo-field="fields.game_cover.field"
                        :hover-content-field="fields.hover_content.field"
                        :game-title-field="fields.game_title.field"
                        :play-money-button-field="
                          fields.play_money_button.field
                        "
                        :play-money-button-state="states.play_money_button"
                        :play-free-button-field="fields.play_free_button.field"
                        :play-free-button-state="states.play_free_button"
                        :cards-elements-field="fields.game_cover.field"
                        :promo-tag-field="fields.promo_tag.field"
                        :promo-tag-state="states.promo_tag"
                        :favorite-icon-field="fields.favorite_icon.field"
                        :is-hover-content="isHoverContent"
                      />

                      <WidgetField
                        v-if="fields.game_name.active"
                        :widget="widget"
                        :field="fields.game_name.field"
                      >
                        <BasicTitleField
                          :field="fields.game_name.field"
                          value="Game Name"
                        />
                      </WidgetField>
                    </BasicDesignWrapperField>
                  </WidgetField>
                  <WidgetField
                    v-if="fields.show_more_games_card.field.options._active"
                    :widget="widget"
                    :field="fields.show_more_games_card.field"
                    :style="cardsWrapperStyles"
                  >
                    <BasicDesignWrapperField
                      :widget="widget"
                      :field="fields.show_more_games_card.field"
                      :style="showMoreGamesCardStyles"
                    >
                      <WidgetField
                        :widget="widget"
                        :field="fields.show_more_button.field"
                      >
                        <BasicButtonField
                          :field="fields.show_more_button.field"
                          :current-state="states.show_more_button"
                        />
                      </WidgetField>
                    </BasicDesignWrapperField>
                    <div>&nbsp;</div>
                  </WidgetField>
                  <template v-if="states.page_type === State.CASINO_SEARCH">
                    <WidgetField
                      v-for="idx in gamesInRowAllGames"
                      :key="idx"
                      :widget="widget"
                      :field="fields.game_cards.field"
                    >
                      <BasicDesignWrapperField
                        :widget="widget"
                        :field="fields.game_cards.field"
                        :state="states.game_cards"
                        :style="cardsWrapperStyles"
                      >
                        <CasinoGameCard
                          :photo-id="idx"
                          :widget="widget"
                          :field="fields.game_cover.field"
                          :state="states.game_cover"
                          :photo-field="fields.game_cover.field"
                          :hover-content-field="fields.hover_content.field"
                          :game-title-field="fields.game_title.field"
                          :play-money-button-field="
                            fields.play_money_button.field
                          "
                          :play-money-button-state="states.play_money_button"
                          :play-free-button-field="
                            fields.play_free_button.field
                          "
                          :play-free-button-state="states.play_free_button"
                          :cards-elements-field="fields.game_cover.field"
                          :promo-tag-field="fields.promo_tag.field"
                          :promo-tag-state="states.promo_tag"
                          :favorite-icon-field="fields.favorite_icon.field"
                          :is-hover-content="isHoverContent"
                        />

                        <WidgetField
                          v-if="fields.game_name.active"
                          :widget="widget"
                          :field="fields.game_name.field"
                        >
                          <BasicTitleField :field="fields.game_name.field" />
                        </WidgetField>
                      </BasicDesignWrapperField>
                    </WidgetField>
                  </template>
                </WidgetField>

                <WidgetField
                  v-else
                  :widget="widget"
                  :field="fields.all_games_list.field"
                  :style="cardsWrapperStyles"
                >
                  <BasicTitleField
                    :field="fields.all_games_nogames_title.field"
                  />

                  <BasicTitleField
                    v-if="
                      fields.all_games_nogames_title.field.options.description
                        ._active
                    "
                    :field="fields.all_games_nogames_title.field"
                    :value="
                      fields.all_games_nogames_title.field.options.description
                        .value
                    "
                    :options="
                      fields.all_games_nogames_title.field.options.description
                    "
                  />
                </WidgetField>

                <WidgetField
                  v-if="fields.show_more_button.active"
                  :widget="widget"
                  :field="fields.show_more_button.field"
                >
                  <BasicButtonField
                    :field="fields.show_more_button.field"
                    :current-state="states.show_more_button"
                  />
                </WidgetField>
              </BasicDesignWrapperField>
            </WidgetField>
          </div>
        </BasicDesignWrapperField>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/casino/list/fenix/useCasinoListFenixInitData";
import {
  StateController,
  useStates,
} from "~~/composables/widgets/common/useStates";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  AlignItems,
  Display,
  FlexDirection,
  State,
} from "~~/models/widgets/widget-controls.model";
import { getCommonStyles } from "~~/assets/utils/styles";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedField } = storeToRefs(widgetSettingsStore);

const {
  states,
  DEFAULT_STATES,

  init,
} = useInit(props.widget);

const gamesListWrapper = computed<ElementStyle>(() => {
  const settings = fields.value.games_list.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(settings.distance),
  };
});

const gamesCategoryWrapper = computed<ElementStyle>(() => {
  const settings = fields.value.games_category.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(settings.distance),
  };
});

const allGamesWrapper = computed<ElementStyle>(() => {
  const settings = fields.value.all_games.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(settings.distance),
  };
});

const cardsWrapperStyles = computed<ElementStyle>(() => {
  const settings = fields.value.game_cards.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(settings.distance),
  };
});

const showMoreGamesCardStyles = computed<ElementStyle>(() => {
  const options = fields.value.show_more_games_card.field.options;

  if (!options) {
    return {};
  }

  return {
    display: Display.FLEX,
    flexDirection: FlexDirection.COLUMN,
    alignItems: AlignItems.CENTER,
    justifyContent: options.alignment,
    width: "100%",
    height: "100%",
  };
});

const gamesInRowCategory = computed<number>(
  () => fields.value.games_category_list.field.options.maxGamesRow || 5
);
const gamesInRowAllGames = computed<number>(
  () => fields.value.all_games_list.field.options.maxGamesRow || 5
);

const categoryListWrapper = computed<ElementStyle>(() => {
  const settings = fields.value.games_category_list.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "grid",
    gridTemplateColumns: `repeat(${gamesInRowCategory.value}, minmax(0, 1fr))`,
    rowGap: getPxValueFromNumber(settings.gap.row),
    columnGap: getPxValueFromNumber(settings.gap.col),
    ...getCommonStyles(settings),
  };
});

const allGamesListWrapper = computed<ElementStyle>(() => {
  const settings = fields.value.all_games_list.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "grid",
    gridTemplateColumns: `repeat(${gamesInRowAllGames.value}, minmax(0, 1fr))`,
    rowGap: getPxValueFromNumber(settings.gap.row),
    columnGap: getPxValueFromNumber(settings.gap.col),
    ...getCommonStyles(settings),
  };
});

const isHoverContent = computed<boolean>(() => {
  return (
    selectedField.value?.name === fields.value.hover_content.field.name ||
    selectedField.value?.name === fields.value.game_title.field.name ||
    selectedField.value?.name === fields.value.play_money_button.field.name ||
    selectedField.value?.name === fields.value.play_free_button.field.name
  );
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = ["page_type"];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

const stateController = reactive<StateController>({
  states: states.value,
  resetStates,
  handleStateUpdate,
  getOptionsValuePath: (fieldName?: string, stateName?: string): string => {
    if (!fieldName && stateName) {
      if (stateName === State.DEFAULT) {
        return "options";
      }
      return `options.states.${stateName}`;
    }

    if (!fieldName || states.value[fieldName] === State.DEFAULT) {
      return "options";
    }
    return `options.states.${states.value[fieldName]}`;
  },
});

init();
</script>

<style lang="scss"></style>
