import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";

import {
  BASE_API_URL,
  BASE_MEDIA_URL,
  DEFAULT_ACCEPT_LANG,
  PROJECT_ID_DEFAULT,
  CONFIG_APP_BASE_API_URL,
} from "~~/constants";
import { useMetaStore } from "~~/store/meta";

const init = (): AxiosInstance => {
  const metaStore = useMetaStore();

  const instance = axios.create({
    timeout: 120000, // 20000, // Temporary solution to avoid timeout
    withCredentials: true,
  });

  instance.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {
      config.baseURL = BASE_API_URL();
      /*
        Note: Used default project id for development purposes
      */
      config.headers["X-PROJECT-ID"] =
        metaStore.projectId || PROJECT_ID_DEFAULT;

      /*
        Todo take from query param
      */
      config.headers["Accept-Language"] =
        config.headers["Accept-Language"] || DEFAULT_ACCEPT_LANG;

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return instance;
};

const initCdn = (): AxiosInstance => {
  // const metaStore = useMetaStore();

  const instance = axios.create({
    timeout: 120000, // 20000, // Temporary solution to avoid timeout
    withCredentials: false,
  });

  instance.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {
      config.baseURL = BASE_MEDIA_URL();
      /*
        Note: Used default project id for development purposes
      */
      // config.headers["X-PROJECT-ID"] =
      //   metaStore.projectId || PROJECT_ID_DEFAULT;

      /*
        Todo take from query param
      */
      // config.headers["Accept-Language"] = languagesStore.currentLanguageCode;

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return instance;
};

const initConfigApp = (): AxiosInstance => {
  const metaStore = useMetaStore();

  const instance = axios.create({
    timeout: 120000, // 20000, // Temporary solution to avoid timeout
    withCredentials: false,
  });

  instance.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {
      config.baseURL = CONFIG_APP_BASE_API_URL();
      /*
        Note: Used default project id for development purposes
      */
      config.headers["X-Project-Id"] =
        metaStore.projectId || PROJECT_ID_DEFAULT;

      /*
        Todo take from query param
      */
      config.headers["Accept-Language"] =
        config.headers["Accept-Language"] || DEFAULT_ACCEPT_LANG;

      return config;
    },
    function (error) {
      console.log(error);
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return instance;
};

const cdnApi = initCdn();

const dashboardApi = init();

const configAppApi = initConfigApp();

export { cdnApi, dashboardApi, configAppApi };

export default dashboardApi;
