import InlineWorker from "./tick-worker.js?worker&inline";

export class TickWorker {
  static instance: TickWorker | null = null;
  public worker!: Worker | null;

  constructor() {
    if (TickWorker.instance) {
      return TickWorker.instance;
    }

    this.worker = new InlineWorker();
    TickWorker.instance = this;

    return this;
  }

  destroy = () => {
    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
      TickWorker.instance = null;
    }
  };
}
