<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <!-- All controls in right sidebar -->
    <CardsListWidgetSettings
      :widget="widget"
      :states="states"
      :state-controller="stateController"
      @update-state="stateController.handleStateUpdate"
      @reset-states="stateController.resetStates"
    />

    <template
      v-if="widget.options.display.mode === 'carousel'"
      #root
    >
      <NavigationArrows
        v-if="fields.arrows.active"
        :widget="widget"
        :field="fields.arrows.field"
        :arrows-styles="arrowsStyles"
        :arrows-container-styles="arrowsContainerStyles"
        :arrows-button-styles="arrowsButtonStyles"
      />
      <NavigationPagination
        v-if="fields.pagination.active"
        :widget="widget"
        :field="fields.pagination.field"
        :pagination-styles="paginationStyles"
        :pagination-dots-styles="paginationDotsStyles"
      />
    </template>

    <div class="cards-list-widget">
      <BasicCardSliderField
        :widget="widget"
        :fields="fields"
        :states="states"
        style="height: 100%; align-content: stretch"
      >
        <template #slide="{ slideField }">
          <CardSlide
            :slide-field="slideField"
            :widget="widget"
            :states="states"
          />
        </template>
      </BasicCardSliderField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import _get from "lodash/get";

import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/cards-list/useCardsListInit";
import { useStates } from "~~/composables/widgets/common/useStates";
import NavigationArrows from "~~/entities/carousel/components/NavigationArrows.vue";
import NavigationPagination from "~~/entities/carousel/components/NavigationPagination.vue";
import { ElementStyle } from "~~/models/common";
import { useArrowsStyles } from "~~/entities/carousel/composables/styles/useArrowsStyles";
import { usePaginationStyles } from "~~/entities/carousel/composables/styles/usePaginationStyles";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { init, fields, states, DEFAULT_STATES } = useInit(toRef(props.widget));

const { stateController } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

init();

const arrowsStyles = computed<ElementStyle>(() => {
  return useArrowsStyles().arrowsStyles() as ElementStyle;
});

const arrowsContainerStyles = computed<ElementStyle>(() => {
  const arrowsOptions = fields.value.arrows.field.options || {};
  return useArrowsStyles(arrowsOptions).arrowsContainerStyles() as ElementStyle;
});

const arrowsButtonStyles = computed(() => {
  const arrows = fields.value.arrows.field;
  const arrowsDefaultOptions = arrows.options;
  const arrowsStatesOptions =
    _get(arrows, `options.states.${states.value.arrows}`) || {};

  return {
    ...useArrowsStyles(arrowsDefaultOptions).arrowsButtonStyles(),
    ...useArrowsStyles(arrowsStatesOptions).arrowsButtonStyles(),
  };
});

const paginationStyles = computed<ElementStyle>(() => {
  const paginationOptions = fields.value.pagination.field.options || {};
  return usePaginationStyles(
    paginationOptions
  ).paginationStyles() as ElementStyle;
});

const paginationDotsStyles = computed<ElementStyle>(() => {
  const pagination = fields.value.pagination.field;
  const paginationDefaultOptions = pagination.options;
  const paginationStatesOptions =
    _get(pagination, `options.states.${states.value.pagination}`) || {};

  paginationStatesOptions.isStroke = paginationDefaultOptions.isStroke;

  return {
    ...usePaginationStyles(paginationDefaultOptions).paginationDotsStyles(),
    ...usePaginationStyles(paginationStatesOptions).paginationDotsStyles(),
  };
});
</script>

<style lang="scss">
.cards-list-widget {
  position: relative;
}
</style>
