import type { Ref } from "vue";

import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import {
  Align,
  IBorderControl,
  IFillControl,
  IShadowControl,
  SpacingKeyName,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import {
  getBorderStyle,
  getBoxShadowStyle,
  getColorValue,
  getCornerRadiusStyle,
  getDecorationValue,
  getDisplayOrientationStyles,
  getFontSizeStyle,
  getHeightStyles,
  getSpacing,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { CellSizeType } from "~~/models/grid.interface";

export const usePopularLeaguesWidgetStyles = (
  widget: IWidgetWithFields,
  states: Ref<Record<string, State>>
) => {
  const fields = useWidgetFields(widget);
  const fieldOptions = computed(() => {
    return fields.value.menu_level_1.field.options;
  });
  const state = computed(() => {
    return states.value.menu_level_1;
  });

  const getStateStyle = (
    options: IWidgetOptions,
    propertyName: string
  ): unknown => {
    if (state.value === State.DEFAULT) {
      return options[propertyName];
    }
    return options.states[state.value][propertyName];
  };

  const itemsStyles = computed(() => {
    return {
      ...getDisplayOrientationStyles(
        fieldOptions.value.items.displayOrientation
      ),
      gap: getPxValueFromNumber(fieldOptions.value.items.gap),
    };
  });

  const elementsStyles = computed(() => {
    const padding = getSpacing(
      fieldOptions.value.padding,
      SpacingKeyName.PADDING
    );

    const border = getBorderStyle(
      getStateStyle(fieldOptions.value, "border") as IBorderControl
    );
    const cornerRadius = getCornerRadiusStyle(fieldOptions.value.cornerRadius);
    const color = getColorValue(
      getStateStyle(fieldOptions.value, "color") as IFillControl
    );
    const backgroundColor = getColorValue(
      getStateStyle(fieldOptions.value, "fill") as IFillControl
    );
    const shadow = getBoxShadowStyle(
      getStateStyle(fieldOptions.value, "shadow") as IShadowControl
    );

    const fontFamily = fieldOptions.value.fontFamily;
    const textAlign = getTextAlignStyle(fieldOptions.value.alignment);
    const fontSize = getFontSizeStyle(fieldOptions.value.theme);
    const decoration = getDecorationValue(
      getStateStyle(fieldOptions.value, "decoration") as TextStyle[]
    );

    const width = fieldOptions.value.size.width ?? {};

    let widthStyles = `${width?.value?.value}${width?.value?.type}`;

    if (width.type === CellSizeType.FILL) {
      widthStyles = "100%";
    }

    if (width.type === CellSizeType.HUG) {
      widthStyles = "fit-content";
    }

    const heightStyles = fieldOptions.value.size
      ? getHeightStyles(fieldOptions.value.size.height)
      : {};

    return {
      width: widthStyles,
      height: heightStyles.height,
      ...getDisplayOrientationStyles(
        fieldOptions.value.elements.displayOrientation
      ),
      gap: getPxValueFromNumber(fieldOptions.value.elements.gap),
      alignItems: Align.MIDDLE,
      justifyContent: fieldOptions.value.baseDisplaySettings.alignment,

      ...padding,

      ...border,
      ...cornerRadius,
      color,
      backgroundColor,
      ...shadow,
      ...textAlign,

      fontFamily,
      ...fontSize,
      ...decoration,
    };
  });

  const itemIconStyles = computed(() => {
    const iconSize = getPxValueFromNumber(fieldOptions.value.itemIcon?.size);

    const stateIconOptions = getStateStyle(fieldOptions.value, "itemIcon") as {
      color: IFillControl;
    };

    const iconColor = getColorValue(stateIconOptions?.color);

    return {
      width: iconSize,
      height: iconSize,
      fill: iconColor,
    };
  });

  return {
    itemsStyles,
    elementsStyles,
    itemIconStyles,
  };
};
