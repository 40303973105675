import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import {
  Alignment,
  DisplayOrientation,
  State,
  ResizingType,
  TextStyle,
  AlignItems,
  JustifyContent,
  ContainerMode,
  Align,
  AlignContent,
  JustifyItems,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { getInitialOptions } from "~~/assets/utils/common/init-data";
import {
  DESIGN_INITIAL,
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
  getDefaultDecoration,
  getDefaultFillValue,
  getDefaultFontFamily,
  getDefaultShadow,
  getDefaultSize,
  getDefaultSpacing,
  getDefaultTheme,
} from "~~/constants/configs/common/widget-initial";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";
import { FIELDS_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/fields-styling";
import { BUTTON_INITIAL } from "~~/constants/configs/card-common/initial-constants";
import { FormStyleFieldName } from "~~/models/widgets/form.model";
import {
  EventInfoDirection,
  EventInfoVariant,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetTypes";
import { useCarouselInit } from "~~/entities/carousel/composables/useCarouselInit";
import { useArrowsInit } from "~~/entities/carousel/composables/useArrowsInit";
import { usePaginationInit } from "~~/entities/carousel/composables/usePaginationInit";

import { TITLE_INITIAL } from "../text/useTextWidgetConfig";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const { initFields, initWidgetOptions, addFieldInitialOptions } =
    useCreateFields(widget.value);

  const DEFAULT_STATES: Record<string, string> = {
    arrows: State.DEFAULT,
    widget: "top_match",
    match_cards: State.DEFAULT,
    button_1: State.DEFAULT,
    button_2: State.DEFAULT,
    continue_button: State.DEFAULT,
    outcomes: State.DEFAULT,
    bet_amounts: State.DEFAULT,
    pagination: State.DEFAULT,
    [FormStyleFieldName.FIELD]: State.DEFAULT,
  };

  const states = ref({
    ...DEFAULT_STATES,
  });

  const widgetOptions = initWidgetOptions([], {
    display: {
      mode: ContainerMode.FLEX,
      layout: DisplayOrientation.HORIZONTAL,
      verticalGap: 0,
      horizontalGap: 0,
      alignment: `${Align.MIDDLE} ${Align.MIDDLE}`,
      horizontalOverflow: "visible",
      verticalOverflow: "visible",
    },
    success: {
      gap: 16,
    },
    ...useCarouselInit(),
  });

  const init = (): void => {
    initFields();
    addFieldInitialOptions("arrows", useArrowsInit());
    addFieldInitialOptions("pagination", usePaginationInit());

    const matchState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
      };
    };

    addFieldInitialOptions("match_cards", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          size: {
            height: getDefaultSize().height,
          },
          display: {
            align: {
              alignItems: AlignItems.STRETCH,
              justifyContent: JustifyContent.STRETCH,
            },
            gap: 8,
          },
          states: {
            [State.HOVER]: matchState(),
            [State.ACTIVE]: matchState(),
          },
        },
      },
    });

    addFieldInitialOptions("title", {
      data: TITLE_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: ResizingType.FILL,
            alignment: Alignment.CENTER,
          },
          icon: {
            _active: true,
            value: "mdi:crown",
            color: getDefaultFillValue("#000000"),
            size: 20,
            position: Alignment.LEFT,
            gap: 12,
          },
        },
      },
    });

    addFieldInitialOptions("image", {
      data: FLEXIBLE_IMAGE_INITIAL(),
    });

    addFieldInitialOptions("content", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            align: {
              alignItems: AlignItems.STRETCH,
              justifyContent: JustifyContent.STRETCH,
            },
            alignContent: "start",
            gap: 8,
          },
        },
      },
    });

    addFieldInitialOptions("timer", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: ResizingType.FILL,
            alignment: Alignment.CENTER,
            gap: 4,
          },
        },
      },
    });

    addFieldInitialOptions("timer_header", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          ...getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment", "padding"],
          }).options,
          display: {
            layout: ResizingType.FILL,
            alignment: Alignment.CENTER,
          },
        },
      },
      values: {
        theme: getDefaultTheme(14),
      },
    });

    addFieldInitialOptions("timer_counter", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: ResizingType.FILL,
            alignment: Alignment.CENTER,
            gap: 4,
          },
        },
      },
    });

    addFieldInitialOptions("timer_value", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: ResizingType.FILL,
            alignment: Alignment.CENTER,
            gap: 4,
          },
          fontFamily: getDefaultFontFamily(),
          numbers: getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment"],
          }).options,
          caption: getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment"],
            values: {
              theme: getDefaultTheme(12),
            },
          }).options,
        },
      },
    });

    addFieldInitialOptions("timer_separator", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          ...getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment", "padding"],
          }).options,
          display: {
            layout: ResizingType.HUG,
            alignment: Alignment.CENTER,
          },
        },
      },
    });

    addFieldInitialOptions("event_info", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          size: getDefaultSize(),
          display: {
            layout: EventInfoVariant.Variant1,
            direction: EventInfoDirection.Default,
            gap: 8,
            layoutAlignment: {
              justifyContent: AlignItems.CENTER,
              alignContent: AlignContent.SPACE_BETWEEN,
              justifyItems: JustifyItems.CENTER,
              alignItems: JustifyContent.FLEX_START,
            },
          },
        },
      },
    });

    const teamState = () => {
      return {
        color: getDefaultFillValue(),
      };
    };

    addFieldInitialOptions("team", {
      data: DESIGN_INITIAL(),
      exclude: ["padding"],
      additionalData: {
        options: {
          size: {
            height: getDefaultSize().height,
          },
          display: {
            layout: ResizingType.FILL,
            alignment: Alignment.CENTER,
          },
          ...getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link"],
          }).options,
          icon: {
            _active: true,
            size: 20,
            position: Alignment.LEFT,
            gap: 4,
          },
          states: {
            [State.HOVER]: teamState(),
            [State.ACTIVE]: teamState(),
          },
        },
      },
      values: {
        theme: getDefaultTheme(13),
      },
    });

    addFieldInitialOptions("date", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: DisplayOrientation.HORIZONTAL,
            gap: 4,
          },
          ...getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment", "padding"],
          }).options,
          icon: {
            _active: true,
            size: 20,
            gap: 8,
            color: getDefaultFillValue("#69B1FF"),
            dateValue: "ant-design:calendar-filled",
            timeValue: "ant-design:clock-circle-filled",
          },
        },
      },
      values: {
        theme: getDefaultTheme(14),
      },
    });

    addFieldInitialOptions("markets", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            layout: DisplayOrientation.HORIZONTAL,
            gap: 4,
          },
        },
      },
    });

    const initialBetItemState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: {
          fill: getDefaultFillValue(),
          x: 0,
          y: 0,
          blur: 0,
          spread: 0,
        },
        name: {
          color: getDefaultFillValue("#1677ff"),
          theme: 13,
          decoration: [],
        },
        coef: {
          color: getDefaultFillValue("#1677ff"),
          theme: 13,
          decoration: [TextStyle.BOLD],
        },
        arrow: {
          color: getDefaultFillValue("#1677ff"),
        },
      };
    };

    addFieldInitialOptions("outcomes", {
      data: DESIGN_INITIAL(),
      exclude: ["spacing"],
      additionalData: {
        options: {
          fontFamily: getDefaultFontFamily(),
          name: {
            color: getDefaultFillValue("#1677ff"),
            theme: 13,
            decoration: [],
          },
          coef: {
            color: getDefaultFillValue("#1677ff"),
            theme: 13,
            decoration: [TextStyle.BOLD],
          },
          padding: getDefaultSpacing(8),

          states: {
            [State.HOVER]: initialBetItemState(),
            [State.ACTIVE]: initialBetItemState(),
            [State.DISABLED]: initialBetItemState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#e6f4ff"),
        cornerRadius: getDefaultCornerRadiusValue({ value: 8 }),
      },
    });

    addFieldInitialOptions("bets", {
      data: DESIGN_INITIAL(),
      exclude: ["padding", "link"],
      additionalData: {
        options: {
          layout: DisplayOrientation.HORIZONTAL,
          direction: "default",
          alignment: Alignment.LEFT,
          gap: 4,
        },
      },
    });

    addFieldInitialOptions("input", {
      data: FIELDS_STYLE_INITIAL(),
      additionalData: {
        options: {
          size: {
            width: getDefaultSize().width,
          },
        },
      },
    });

    const betAmountState = () => {
      return {
        fill: getDefaultFillValue(),
        border: getDefaultBorderValue(),
        shadow: getDefaultShadow(),
        color: getDefaultFillValue(),
      };
    };

    addFieldInitialOptions("bet_amounts", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            gap: 4,
          },
          ...getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment", "padding"],
          }).options,
          states: {
            [State.HOVER]: betAmountState(),
            [State.ACTIVE]: betAmountState(),
            [State.DISABLED]: betAmountState(),
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#E6F4FF"),
        color: getDefaultFillValue("#1677FF"),
        theme: getDefaultTheme(12),
      },
    });

    addFieldInitialOptions("not_enough_container", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            gap: 8,
            layout: DisplayOrientation.VERTICAL,
            alignment: Alignment.CENTER,
          },
          ...getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment", "padding"],
          }).options,
          icon: {
            _active: true,
            size: 20,
            gap: 8,
            color: getDefaultFillValue(),
            value: "carbon:warning-filled",
          },
          deposit: getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment", "padding"],
            values: {
              theme: getDefaultTheme(14),
              color: getDefaultFillValue("#FAAD14"),
            },
          }).options,
        },
      },
      values: {
        fill: getDefaultFillValue("#FFFBE6"),
        color: getDefaultFillValue("#FAAD14"),
        theme: getDefaultTheme(14),
      },
    });

    addFieldInitialOptions("button_group", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            gap: 16,
            layout: DisplayOrientation.VERTICAL,
            // alignment: Alignment.CENTER,
          },
        },
      },
    });

    addFieldInitialOptions("button_1", {
      data: BUTTON_INITIAL(),
      values: {
        link: {
          type: null,
          value: null,
          authorizeValue: null,
          hasAuthorizeValue: false,
          isNewTabOpen: null,
        },
      },
    });

    addFieldInitialOptions("button_2", {
      data: BUTTON_INITIAL(),
    });

    addFieldInitialOptions("success_message", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            gap: 8,
            layout: DisplayOrientation.VERTICAL,
            alignment: Alignment.CENTER,
          },
          ...getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment", "padding"],
          }).options,
          icon: {
            _active: true,
            size: 20,
            gap: 12,
            color: getDefaultFillValue("#52C41A"),
            value: "ant-design:check-circle-filled",
          },
          print: getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment"],
            values: {
              theme: getDefaultTheme(12),
              color: getDefaultFillValue("#52C41A"),
            },
          }).options,
        },
      },
      values: {
        color: getDefaultFillValue("#52C41A"),
        fill: getDefaultFillValue("#F6FFED"),
        theme: getDefaultTheme(16),
        spacing: {
          margin: getDefaultSpacing(0),
          padding: getDefaultSpacing(12),
        },
      },
    });

    addFieldInitialOptions("bet_card", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            gap: 8,
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#F2F4F8"),
        spacing: {
          margin: getDefaultSpacing(),
          padding: getDefaultSpacing(8),
        },
      },
    });

    addFieldInitialOptions("bets_type", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          ...getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment", "padding"],
          }).options,
        },
      },
      values: {
        color: getDefaultFillValue("#1677FF"),
        fill: getDefaultFillValue("#E6F4FF"),
        theme: getDefaultTheme(12),
        cornerRadius: getDefaultCornerRadiusValue(4),
        spacing: {
          margin: getDefaultSpacing(0),
          padding: getDefaultSpacing({
            left: 8,
            right: 8,
            top: 2,
            bottom: 2,
          }),
        },
      },
    });

    addFieldInitialOptions("event_container", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          display: {
            gap: 8,
          },
        },
      },
      values: {
        fill: getDefaultFillValue("#ffffff"),
        spacing: {
          margin: getDefaultSpacing(),
          padding: getDefaultSpacing(8),
        },
      },
    });

    addFieldInitialOptions("event_name", {
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment"],
      values: {
        theme: getDefaultTheme(14),
        decoration: getDefaultDecoration([TextStyle.BOLD]),
        padding: getDefaultSpacing({
          bottom: 8,
        }),
      },
    });

    addFieldInitialOptions("bet_market", {
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment"],
      values: {
        theme: getDefaultTheme(14),
        color: getDefaultFillValue("#000000", 65),
        padding: getDefaultSpacing({
          bottom: 8,
        }),
      },
    });

    addFieldInitialOptions("bet_name", {
      data: TITLE_INITIAL(),
      exclude: ["link", "alignment"],
      values: {
        theme: getDefaultTheme(14),
      },
    });

    addFieldInitialOptions("coefficent", {
      data: DESIGN_INITIAL(),
      additionalData: {
        options: {
          ...getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment", "padding"],
          }).options,
        },
      },
      values: {
        color: getDefaultFillValue("#1677FF"),
        fill: getDefaultFillValue("#E6F4FF"),
        theme: getDefaultTheme(12),
        cornerRadius: getDefaultCornerRadiusValue(4),
        spacing: {
          margin: getDefaultSpacing(0),
          padding: getDefaultSpacing({
            left: 8,
            right: 8,
            top: 2,
            bottom: 2,
          }),
        },
      },
    });

    addFieldInitialOptions("result_bet_amounts", {
      data: {
        options: {
          display: {
            gap: 8,
            layout: DisplayOrientation.VERTICAL,
          },
        },
      },
      exclude: ["spacing"],
      additionalData: {
        options: {
          padding: getDefaultSpacing(),
          fontFamily: getDefaultFontFamily(),
          label: getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment"],
            values: {
              theme: getDefaultTheme(12),
              color: getDefaultFillValue("#000000", 45),
            },
          }).options,
          value: getInitialOptions({
            data: TITLE_INITIAL(),
            exclude: ["link", "alignment"],
            values: {
              theme: getDefaultTheme(14),
            },
          }).options,
        },
      },
    });

    addFieldInitialOptions("continue_button", {
      data: BUTTON_INITIAL(),
      values: {},
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
