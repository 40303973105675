<template>
  <div
    class="config-radio-icons p-l-16 p-r-16 p-t-16 widget-control-container p-b-16"
    :class="{
      'config-radio-icons--shrink': shrink,
      'config-radio-icons--row': row,
    }"
  >
    <p
      class="m-b-4"
      :class="{
        'sub-heading': isBold,
      }"
    >
      {{ label }}
    </p>
    <div
      class="config-radio-icons__container"
      :class="{
        'm-t-16': isBold,
      }"
    >
      <span
        v-for="col in items"
        :key="col.value"
        :class="{
          'config-radio-icons__icon-wrapper--active': modelValue === col.value,
          'config-radio-icons__icon-wrapper--square': isSquareIcons,
        }"
        class="config-radio-icons__icon-wrapper pointer"
        @click="updateValue(col.value)"
      >
        <span class="config-radio-icons__icon">
          <CommonIcon
            :name="col.icon"
            :style="iconStyle"
            :class="col.iconClassName"
          />
        </span>

        <span
          v-if="col.label"
          class="config-radio-icons__label"
        >
          {{ col.label }}
        </span>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ElementStyle } from "~~/models/common";

type ItemValue = string | number;

type Item = {
  icon: Component;
  value: ItemValue;
  label?: string;
  iconClassName?: string;
};

const props = defineProps<{
  modelValue?: ItemValue;
  label: string;
  items: Item[];
  isBold?: boolean;
  shrink?: boolean;
  row?: boolean;
  isSquareIcons?: boolean;
  customIconWidth?: string;
}>();

const emit = defineEmits<{
  (e: "update:model-value", event: ItemValue): void;
}>();

const updateValue = (value: ItemValue): void => {
  emit("update:model-value", value);
};

const iconStyle = computed<ElementStyle>(() => {
  if (!props.customIconWidth) {
    return {};
  }

  return {
    "--customWidth": props.customIconWidth,
    "--customHeight": "fit-content",
  };
});
</script>

<style lang="scss">
$wrapper: "config-radio-icons";
.#{$wrapper} {
  &__container {
    @include flex(center, space-around, $space-s);
  }

  &__label {
    font-size: 14px;
    line-height: 22px;
    margin-top: 4px;
  }

  &__icon-wrapper {
    @include flex(center, center, 0);
    flex-direction: column;
    border: 1px solid $c-grey-15;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: 32px;

    &--active {
      border: 2px solid $c-primary-base !important;
    }

    .#{$wrapper}__icon {
      display: flex;
      align-self: stretch;
      justify-content: center;
    }

    &--square {
      padding: 8px;
      border: 2px solid $c-grey-15;
      border-radius: 6px;

      .#{$wrapper}__icon {
        display: flex;
        background: $c-light-blue;
        padding: 8px;
        border-radius: 4px;
      }

      svg {
        color: $c-primary-base-hover;
        width: 16px;
        height: 16px;
      }
    }
  }

  .common-icon {
    flex: 1;
    justify-content: center;

    svg {
      width: var(--customWidth);
      height: var(--customHeight);
    }
  }

  &--shrink {
    .config-radio-icons__icon-wrapper {
      padding: 8px 0;
      flex-grow: 1;
    }
  }

  &--row {
    .config-radio-icons__container {
      gap: 0;

      .config-radio-icons__icon-wrapper {
        flex: 1;
        font-size: 16px;
        padding: 4px;
        border-width: 1px !important;

        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
</style>
