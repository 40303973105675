<script lang="ts" setup>
import { IconColor, IconType } from "~~/models/common/common-modal.enum";

const { modelValue } = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const loading = ref<boolean>(false);
const stringsStrategy = ref<boolean>(false);

const toolsTabs = ref({
  "import": "Import OSG",
  "export": "Export OSG",
  "operations": "Operations with content",
  "url_change": "Url change",
});

const toolsTabsActive = ref<string>("import");

const isActiveExport = computed<boolean>(
  () => toolsTabsActive.value === "export" && modelValue
);

watch(toolsTabsActive, () => {
  stringsStrategy.value = false;
});
</script>

<template>
  <CommonModal
    :visible="modelValue"
    :icon-color="IconColor.DEFAULT"
    :icon-type="IconType.INFO"
    :destroy-on-close="true"
    width="1600px"
    title="Content tools"
    closable
    @cancel="emit('close')"
  >
    <template #content>
      <a-tabs
        v-model:active-key="toolsTabsActive"
        size="large"
      >
        <a-tab-pane
          key="import"
          :tab="toolsTabs['import']"
          :disabled="loading"
        >
          <ContentToolsImport />
        </a-tab-pane>

        <a-tab-pane
          key="export"
          :tab="toolsTabs['export']"
          :disabled="loading"
        >
          <ContentToolsExport
            v-model:strategy="stringsStrategy"
            v-model:loading="loading"
            :is-active="isActiveExport"
          />
        </a-tab-pane>

        <a-tab-pane
          key="operations"
          :tab="toolsTabs['operations']"
          :disabled="loading"
        >
          <ContentToolsOperations v-model:loading="loading" />
        </a-tab-pane>

        <a-tab-pane
          key="url_change"
          :tab="toolsTabs['url_change']"
          :disabled="loading"
        >
          <ContentToolsUrl
            v-if="toolsTabsActive === 'url_change'"
            @close="$emit('close')"
          />
        </a-tab-pane>
      </a-tabs>
    </template>

    <template #footer>
      <a-typography-text
        v-show="stringsStrategy"
        type="secondary"
      >
        <small
          >*do not close the modal window when global process is
          running...</small
        >
      </a-typography-text>
    </template>
  </CommonModal>
</template>
