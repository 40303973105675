<template>
  <div class="promo-group">
    <WidgetField
      :widget="widget"
      :field="groupListField"
      :style="promoGroupListStyles"
      class="promo-group__field"
    >
      <PromoGroupCard
        v-for="idx in cardsCount"
        :key="idx"
        :widget="widget"
        :field="groupCardsField"
        :field-state="states[groupCardsField.name]"
        :image-field="cardImageField"
        :title-field="cardTitleField"
        :description-field="cardDescriptionField"
        :secondary-description-field="cardSecondaryDescriptionField"
        :button-group-field="cardButtonGroupField"
        :button1-field="cardButton1Field"
        :button2-field="cardButton2Field"
        :image-value="`${imageValuePath}${idx}.${imageFormat}`"
        :button-state-1="states[cardButton1Field.name]"
        :button-state-2="states[cardButton2Field.name]"
        :style="cellStyles"
      />

      <template
        v-if="
          fields &&
          props.groupListField.options.displayMode === CardsDisplayMode.Carousel
        "
      >
        <NavigationArrows
          v-if="fields.arrows.field.options._active"
          :widget="widget"
          :field="fields.arrows.field"
          :arrows-styles="arrowsStyles"
          :arrows-container-styles="arrowsContainerStyles"
          :arrows-button-styles="arrowsButtonStyles"
        />
        <NavigationPagination
          v-if="fields.pagination.field.options._active"
          :widget="widget"
          :field="fields.pagination.field"
          :pagination-styles="paginationStyles"
          :pagination-dots-styles="paginationDotsStyles"
        />
      </template>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";
import _get from "lodash/get";

import { getCommonStyles, getDisplayGridStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";
import {
  Display,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { CardsDisplayMode } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetTypes";
import { getFlexAlign } from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import NavigationPagination from "~~/entities/carousel/components/NavigationPagination.vue";
import NavigationArrows from "~~/entities/carousel/components/NavigationArrows.vue";
import { IFields } from "~~/composables/widgets/useWidgetFields";
import { useArrowsStyles } from "~~/entities/carousel/composables/styles/useArrowsStyles";
import { usePaginationStyles } from "~~/entities/carousel/composables/styles/usePaginationStyles";
import { StateController } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields?: IFields;
  groupListField: IWidgetField;
  groupCardsField: IWidgetField;
  cardImageField: IWidgetField;
  cardTitleField: IWidgetField;
  cardDescriptionField: IWidgetField;
  cardSecondaryDescriptionField?: IWidgetField;
  cardButtonGroupField: IWidgetField;
  cardButton1Field: IWidgetField;
  cardButton2Field: IWidgetField;
  states: Record<string, State>;
  stateController?: StateController;
  cardsCount: number;
  imageValuePath: string;
  imageFormat: string;
  imageValue?: string;
  titleValue?: string;
  descriptionValue?: string;
  button1Value?: string;
  button2Value?: string;
}>();

const fields = computed(() => {
  return props.fields;
});

const promoGroupListStyles = computed<ElementStyle>(() => {
  const displayMode = props.groupListField.options.displayMode;
  const carouselSettings = props.groupListField.options.carouselSettings;
  const carousel = props.groupListField.options.carousel;

  const styles = {
    ...getCommonStyles({
      ...props.groupListField.options,
    }),
  };
  if (displayMode === CardsDisplayMode.Carousel) {
    const alignItems = getFlexAlign(carousel.alignment)["align-items"];
    return {
      display: Display.FLEX,
      alignItems,
      gap: getPxValueFromNumber(carouselSettings.gap),
      ...styles,
    };
  }

  return {
    ...getDisplayGridStyles(props.groupListField.options.gridSettings),
    ...styles,
  };
});

const cellStyles = computed(() => {
  const displayMode = props.groupListField.options.displayMode;
  const carouselSettings = props.groupListField.options.carouselSettings;

  if (displayMode === CardsDisplayMode.Carousel) {
    const styles = {
      flexShrink: 0,
    };

    if (carouselSettings.layout === ResizingType.FIXED) {
      return {
        ...styles,
        width: getPxValueFromNumber(carouselSettings.width),
      };
    } else {
      return {
        ...styles,
        width: `calc((100% - (${
          carouselSettings.maxVisibleCards
        } - 1) * ${getPxValueFromNumber(carouselSettings.gap)}) / ${
          carouselSettings.maxVisibleCards
        })`,
      };
    }
  }

  return {};
});

const arrowsStyles = computed<CSSProperties>(() => {
  return useArrowsStyles().arrowsStyles();
});

const arrowsContainerStyles = computed<CSSProperties>(() => {
  if (fields.value) {
    const arrowsOptions = fields.value.arrows?.field.options || {};
    return useArrowsStyles(arrowsOptions).arrowsContainerStyles();
  }
  return {};
});

const arrowsButtonStyles = computed(() => {
  if (fields.value && props.stateController) {
    const arrows = fields.value.arrows.field;
    const arrowsDefaultOptions = arrows.options;
    const arrowsStatesOptions =
      _get(arrows, `${props.stateController.getOptionsValuePath("arrows")}`) ||
      {};

    return {
      ...useArrowsStyles(arrowsDefaultOptions).arrowsButtonStyles(),
      ...useArrowsStyles(arrowsStatesOptions).arrowsButtonStyles(),
    };
  }

  return {};
});

const paginationStyles = computed<CSSProperties>(() => {
  const paginationOptions = fields.value?.pagination?.field.options || {};
  return usePaginationStyles(paginationOptions).paginationStyles();
});

const paginationDotsStyles = computed<CSSProperties>(() => {
  if (fields.value && props.stateController) {
    const pagination = fields.value.pagination.field;
    const paginationDefaultOptions = pagination.options;
    const paginationStatesOptions =
      _get(
        pagination,
        `${props.stateController.getOptionsValuePath("pagination")}`
      ) || {};

    paginationStatesOptions.isStroke = paginationDefaultOptions.isStroke;

    return {
      ...usePaginationStyles(paginationDefaultOptions).paginationDotsStyles(),
      ...usePaginationStyles(paginationStatesOptions).paginationDotsStyles(),
    };
  }

  return {};
});
</script>

<style lang="scss">
.promo-group {
  &__field {
    position: relative;
  }
}
</style>
