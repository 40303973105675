import { State } from "~~/models/widgets/widget-controls.model";
import { useStates } from "~~/composables/widgets/common/useStates";

export const useWelcomeBonusFenixWidgetStateController = () => {
  const DEFAULT_STATES = {
    bonus_header: State.DEFAULT,
    bonus_title: State.DEFAULT,

    no_bonus: State.DEFAULT,
    no_bonus_title: State.DEFAULT,

    button: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const { stateController } = useStates({
    defaultStates: DEFAULT_STATES,
    states,
    resetConditionHandler: (stateName: string) => {
      const skip = [""];
      if (skip.includes(stateName)) return false;
      return true;
    },
  });

  return {
    stateController,
  };
};
