import {
  getColorValue,
  getDecorationValue,
  getFontFamilyStyle,
  getFontSizeStyle,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";
import { clearObject } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";

export const useBonusTitleStyles = (bonusTitleOptions: any = {}) => {
  const theme = bonusTitleOptions.theme
    ? getFontSizeStyle(bonusTitleOptions.theme)
    : {};
  const fontFamily = bonusTitleOptions.fontFamily
    ? getFontFamilyStyle(bonusTitleOptions.fontFamily)
    : {};
  const decoration = bonusTitleOptions.decoration
    ? getDecorationValue(bonusTitleOptions.decoration)
    : {};
  const alignment = bonusTitleOptions.alignment
    ? getTextAlignStyle(bonusTitleOptions.alignment)
    : {};

  const styles = {
    color: bonusTitleOptions.color
      ? getColorValue(bonusTitleOptions.color)
      : undefined,
    ...theme,
    ...fontFamily,
    ...decoration,
    ...alignment,
  };
  return clearObject(styles);
};
