import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { ResizingType } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import { generateFlex } from "../helpers";
import { getFlexPosition } from "../../widget-settings";

import { generateDropdownContainerCssString } from "./timezones-widget-css";

const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    const isFill = options.display.resizing === ResizingType.FILL;

    const widgetStyles = generateFlex({
      flex: "flex",
      align: isFill ? "stretch" : getFlexPosition(options.display.alignment),
      justify: "flex-start",
      direction: "column",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateCasinoProvidersFilterWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);

  const dropdown_container = widget.content["dropdown_container"];
  const dropdown_menu = widget.content["dropdown_menu"];
  const menu_items = widget.content["menu_items"];

  // Dropdown container
  dropdown_container.options._cssClass =
    generateClassName("dropdown_container");
  dropdown_container.options.text._cssClass = generateClassName(
    "dropdown_container_text"
  );
  dropdown_container.options.icon._cssClass = generateClassName(
    "dropdown_container_icon"
  );
  dropdown_container.options.arrow._cssClass = generateClassName(
    "dropdown_container_arrow"
  );
  // Dropdown menu
  dropdown_menu.options._cssClass = generateClassName("dropdown_menu");

  // Menu items
  menu_items.options._cssClass = generateClassName("menu_items");
  menu_items.options.itemsText._cssClass = generateClassName("menu_items_text");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateDropdownContainerCssString(dropdown_container, widget)
    // generateMenuCssString(dropdown_menu, menu_items, widget),
    // generateFieldContainerCssString(search_field as IWidgetField),
    // generateMenuItemsCssString(menu_items, widget)
  )("");
};
