<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>
        <!-- back_button -->
        <template #back_button-design>
          <ControlsList
            :widget="widget"
            :controls="backButtonDesignControls"
          />
        </template>

        <template #back_button-content>
          <ControlsList
            :widget="widget"
            :controls="backButtonContentControls"
          />
        </template>

        <template #back_button-states>
          <ControlsList
            :widget="widget"
            :controls="backButtonStateControls"
          />
        </template>
        <!-- header -->
        <template #header-design>
          <ControlsList
            :widget="widget"
            :controls="headerDesignControls"
          />
        </template>

        <template #header-content>
          <ControlsList
            :widget="widget"
            :controls="headerContentControls"
          />
        </template>
        <!-- page_title -->
        <template #page_title-design>
          <ControlsList
            :widget="widget"
            :controls="pageTitleDesignControls"
          />
        </template>

        <template #page_title-content>
          <ControlsList
            :widget="widget"
            :controls="pageTitleContentControls"
          />
        </template>
        <!-- search_result -->
        <template #search_result-design>
          <ControlsList
            :widget="widget"
            :controls="searchResultDesignControls"
          />
        </template>

        <template #search_result-content>
          <ControlsList
            :widget="widget"
            :controls="searchResultContentControls"
          />
        </template>
        <!-- sorting -->
        <template #sorting-design>
          <ControlsList
            :widget="widget"
            :controls="sortingDesignControls"
          />
        </template>

        <template #sorting-content>
          <ControlsList
            :widget="widget"
            :controls="sortingContentControls"
          />
        </template>

        <template #sorting-states>
          <ControlsList
            :widget="widget"
            :controls="sortingStateControls"
          />
        </template>

        <!-- search -->
        <template #search-design>
          <ControlsList
            :widget="widget"
            :controls="searchDesignControls"
          />
        </template>

        <template #search-content>
          <ControlsList
            :widget="widget"
            :controls="searchContentControls"
          />
        </template>

        <template #search-states>
          <ControlsList
            :widget="widget"
            :controls="searchStateControls"
          />
        </template>
        <!-- games_list -->
        <template #games_list-design>
          <ControlsList
            :widget="widget"
            :controls="gamesListDesignControls"
          />
        </template>

        <template #games_list-content>
          <ControlsList
            :widget="widget"
            :controls="gamesListContentControls"
          />
        </template>
        <!-- games_category -->
        <template #games_category-design>
          <ControlsList
            :widget="widget"
            :controls="gamesCategoryDesignControls"
          />
        </template>

        <template #games_category-content>
          <ControlsList
            :widget="widget"
            :controls="gamesCategoryContentControls"
          />
        </template>

        <template #games_category-states>
          <ControlsList
            :widget="widget"
            :controls="gamesCategoryStateControls"
          />
        </template>
        <!-- games_category_title -->
        <template #games_category_title-design>
          <ControlsList
            :widget="widget"
            :controls="categoryTitleDesignControls"
          />
        </template>

        <template #games_category_title-content>
          <ControlsList
            :widget="widget"
            :controls="categoryTitleContentControls"
          />
        </template>

        <template #games_category_title-states>
          <ControlsList
            :widget="widget"
            :controls="categoryTitleStateControls"
          />
        </template>
        <!-- games_category_button -->
        <template #games_category_button-design>
          <ControlsList
            :widget="widget"
            :controls="categoryButtonDesignControls"
          />
        </template>

        <template #games_category_button-content>
          <ControlsList
            :widget="widget"
            :controls="categoryButtonContentControls"
          />
        </template>

        <template #games_category_button-states>
          <ControlsList
            :widget="widget"
            :controls="categoryButtonStateControls"
          />
        </template>
        <!-- games_category_hover_button -->
        <template #games_category_hover_button-design>
          <ControlsList
            :widget="widget"
            :controls="categoryHoverButtonDesignControls"
          />
        </template>

        <template #games_category_hover_button-content>
          <ControlsList
            :widget="widget"
            :controls="categoryHoverButtonContentControls"
          />
        </template>

        <template #games_category_hover_button-states>
          <ControlsList
            :widget="widget"
            :controls="categoryHoverButtonStateControls"
          />
        </template>
        <!-- games_category_list -->
        <template #games_category_list-design>
          <ControlsList
            :widget="widget"
            :controls="categoryListDesignControls"
          />
        </template>

        <template #games_category_list-content>
          <ControlsList
            :widget="widget"
            :controls="categoryListContentControls"
          />
        </template>
        <!-- all_games -->
        <template #all_games-design>
          <ControlsList
            :widget="widget"
            :controls="allGamesDesignControls"
          />
        </template>

        <template #all_games-content>
          <ControlsList
            :widget="widget"
            :controls="allGamesContentControls"
          />
        </template>
        <!-- all_games_title -->
        <template #all_games_title-design>
          <ControlsList
            :widget="widget"
            :controls="allGamesTitleDesignControls"
          />
        </template>

        <template #all_games_title-content>
          <ControlsList
            :widget="widget"
            :controls="allGamesTitleContentControls"
          />
        </template>
        <!-- all_games_list -->
        <template #all_games_list-design>
          <ControlsList
            :widget="widget"
            :controls="allGamesListDesignControls"
          />
        </template>

        <template #all_games_list-content>
          <ControlsList
            :widget="widget"
            :controls="allGamesListContentControls"
          />
        </template>
        <!-- show_more_button -->
        <template #show_more_button-design>
          <ControlsList
            :widget="widget"
            :controls="showMoreButtonDesignControls"
          />
        </template>

        <template #show_more_button-content>
          <ControlsList
            :widget="widget"
            :controls="showMoreButtonContentControls"
          />
        </template>

        <template #show_more_button-states>
          <ControlsList
            :widget="widget"
            :controls="showMoreButtonStateControls"
          />
        </template>
        <!-- game_name -->
        <template #game_name>
          <ControlsList
            :widget="widget"
            :controls="gameNameControls"
          />
        </template>
        <!-- game_cards -->
        <template #game_cards-design>
          <ControlsList
            :widget="widget"
            :controls="gameCardsDesignControls"
          />
        </template>

        <template #game_cards-content>
          <ControlsList
            :widget="widget"
            :controls="gameCardsContentControls"
          />
        </template>
        <template #show_more_games_card-design>
          <ControlsList
            :widget="widget"
            :controls="showMoreGamesCardDesignControls"
          />
        </template>

        <template #show_more_games_card-content>
          <ControlsList
            :widget="widget"
            :controls="showMoreGamesCardContentControls"
          />
        </template>
        <!-- game_cover -->
        <template #game_cover-design>
          <ControlsList
            :widget="widget"
            :controls="gameCoverDesignControls"
          />
        </template>

        <template #game_cover-content>
          <ControlsList
            :widget="widget"
            :controls="gameCoverContentControls"
          />
        </template>

        <template #game_cover-states>
          <ControlsList
            :widget="widget"
            :controls="gameCoverStatesControls"
          />
        </template>
        <!-- favorite_icon -->
        <template #favorite_icon>
          <ControlsList
            :widget="widget"
            :controls="casinoListFavIconControls"
          />
        </template>
        <!-- promo_tag -->
        <template #promo_tag-design>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTagButtonDesignControls"
          />
        </template>

        <template #promo_tag-content>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTagButtonContentControls"
          />
        </template>

        <template #promo_tag-states>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTagButtonStateControls"
          />
        </template>
        <!-- promo_tag -->
        <template #hover_content>
          <ControlsList
            :widget="widget"
            :controls="casinoListHoverContentControls"
          />
        </template>
        <!-- game_title -->
        <template #game_title>
          <ControlsList
            :widget="widget"
            :controls="casinoListGameTitleControls"
          />
        </template>
        <!-- play_money_button -->
        <template #play_money_button-design>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayMoneyButtonDesignControls"
          />
        </template>

        <template #play_money_button-content>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayMoneyButtonContentControls"
          />
        </template>

        <template #play_money_button-states>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayMoneyButtonStateControls"
          />
        </template>
        <!-- play_free_button -->
        <template #play_free_button-design>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayFreeButtonDesignControls"
          />
        </template>

        <template #play_free_button-content>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayFreeButtonContentControls"
          />
        </template>

        <template #play_free_button-states>
          <ControlsList
            :widget="widget"
            :controls="casinoListPlayFreeButtonStateControls"
          />
        </template>

        <template #promo_group_list-design>
          <ControlsList
            :widget="widget"
            :controls="promoGroupListDesignControls"
          />
        </template>

        <template #promo_group_list-content>
          <ControlsList
            :widget="widget"
            :controls="promoGroupListContentControls"
          />
        </template>

        <!--arrowsSettings-->
        <template #arrows-design>
          <ControlsList
            :widget="widget"
            :controls="arrowsDesignControls"
          />
        </template>
        <template #arrows-content>
          <ControlsList
            :widget="widget"
            :controls="arrowsContentControls"
          />
        </template>
        <template #arrows-states>
          <ControlsList
            :widget="widget"
            :controls="arrowsStatesControls"
          />
        </template>

        <!--paginationSettings-->
        <template #pagination-design>
          <ControlsList
            :widget="widget"
            :controls="paginationDesignControls"
          />
        </template>
        <template #pagination-content>
          <ControlsList
            :widget="widget"
            :controls="paginationContentControls"
          />
        </template>
        <template #pagination-states>
          <ControlsList
            :widget="widget"
            :controls="paginationStatesControls"
          />
        </template>

        <template #promo_cards-design>
          <ControlsList
            :widget="widget"
            :controls="promoCardsDesignControls"
          />
        </template>

        <template #promo_cards-content>
          <ControlsList
            :widget="widget"
            :controls="promoCardsContentControls"
          />
        </template>

        <template #promo_cards-states>
          <ControlsList
            :widget="widget"
            :controls="promoCardsStatesControls"
          />
        </template>

        <template #promo_image>
          <ControlsList
            :widget="widget"
            :controls="promoImageControls"
          />
        </template>

        <template #promo_title>
          <ControlsList
            :widget="widget"
            :controls="promoTitleControls"
          />
        </template>

        <template #promo_description>
          <ControlsList
            :widget="widget"
            :controls="promoTitleControls"
          />
        </template>

        <template #promo_button_group-design>
          <ControlsList
            :widget="widget"
            :controls="promoButtonGroupDesignControls"
          />
        </template>

        <template #promo_button_group-content>
          <ControlsList
            :widget="widget"
            :controls="promoButtonGroupContentControls"
          />
        </template>

        <template #promo_button_1-design>
          <ControlsList
            :widget="widget"
            :controls="promoButtonDesignControls"
          />
        </template>

        <template #promo_button_1-content>
          <ControlsList
            :widget="widget"
            :controls="promoButtonContentControls"
          />
        </template>

        <template #promo_button_1-states>
          <ControlsList
            :widget="widget"
            :controls="promoButtonStatesControls"
          />
        </template>

        <template #promo_button_2-design>
          <ControlsList
            :widget="widget"
            :controls="promoButtonDesignControls"
          />
        </template>

        <template #promo_button_2-content>
          <ControlsList
            :widget="widget"
            :controls="promoButtonContentControls"
          />
        </template>

        <template #promo_button_2-states>
          <ControlsList
            :widget="widget"
            :controls="promoButtonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, toRef } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useCasinoListFenixConfig } from "~~/composables/widgets/casino/list/fenix/useCasinoListFenixConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";
import { useArrowsConfig } from "~~/entities/carousel/composables/useArrowsConfig";
import { usePaginationConfig } from "~~/entities/carousel/composables/usePaginationConfig";
import { StateController } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
  stateController: StateController;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "pageType": {
      label: "Page type",
      description:
        "Possible values: Casino | Category | Subcategory | All games",
      sources: ["custom"],
      required: true,
    },
    "visibleCategoriesMode": {
      label: `(For "Casino" page type) Display categories`,
      description: `Possible values: with subcategories only |, | without subcategories only | all |. Default value: "with subcategories only"`,
      sources: ["custom"],
      required: false,
    },
    "casinoGamesListMode": {
      label: `(For "Casino" page type) Display games list`,
      description: `Possible values: from categories only | from subcategories only | from categories and subcategories |. Default value: "from categories only"`,
      sources: ["custom"],
      required: false,
    },
    "categoryId": {
      label: "Category ID",
      sources: ["pageRoute", "custom"],
    },
    "subCategoryId": {
      label: "Subcategory ID",
      sources: ["pageRoute", "custom"],
    },
    "maxGamesCategory": {
      label: "Maximum games in category",
      sources: ["custom"],
    },
    "maxGamesSubCategory": {
      label: "Maximum games in subcategory",
      sources: ["custom"],
    },
    "maxAllGames": {
      label: "Maximum all games",
      sources: ["custom"],
    },
    "bannersSourceMode": {
      label: "Banners source",
      description: `Repeat banners after each N category or each games N row. Possible values: Info cards from categories | Banners in games list`,
      sources: ["custom"],
      required: false,
    },
    "initialBannersPosition": {
      label: "Initial banners position",
      description: `Configure before X row banners should be rendered first. By default banners will be displayed before categories list element or before all games element. Example value: 2`,
      sources: ["custom"],
      required: false,
    },
    "isBannersRepeating": {
      label: "Is banners repeating after each N row",
      description: `Possible values: yes | no`,
      sources: ["custom"],
      required: false,
    },
    "repeatBannerAfterEach": {
      label: "Repeat banners after each N row",
      description: `Repeat banners after each N category or each games N row. Banners repeating will start after initial banner position`,
      sources: ["custom"],
      required: false,
    },
    "casinoPageUrl": {
      label: "Casino page URL",
      sources: ["custom"],
    },
    "categoryPageUrl": {
      label: "Category page URL",
      sources: ["custom"],
    },
    "subcategoryPageUrl": {
      label: "Subcategory page URL",
      sources: ["custom"],
    },
    "oneGameForMoneyPageUrl": {
      label: "One game for money page URL",
      sources: ["custom"],
      required: true,
    },
    "oneGameForFunPageUrl": {
      label: "One game for fun page URL",
      sources: ["custom"],
      required: true,
    },
    "bannersPosition": {
      label: "Banners position",
      description: "Possible values: 1 | 2",
      sources: ["custom"],
      required: false,
    },
    "repeatAfterEach": {
      label: "Repeat after each",
      description: "Possible values: 1 | 2 | 3. Empty value means OFF",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  backButtonDesignControls,
  backButtonContentControls,
  backButtonStateControls,

  headerDesignControls,
  headerContentControls,

  pageTitleDesignControls,
  pageTitleContentControls,

  searchResultDesignControls,
  searchResultContentControls,

  sortingDesignControls,
  sortingContentControls,
  sortingStateControls,

  searchDesignControls,
  searchContentControls,
  searchStateControls,

  gamesListDesignControls,
  gamesListContentControls,

  gamesCategoryDesignControls,
  gamesCategoryContentControls,
  gamesCategoryStateControls,

  categoryTitleDesignControls,
  categoryTitleContentControls,
  categoryTitleStateControls,

  categoryButtonDesignControls,
  categoryButtonContentControls,
  categoryButtonStateControls,

  categoryHoverButtonDesignControls,
  categoryHoverButtonContentControls,
  categoryHoverButtonStateControls,

  categoryListDesignControls,
  categoryListContentControls,

  allGamesDesignControls,
  allGamesContentControls,

  allGamesTitleDesignControls,
  allGamesTitleContentControls,

  allGamesListDesignControls,
  allGamesListContentControls,

  showMoreButtonDesignControls,
  showMoreButtonContentControls,
  showMoreButtonStateControls,

  gameNameControls,

  gameCardsDesignControls,
  gameCardsContentControls,

  showMoreGamesCardDesignControls,
  showMoreGamesCardContentControls,

  gameCoverDesignControls,
  gameCoverContentControls,
  gameCoverStatesControls,

  casinoListFavIconControls,

  casinoListGameTagButtonDesignControls,
  casinoListGameTagButtonContentControls,
  casinoListGameTagButtonStateControls,

  casinoListHoverContentControls,

  casinoListGameTitleControls,

  casinoListPlayMoneyButtonDesignControls,
  casinoListPlayMoneyButtonContentControls,
  casinoListPlayMoneyButtonStateControls,

  casinoListPlayFreeButtonDesignControls,
  casinoListPlayFreeButtonContentControls,
  casinoListPlayFreeButtonStateControls,

  promoGroupListDesignControls,
  promoGroupListContentControls,

  promoCardsDesignControls,
  promoCardsContentControls,
  promoCardsStatesControls,

  promoImageControls,

  promoTitleControls,

  promoButtonGroupDesignControls,
  promoButtonGroupContentControls,
  promoButtonDesignControls,
  promoButtonContentControls,
  promoButtonStatesControls,
} = useCasinoListFenixConfig(ref(props.widget), states, emit);

const { arrowsDesignControls, arrowsContentControls, arrowsStatesControls } =
  useArrowsConfig(ref(props.widget), props.stateController);

const {
  paginationDesignControls,
  paginationContentControls,
  paginationStatesControls,
} = usePaginationConfig(ref(props.widget), props.stateController);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
