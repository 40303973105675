import { Ref } from "vue";

import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import {
  ResizingType,
  SliderTransition,
} from "~~/models/widgets/widget-controls.model";
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";

export const useCarouselConfig = (
  widget: Ref<IWidgetWithFields>,
  source?: IWidgetField | IWidgetWithFields | null
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  if (!source) {
    source = widget.value;
  }

  const carouselSettings = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.carouselSettings.layout",
        valueSource: source,
        options: {
          label: "Layout",
          items: [
            {
              label: "Fill",
              value: ResizingType.FILL,
            },
            {
              label: "Fixed",
              value: ResizingType.FIXED,
            },
          ],
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.carouselSettings.maxVisibleCards",
        className: "p-t-16 p-r-16 p-b-0 p-l-16",
        valueSource: source,
        visible: source?.options.carouselSettings.layout === ResizingType.FILL,
        options: {
          label: "Max. number of visible elements",
          suffix: " ",
          minValue: 1,
          maxValue: 10,
        },
      },
      {
        componentPath: "content/ConfigNumberInput",
        valuePath: "options.carouselSettings.width",
        valueSource: source,
        visible: source?.options.carouselSettings.layout === ResizingType.FIXED,
        options: {
          label: "Width",
        },
      },
      {
        componentPath: "ConfigHorizontalSpace",
        valuePath: "options.carouselSettings.gap",
        valueSource: source,
        options: {
          label: "Gap",
        },
      },
      {
        componentPath: "ConfigAlignmentFull",
        valuePath: "options.carousel.alignment",
        valueSource: source,
        options: {
          label: "Alignment",
        },
      },
    ];
  });

  const carousel = computed(() => {
    return [
      {
        section: generateContentSectionName("Transition"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.carousel.transition",
            valueSource: source,
            options: {
              items: [
                { value: SliderTransition.ALL, label: "All" },
                { value: SliderTransition.BY_ONE, label: "By one" },
              ],
            },
          },
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.carousel.infinite",
            valueSource: source,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Infinite loop",
              size: "small",
              allowDisable: true,
              showOptions: false,
            },
          },
          {
            componentPath: "common/ConfigToggle",
            valuePath: "options.carousel.autoplay",
            valueSource: source,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Auto slide",
              size: "small",
              allowDisable: true,
              showOptions: false,
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.carousel.speed",
            valueSource: source,
            className: "group-control-element",
            visible: source?.options.carousel.autoplay,
            options: {
              label: "Delay",
              suffix: "sec",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "pagination",
              label: "Pagination",
              isActiveValueSource: fields.value.pagination.field.options,
            },
            {
              value: "arrows",
              label: "Arrows",
              isActiveValueSource: fields.value.arrows.field.options,
            },
          ],
        },
      },
    ];
  });

  return {
    carouselSettings,
    carousel,
  };
};
