import {
  ColorPickerType,
  type IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import {
  Align,
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { CellSizeType } from "~~/models/grid.interface";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";

import { useWidgetFields } from "../useWidgetFields";

export const useCardSlideControls = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State | string>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const cardSlideDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
      except: ["ConfigColorPickerInput", "ConfigSpacingInputs"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: selectedField.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: selectedField.value,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
      ],
    });
  });

  const cardSlideContentControls = computed<ControlProp[]>(() => {
    const fields = useWidgetFields(
      selectedField.value as unknown as IWidgetWithFields
    );

    if (!Object.keys(fields.value).length) return [];

    return [
      {
        section: generateContentSectionName("Size"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.display.height",
            valueSource: widget.value,
            options: {
              placeholder: "Enter text",
              type: ColorPickerType.TEXT,
              label: "Height",
              icon: "ant-design:column-height-outlined",
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigLayoutPosition",
            valuePath: "options.display.layout",
            valueSource: selectedField.value,
            options: {},
            visible: true,
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.display.alignment",
            visible:
              widget.value.options.size.height.type === CellSizeType.FIXED,
            valueSource: selectedField.value,
            options: {
              label: "Widget alignment",
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.gap",
            valueSource: selectedField.value,
            options: {
              label: "Gap",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignmentFull",
            valuePath: "options.display.alignment",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Alignment",
              layout: DisplayOrientation.VERTICAL,
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.alignSelf",
            valueSource: selectedField.value,
            options: {
              label: "Align content",
              items: [
                {
                  label: "Start",
                  value: Align.START,
                },
                {
                  label: "End",
                  value: Align.END,
                },
              ],
            },
          },
        ],
      },
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          shouldShowStyleSettings: false,
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
      {
        componentPath: "content/ConfigImageWithToggle",
        valuePath: "value",
        valueSource: fields.value.image.field,
        options: {
          label: "Image",
          allowDisable: true,
          active: fields.value.image.active,
          showOptions: fields.value.image.field.options._active,
          "onUpdate:active": (value: boolean) => {
            fields.value.image.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.image.field
            );
          },
        },
      },
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.title.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Title",
          allowDisable: true,
          active: fields.value.title.active,
          showOptions: fields.value.title.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.title.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.title.field
            );
          },
        },
      },
      {
        componentPath: "content/ConfigEditorWithToggle",
        valuePath: "value",
        valueSource: fields.value.description_1.field,
        className: "widget-control-container",
        options: {
          isMultiple: false,
          placeholder: "Enter text",
          label: "Description",
          allowDisable: true,
          fields: [fields.value.description_1],
          primaryField: fields.value.description_1.field,
          active: fields.value.description_1.active,
          showOptions: fields.value.description_1.active,
          "onUpdate:active": (value: boolean) => {
            fields.value.description_1.field.options._active = value;
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.description_1.field
            );
          },
        },
      },
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options._active",
        valueSource: fields.value.buttons_group.field,
        className: "p-l-16 p-t-16 p-r-16 p-b-16 widget-control-container",
        options: {
          label: "Buttons group",
          size: "small",
          allowDisable: true,
          showOptions: fields.value.buttons_group.active,
          "onOptions-click": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.buttons_group.field
            );
          },
        },
      },
    ];
  });

  const cardSlideValuePath = computed<string>(() => {
    if (states.value.card === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.card}`;
  });

  const cardSlideStatesControls = computed<ControlProp[]>(() => {
    const fields = useWidgetFields(
      selectedField.value as unknown as IWidgetWithFields
    );

    if (!Object.keys(fields.value).length) return [];

    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.card,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", {
            state: selectedField.value?.name ?? "widget",
            value,
          });
        },
      },
      {
        componentPath: "ConfigFillImageColor",
        valuePath: `${cardSlideValuePath.value}.fillImageColor`,
        valueSource: selectedField.value,
        options: {
          label: "Fill",
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${cardSlideValuePath.value}.border`,
        valueSource: selectedField.value,
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${cardSlideValuePath.value}.shadow`,
        valueSource: selectedField.value,
      },
      {
        section: generateDesignSectionName("Title"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.link.states.${State.HOVER}.color`,
            valueSource: fields.value.title.field,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Description"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `options.states.${State.HOVER}.color`,
            valueSource: fields.value.description_1.field,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    cardSlideDesignControls,
    cardSlideContentControls,
    cardSlideStatesControls,
  };
};
