import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";

import { generateClassName } from "../utils/generate-class-name";

export const generateWidgetStyles = () => {
  return (cssString: string): string => {
    return cssString;
  };
};

export const generatePromotionsPageWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);

  return pipeSync<string>(generateWidgetStyles())("");
};
