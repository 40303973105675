import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { ResizingType, State } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateFontSize,
  generateMarginStyle,
  generateTextAlignStyle,
  generateTextColor,
  generateWidgetWidth,
  generateWidth,
} from "../helpers";
import { getFlexPosition } from "../../widget-settings";

import { generateFieldContainerCssString } from "./search-field-widget-css";

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    const isFill = options.display.resizing === ResizingType.FILL;

    const widgetStyles = generateFlex({
      flex: "flex",
      align: isFill ? "stretch" : getFlexPosition(options.display.alignment),
      justify: "flex-start",
      direction: "column",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateDropdownContainerCssString =
  (items: IWidgetOptions, widget: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    const boxCssContent = generateFlex({
      flex: "flex",
      align: "center",
      justify: getFlexPosition(options.display?.alignment),
      gap: options.distanceBetweenItems,
    });

    cssString += generateDefaultStylesWithStates(items, false);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: boxCssContent + "cursor: pointer;",
    });

    const isFill = widget.options.display.resizing === ResizingType.FILL;
    const isFixed = options.display?.resizing?.type === ResizingType.FIXED;

    cssString += generateCssClassWithContent({
      className: options.text._cssClass,
      content:
        generateDefaultStyles(options.text) +
        (isFill || isFixed ? "" : "flex:1;") +
        generateWidth(100, "%"),
    });

    // For profile dropdown
    if (isFixed) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: generateWidgetWidth(options.display.resizing),
      });
    }

    if (isFill) {
      cssString += generateCssClassWithContent({
        className: options.text._cssClass,
        content: generateTextAlignStyle(widget.options.display.alignment),
      });
    }

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: `
        ${generateMarginStyle({ right: options.icon.gap })}
        ${generateFontSize(options.icon.iconSize)}
        ${generateTextColor(options.icon.textColor)}
      `,
    });

    cssString += generateCssClassWithContent({
      className: options.arrow._cssClass,
      content: `
        ${generateMarginStyle({ left: options.arrow.gap })}
        ${generateFontSize(options.arrow.arrowSize)}
        ${generateTextColor(options.arrow.textColor)}
      `,
    });

    for (const state in options.states) {
      const currentState = options.states[state];
      // For casino provider filter
      const isFilteredState = state === State.FILTERED;
      const filteredClassName = isFilteredState ? ".is-filtered" : "";
      if (isFilteredState) {
        cssString += generateCssClassWithContent({
          className: `${options._cssClass}${filteredClassName}`,
          content: generateDefaultStyles(currentState),
        });
      }

      cssString += generateCssClassWithContent({
        className: `${options._cssClass}${filteredClassName}`,
        childClassName: options.icon?._cssClass,
        content: currentState.icon?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: !isFilteredState ? `:${state}` : "",
      });

      cssString += generateCssClassWithContent({
        className: `${options._cssClass}${filteredClassName}`,
        childClassName: options.arrow?._cssClass,
        content: currentState.arrow?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: !isFilteredState ? `:${state}` : "",
      });

      cssString += generateCssClassWithContent({
        className: `${options._cssClass}${filteredClassName}`,
        childClassName: options.text?._cssClass,
        content: currentState.text,
        customFunction: generateDefaultStyles,
        pseudoClassName: !isFilteredState ? `:${state}` : "",
      });
    }

    return cssString;
  };

export const generateMenuCssString =
  (items: IWidgetOptions, item: IWidgetOptions, widget: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    const flex = generateFlex({
      flex: "flex",
      align: "stretch",
      justify: "flex-start",
      direction: "column",
      gap: item.options.spaceBetween,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options) + "z-index: 2;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      childClassName: "dropdown-element__container-override",
      content: flex,
    });

    cssString += generateCssClassWithContent({
      className: widget.options._cssClass,
      pseudoClassName: " div[data-radix-popper-content-wrapper]",
      content: "z-index: 5 !important;",
    });

    if (widget.options.display.resizing === ResizingType.FILL) {
      cssString += generateCssClassWithContent({
        className: widget.options._cssClass,
        pseudoClassName: " [data-radix-popper-content-wrapper]",
        content: "position: absolute !important; width: 100%;",
      });
    }

    return cssString;
  };

export const generateMenuItemsCssString =
  (items: IWidgetOptions, widget: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    const isFill = widget.options.display.resizing === ResizingType.FILL;

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: isFill
        ? getFlexPosition(widget.options.display.alignment)
        : "flex-start",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex + "cursor: pointer;",
    });

    cssString += generateDefaultStylesWithStates(items, false);

    cssString += generateCssClassWithContent({
      className: options.itemsText._cssClass,
      content: generateDefaultStyles(options.itemsText),
    });

    for (const state in options.states) {
      const currentState = options.states[state].itemsText;

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.itemsText?._cssClass,
        content: currentState,
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }
    return cssString;
  };

export const generateTimezonesWidgetCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);

  const dropdown_container = widget.content["dropdown_container"];
  const dropdown_menu = widget.content["dropdown_menu"];
  const search_field = widget.content["search_field"];
  const menu_items = widget.content["menu_items"];

  // Dropdown container
  dropdown_container.options._cssClass =
    generateClassName("dropdown_container");
  dropdown_container.options.text._cssClass = generateClassName(
    "dropdown_container_text"
  );
  dropdown_container.options.icon._cssClass = generateClassName(
    "dropdown_container_icon"
  );
  dropdown_container.options.arrow._cssClass = generateClassName(
    "dropdown_container_arrow"
  );
  // Dropdown menu
  dropdown_menu.options._cssClass = generateClassName("dropdown_menu");
  // Search field
  search_field.options._cssClass = generateClassName("search_field");
  search_field.options.text._cssClass = generateClassName("search_field--text");
  search_field.options.icon._cssClass = generateClassName("search_field--icon");
  // Menu items
  menu_items.options._cssClass = generateClassName("menu_items");
  menu_items.options.itemsText._cssClass = generateClassName("menu_items_text");

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateDropdownContainerCssString(dropdown_container, widget),
    generateMenuCssString(dropdown_menu, menu_items, widget),
    generateFieldContainerCssString(search_field as IWidgetField),
    generateMenuItemsCssString(menu_items, widget)
  )("");
};
