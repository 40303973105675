<template>
  <div>
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        class="welcome-bonus-fenix-widget-settings"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <!--widget-->
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>
        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <!--bonus_block-->
        <template #bonus_block-design>
          <ControlsList
            :widget="widget"
            :controls="designControls"
          />
        </template>
        <template #bonus_block-content>
          <ControlsList
            :widget="widget"
            :controls="bonusBlockContentControls"
          />
        </template>

        <!--bonus-->
        <template #bonus-design>
          <ControlsList
            :widget="widget"
            :controls="designControls"
          />
        </template>
        <template #bonus-content>
          <ControlsList
            :widget="widget"
            :controls="bonusContentControls"
          />
        </template>

        <!--bonus_header-->
        <template #bonus_header-design>
          <ControlsList
            :widget="widget"
            :controls="designControls"
          />
        </template>
        <template #bonus_header-content>
          <ControlsList
            :widget="widget"
            :controls="bonusHeaderContentControls"
          />
        </template>
        <template #bonus_header-states>
          <ControlsList
            :widget="widget"
            :controls="bonusHeaderStatesControls"
          />
        </template>

        <!--bonus_image-->
        <template #bonus_image>
          <ControlsList
            :widget="widget"
            :controls="bonusImageControls"
          />
        </template>

        <!--bonus_title-->
        <template #bonus_title>
          <ControlsList
            :widget="widget"
            :controls="bonusTitleControls"
          />
        </template>

        <!--bonus_content-->
        <template #bonus_content-design>
          <ControlsList
            :widget="widget"
            :controls="designControls"
          />
        </template>
        <template #bonus_content-content>
          <ControlsList
            :widget="widget"
            :controls="bonusContentContentControls"
          />
        </template>

        <!--no_bonus-->
        <template #no_bonus-design>
          <ControlsList
            :widget="widget"
            :controls="designControls"
          />
        </template>
        <template #no_bonus-content>
          <ControlsList
            :widget="widget"
            :controls="noBonusContentControls"
          />
        </template>
        <template #no_bonus-states>
          <ControlsList
            :widget="widget"
            :controls="noBonusStatesControls"
          />
        </template>

        <!--no_bonus_image-->
        <template #no_bonus_image>
          <ControlsList
            :widget="widget"
            :controls="noBonusImageControls"
          />
        </template>

        <!--no_bonus_title-->
        <template #no_bonus_title>
          <ControlsList
            :widget="widget"
            :controls="noBonusTitleControls"
          />
        </template>

        <!--description-->
        <template #description>
          <ControlsList
            :widget="widget"
            :controls="descriptionControls"
          />
        </template>

        <!--button-->
        <template #button-design>
          <ControlsList
            :widget="widget"
            :controls="designControls"
          />
        </template>
        <template #button-content>
          <ControlsList
            :widget="widget"
            :controls="buttonContentControls"
          />
        </template>
        <template #button-states>
          <ControlsList
            :widget="widget"
            :controls="buttonStatesControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";
import { StateController } from "~~/composables/widgets/common/useStates";

import { useWelcomeBonusFenixWidgetConfig } from "./composables/useWelcomeBonusFenixWidgetConfig";

const props = defineProps<{
  widget: IWidgetWithFields;
  stateController: StateController;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const {
  DROPDOWN_ITEMS,
  TABS,

  widgetDesignControls,
  widgetContentControls,

  bonusBlockContentControls,

  bonusContentControls,

  bonusHeaderContentControls,
  bonusHeaderStatesControls,

  bonusImageControls,

  bonusTitleControls,

  bonusContentContentControls,

  noBonusContentControls,
  noBonusStatesControls,

  noBonusImageControls,

  noBonusTitleControls,

  descriptionControls,

  designControls,

  buttonContentControls,
  buttonStatesControls,
} = useWelcomeBonusFenixWidgetConfig(ref(props.widget), props.stateController);

const resetStates = () => {
  props.stateController.resetStates();
};

const { bindingParams } = useWidgetDynamicParams(
  {
    "depositPageURL": {
      label: "Deposit page URL",
      sources: ["custom"],
      required: false,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

onBeforeUnmount(() => {
  resetStates();
});
</script>
