declare global {
  interface Window {
    _APP_ENV: {
      VITE_BASE_API_URL: string;
      VITE_BASE_MEDIA_URL: string;
      VITE_CONFIG_APP_BASE_API_URL: string;
      VITE_DASHBOARD_BASE_URL: string;
    };
  }
}

export async function loadEnvFile() {
  const envFileURL = new URL("/env.json", window.location.origin).href;

  window._APP_ENV = await fetch(envFileURL).then(res => res.json());
}
