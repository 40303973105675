import { defineStore } from "pinia";
import { LocationQueryValue } from "vue-router";

import {
  IPagesStore,
  IPage,
  PageQueryType,
} from "~~/models/stores/pages-store.model";
import {
  IWidgetWithFields,
  PageIdType,
} from "~~/models/widgets/widget.core/widget.model";
import { IPageContent, IPageContentResponse } from "~~/models/page.model";
import http from "~~/services/http";
import { useGridConfig } from "~~/store/grid";
import {
  findCellFromGridLayout,
  sortGridByPosition,
} from "~~/assets/utils/grid";
import {
  createWidgetFromContentElement,
  generateGroupedWidgets,
  mergeForms,
} from "~~/assets/utils/widget";
import { deepCopy } from "~~/assets/utils";
import runWidgetMigrations from "~~/migrations/runWidgetMigrations";
import { toSorted } from "~~/helpers/toSorted";
import { Grid } from "~~/models/grid.interface";

import { useLanguagesStore } from "./languages";

export interface IEditPageParams extends Omit<Partial<IPage>, "id"> {
  id: PageIdType;
}

export const usePagesStore = defineStore("pages", {
  state: (): IPagesStore => ({
    pages: [],

    selectedPage: null,
    templateId: null,

    isCreatingNewPage: false,
    isWidgetsTemplateLoading: false,

    allowSavePage: false,
  }),
  getters: {
    selectedPageName({ selectedPage }): string {
      return selectedPage?.name ?? "";
    },

    selectedPageId({ selectedPage }): PageIdType {
      return selectedPage?.id ?? "";
    },

    // TODO: For future
    // isDynamicParamsChanged: state => {
    //   const gridStore = useGridConfig();

    //   const widgetsBindings = gridStore.pageWidgets.map(
    //     widget => widget.options.bindingParams
    //   );

    //   const widgetsUniqParams = new Set(
    //     ...widgetsBindings.map(binding => {
    //       return Object.entries(binding!)
    //         .map(([key, value]) => {
    //           if (value.source === "pageRoute") {
    //             return key;
    //           }
    //         })
    //         .filter(Boolean);
    //     })
    //   );

    //   // const { route } = state.selectedPage!

    //   return [...widgetsUniqParams];
    // },
  },
  actions: {
    setPageSaveStatus(status: boolean): void {
      this.allowSavePage = status;
    },

    setTemplateId(id: PageQueryType): void {
      this.templateId = id;
    },

    setSelectedPage(page: IPage | null): void {
      this.selectedPage = page;
    },

    setPageId(id: PageIdType): void {
      if (this.selectedPage) {
        this.selectedPage!.id = id;
      }
    },

    setPageName(name: string): void {
      if (this.selectedPage) {
        this.selectedPage!.name = name;
      }
    },

    setPages(pages: IPage[]): void {
      this.pages = pages;
    },

    setIsCreatingNewPage(value: boolean): void {
      this.isCreatingNewPage = value;
    },

    setIsWidgetsTemplateLoading(value: boolean): void {
      this.isWidgetsTemplateLoading = value;
    },

    async fetchPage(
      id: LocationQueryValue | LocationQueryValue[],
      templateId: LocationQueryValue | LocationQueryValue[]
    ): Promise<void> {
      this.setTemplateId(templateId as PageQueryType);

      const { data: page } = await http.get(`/pages/${id}`);

      this.setSelectedPage(page);
    },

    updatePageOptions(params: Partial<IEditPageParams>): void {
      this.selectedPage = {
        ...this.selectedPage!,
        ...params,
      };
    },

    async editPage(params: IEditPageParams): Promise<void> {
      await http.post(`/pages/${params.id}/edit`, {
        ...params,
        template: this.templateId,
      });

      const pages = await this.fetchPagesByTemplate(this.templateId);

      this.setPages(pages);
    },

    fetchPagesByTemplate(id: PageQueryType): Promise<IPage[]> {
      return http.get(`/pages?template=${id}`).then(response => response.data);
    },

    async createPage(name: string): Promise<void> {
      const body = {
        name,
        "template": this.templateId,
      };

      const { data: page } = await http.post("/pages/create", body);

      this.setSelectedPage(page);

      const pages = await this.fetchPagesByTemplate(this.templateId);

      this.setPages(pages);
    },

    deletePage(id: PageIdType): Promise<void> {
      return http
        .post(`/pages/${id}/delete`, {
          id,
        })
        .then(() => this.fetchPagesByTemplate(this.templateId))
        .then(pages => this.setPages(pages));
    },

    editPageName(name: string, id: PageIdType): Promise<void> {
      return http
        .post(`/pages/${id}/edit`, {
          name,
          template: this.templateId,
        })
        .then(() => this.fetchPagesByTemplate(this.templateId))
        .then(pages => this.setPages(pages));
    },

    copyPage(id: PageIdType, newWidgetsGrid?: Grid): Promise<void> {
      const gridStore = useGridConfig();

      return http
        .post(`/pages/${id}/copy`, {
          id,
        })
        .then(() => this.fetchPagesByTemplate(this.templateId))
        .then(pages => {
          this.setPages(pages);
          this.setPageId(pages[0].id);
          this.setPageName(pages[0].name);
          return gridStore.saveWidgetsGrid(newWidgetsGrid);
        });
    },

    fetchPageContent(): Promise<void> {
      const gridStore = useGridConfig();
      const languagesStore = useLanguagesStore();

      const config = {
        headers: {
          "Accept-Language": languagesStore.currentLanguage?.codeWithRegion,
        },
      };

      this.setIsWidgetsTemplateLoading(true);

      gridStore.updateWidgetsGridLayout(
        sortGridByPosition(deepCopy(gridStore.gridLayout))
      );

      const getContentFn = async () => {
        return http.get(`/pages/${this.selectedPageId}/get-content`, config);
      };

      return getContentFn()
        .then((res: IPageContentResponse) => {
          const cellsData = res.data.pageContent;
          gridStore.setCellsOptions(res.data.options || {});

          return cellsData;
        })
        .then(this.syncFormWidgets)
        .then(cellsData => {
          for (const cellId in cellsData) {
            const cell = findCellFromGridLayout(
              gridStore.widgetsGridLayout,
              cellId
            );

            if (!cell) {
              continue;
            }

            const widgets: IWidgetWithFields[] = [];

            const widgetsContentList = cellsData[cellId].widgets;

            widgetsContentList.forEach(widget => {
              const migratedWidget = runWidgetMigrations(widget);

              widgets.push(createWidgetFromContentElement(migratedWidget));
            });

            /* 
              Generate widgets of format:
              {
                ...,
                _children: [...]
              }
            */
            const groupedWidgets = generateGroupedWidgets(widgets);

            cell.widgets = toSorted(
              groupedWidgets,
              (a, b) => a.position - b.position
            );
          }
        })
        .catch(error => {
          console.error("Error occurred during page render");
          console.error(error);
          this.allowSavePage = false;
        })
        .finally(() => {
          this.setIsWidgetsTemplateLoading(false);
        });
    },

    async syncFormWidgets(content: IPageContent): Promise<IPageContent> {
      // hack: to handle cyclic modules dependencies
      const widgetsStore = (await import("~~/store/widgets")).useWidgetsStore();

      await Promise.allSettled(
        Object.values(content).map(async ({ widgets }) => {
          for (let index = 0; index < widgets.length; index++) {
            const widget = widgets[index];

            const formField = Object.values(widget.content).find(
              field => field.type === "FormField"
            );
            if (!formField) continue;

            const targetWidgetDetails = await widgetsStore.fetchWidgetDetails(
              widget.id!,
              widget.staticId
            );

            widgets[index] = mergeForms(targetWidgetDetails, widget);
          }
        })
      );

      return content;
    },
  },
});
