import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 97;

const v84WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (widget.name === "TopMatchFenixWidget") {
    delete widget.content.bets.options.padding;
    delete widget.content.bet_amounts.options.padding;
    delete widget.content.not_enough_container.options.padding;
    delete widget.content.bets_type.options.padding;
    delete widget.content.coefficent.options.padding;
  }

  return widget;
};
export default v84WidgetMigration;
