import { VerticalAlignment } from "~~/models/widgets/widget-controls.model";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 98;

const v87WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (widget.name === "PopularLeaguesWidget") {
    if (!widget.content.menu_level_1.options.baseDisplaySettings) {
      widget.content.menu_level_1.options.baseDisplaySettings = {
        alignment: VerticalAlignment.TOP,
      };
    }
  }

  return widget;
};
export default v87WidgetMigration;
