<template>
  <WidgetField
    :widget="widget"
    :field="fields.bets.field"
    :class="[
      `top-match-fenix__bets--${fields.bets.field.options.layout}`,
      `top-match-fenix__bets--${fields.bets.field.options.direction}`,
    ]"
    class="top-match-fenix__bets"
  >
    <BasicDesignWrapperField
      :widget="widget"
      :field="fields.bets.field"
      :style="alignStyle"
      class="top-match-fenix__bets-content"
    >
      <WidgetField
        :widget="widget"
        :field="fields.input.field"
      >
        <FormTextInputField
          :widget="widget"
          :field="fields.input.field"
          value="100"
          :states="states"
          :styling-fields="inputStylingFields"
          input-only
        />
      </WidgetField>

      <div class="top-match-fenix__bet-amounts">
        <WidgetField
          v-for="bet in BETS"
          :key="bet"
          :widget="widget"
          :field="fields.bet_amounts.field"
          class="top-match-fenix__bet-item"
        >
          <BasicDesignWrapperField
            :widget="widget"
            :field="fields.bet_amounts.field"
            :state="states.bet_amounts"
            class="top-match-fenix__bet-content"
          >
            <BasicTitleField
              :field="fields.bet_amounts.field"
              :state="states.bet_amounts"
            >
              {{ bet }}
            </BasicTitleField>
          </BasicDesignWrapperField>
        </WidgetField>
      </div>

      <div></div>
    </BasicDesignWrapperField>
  </WidgetField>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getFlexPosition } from "~~/assets/utils/widget-settings";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { FormStyleFieldName } from "~~/models/widgets/form.model";

const BETS = [50, 200, 500, 1000];

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
}>();

const fields = useWidgetFields(props.widget);

const gap = computed(() => {
  return getPxValueFromNumber(fields.value.bets.field.options.gap);
});

const order = computed<number>(() => {
  const dir = fields.value.bets.field.options.direction;

  if (dir === "reverse") {
    return -1;
  }

  return 1;
});

const dir = computed<string>(() => {
  const dir = fields.value.bets.field.options.layout;

  if (dir === DisplayOrientation.HORIZONTAL) {
    return "row";
  }

  return "column";
});

const alignStyle = computed<ElementStyle>(() => {
  const dir = fields.value.bets.field.options.layout;

  if (dir === DisplayOrientation.HORIZONTAL) {
    return {
      // justifyContent: getFlexPosition(
      //   fields.value.bets.field.options.alignment
      // ),
      alignItems: getFlexPosition(fields.value.bets.field.options.alignment),
    };
  }

  return {
    alignItems: "stretch",
  };
});

const betAmountsGap = computed(() => {
  return getPxValueFromNumber(
    fields.value.bet_amounts.field.options.display.gap
  );
});

const inputStylingFields = computed(() => {
  return [{ ...fields.value.input.field, name: FormStyleFieldName.FIELD }];
});
</script>

<style lang="scss">
.top-match-fenix {
  &__bets-content {
    display: flex;
    gap: v-bind(gap);
    align-items: center;
    flex-direction: v-bind(dir);

    .top-match-fenix__bet-amounts {
      order: v-bind(order);
    }

    input {
      text-align: right;
    }
  }

  &__bet-amounts {
    display: flex;
    gap: v-bind(betAmountsGap);
    flex: 1;

    & > div {
      flex: 1;
      text-align: center;
    }
  }

  &__bet-content {
    display: flex;
    justify-content: center;
  }
}
</style>
