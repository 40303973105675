import { CSSProperties } from "vue";
import _get from "lodash/get";

import { IPageContentWidget } from "~~/models/page.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { generateClassName } from "~~/assets/utils/widget-css/utils/generate-class-name";
import { generateCssClassWithContent } from "~~/assets/utils/widget-css/compiler/default-css-compiler";
import { styleObjectToStringAdvanced } from "~~/assets/utils";
import { generateStringDefault } from "~~/assets/utils/widget-css/utils/pipe-helper-functions";
import { pipeSync } from "~~/helpers/pipe";
import { prefillWithClasses } from "~~/assets/utils/widget-css/utils/prefill-with-classes";
import {
  generateFlexibleImageCssString,
  iterableStates,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";
import { generateButton } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/promo-bets-fenix-widget-css";

import { useWelcomeBonusFenixWidgetStateController } from "./composables/useWelcomeBonusFenixWidgetStateController";
import { useContentStyles } from "./composables/styles/useContentStyles";
import { useBonusBlockStyles } from "./composables/styles/useBonusBlockStyles";
import { useBonusStyles } from "./composables/styles/useBonusStyles";
import { useBonusHeaderStyles } from "./composables/styles/useBonusHeaderStyles";
import { useBonusTitleStyles } from "./composables/styles/useBonusTitleStyles";
import { useBonusContentStyles } from "./composables/styles/useBonusContentStyles";
import { useNoBonusStyles } from "./composables/styles/useNoBonusStyles";
import { useNoBonusTitleStyles } from "./composables/styles/useNoBonusTitleStyles";

export const generateWelcomeBonusFenixWidgetCssString = (
  widget: IPageContentWidget
) => {
  const { stateController } = useWelcomeBonusFenixWidgetStateController();

  widget.options._cssClass = generateClassName(widget.name);
  widget.options._contentCssClass = generateClassName(`${widget.name}_content`);

  const fields = prefillWithClasses(widget.content);

  const button = fields.button;
  button.options._containerCssClass = generateClassName("buttonContainer");
  button.options.buttonTextSettings._cssClass =
    generateClassName("buttonTextSettings");
  button.options.iconSettings._cssClass = generateClassName("iconSettings");

  return pipeSync<string>(
    generateStringDefault(widget),
    generateCSS(),
    generateHeader(
      "bonus_header",
      "bonus_title",
      useBonusHeaderStyles,
      useBonusTitleStyles
    ),
    generateHeader(
      "no_bonus",
      "no_bonus_title",
      useNoBonusStyles,
      useNoBonusTitleStyles
    ),
    generateFlexibleImageCssString(fields.bonus_image as IWidgetField),
    generateFlexibleImageCssString(fields.no_bonus_image as IWidgetField),
    generateStringDefault(fields.description),
    generateButton(button as IWidgetField, stateController)
  )("");

  function generateCSS() {
    return (cssString: string): string => {
      cssString += generateCssClassWithContent({
        className: widget.options._contentCssClass,
        content: styleObjectToStringAdvanced(useContentStyles(widget.options)),
      });

      const bonusBlockOptions = fields.bonus_block.options;
      cssString += generateCssClassWithContent({
        className: bonusBlockOptions._cssClass,
        content: styleObjectToStringAdvanced(
          useBonusBlockStyles(bonusBlockOptions)
        ),
      });

      const bonusOptions = fields.bonus.options;
      cssString += generateCssClassWithContent({
        className: bonusOptions._cssClass,
        content: styleObjectToStringAdvanced(useBonusStyles(bonusOptions)),
      });

      const bonusTitleOptions = fields.bonus_title.options;
      cssString += generateCssClassWithContent({
        className: bonusTitleOptions._cssClass,
        content: styleObjectToStringAdvanced(
          useBonusTitleStyles(bonusTitleOptions)
        ),
      });

      const bonusContentOptions = fields.bonus_content.options;
      cssString += generateCssClassWithContent({
        className: bonusContentOptions._cssClass,
        content: styleObjectToStringAdvanced(
          useBonusContentStyles(bonusContentOptions)
        ),
      });

      const noBonusTitleOptions = fields.no_bonus_title.options;
      cssString += generateCssClassWithContent({
        className: noBonusTitleOptions._cssClass,
        content: styleObjectToStringAdvanced(
          useNoBonusTitleStyles(noBonusTitleOptions)
        ),
      });

      return cssString;
    };
  }

  function generateHeader(
    fieldName: string,
    childName: string,
    stylesFunction: (options: any) => CSSProperties,
    childStylesFunction: (options: any) => CSSProperties
  ) {
    return (cssString: string): string => {
      iterableStates(
        fields[fieldName].options.states,
        (stateName, isDefaultStateName) => {
          const cardsOptions =
            _get(
              fields[fieldName],
              `${stateController.getOptionsValuePath(undefined, stateName)}`
            ) || {};

          cssString += generateCssClassWithContent({
            className: fields[fieldName].options._cssClass,
            pseudoClassName: !isDefaultStateName ? `:${stateName}` : undefined,
            content: styleObjectToStringAdvanced(stylesFunction(cardsOptions)),
          });

          if (stateName === State.ACTIVE) {
            cssString += generateCssClassWithContent({
              className: fields[fieldName].options._cssClass,
              pseudoClassName: !isDefaultStateName
                ? `.${stateName}`
                : undefined,
              content: styleObjectToStringAdvanced(
                stylesFunction(cardsOptions)
              ),
            });
          }

          if (!isDefaultStateName) {
            const bonusTitleOptions =
              _get(
                fields[childName],
                `${stateController.getOptionsValuePath(undefined, stateName)}`
              ) || {};

            cssString += generateCssClassWithContent({
              className: fields[fieldName].options._cssClass,
              pseudoClassName: !isDefaultStateName
                ? `:${stateName} .${fields[childName].options._cssClass}`
                : undefined,
              content: styleObjectToStringAdvanced(
                childStylesFunction(bonusTitleOptions)
              ),
            });

            if (stateName === State.ACTIVE) {
              cssString += generateCssClassWithContent({
                className: fields[fieldName].options._cssClass,
                pseudoClassName: !isDefaultStateName
                  ? `.${stateName} .${fields[childName].options._cssClass}`
                  : undefined,
                content: styleObjectToStringAdvanced(
                  childStylesFunction(bonusTitleOptions)
                ),
              });
            }
          }
        }
      );

      return cssString;
    };
  }
};
