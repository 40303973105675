import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { FormDropdownItem } from "~~/models/widgets/form.model";

export const PHONE_INITIAL = (
  fieldDetails?: {
    placeholder: string;
    label: string;
    options?: IWidgetOptions;
    items?: FormDropdownItem[] | null;
  },
  formName = "form"
) => {
  const items = fieldDetails?.items || fieldDetails?.options?.items;

  if (fieldDetails?.options && Object.hasOwn(fieldDetails.options, "label")) {
    return {
      options: {
        ...fieldDetails.options,
        items: items as FormDropdownItem[] | null,
      },
    };
  }

  return {
    options: {
      _active: true,
      _parent: formName,
      items: items as FormDropdownItem[] | null,
      label: {
        _active: true,
        value: fieldDetails?.label,
      },
      tooltip: {
        _active: true,
        value: "Tooltip text",
      },
      placeholder: {
        _active: true,
        value: fieldDetails?.placeholder || "Placeholder",
      },
    },
  };
};
