<template>
  <WidgetField
    :widget="widget"
    :field="fields.not_enough_container.field"
    class="top-match-fenix__not-enough"
    :class="`top-match-fenix__not_enough--${fields.not_enough_container.field.options.display.layout}`"
  >
    <BasicDesignWrapperField
      :widget="widget"
      :field="fields.not_enough_container.field"
      class="top-match-fenix__not_enough-content"
    >
      <BasicTitleField
        :field="fields.not_enough_container.field"
        class="top-match-fenix__not_enough-text"
      >
        <CommonIcon
          v-if="fields.not_enough_container.field.options.icon._active"
          :name="fields.not_enough_container.field.options.icon.value"
          :size="fields.not_enough_container.field.options.icon.size"
          :color="
            getColorFromHex(
              fields.not_enough_container.field.options.icon.color
            )
          "
          class="top-match-fenix__not_enough-icon"
        />

        <span>Not enough funds message</span>
      </BasicTitleField>

      <BasicTitleField
        :field="fields.not_enough_container.field"
        :options="fields.not_enough_container.field.options.deposit"
      >
        Deposit
      </BasicTitleField>
    </BasicDesignWrapperField>
  </WidgetField>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorFromHex,
  getFlexPosition,
} from "~~/assets/utils/widget-settings";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
}>();

const fields = useWidgetFields(props.widget);

const gap = computed(() => {
  return getPxValueFromNumber(
    fields.value.not_enough_container.field.options.display.gap
  );
});

const dir = computed<string>(() => {
  const dir = fields.value.not_enough_container.field.options.display.layout;

  if (dir === DisplayOrientation.HORIZONTAL) {
    return "row";
  }

  return "column";
});

const align = computed<string>(() => {
  const align =
    fields.value.not_enough_container.field.options.display.alignment;

  return getFlexPosition(align);
});

const iconGap = computed<string>(() => {
  const gap = fields.value.not_enough_container.field.options.icon.gap;

  return getPxValueFromNumber(gap);
});
</script>

<style lang="scss">
.top-match-fenix__not_enough {
  &-content {
    display: flex;
    gap: v-bind(gap);
    flex-direction: v-bind(dir);
    align-items: v-bind(align);
  }

  &-text {
    .basic-title-widget__content {
      display: flex;
      gap: v-bind(iconGap);
      justify-content: v-bind(align);
      align-items: center;
    }
  }

  &--horizontal {
    .top-match-fenix__not_enough-content {
      justify-content: space-between;
    }
  }
}
</style>
