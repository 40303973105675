<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
    class="top-match-fenix-widget"
  >
    <template
      v-if="states.widget === 'top_match'"
      #root
    >
      <NavigationArrows
        v-if="fields.arrows.field.options._active"
        :widget="widget"
        :field="fields.arrows.field"
        :arrows-styles="arrowsStyles"
        :arrows-container-styles="arrowsContainerStyles"
        :arrows-button-styles="arrowsButtonStyles"
      />
      <NavigationPagination
        v-if="fields.pagination.field.options._active"
        :widget="widget"
        :field="fields.pagination.field"
        :pagination-styles="paginationStyles"
        :pagination-dots-styles="paginationDotsStyles"
      />
    </template>

    <TopMatchFenixWidgetSettings
      :widget="widget"
      :states="states"
      :state-controller="stateController"
      @reset-states="resetStates"
      @update-state="handleStateUpdate"
    />

    <TopMatchFenixDefaultScreen
      v-if="states.widget === 'top_match'"
      :widget="widget"
      :states="states"
    />

    <TopMatchFenixSuccessScreen
      v-else
      :widget="widget"
      :states="states"
    />
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { CSSProperties } from "vue";
import _get from "lodash/get";

import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/top-match-fenix/useTopMatchFenixInit";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useStates } from "~~/composables/widgets/common/useStates";
import NavigationArrows from "~~/entities/carousel/components/NavigationArrows.vue";
import NavigationPagination from "~~/entities/carousel/components/NavigationPagination.vue";
import { useArrowsStyles } from "~~/entities/carousel/composables/styles/useArrowsStyles";
import { usePaginationStyles } from "~~/entities/carousel/composables/styles/usePaginationStyles";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const { init, states, DEFAULT_STATES } = useInit(toRef(props.widget));

init();

const { resetStates, handleStateUpdate, stateController } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const arrowsStyles = computed<CSSProperties>(() => {
  return useArrowsStyles().arrowsStyles();
});

const arrowsContainerStyles = computed<CSSProperties>(() => {
  const arrowsOptions = fields.value.arrows?.field.options || {};
  return useArrowsStyles(arrowsOptions).arrowsContainerStyles();
});

const arrowsButtonStyles = computed(() => {
  const arrows = fields.value.arrows.field;
  const arrowsDefaultOptions = arrows.options;
  const arrowsStatesOptions =
    _get(arrows, `options.states.${states.value.arrows}`) || {};

  return {
    ...useArrowsStyles(arrowsDefaultOptions).arrowsButtonStyles(),
    ...useArrowsStyles(arrowsStatesOptions).arrowsButtonStyles(),
  };
});

const paginationStyles = computed<CSSProperties>(() => {
  const paginationOptions = fields.value.pagination?.field.options || {};
  return usePaginationStyles(paginationOptions).paginationStyles();
});

const paginationDotsStyles = computed<CSSProperties>(() => {
  const pagination = fields.value.pagination.field;
  const paginationDefaultOptions = pagination.options;
  const paginationStatesOptions =
    _get(pagination, `options.states.${states.value.pagination}`) || {};

  paginationStatesOptions.isStroke = paginationDefaultOptions.isStroke;

  return {
    ...usePaginationStyles(paginationDefaultOptions).paginationDotsStyles(),
    ...usePaginationStyles(paginationStatesOptions).paginationDotsStyles(),
  };
});
</script>

<style lang="scss">
.top-match-fenix-widget {
  &__arrow-container {
    align-self: flex-start;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 6;

    &--right {
      right: 0;
      left: initial;

      .container-widget__arrow-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__pagination-container {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 6;
  }

  .base-widget__content-container {
    position: relative;
  }
}
</style>
