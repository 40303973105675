<template>
  <WidgetField
    :widget="widget"
    :field="field"
    :style="headerStyles"
  >
    <slot name="left">
      <WidgetField
        v-if="pageTitleField.options._active"
        :widget="widget"
        :field="isSearchResult ? searchResultField : pageTitleField"
        class="w-pct-100"
      >
        <BasicTitleField
          :field="isSearchResult ? searchResultField : pageTitleField"
        >
          <div :style="pageTitleWrapperStyles">
            <CommonIcon
              v-if="pageTitleField.options.icon._active && !isSearchResult"
              :name="pageTitleField.options.icon.default_value"
              :size="pageTitleField.options.icon.iconSize"
              :style="pageTitleIconStyles"
            />
            <span
              v-if="
                !isSearchResult ||
                (searchResultField?.options._active && isSearchResult)
              "
              >{{ titleValue }}</span
            >
            <span
              v-if="isSearchResult"
              :style="searchResultStyles"
            >
              {{ getLocalizedValue(searchResultField?.options.query.value) }}
            </span>
          </div>
        </BasicTitleField>
      </WidgetField>
    </slot>

    <slot name="right">
      <WidgetField
        v-if="sortingField && sortingField.options._active"
        :widget="widget"
        :field="sortingField"
        :style="sortingWrapperStyles"
      >
        <div class="button-group">
          <span :style="sortingStyles">Random</span>
          <span :style="sortingStyles">A-Z</span>
        </div>
      </WidgetField>

      <WidgetField
        v-if="searchField && searchField.options._active"
        :widget="widget"
        :field="searchField"
        :style="searchWrapperStyles"
      >
        <DropdownTriggerField
          :widget="widget"
          :field="searchField"
          :placeholder-value="searchField.options.placeholder.value"
          :state="searchState"
          value="Search query"
        />
      </WidgetField>
    </slot>
  </WidgetField>
</template>

<script lang="ts" setup>
import {
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import { getColorStyle, getWidthStyles } from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { State } from "~~/models/widgets/widget-controls.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";

const props = defineProps<{
  widget: IWidgetWithFields;
  field: IWidgetField;
  pageTitleField: IWidgetField;
  searchField?: IWidgetField;
  searchResultField?: IWidgetField;
  sortingField?: IWidgetField;
  searchState?: State;
  pageTypeState?: State;
  sortingState?: State;
}>();

const { getLocalizedValue } = useLocalizedValue();

const isSearchResult = computed<boolean>(
  () => props.pageTypeState === State.CASINO_SEARCH
);

const titleValue = computed<string>(() =>
  getLocalizedValue.value(
    isSearchResult.value
      ? props.searchResultField?.value
      : props.pageTitleField.value
  )
);

const searchResultStyles = computed<ElementStyle>(() => {
  const settings = props.searchResultField?.options;

  if (!settings) {
    return {};
  }

  return {
    ...getCommonStyles(settings),
    ...getTextCommonStyles(settings.query),
  };
});

const headerStyles = computed<ElementStyle>(() => {
  const settings = props.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    alignItems: "center",
    flexWrap: settings.layout === 3 ? "nowrap" : "wrap",
    gap: getPxValueFromNumber(settings.distance),
    ...getCommonStyles(settings),
  };
});

const pageTitleIconStyles = computed<ElementStyle>(() => {
  const settings = props.pageTitleField.options;

  if (!settings) {
    return {};
  }

  return {
    ...getColorStyle(settings.icon.textColor),
  };
});

const pageTitleWrapperStyles = computed<ElementStyle>(() => {
  const settings = props.pageTitleField.options;
  const settingsResult = props.searchResultField?.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: settings.alignment,
    gap: getPxValueFromNumber(settings.icon.gap),
    ...getCommonStyles(
      isSearchResult.value && settingsResult ? settingsResult : settings
    ),
  };
});

const sortingStyles = computed<ElementStyle>(() => {
  const settings = props.sortingField?.options;

  if (!settings) {
    return {};
  }

  return {
    ...getCommonStyles(settings, props.sortingState),
    ...getTextCommonStyles(settings, props.sortingState),
  };
});

const searchWrapperStyles = computed<ElementStyle>(() => {
  const settings = props.searchField?.options;
  const settingsHeader = props.field.options;

  if (!settings || !settingsHeader) {
    return {};
  }

  const mode = settingsHeader.layout;

  return {
    width: mode === 2 ? "auto" : getWidthStyles(settings.width).width,
    flexGrow: mode === 3 || mode === 2 ? "1" : "0",
  };
});

const sortingWrapperStyles = computed<ElementStyle>(() => {
  const settings = props.sortingField?.options;
  const settingsHeader = props.field.options;

  if (!settings || !settingsHeader) {
    return {};
  }

  return {
    order: settingsHeader.layout === 1 ? "1" : "initial",
  };
});
</script>

<style lang="scss">
.button-group {
  min-width: 175px;
  @include flex(center, center, 0);
  span {
    text-align: center;
    flex: 1 1 50%;
    &:first-child {
      border-color: #1677ff !important;
      z-index: 1;
      border: {
        top-right-radius: 0 !important;
        bottom-right-radius: 0 !important;
      }
    }
    &:last-child {
      border-left: 0 !important;
      border: {
        top-left-radius: 0 !important;
        bottom-left-radius: 0 !important;
      }
    }
  }
}
</style>
