<template>
  <div
    :style="styles.contentStyles.value"
    :class="hasGeneratedStyles && widget.options._contentCssClass"
  >
    <WidgetField
      :widget="widget"
      :field="fields.bonus_block.field"
      :style="styles.bonusBlockStyles.value"
      :class="hasGeneratedStyles && fields.bonus_block.field.options._cssClass"
    >
      <WidgetField
        v-for="(bonus, index) in bonuses"
        :key="index"
        :widget="widget"
        :field="fields.bonus.field"
        :style="styles.bonusStyles.value"
        :class="hasGeneratedStyles && fields.bonus.field.options._cssClass"
      >
        <WidgetField
          :widget="widget"
          :field="fields.bonus_header.field"
          :style="styles.bonusHeaderStyles.value"
          :class="
            hasGeneratedStyles && fields.bonus_header.field.options._cssClass
          "
        >
          <FlexibleImageField
            v-if="fields.bonus_image.field.options._active"
            class="w-100"
            :widget="widget"
            :field="fields.bonus_image.field"
            :value="bonus.image"
            :style="{ overflow: 'visible' }"
          />
          <WidgetField
            :widget="widget"
            :field="fields.bonus_title.field"
            :style="styles.bonusTitleStyles.value"
            :class="
              hasGeneratedStyles && fields.bonus_title.field.options._cssClass
            "
          >
            {{ bonus.title }}
          </WidgetField>
        </WidgetField>
        <WidgetField
          :widget="widget"
          :field="fields.bonus_content.field"
          :style="styles.bonusContentStyles.value"
          :class="
            hasGeneratedStyles && fields.bonus_content.field.options._cssClass
          "
        >
          {{ "Description of the bonus" }}
        </WidgetField>
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.no_bonus.field"
        :style="styles.noBonusStyles.value"
        :class="hasGeneratedStyles && fields.no_bonus.field.options._cssClass"
      >
        <FlexibleImageField
          v-if="fields.no_bonus_image.field.options._active"
          class="w-100"
          :widget="widget"
          :field="fields.no_bonus_image.field"
          :style="{ overflow: 'visible' }"
        />
        <WidgetField
          :widget="widget"
          :field="fields.no_bonus_title.field"
          :style="styles.noBonusTitleStyles.value"
          :class="
            hasGeneratedStyles && fields.no_bonus_title.field.options._cssClass
          "
        >
          {{ getLocalizedValue(fields.no_bonus_title.field.value) }}
        </WidgetField>
      </WidgetField>
    </WidgetField>

    <WidgetField
      v-if="fields.description.field.options._active"
      :widget="widget"
      :field="fields.description.field"
    >
      <BasicDescriptionField
        :fields="[fields.description.field]"
        :widget="widget"
      />
    </WidgetField>

    <WidgetField
      :widget="widget"
      :field="fields.button.field"
      :style="styles.buttonContainerStyles.value"
      :class="fields.button.field.options._containerCssClass"
    >
      <PromoBetsFenixWidgetButton
        :icon-active="fields.button.field.options.iconSettings._active"
        :icon="
          fields.button.field.options.iconSettings.value ||
          fields.button.field.options.iconSettings.default_value
        "
        :text="'Deposit Now'"
        :button-style="styles.buttonStyles.value"
        :button-text-style="styles.buttonTextStyles.value"
        :button-icon-style="styles.buttonIconStyles.value"
        :button-css-class="fields.button.field.options._cssClass"
        :button-text-css-class="
          fields.button.field.options.buttonTextSettings._cssClass
        "
        :button-icon-css-class="
          fields.button.field.options.iconSettings._cssClass
        "
      />
    </WidgetField>
  </div>
</template>
<script setup lang="ts">
const { getLocalizedValue } = useLocalizedValue();
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { StateController } from "~~/composables/widgets/common/useStates";
import { IFields } from "~~/composables/widgets/useWidgetFields";
import { PromoBetsFenixWidgetButton } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";

import casinoBonusImage from "./images/casino-bonus-image.svg?url";
import sportsBonusImage from "./images/sports-bonus-image.svg?url";
import { useWelcomeBonusFenixWidgetStyles } from "./composables/useWelcomeBonusFenixWidgetStyles";

const props = defineProps<{
  widget: IWidgetWithFields;
  fields: IFields;
  stateController: StateController;
  hasGeneratedStyles?: boolean;
}>();

const _styles = useWelcomeBonusFenixWidgetStyles(
  ref(props.widget),
  ref(props.fields),
  props.stateController
);

const styles = computed(() => {
  if (props.hasGeneratedStyles) {
    return Object.keys(_styles).reduce((acc, key) => {
      acc[key] = {
        value: {},
      };
      return acc;
    }, {} as Record<any, any>);
  }

  return _styles;
});

const bonuses = [
  {
    image: casinoBonusImage,
    title: "Casino Bonus",
    description: "Description of the bonus",
  },
  {
    image: sportsBonusImage,
    title: "Sports Bonus",
    description: "Description of the bonus",
  },
];
</script>
