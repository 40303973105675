import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { ConfigTabItem, State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { useBetslipCollapseControls } from "~~/composables/widgets/betslip-fenix/useBetslipFenixCollapseConfig";
import { useFenixBetslipItems } from "~~/composables/widgets/betslip-fenix/useFenixBetslipItems";
import { useFenixBetslipWidgetConfig } from "~~/composables/widgets/betslip-fenix/useBetslipFenixWidgetConfig";
import { generateContentSectionName } from "~~/helpers/configs/generate-section-name";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useEachWayBetControls } from "~~/composables/widgets/betslip-fenix/useEachWayBetControls";
import { useAcceptChangesConfig } from "~~/composables/widgets/betslip-fenix/useAcceptChangesConfig";

import { useFormFieldConfig } from "../form/useFormFieldConfig";

import { useBetslipFenixHeaderControls } from "./useBetslipFenixHeaderControls";
import { useBetslipTabsControls } from "./../betslip/useBetslipTabsControls";
import { useBetslipBetsContainerControls } from "./../betslip/useBetslipBetsContainerControls";
import { useBetslipMessagesControls } from "./../betslip/useBetslipMessagesControls";
import { useBetslipResultContainerControls } from "./../betslip/useBetslipResultContainerControls";
import { useBetslipButtonsControls } from "./../betslip/useBetslipButtonsControls";
import { useBetslipFenixMultibetTooltipConfig } from "./useBetslipFenixMultibetTooltipConfig";

export const useBetslipFenixConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const statesValuePath = computed<string>(() => {
    if (
      states.value[selectedField.value!.name] === State.DEFAULT ||
      states.value[selectedField.value!.name] === State.SINGLE_BETS
    ) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const {
    BETSLIP_DROPDOWN_ITEMS,
    MY_BETS_DROPDOWN_ITEMS,
    BETS_RESULT_DROPDOWN_ITEMS,
    BOOKING_CODE_DROPDOWN_ITEMS,
    TABS,

    BETS_SETTINGS_BETSLIP_ITEMS,
    BETS_SETTINGS_BETS_RESULT_ITEMS,
    BETS_SETTINGS_DEFAULT_ITEMS,
    BETS_CARDS_DEFAULT_SETTINGS_ITEMS,
    BETS_CARDS_MY_BETS_SETTINGS_ITEMS,
    BETS_CARDS_BETS_RESULT_SETTINGS_ITEMS,
    EVENT_CONTAINER_ITEMS,
    NOBETS_SETTINGS_BETSLIP_ITEMS,
    NOBETS_SETTINGS_MYBETS_ITEMS,
    BETS_SETTINGS_BOOKING_CODE_ITEMS,
  } = useFenixBetslipItems(widget);

  const currentTabs = computed<any>(() => {
    if (states.value[widget.value.name] === State.BETSLIP) {
      TABS.bets_container = [
        generateTabItem("Design", "design"),
        generateTabItem("Content", "content"),
      ];
    }
    return TABS;
  });

  const { widgetDesignControls, widgetContentControls } =
    useFenixBetslipWidgetConfig(widget, states, emit, fields);

  const {
    collapseDesignControls,
    collapseContentControls,
    collapseStateControls,
  } = useBetslipCollapseControls(widget, states, emit);

  const { multibetTooltipDesignStyles, multibetTipContentStyles } =
    useBetslipFenixMultibetTooltipConfig(widget);

  const {
    headerDesignControls,
    headerContentControls,
    headerStatesControls,
    headerCountersDesignControls,
    headerCountersContentControls,
    cashoutTooltipDesignStyles,
    cashoutTooltipContentStyles,
  } = useBetslipFenixHeaderControls(states, emit, statesValuePath, fields);

  const {
    betslipTabsDesignControls,
    betslipTabsContentControls,
    betslipTabsStatesControls,
  } = useBetslipTabsControls(states, emit, statesValuePath);

  const {
    betsDesignControls,
    betsContentControls,
    betsPreviewControls,

    betsCardsDesignControls,
    betsCardsContentControls,

    liveStatusDesignControls,
    liveStatusContentControls,

    notAviableStatusDesignControls,
    notAviableStatusContentControls,

    closeIconControls,

    eventNameControls,

    betMarketControls,

    betNameControls,

    coefficientDesignControls,
    coefficientContentControls,
    coefficientStatesControls,

    betsTypeDesignControls,
    betsTypeContentControls,
    betsTypeStatesControls,

    timeControls,

    eventContainerDesignControls,
    eventContainerContentControls,

    bookingLabelControls,

    bookingButtonStatesControls,
    bookingButtonDesignControls,
    bookingButtonContentControls,

    bookingErrorDesignControls,
    bookingErrorContentControls,
  } = useBetslipBetsContainerControls(
    widget,
    states,
    emit,
    statesValuePath,
    {
      BETS_SETTINGS_BETSLIP_ITEMS,
      BETS_SETTINGS_BETS_RESULT_ITEMS,
      BETS_SETTINGS_DEFAULT_ITEMS,
      BETS_SETTINGS_BOOKING_CODE_ITEMS,
      BETS_CARDS_DEFAULT_SETTINGS_ITEMS,
      BETS_CARDS_MY_BETS_SETTINGS_ITEMS,
      BETS_CARDS_BETS_RESULT_SETTINGS_ITEMS,
      EVENT_CONTAINER_ITEMS,
      NOBETS_SETTINGS_BETSLIP_ITEMS,
      NOBETS_SETTINGS_MYBETS_ITEMS,
    },
    true
  );

  const {
    warningMessageDesignControls,
    warningMessageContentControls,

    myBetsWarningMessageDesignControls,
    myBetsWarningMessageContentControls,

    betsResultSuccessMessageDesignControls,
    betsResultSuccessMessageContentControls,

    successMessageDesignControls,
    successMessageContentControls,
  } = useBetslipMessagesControls(true);

  const {
    resultDesignControls,
    resultContentControls,

    totalAmountsControls,

    betAmountsControls,
  } = useBetslipResultContainerControls(widget, states);

  const {
    actionButtonDesignControls,
    actionButtonContentControls,
    actionButtonStatesControls,

    removeButtonDesignControls,
    removeButtonContentControls,
    removeButtonStatesControls,

    copyButtonDesignControls,
    copyButtonContentControls,
    copyButtonStatesControls,

    saveChoiseButtonDesignControls,
    saveChoiseButtonContentControls,
    saveChoiseButtonStatesControls,

    continueButtonDesignControls,
    continueButtonContentControls,
    continueButtonStatesControls,

    primaryButtonDesignControls,
    primaryButtonContentControls,
    primaryButtonStatesControls,

    secondaryButtonDesignControls,
    secondaryButtonContentControls,
    secondaryButtonStatesControls,
  } = useBetslipButtonsControls(states, emit, statesValuePath);

  // FORM
  const {
    formLabelsStylesWithIconSettings,
    formFieldDesignStyles,
    formFieldTypograpyControls,
    formFieldStates,
    formErrorStyles,
  } = useFormFieldConfig(widget, states);

  const {
    formFieldDesignStyles: bookingInputDesignControls,
    formFieldTypograpyControls: bookingInputContentTextControl,
    formFieldStates: bookingInputStatesControls,
  } = useFormFieldConfig(widget, states, {
    fields_styling: "booking_input",
  });

  const bookingInputContentControls = computed(() => {
    return [bookingInputContentTextControl.value];
  });

  const {
    eachWayBetDesignControls,
    eachWayBetContentControls,

    eachWayCheckboxDesignControls,
    eachWayCheckboxContentControls,
    eachWayCheckboxStatesControls,

    formTooltipDesignStyles,
    formTooltipContentStyles,

    stakeInfoDesignControls,
    stakeInfoContentControls,

    totalStakeDesignControls,
    totalStakeContentControls,
  } = useEachWayBetControls({
    widget: widget,
    states: states,
    emit,
  });

  const { acceptChangesDesignControls, acceptChangesContentControls } =
    useAcceptChangesConfig({ widget: widget });

  const formContentControls = computed<Record<string, ControlProp[]>>(() => {
    return {
      "labels_styling": formLabelsStylesWithIconSettings.value,
      "fields_styling-design": formFieldDesignStyles.value,
      "fields_styling-content": [formFieldTypograpyControls.value],
      "fields_styling-states": formFieldStates.value,
      "errors_styling": formErrorStyles.value.filter(
        el => !el.section || el.section !== "Label"
      ),
    };
  });

  const stylesTabs = computed<ConfigTabItem[]>(() => {
    return [
      {
        "value": "labels_styling",
        "label": "Label style",
      },
      {
        "value": "fields_styling",
        "label": "Fields container style",
      },
      {
        "value": "errors_styling",
        "label": "Field errors style",
      },
    ];
  });

  const betslipFormContentControls = computed(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: selectedField.value,
            visible: selectedField.value?.options.display?.layout !== undefined,
            options: {
              label: "Layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.display.distance",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Distance between elements",
              showIconPrefix: false,
              showSuffix: true,
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: stylesTabs.value,
        },
      },
    ];
  });

  const betslipFormDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "ConfigMarginInputs",
        valuePath: "options.margin",
        valueSource: selectedField.value,
        options: {
          meta: {
            isBold: true,
          },
          label: "Margin",
          isAllSideSetting: true,
        },
      },
    ];
  });

  const betsResultTextControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: selectedField.value,
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
        },
      },
    ];
  });

  const loaderControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.stroke",
        className: "p-l-16 p-r-16 p-b-16 p-t-16",
        valueSource: selectedField.value,
        options: {
          label: "Stroke",
          minValue: 24,
          maxValue: 240,
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.size",
        className: "p-l-16 p-r-16 p-b-16 p-t-16",
        valueSource: selectedField.value,
        options: {
          label: "Size",
          minValue: 1,
          maxValue: 50,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.progressColor",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Progress color",
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.backgroundColor",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Background color",
          type: ColorPickerType.TEXT,
        },
      },
    ];
  });

  const headerLabelControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: selectedField.value,
      }),
    ];
  });

  return {
    BETSLIP_DROPDOWN_ITEMS,
    MY_BETS_DROPDOWN_ITEMS,
    BETS_RESULT_DROPDOWN_ITEMS,
    BOOKING_CODE_DROPDOWN_ITEMS,
    currentTabs,
    fields,

    /* 
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    headerDesignControls,
    headerContentControls,
    headerStatesControls,

    headerCountersDesignControls,
    headerCountersContentControls,

    cashoutTooltipDesignStyles,
    cashoutTooltipContentStyles,

    betslipTabsDesignControls,
    betslipTabsContentControls,
    betslipTabsStatesControls,

    betsDesignControls,
    betsContentControls,
    betsPreviewControls,

    betsCardsDesignControls,
    betsCardsContentControls,

    liveStatusDesignControls,
    liveStatusContentControls,

    notAviableStatusDesignControls,
    notAviableStatusContentControls,

    closeIconControls,

    eventNameControls,

    betMarketControls,

    betNameControls,

    coefficientDesignControls,
    coefficientContentControls,
    coefficientStatesControls,

    resultDesignControls,
    resultContentControls,

    totalAmountsControls,

    warningMessageDesignControls,
    warningMessageContentControls,

    myBetsWarningMessageDesignControls,
    myBetsWarningMessageContentControls,

    betsResultSuccessMessageDesignControls,
    betsResultSuccessMessageContentControls,

    successMessageDesignControls,
    successMessageContentControls,

    betsTypeDesignControls,
    betsTypeContentControls,
    betsTypeStatesControls,

    timeControls,

    eventContainerDesignControls,
    eventContainerContentControls,

    betAmountsControls,

    actionButtonDesignControls,
    actionButtonContentControls,
    actionButtonStatesControls,

    removeButtonDesignControls,
    removeButtonContentControls,
    removeButtonStatesControls,

    copyButtonDesignControls,
    copyButtonContentControls,
    copyButtonStatesControls,

    saveChoiseButtonDesignControls,
    saveChoiseButtonContentControls,
    saveChoiseButtonStatesControls,

    continueButtonDesignControls,
    continueButtonContentControls,
    continueButtonStatesControls,

    primaryButtonDesignControls,
    primaryButtonContentControls,
    primaryButtonStatesControls,

    secondaryButtonDesignControls,
    secondaryButtonContentControls,
    secondaryButtonStatesControls,

    formContentControls,

    betslipFormDesignControls,
    betslipFormContentControls,

    collapseDesignControls,
    collapseContentControls,
    collapseStateControls,

    betsResultTextControls,

    multibetTooltipDesignStyles,
    multibetTipContentStyles,

    eachWayBetDesignControls,
    eachWayBetContentControls,

    eachWayCheckboxDesignControls,
    eachWayCheckboxContentControls,
    eachWayCheckboxStatesControls,

    formTooltipDesignStyles,
    formTooltipContentStyles,

    stakeInfoDesignControls,
    stakeInfoContentControls,

    totalStakeDesignControls,
    totalStakeContentControls,

    loaderControls,

    headerLabelControls,

    bookingLabelControls,

    bookingButtonStatesControls,
    bookingButtonDesignControls,
    bookingButtonContentControls,

    bookingInputDesignControls,
    bookingInputContentControls,
    bookingInputStatesControls,

    bookingErrorDesignControls,
    bookingErrorContentControls,
    acceptChangesDesignControls,
    acceptChangesContentControls,
  };
};
