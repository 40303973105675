<template>
  <div class="top-match-fenix__default-container">
    <WidgetField
      :widget="widget"
      :field="fields.match_cards.field"
    >
      <BasicDesignWrapperField
        :widget="widget"
        :field="fields.match_cards.field"
        :style="matchCardStyle"
        :state="states.match_cards"
        class="top-match-fenix__match-cards"
      >
        <WidgetField
          v-if="fields.title.field.options._active"
          :widget="widget"
          :field="fields.title.field"
          :class="`top-match-fenix__title--${fields.title.field.options.display.layout}`"
        >
          <BasicTitleField
            :field="fields.title.field"
            class="top-match-fenix__title"
          >
            <CommonIcon
              v-if="fields.title.field.options.icon._active"
              :name="fields.title.field.options.icon.value"
              :size="fields.title.field.options.icon.size"
              :color="getColorFromHex(fields.title.field.options.icon.color)"
              class="top-match-fenix__title-icon"
            />

            <span>Top match</span>
          </BasicTitleField>
        </WidgetField>

        <FlexibleImageField
          v-if="fields.image.active"
          :field="fields.image.field"
          :widget="widget"
          value="/images/topMatchFenix/basketball.jpg"
        />

        <WidgetField
          :widget="widget"
          :field="fields.content.field"
        >
          <BasicDesignWrapperField
            :widget="widget"
            :field="fields.content.field"
            :style="cardContentStyle"
            class="top-match-fenix__card-content"
          >
            <WidgetField
              v-if="fields.timer.field.options._active"
              :widget="widget"
              :field="fields.timer.field"
              class="top-match-fenix__timer-container"
              :class="`top-match-fenix__timer-container--${fields.timer.field.options.display.layout}`"
            >
              <BasicDesignWrapperField
                :widget="widget"
                :field="fields.timer.field"
                class="top-match-fenix__timer"
              >
                <WidgetField
                  v-if="fields.timer_header.field.options._active"
                  :widget="widget"
                  :field="fields.timer_header.field"
                  class="top-match-fenix__timer-header"
                  :class="`top-match-fenix__timer-header--${fields.timer_header.field.options.display.layout}`"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.timer_header.field"
                    class="top-match-fenix__timer-header-content"
                  >
                    <BasicTitleField :field="fields.timer_header.field">
                      Timer header
                    </BasicTitleField>
                  </BasicDesignWrapperField>
                </WidgetField>

                <WidgetField
                  v-if="fields.timer_counter.field.options._active"
                  :widget="widget"
                  :field="fields.timer_counter.field"
                  class="top-match-fenix__timer-counter"
                  :class="`top-match-fenix__timer-counter--${fields.timer_counter.field.options.display.layout}`"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.timer_counter.field"
                    class="top-match-fenix__timer-counter-content"
                  >
                    <TopMatchFenixTimer :widget="widget" />
                  </BasicDesignWrapperField>
                </WidgetField>
              </BasicDesignWrapperField>
            </WidgetField>

            <TopMatchEventInfo
              v-if="fields.event_info.field.options._active"
              :widget="widget"
              :states="states"
            />

            <TopMatchFenixMarkets
              :widget="widget"
              :states="states"
            />

            <TopMatchFenixBets
              :widget="widget"
              :states="states"
            />

            <TopMatchNotEnough :widget="widget" />
            <TopMatchButtons
              :widget="widget"
              :states="states"
            />
          </BasicDesignWrapperField>
        </WidgetField>
      </BasicDesignWrapperField>
    </WidgetField>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getColorFromHex,
  getFlexPosition,
  getHeightStyles,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
}>();

const fields = useWidgetFields(props.widget);

const titleAlignment = computed<string>(() => {
  return getFlexPosition(fields.value.title.field.options.display.alignment);
});

const titleGap = computed<string>(() => {
  return getPxValueFromNumber(fields.value.title.field.options.icon.gap);
});

const titleIconOrder = computed<number>(() => {
  const position = fields.value.title.field.options.icon.position;

  if (position === "left") {
    return 0;
  }

  return 1;
});

const matchCardStyle = computed(() => {
  return {
    ...getHeightStyles(
      fields.value.match_cards.field.options.size.height,
      false
    ),
    ...fields.value.match_cards.field.options.display.align,
  };
});

const matchCardGap = computed<string>(() => {
  return getPxValueFromNumber(
    fields.value.match_cards.field.options.display.gap
  );
});

const cardContentStyle = computed(() => {
  return {
    ...fields.value.content.field.options.display.align,
    gap: getPxValueFromNumber(fields.value.content.field.options.display.gap),
  };
});

const timerContainerAlign = computed<string>(() => {
  return getFlexPosition(fields.value.timer.field.options.display.alignment);
});

const timerContainerGap = computed<string>(() => {
  return getPxValueFromNumber(fields.value.timer.field.options.display.gap);
});

const timerHeaderAlign = computed<string>(() => {
  return getFlexPosition(
    fields.value.timer_header.field.options.display.alignment
  );
});

const timerCounterGap = computed<string>(() => {
  return getPxValueFromNumber(
    fields.value.timer_counter.field.options.display.gap
  );
});

const timerCounterAlign = computed<string>(() => {
  return getFlexPosition(
    fields.value.timer_counter.field.options.display.alignment
  );
});
</script>

<style lang="scss">
.top-match-fenix {
  &__match-cards {
    display: flex;
    flex-direction: column;
    gap: v-bind(matchCardGap);
  }

  &__card-content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    .basic-title-widget__content {
      @include flex(center, v-bind(titleAlignment), v-bind(titleGap));
    }

    &-icon {
      order: v-bind(titleIconOrder);
    }

    &--hug {
      display: inline-block;
      align-self: flex-start;
    }

    &--fill {
      align-self: stretch;
    }
  }

  &__timer {
    display: flex;
    flex-direction: column;
    align-items: v-bind(timerContainerAlign);
    gap: v-bind(timerContainerGap);
  }

  &__timer-container--fill {
    align-self: stretch;
  }

  &__timer-container--hug {
    display: inline-flex;
  }

  &__timer-header-content {
    display: flex;
    justify-content: v-bind(timerHeaderAlign);
  }

  &__timer-header--fill {
    align-self: stretch;
  }

  &__timer-header--hug {
    display: inline-flex;
    width: fit-content;
  }

  &__timer-counter-content {
    display: flex;
    gap: v-bind(timerCounterGap);
    justify-content: v-bind(timerCounterAlign);
    align-items: stretch;
  }

  &__timer-counter--fill {
    align-self: stretch;
  }

  &__timer-counter--hug {
    display: inline-flex;
    width: fit-content;
  }
}
</style>
