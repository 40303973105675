import { getDefaultSizeValue } from "~~/constants/configs/common/widget-initial";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { Dimension } from "~~/models/grid.interface";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 103;

const v103WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (
    widget.name === "BetslipFenixWidget" &&
    !widget.content.bets_container.options.size
  ) {
    widget.content.bets_container.options.size = {
      height: getDefaultSizeValue(ResizingType.FIXED, "45", Dimension.VH),
    };
  }

  return widget;
};
export default v103WidgetMigration;
