<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <PaymentProvidersListFenixWidgetSettings
      v-if="selectedWidget?.id === widget.id"
      :widget="widget"
      :states="states"
    />

    <div
      v-if="states.providers_list !== State.NO_PROVIDERS"
      class="payment-providers-list-fenix"
    >
      <WidgetField
        v-if="fields.page_title.active"
        :widget="widget"
        :field="fields.page_title.field"
      >
        <BasicTitleField :field="fields.page_title.field" />
      </WidgetField>

      <WidgetField
        v-if="states.providers_list === State.INSUFFICIENT_PERSONAL_DATA"
        :widget="widget"
        :field="fields.information_message.field"
        :style="informationMessageStyles"
      >
        <div class="payment-providers-list-fenix__information-text-container">
          <WidgetField
            :widget="widget"
            :field="fields.information_title.field"
          >
            <BasicTitleField :field="fields.information_title.field" />
          </WidgetField>

          <WidgetField
            :widget="widget"
            :field="fields.information_description.field"
          >
            <BasicTitleField
              :field="fields.information_description.field"
              value="Please fill in the fields: the list of fields is here"
            />
          </WidgetField>
        </div>

        <WidgetField
          :widget="widget"
          :field="fields.information_button.field"
          class="payment-providers-list-fenix__information-button-container"
        >
          <BasicButtonField
            :field="fields.information_button.field"
            :current-state="states.information_button"
            custom-value="Fill data"
          />
        </WidgetField>
      </WidgetField>

      <WidgetField
        v-if="
          fields.providers_list.field.options._active ||
          states.providers_list !== State.INSUFFICIENT_PERSONAL_DATA
        "
        :widget="widget"
        :field="fields.providers_list.field"
        class="payment-providers-list-fenix__provider-cards-field"
        :style="[cardsListContainerStyles, providersListOpacityStyles]"
      >
        <PaymentProvidersListFenixCard
          v-for="idx in 10"
          :key="idx"
          :widget="widget"
          :fields="fields"
          :item-index="idx"
          :states="states"
        />
      </WidgetField>
    </div>

    <WidgetField
      v-else
      :widget="widget"
      :field="fields.no_providers_title.field"
      :style="noProvidersContainerAlignment"
    >
      <div :style="noProvidersContainerStyles">
        <span :style="noProvidersTextStyleValue">
          {{ localizedNoProvidersValue }}
        </span>

        <span
          v-if="
            fields.no_providers_title.field.options.iconSettings &&
            fields.no_providers_title.field.options.iconSettings._active
          "
          :style="iconStyleValue"
        >
          <CommonIcon
            :name="
              fields.no_providers_title.field.options.iconSettings.value ||
              fields.no_providers_title.field.options.iconSettings.default_value
            "
          />
        </span>
      </div>
    </WidgetField>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { getPxValueFromNumber } from "~~/assets/utils";
import { getCommonStyles, getDisplayGridStyles } from "~~/assets/utils/styles";
import {
  getBackgroundColorStyle,
  getBackgroundFillStyle,
  getBorderStyle,
  getBoxShadowStyle,
  getColorFromHex,
  getColorValue,
  getCornerRadiusStyle,
  getDecorationValue,
  getFontSizeStyle,
  getSpacing,
} from "~~/assets/utils/widget-settings";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { useInit } from "~~/composables/widgets/payment-providers-list-fenix/usePaymentProvidersListFenixInit";
import { ElementStyle } from "~~/models/common";
import { ICell } from "~~/models/grid.interface";
import {
  Alignment,
  DisplayOrientation,
  SpacingKeyName,
  State,
} from "~~/models/widgets/widget-controls.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const DEFAULT_STATES = {
  providers_list: State.PROVIDERS_EXIST,
  provider_card: State.DEFAULT,
  provider_button: State.DEFAULT,
  information_button: State.DEFAULT,
  information_message: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { fields, init } = useInit(ref(props.widget));

const { getLocalizedValue } = useLocalizedValue();

const providersListOpacityStyles = computed<ElementStyle>(() => {
  if (states.value.providers_list !== State.INSUFFICIENT_PERSONAL_DATA)
    return {};

  return {
    opacity: `${fields.value.providers_list.field.options.insufficientOpacity}%`,
    pointerEvents: "none",
  };
});

const cardsListContainerStyles = computed<ElementStyle>(() => {
  return {
    ...getCommonStyles(fields.value.providers_list.field.options),
    ...getBackgroundFillStyle(
      fields.value.providers_list.field.options.fillImageColor
    ),
    ...getDisplayGridStyles(
      fields.value.providers_list.field.options.gridSettings
    ),
  };
});

const localizedNoProvidersValue = computed(() => {
  return getLocalizedValue.value(fields.value.no_providers_title.field.value);
});

const colorStyle = computed<{ color: string }>(() => {
  const options = fields.value.no_providers_title.field.options;

  if (options.color) {
    const color = getColorValue(options.color);

    return { color };
  }
  return { color: "unset" };
});

const noProvidersTextStyleValue = computed<ElementStyle>(() => {
  let result: ElementStyle = {};
  const options = fields.value.no_providers_title.field.options;

  return {
    ...result,
    textAlign: options.alignment,
    ...getFontSizeStyle(options.theme),
    fontFamily: options.fontFamily,
    ...colorStyle.value,
    ...getDecorationValue(options.decoration),
  };
});

const iconStyleValue = computed<ElementStyle>(() => {
  const iconSettings =
    fields.value.no_providers_title.field.options.iconSettings;

  return {
    color: getColorFromHex(iconSettings?.textColor) as string,
    ...getFontSizeStyle(iconSettings?.iconSize),
  };
});

const noProvidersContainerStyles = computed<ElementStyle>(() => {
  return {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    flexDirection:
      fields.value.no_providers_title.field.options.iconSettings.position ===
      Alignment.LEFT
        ? "row-reverse"
        : "row",
    gap: getPxValueFromNumber(
      fields.value.no_providers_title.field.options.iconSettings.gap
    ),
    ...getSpacing(
      fields.value.no_providers_title.field.options.padding,
      SpacingKeyName.PADDING
    ),
  };
});

const noProvidersContainerAlignment = computed<ElementStyle>(() => {
  const options = fields.value.no_providers_title.field.options;

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: fields.value.no_providers_title.field.options.alignment,
    ...getBorderStyle(options.border),
    ...getBoxShadowStyle(options.shadow),
    ...getCornerRadiusStyle(options.cornerRadius),
    ...getBorderStyle(options.border),
    ...getBackgroundColorStyle(options.fill),
  };
});

const informationMessageStyles = computed<ElementStyle>(() => {
  const options = fields.value.information_message.field.options;

  return {
    ...getCommonStyles(
      fields.value.information_message.field.options,
      states.value.information_message
    ),
    display: "flex",
    flexDirection:
      options.layout === DisplayOrientation.VERTICAL ? "column" : "row",
    gap: getPxValueFromNumber(
      fields.value.information_message.field.options.gap
    ),
    alignItems:
      options.layout === DisplayOrientation.HORIZONTAL
        ? options.alignment
        : "unset",
  };
});
init();
</script>

<style lang="scss">
.payment-providers-list-fenix {
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__information-text-container {
    width: auto;
  }

  &__information-button-container {
    flex: auto;
  }
}
</style>
