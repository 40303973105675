<template>
  <div
    :style="textStyles"
    class="sportsbook-live__time"
  >
    <CommonIcon
      v-if="showIcon"
      :style="iconStyles"
      :name="icon || field.options.icon.value"
      class="sportsbook-events__icon"
    />
    <slot>
      <span> 00:00 Period </span>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetField } from "~~/models/widgets/widget.core/widget.model";
import { ElementStyle } from "~~/models/common";
import { getCommonStyles, getTextCommonStyles } from "~~/assets/utils/styles";
import {
  getColorStyle,
  getFontSizeStyle,
} from "~~/assets/utils/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";

const { field, icon, state, showIcon } = defineProps<{
  field: IWidgetField;
  icon?: string;
  showIcon?: boolean;
  state?: State;
}>();

const iconStyles = computed<ElementStyle>(() => {
  return {
    ...getColorStyle(
      state && state !== State.DEFAULT
        ? field.options.states[state].icon.color
        : field.options.icon.color
    ),
    ...getFontSizeStyle(field.options.icon.size),
  };
});

const textStyles = computed<ElementStyle>(() => {
  return {
    ...getTextCommonStyles(field.options, state),
    ...getCommonStyles(field.options),
  };
});
</script>

<style lang="scss">
.sportsbook-live {
  &__time {
    @include flex(center, flex-start, 4px);
  }
}
</style>
