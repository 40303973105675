import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import { deepCopy } from "~~/assets/utils";
import { DYNAMIC_FIELD_OPTIONS } from "~~/constants/widget-details/form/dynamic-fields";
import { FORM_ELEMENTS_LIST } from "~~/models/widgets/form.model";
import { WIDGET_FIELD_TYPES } from "~~/models/common/field-types.enum";

const TEXT_FIELDS = [
  WIDGET_FIELD_TYPES.IMAGE_ELEMENT,
  WIDGET_FIELD_TYPES.TEXT_ELEMENT,
  WIDGET_FIELD_TYPES.TEXT_EDITOR_ELEMENT,
  WIDGET_FIELD_TYPES.TEXT_AREA_ELEMENT,
  WIDGET_FIELD_TYPES.DROPDOWN_ELEMENT,
  WIDGET_FIELD_TYPES.BUTTON_LINK_ELEMENT,
  WIDGET_FIELD_TYPES.CHECKBOX,
  WIDGET_FIELD_TYPES.INPUT,
  WIDGET_FIELD_TYPES.UNKNOWN,
];

const ITEMS_FIELDS = [
  WIDGET_FIELD_TYPES.ARRAY_ELEMENT,
  WIDGET_FIELD_TYPES.ITEMS_ELEMENT,
];

const isObject = (value: any) =>
  value && typeof value === "object" && !Array.isArray(value);

export function useLocalisedField() {
  const { transformToLocalizedValue } = useLocalizedValue();

  const localizeItems = (items: any, force = false): any => {
    const oldItemsInOptions = (items || []).reduce(
      // @ts-ignore
      (acc, item) => {
        const keyName = item.name || item.label;
        acc[keyName] = deepCopy(item);

        acc[keyName].label = transformToLocalizedValue(
          acc[keyName].label,
          force
        ).localizedValue;

        return acc;
      },
      {} as Record<string, any>
    );

    return items.map((formFieldItem: any) => {
      if (oldItemsInOptions[formFieldItem.name]) {
        return oldItemsInOptions[formFieldItem.name];
      }

      return {
        value: formFieldItem.value,
        label: transformToLocalizedValue(formFieldItem.label, force)
          .localizedValue,
      };
    });
  };

  const applyFormElementLocalization = (formField: any, force = false) => {
    const initialFieldValue = DYNAMIC_FIELD_OPTIONS[formField.type]({
      ...formField,
      placeholder: formField.placeholder || "",
      label: formField.label || formField.title || "",
      options: formField.options,
    });

    /*
      For radio element items values are located
      inside options. So options are Array
    */
    if (Array.isArray(formField.options) && formField.options.length > 0) {
      formField.options = initialFieldValue.options;

      initialFieldValue.options.items = localizeItems(
        initialFieldValue.options.items,
        force
      );
    } else if (
      formField.type === "FormRadioField" &&
      !formField.options?.items?.length &&
      formField.items?.length
    ) {
      const items = deepCopy(formField.items);

      formField.options = initialFieldValue.options;
      delete formField.items;

      initialFieldValue.options.items = localizeItems(items, force);
    } else {
      for (const key in initialFieldValue.options) {
        const option = initialFieldValue.options[key];

        // life hack to fix localized value
        const neededLocalizeOptionsKeys = [
          "query",
          "tooltip",
          "search",
          "text",
          "label",
          "placeholder",
        ];

        if (!neededLocalizeOptionsKeys.includes(key)) {
          continue;
        }

        if (typeof option === "object" && option && !Array.isArray(option)) {
          const value = formField[key] || initialFieldValue.options[key]?.value;

          const oldActiveValue = formField.options[key]?._active;

          formField.options[key] = {
            ...initialFieldValue.options[key],
            _active:
              typeof oldActiveValue === "boolean" ? oldActiveValue : true,
            value: transformToLocalizedValue(value, force).localizedValue,
          };
          continue;
        }

        formField.options[key] = initialFieldValue.options[key];
      }
    }
  };

  /*
    force - reassign value even if it was localized before.
    default - false
  */
  function toLocalised(field: any, force = false) {
    // Localize values for widget with that have text input for .value
    if (TEXT_FIELDS.includes(field.type)) {
      field.value =
        isObject(field.value) && !force
          ? field.value
          : transformToLocalizedValue(field.value || field.default_value, force)
              .localizedValue;
    }
    // Localize array items
    else if (ITEMS_FIELDS.includes(field.type)) {
      const itemsValue = Array.isArray(field.items) && field.items[0]?.fields;

      if (Array.isArray(itemsValue)) {
        field.items[0].fields = itemsValue.map((arrayItem: any) =>
          useLocalisedField().toLocalised(arrayItem, force)
        );

        field.value = field.items;
      }
    }
    // Localize form fields
    else if ([WIDGET_FIELD_TYPES.FORM_ELEMENT].includes(field.type)) {
      Object.values(field.value?.fields || {}).forEach((formField: any) => {
        if (!formField.options) {
          formField.options = {};
        }

        // Localize form field items
        if (Array.isArray(formField.items) && formField.items.length) {
          formField.options.items = localizeItems(
            formField.options?.items || []
          );
        }

        applyFormElementLocalization(formField, force);
      });
    } else if (FORM_ELEMENTS_LIST.includes(field.type)) {
      applyFormElementLocalization(field, force);
    }

    return field;
  }

  return {
    toLocalised,
  };
}
