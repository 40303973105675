import { CSSProperties } from "vue";

import {
  getColorFromHex,
  getColorValue,
  getDecorationValue,
  getDisplayOrientationStyles,
  getFlexAlign,
  getFontFamilyStyle,
  getFontSizeStyle,
  getResizingHeightStyles,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";
import { getPxValueFromNumber } from "~~/assets/utils";
import { Align, Display } from "~~/models/widgets/widget-controls.model";

import { EventInfoDirection } from "../../PromoBetsFenixWidgetTypes";
import { getEventInfoVariantStyles } from "../../PromoBetsFenixWidgetHelpers";

import { useDesignInitialStyles } from "./useDesignInitialStyles";

export const useDateTimeStyles = (
  dateTimeOptions: any = {},
  _active: boolean = true
) => {
  const dateTimeStyles = (eventInfoOptions: any = {}): CSSProperties => {
    if (!_active) {
      return {};
    }

    const size = dateTimeOptions.size?.height
      ? getResizingHeightStyles(dateTimeOptions.size?.height)
      : {};

    const displayOrientation = getDisplayOrientationStyles(
      dateTimeOptions.displayOrientation
    );
    const layout = getEventInfoVariantStyles(eventInfoOptions.variant)[
      eventInfoOptions.direction as EventInfoDirection
    ];

    const flexAlign = getFlexAlign(
      dateTimeOptions.layoutAlignment,
      dateTimeOptions.displayOrientation
    );
    return {
      ...layout.dateTimeStyles,

      ...useDesignInitialStyles(dateTimeOptions),

      ...displayOrientation,
      alignItems: flexAlign["align-items"],
      justifyContent: flexAlign["justify-content"],
      gap: getPxValueFromNumber(dateTimeOptions.gap),
      ...size,
    };
  };

  const dateTimeElementStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    return {
      display: Display.FLEX,
      alignItems: Align.MIDDLE,
      gap: getPxValueFromNumber(dateTimeOptions.icon.gap),
    };
  };

  const dateTimeIconStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    return {
      color: getColorFromHex(dateTimeOptions.icon.color) || undefined,
      ...getFontSizeStyle(dateTimeOptions.icon.size),
    };
  };

  const dateTimeTextStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    return {
      color: getColorValue(dateTimeOptions.color),
      ...getFontSizeStyle(dateTimeOptions.theme),
      ...getFontFamilyStyle(dateTimeOptions.fontFamily),
      ...getDecorationValue(dateTimeOptions.decoration),
      ...getTextAlignStyle(dateTimeOptions.alignment),
    };
  };

  return {
    dateTimeStyles,
    dateTimeElementStyles,
    dateTimeIconStyles,
    dateTimeTextStyles,
  };
};
