import { CSSProperties, Ref } from "vue";
import _get from "lodash/get";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { IFields } from "~~/composables/widgets/useWidgetFields";
import type { StateController } from "~~/composables/widgets/common/useStates";
import { useButtonStyles } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";

import { useContentStyles } from "./styles/useContentStyles";
import { useBonusBlockStyles } from "./styles/useBonusBlockStyles";
import { useBonusStyles } from "./styles/useBonusStyles";
import { useBonusHeaderStyles } from "./styles/useBonusHeaderStyles";
import { useBonusTitleStyles } from "./styles/useBonusTitleStyles";
import { useBonusContentStyles } from "./styles/useBonusContentStyles";
import { useNoBonusStyles } from "./styles/useNoBonusStyles";
import { useNoBonusTitleStyles } from "./styles/useNoBonusTitleStyles";

export const useWelcomeBonusFenixWidgetStyles = (
  widget: Ref<IWidgetWithFields>,
  fields: Ref<IFields>,
  stateController: StateController
) => {
  const contentStyles = computed((): CSSProperties => {
    const widgetOptions = widget.value.options || {};
    return useContentStyles(widgetOptions);
  });

  const bonusBlockStyles = computed((): CSSProperties => {
    const bonusBlockOptions = fields.value.bonus_block?.field.options || {};
    return useBonusBlockStyles(bonusBlockOptions);
  });

  const bonusStyles = computed((): CSSProperties => {
    const bonusOptions = fields.value.bonus?.field.options || {};
    return useBonusStyles(bonusOptions);
  });

  const bonusHeaderStyles = computed((): CSSProperties => {
    const bonusHeader = fields.value.bonus_header?.field;
    const bonusHeaderDefaultOptions = bonusHeader?.options;
    const bonusHeaderStatesOptions =
      _get(
        bonusHeader,
        `${stateController.getOptionsValuePath("bonus_header")}`
      ) || {};

    return {
      ...useBonusHeaderStyles(bonusHeaderDefaultOptions),
      ...useBonusHeaderStyles(bonusHeaderStatesOptions),
    };
  });

  const bonusImageStyles = computed((): CSSProperties => {
    return {};
  });

  const bonusTitleStyles = computed((): CSSProperties => {
    const bonusTitle = fields.value.bonus_title?.field;
    const bonusTitleDefaultOptions = bonusTitle?.options;
    const bonusTitleStatesOptions =
      _get(
        bonusTitle,
        `${stateController.getOptionsValuePath("bonus_header")}`
      ) || {};

    return {
      ...useBonusTitleStyles(bonusTitleDefaultOptions),
      ...useBonusTitleStyles(bonusTitleStatesOptions),
    };
  });

  const bonusContentStyles = computed((): CSSProperties => {
    const bonusContentOptions = fields.value.bonus_content?.field.options || {};
    return useBonusContentStyles(bonusContentOptions);
  });

  const noBonusStyles = computed((): CSSProperties => {
    const noBonus = fields.value.no_bonus?.field;
    const noBonusDefaultOptions = noBonus?.options;
    const noBonusStatesOptions =
      _get(noBonus, `${stateController.getOptionsValuePath("no_bonus")}`) || {};

    return {
      ...useNoBonusStyles(noBonusDefaultOptions),
      ...useNoBonusStyles(noBonusStatesOptions),
    };
  });

  const noBonusImageStyles = computed((): CSSProperties => {
    return {};
  });

  const noBonusTitleStyles = computed((): CSSProperties => {
    const noBonusTitle = fields.value.no_bonus_title?.field;
    const noBonusTitleDefaultOptions = noBonusTitle?.options;
    const noBonusTitleStatesOptions =
      _get(
        noBonusTitle,
        `${stateController.getOptionsValuePath("no_bonus_title")}`
      ) || {};

    return {
      ...useNoBonusTitleStyles(noBonusTitleDefaultOptions),
      ...useNoBonusTitleStyles(noBonusTitleStatesOptions),
    };
  });

  const buttonContainerStyles = computed<CSSProperties>(() => {
    const buttonOptions = fields.value.button?.field.options || {};
    return useButtonStyles(
      buttonOptions,
      Boolean(buttonOptions._active)
    ).buttonContainerStyles();
  });

  const buttonStyles = computed<CSSProperties>(() => {
    const button = fields.value.button.field;
    const buttonDefaultOptions = button.options;
    const buttonStatesOptions =
      _get(button, `${stateController.getOptionsValuePath("button")}`) || {};

    return {
      ...useButtonStyles(
        buttonDefaultOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonStyles(),
      ...useButtonStyles(
        buttonStatesOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonStyles(),
    };
  });

  const buttonTextStyles = computed<CSSProperties>(() => {
    const button = fields.value.button.field;
    const buttonDefaultOptions = button.options;
    const buttonStatesOptions =
      _get(button, `${stateController.getOptionsValuePath("button")}`) || {};

    return {
      ...useButtonStyles(
        buttonDefaultOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonTextStyles(),
      ...useButtonStyles(
        buttonStatesOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonTextStyles(),
    };
  });

  const buttonIconStyles = computed<CSSProperties>(() => {
    const button = fields.value.button.field;
    const buttonDefaultOptions = button.options;
    const buttonStatesOptions =
      _get(button, `${stateController.getOptionsValuePath("button")}`) || {};

    return {
      ...useButtonStyles(
        buttonDefaultOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonIconStyles(),
      ...useButtonStyles(
        buttonStatesOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonIconStyles(),
    };
  });

  return {
    contentStyles,

    bonusBlockStyles,

    bonusStyles,
    bonusHeaderStyles,
    bonusImageStyles,
    bonusTitleStyles,
    bonusContentStyles,

    noBonusStyles,
    noBonusImageStyles,
    noBonusTitleStyles,

    buttonContainerStyles,
    buttonStyles,
    buttonTextStyles,
    buttonIconStyles,
  };
};
