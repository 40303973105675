import { CHECKBOX_STYLE_INITIAL } from "~~/constants/widget-details/form/dynamic-fields/styling/checkboxes-styling";

import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 91;

const MODE_VALUE = ["table", "rows", "wrap"];

const v74WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (widget.name === "TransactionHistoryFenixWidget") {
    /* 
      Checkbox
    */
    const initialCheckbox = CHECKBOX_STYLE_INITIAL();
    const checkboxOptions = widget.content.table_checkboxes_settings.options;

    /* 
      If old style checkbox
    */
    if (checkboxOptions.checkIcon) {
      initialCheckbox.options.check.fill = checkboxOptions.fill;
      initialCheckbox.options.check.border = checkboxOptions.border;
      initialCheckbox.options.check.icon = checkboxOptions.checkIcon.color;

      for (const state in initialCheckbox.options.states) {
        const currStateOptions = (
          initialCheckbox.options.states as Record<string, any>
        )[state];
        const prevStateOptions = checkboxOptions.states[state];

        currStateOptions.check.fill = prevStateOptions.fill;
        currStateOptions.check.border = prevStateOptions.border;
        currStateOptions.check.icon = prevStateOptions.checkIcon.color;
      }

      widget.content.table_checkboxes_settings.options =
        initialCheckbox.options;
    }

    /* 
      Table
    */

    if (!MODE_VALUE.includes(widget.content.table.options.mode)) {
      widget.content.table.options.mode = "table";
    }
  }

  return widget;
};
export default v74WidgetMigration;
