import {
  CellSizeType,
  Dimension,
  ICellDimensionValue,
  IInsertPosition,
} from "~~/models/grid.interface";
import {
  Align,
  Alignment,
  BorderStrokeType,
  CustomLinkTypeValue,
  Display,
  DisplayOrientation,
  FillType,
  IBorderControl,
  IBorderWidthComplex,
  ICornerRadiusControl,
  ICornerValueComplex,
  IFillControl,
  IHeadingControl,
  IImagePosition,
  IShadowControl,
  ISpacingInput,
  LinkType,
  MinMaxDimension,
  ResizingType,
  SpacingKeyName,
  TextPosition,
  TextStyle,
  VerticalAlignment,
  VerticalPosition,
  WidgetDimension,
} from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";
import { DEFAULT_COLOR_VALUE } from "~~/constants/widget-config";
import { ElementStyle } from "~~/models/common";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { useLocalizedValue } from "~~/composables/useLocalizedValue";
import useImageCdnChange from "~~/composables/useImageCdnChange";
import {
  displayOrientationToFlexDirection,
  textPositionToFlexDirection,
} from "~~/constants/dictionaries";

/**
 * Generates style object with spacing values
 * @example INPUT getSpacing({top: 15, bottom: 15}, SpacingKeyName.MARGIN)
 * @example OUTPUT {margin-top: 15px, margin-bottom: 15px}
 */
export const getSpacing = (
  spacing: Partial<ISpacingInput>,
  spacingName: SpacingKeyName
): Record<string, string> => {
  const res: Record<string, string> = {};

  for (const spacingPropertyName in spacing) {
    const styleProperty: string = `${spacingName}-${spacingPropertyName}`;
    const propertyValue: number | string | undefined =
      spacing[spacingPropertyName as keyof ISpacingInput];

    res[styleProperty] = getPxValueFromNumber(propertyValue);
  }

  return res;
};

export const hexToRgb = (
  hex: string | null
): { r: number; g: number; b: number } | null => {
  if (!hex) {
    return null;
  }

  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  const expandHex = hex.replace(
    shorthandRegex,
    (_: string, r: string, g: string, b: string) => {
      return r + r + g + g + b + b;
    }
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(expandHex);

  if (!result) {
    return null;
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

/*
  @example INPUT fetchColorFromRgba('rgba(0,0,0,1)') OR fetchColorFromRgba('rgb(0,0,0)')
  @example OUTPUT { r: 0; g: 0; b: 0; a: 1 }
*/
export const fetchColorFromRgba = (
  rgba: string
): { r: number; g: number; b: number; a: number } | null => {
  const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(,\s*)?([01]|0?\.\d+)?\)$/;
  const color = rgba.match(rgbaRegex);

  if (!color) {
    return null;
  }

  return {
    r: +color[1],
    g: +color[2],
    b: +color[3],
    /*
      If opacity was fetched from rgb value - it's undefined.
      So we need to provide 1 instead of that
    */
    a: !isNaN(+color[5]) ? +color[5] : 1,
  };
};

/*
  @example INPUT getPercentageFromOpacityNormalized(0.5)
  @example OUTPUT 50
*/
export const getPercentageFromOpacityNormalized = (value: number): number => {
  const percentage = value * 100;

  /*
    Fix floating issue (55.00000000001 instead of 55, for example)
  */
  if (+percentage.toFixed(2) === percentage) {
    return percentage;
  }

  return +percentage.toFixed(2);
};

/*
  @example INPUT fetchColorFromRgba('rgba(0,0,0)')
  @example OUTPUT #000000
*/
export const rgbToHex = (r: number, g: number, b: number) => {
  return (
    "#" +
    ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()
  );
};

const getOpacityNormalized = (opacity: number | string | undefined): number => {
  const currValue = Math.min(Number(opacity), 100);

  if (!currValue) {
    return 0;
  }

  return currValue / 100;
};

export const getColorFromHex = (
  color: IFillControl | undefined
): string | null => {
  if (!color) {
    return null;
  }

  const rgb = hexToRgb(color.color);

  if (!rgb) {
    return null;
  }

  const opacity = getOpacityNormalized(color.opacity);
  return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
};

export const getBorderStyle = (
  border: IBorderControl | null
): Record<string, string> => {
  if (!border || !border.fill?.color) {
    return {};
  }

  const styles: Record<string, string> = {};

  styles.borderColor = getColorFromHex(border.fill) as string;
  styles.borderStyle = border.style as string;

  if (border.stroke?.type === BorderStrokeType.CUSTOM) {
    const width = border.stroke.width as IBorderWidthComplex;

    styles[`borderWidth`] = `${getPxValueFromNumber(
      width.top
    )} ${getPxValueFromNumber(width.right)} ${getPxValueFromNumber(
      width.bottom
    )} ${getPxValueFromNumber(width.left)}`;
  } else {
    styles.borderWidth = getPxValueFromNumber(border.stroke?.width as string);
  }

  return styles;
};

export const getBoxShadowStyle = (
  shadow: IShadowControl | null
): { boxShadow?: string } => {
  if (!shadow || !shadow.fill?.color) {
    return {};
  }

  const boxShadowColor = getColorFromHex(shadow.fill);
  const boxShadowXOffset = getPxValueFromNumber(shadow.x);
  const boxShadowYOffset = getPxValueFromNumber(shadow.y);
  const boxShadowBlur = getPxValueFromNumber(shadow.blur);
  const boxShadowSpread = getPxValueFromNumber(shadow.spread);

  return {
    boxShadow: `${boxShadowXOffset} ${boxShadowYOffset} ${boxShadowBlur} ${boxShadowSpread} ${boxShadowColor}`,
  };
};

export const getTextShadowStyle = (
  shadow: IShadowControl | null
): { textShadow?: string } => {
  if (!shadow || !shadow.fill?.color) {
    return {};
  }

  const boxShadowColor = getColorFromHex(shadow.fill);
  const boxShadowXOffset = getPxValueFromNumber(shadow.x);
  const boxShadowYOffset = getPxValueFromNumber(shadow.y);
  const boxShadowBlur = getPxValueFromNumber(shadow.blur);

  return {
    textShadow: `${boxShadowColor} ${boxShadowXOffset} ${boxShadowYOffset} ${boxShadowBlur} `,
  };
};

export const getCornerRadiusStyle = (
  cornerRadius: ICornerRadiusControl
): Record<string, string> => {
  if (!cornerRadius) {
    return {};
  }

  const styles: Record<string, string> = {};

  if (cornerRadius.type === BorderStrokeType.CUSTOM) {
    let style = "";
    for (const key of ["topLeft", "topRight", "bottomRight", "bottomLeft"]) {
      const value = getPxValueFromNumber(
        (cornerRadius.value as ICornerValueComplex)[
          key as keyof ICornerValueComplex
        ]
      );

      style += `${value} `;
    }

    styles.borderRadius = style;
  } else {
    styles.borderRadius = getPxValueFromNumber(cornerRadius.value as string);
  }

  return styles;
};

export const getBackgroundPosition = (
  position?: IImagePosition
): ElementStyle => {
  if (!position) {
    return {};
  }

  if (position.value) {
    return {
      backgroundPosition: position.value,
    };
  }

  return {
    backgroundPosition: `${position.x}% ${position.y}%`,
  };
};

export const getBackgroundFillStyle = (fill: IWidgetOptions): ElementStyle => {
  if (!fill) {
    return {};
  }

  if (fill.fillType === FillType.IMAGE) {
    if (!fill.value) return {};
    const changedValue = useImageCdnChange(fill.value);

    return {
      backgroundImage: `url('${changedValue}')`,
      ...getBackgroundPosition(fill.position),
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
  }

  if (fill.fillType === FillType.COLOR) {
    return getBackgroundColorStyle(fill.value as IFillControl);
  }

  return getBackgroundColorStyle(fill as IFillControl);
};

export const getBackgroundColorStyle = (
  fill: IFillControl | undefined
): Record<string, string> => {
  if (!fill) {
    return {};
  }

  if (!fill.color) {
    return {
      backgroundColor: "transparent",
    };
  }

  const styles: Record<string, string> = {
    backgroundColor: getColorFromHex(fill) || "",
  };

  return styles;
};

export const getColorStyle = (
  fill: IFillControl | undefined
): Record<string, string> => {
  if (!fill) {
    return {};
  }

  const styles: Record<string, string> = {
    color: getColorFromHex(fill) || "",
  };

  return styles;
};

export const getDecorationValue = (
  decoration: TextStyle[] | undefined
): { [key: string]: string | number } => {
  if (!decoration) {
    return {};
  }

  const result: { [key: string]: string | number } = {};
  let textDecoration = "";

  if (decoration.includes(TextStyle.BOLD)) {
    result.fontWeight = 600;
  }

  if (decoration.includes(TextStyle.ITALIC)) {
    result.fontStyle = TextStyle.ITALIC;
  }

  /*
    Since text editor accepts TextStyle.STRIKE(instead of TextStyle.LINE_THROUGH) value,
    we're using it everywhere, but for title css style we're adding TextStyle.LINE_THROUGH
   */
  if (decoration.includes(TextStyle.STRIKE)) {
    textDecoration = `${TextStyle.LINE_THROUGH} `;
  }

  if (decoration.includes(TextStyle.UNDERLINE)) {
    textDecoration += TextStyle.UNDERLINE;
  }

  result.textDecoration = textDecoration;

  return result;
};

export const getDecorationColor = (
  color?: IFillControl
): Record<string, string> => {
  if (!color) {
    return {};
  }

  const rgba = fetchColorFromRgba(getColorFromHex(color) as string);

  return {
    textDecorationColor: rgbaToHash(rgba!.r, rgba!.g, rgba!.b, rgba!.a),
  };
};

export const getFontSizeStyle = (
  fontSize: IHeadingControl | string
): Record<string, string> => {
  if (!fontSize) {
    return {};
  }

  const size = typeof fontSize === "object" ? fontSize.value : fontSize;

  const styles: Record<string, string> = {
    fontSize: size ? `${size}px` : "initial",
  };

  return styles;
};

export const getFontFamilyStyle = (
  fontFamily: string
): Record<string, string> => {
  if (!fontFamily) {
    return {};
  }

  const styles: Record<string, string> = {
    fontFamily,
  };

  return styles;
};

export const getTextAlignStyle = (
  alignment: Alignment
): Record<string, string> => {
  return {
    textAlign: alignment,
  };
};

export const getLinkValue = (
  url: string | null,
  type: LinkType | null
): string => {
  if (!url) {
    return "";
  }

  if (type === LinkType.EMAIL) {
    return `${CustomLinkTypeValue.EMAIL}${url}`;
  }

  if (type === LinkType.PHONE) {
    return `${CustomLinkTypeValue.PHONE}${url}`;
  }

  return useLocalizedValue().getLocalizedValue.value(url);
};

export const getLinkHref = (url?: string): string => {
  if (!url) {
    return "";
  }

  if (url.startsWith(CustomLinkTypeValue.EMAIL)) {
    return url.slice(CustomLinkTypeValue.EMAIL.length, url.length);
  }

  if (url.startsWith(CustomLinkTypeValue.PHONE)) {
    return url.slice(CustomLinkTypeValue.PHONE.length, url.length);
  }

  return url;
};

/*
  Parse rgba to rgb if opacity is 1.
  Otherwise return rgba value
*/
export const normalizeColor = (color: string): string | null => {
  const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(,\s*)?([01]|0?\.\d+)?\)$/;
  const colorValue = color.match(rgbaRegex);

  if (!colorValue) {
    return null;
  }

  if (colorValue[5] && +colorValue[5] !== 1) {
    return `rgba(${colorValue[1]},${colorValue[2]},${colorValue[3]},${colorValue[5]})`;
  }

  return `rgb(${colorValue[1]},${colorValue[2]},${colorValue[3]})`;
};

/*
  Parse rgba to rgb.
  @INPUT rgbaToHash(255,255,255,1)
  @OUTPUT #000000
*/
export const rgbaToHash = (r: number, g: number, b: number, a: number) => {
  const red = Math.round(r * a + 255 * (1 - a));
  const blue = Math.round(g * a + 255 * (1 - a));
  const green = Math.round(b * a + 255 * (1 - a));

  return (
    "#" +
    ("0" + red.toString(16)).slice(-2) +
    ("0" + blue.toString(16)).slice(-2) +
    ("0" + green.toString(16)).slice(-2)
  );
};

/*
  Compares rgb and rgba colors

  Example: compareRgbaColors("rgb(255,255,255)", "rgba(255,255,255, 1)") => true
  Example: compareRgbaColors("rgb(255,255,255)", "rgb(255,255,255)") => true
  Example: compareRgbaColors("rgb(255,255,255)", "rgba(255,255,255, 0.5)") => false
*/
export const compareRgbaColors = (
  colorA: string | null,
  colorB: string | null
): boolean => {
  if (colorA === colorB) {
    return true;
  }

  if (!colorA || !colorB) {
    return false;
  }

  const normalizedA = normalizeColor(colorA);
  const normalizedB = normalizeColor(colorB);

  if (!normalizedA || !normalizedB) {
    return false;
  }

  return normalizedA === normalizedB;
};

export const reorderListElements = <T>(
  key: keyof T,
  targetId: number | string,
  insertPosition: IInsertPosition,
  list: T[]
): [T[], T | null, number] => {
  const cp = [...list];

  let newIndex = insertPosition.index + insertPosition.insertionRule;
  const oldIndex = cp.findIndex(item => item[key] === targetId);

  if (oldIndex === insertPosition.index) {
    return [cp, null, oldIndex];
  }

  const replacedItem = cp.splice(oldIndex, 1)[0];
  if (oldIndex < newIndex) {
    newIndex--;
  }

  cp.splice(newIndex, 0, replacedItem!);

  return [cp, replacedItem, oldIndex];
};

export const getFlexPosition = (position: string): string => {
  if (
    !position ||
    position === Alignment.LEFT ||
    position === VerticalAlignment.TOP ||
    position === VerticalPosition.TOP
  ) {
    return "flex-start";
  }

  if (position === Alignment.CENTER || position === VerticalAlignment.MIDDLE) {
    return position;
  }

  return `flex-end`;
};

export const getInitialFillColorValue = () => {};

export const getColorValue = (colorValue: IFillControl | null): string => {
  const color = getColorFromHex({
    opacity: colorValue?.opacity || 100,
    color: colorValue?.color || DEFAULT_COLOR_VALUE,
  });

  return color || "";
};

export const getTextAlignStyleReversed = (
  align: string
): { textAlign: Alignment } => {
  if (align === Alignment.LEFT) {
    return {
      textAlign: Alignment.RIGHT,
    };
  }

  if (align === Alignment.RIGHT) {
    return {
      textAlign: Alignment.LEFT,
    };
  }

  return {
    textAlign: Alignment.CENTER,
  };
};

export const getFlexAlign = (
  flexString?: string,
  layout?: DisplayOrientation
): Record<any, Align> => {
  if (!flexString) {
    return {};
  }

  const wrapStyles: ElementStyle = {};

  const flexAlignArr = <Align[]>flexString.split(" ");

  if (layout === DisplayOrientation.VERTICAL) {
    return {
      "align-items": flexAlignArr[1],
      "justify-content": flexAlignArr[0],
    };
  }

  if (layout === DisplayOrientation.WRAP) {
    wrapStyles["align-content"] = flexAlignArr[1];
  }

  return {
    "align-items": flexAlignArr[0],
    "justify-content": flexAlignArr[1],
    ...wrapStyles,
  };
};

export const getGridAlign = (gridString?: string): Record<any, Align> => {
  if (!gridString) {
    return {};
  }

  const gridAlignArr = <Align[]>gridString.split(" ");

  return {
    "align-self": gridAlignArr[0],
    "justify-items": gridAlignArr[1],
  };
};

export const getWidthStyles = (width: WidgetDimension): ElementStyle => {
  if (width.type === CellSizeType.FILL) {
    return {
      width: "100%",
    };
  }

  if (width.type === CellSizeType.HUG) {
    return {
      width: "initial",
      display: "inline-block",
    };
  }

  return {
    width: `${width.value?.value}${width.value?.type}`,
  };
};

export const getResizingWidthStyles = (
  width: WidgetDimension
): ElementStyle => {
  if (width?.type === ResizingType.FIT || width?.type === ResizingType.HUG) {
    return {
      width: "fit-content",
    };
  }

  if (width?.type === ResizingType.FILL) {
    return {
      width: "100%",
    };
  }

  if (width?.type === ResizingType.FIXED) {
    if (width?.value?.type === Dimension.CALC) {
      return {
        width: `calc(${width?.value?.value})`,
      };
    } else {
      return {
        width: `${width?.value?.value}${width?.value?.type}`,
      };
    }
  }

  return {
    width: undefined,
  };
};

export const getResizingHeightStyles = (
  height: WidgetDimension,
  flex?: boolean
): ElementStyle => {
  if (height?.type === ResizingType.FIT || height?.type === ResizingType.HUG) {
    return {
      height: "fit-content",
    };
  }

  if (height?.type === ResizingType.FILL) {
    if (flex) {
      return {
        flexGrow: "1",
      };
    }
    return {
      height: "100%",
    };
  }

  if (height?.type === ResizingType.FIXED) {
    if (height?.value?.type === Dimension.CALC) {
      return {
        height: `calc(${height?.value?.value})`,
      };
    } else {
      return {
        height: `${height?.value?.value}${height?.value?.type}`,
      };
    }
  }

  return {
    height: undefined,
  };
};

export const getHeightStyles = (
  height: WidgetDimension,
  inline = true
): ElementStyle => {
  if (!height || height.type === CellSizeType.HUG) {
    return {
      height: "initial",
      display: inline ? "inline-block" : "",
    };
  }

  if (height.type === CellSizeType.FILL) {
    return {
      alignSelf: "stretch",
    };
  }

  return {
    height: `${height.value?.value}${height.value?.type}`,
  };
};

export const getDisplayOrientationStyles = (
  displayOrientation: DisplayOrientation
) => {
  return {
    display: Display.FLEX,
    flexDirection: displayOrientationToFlexDirection[displayOrientation],
  };
};

export const getDimensionValue = (
  value: ICellDimensionValue | null
): string => {
  if (!value) {
    return "0px";
  }

  if (value.type === Dimension.CALC) {
    return `calc(${value?.value})`;
  }

  if (value.type === Dimension.CUSTOM) {
    return `${value.value}`;
  }

  return `${value?.value}${value?.type}`;
};

export const getTextPosition = (textPosition: TextPosition) => {
  return {
    display: Display.FLEX,
    flexDirection: textPositionToFlexDirection[textPosition],
  };
};

export const getMinMaxStyles = (
  minMaxOptions: MinMaxDimension,
  key: string
): ElementStyle => {
  if (!minMaxOptions || !minMaxOptions._active) {
    return {};
  }

  if (minMaxOptions.mode === "min" && minMaxOptions.min) {
    return {
      [`min${key}`]: getDimensionValue(minMaxOptions.min),
    };
  }

  if (minMaxOptions.mode === "max" && minMaxOptions.max) {
    return {
      [`max${key}`]: getDimensionValue(minMaxOptions.max),
    };
  }

  if (
    minMaxOptions.mode === "min_max" &&
    minMaxOptions.max &&
    minMaxOptions.min
  ) {
    return {
      [`min${key}`]: getDimensionValue(minMaxOptions.min),
      [`max${key}`]: getDimensionValue(minMaxOptions.max),
    };
  }

  return {};
};
