<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <!-- All controls in right sidebar -->
    <CasinoSmartGamesListFenixWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div
      class="casino-smart-games-widget"
      :style="widgetWrapperStyles"
    >
      <CasinoHeader
        v-if="fields.header.active"
        :widget="widget"
        :field="fields.header.field"
        :page-title-field="fields.title.field"
        class="casino-smart-games-widget__header"
      >
        <template #right>
          <WidgetField
            v-if="fields.header_button.active"
            :widget="widget"
            :field="fields.header_button.field"
          >
            <BasicButtonField
              :field="fields.header_button.field"
              :current-state="states.header_button"
              custom-value="Button"
            />
          </WidgetField>
        </template>
      </CasinoHeader>

      <WidgetField
        :widget="widget"
        :field="fields.games_list.field"
      >
        <BasicDesignWrapperField
          :widget="widget"
          :field="fields.games_list.field"
        >
          <div
            v-if="states.games_status === State.WITH_ACTIVE_GAMES"
            :style="gamesListWrapper"
          >
            <WidgetField
              v-for="idx in 5"
              :key="idx"
              :widget="widget"
              :field="fields.game_cards.field"
            >
              <BasicDesignWrapperField
                :widget="widget"
                :field="fields.game_cards.field"
                :state="states.game_cards"
                :style="cardsWrapperStyles"
              >
                <CasinoGameCard
                  :photo-id="idx"
                  :widget="widget"
                  :field="fields.game_cover.field"
                  :state="states.game_cover"
                  :photo-field="fields.game_cover.field"
                  :hover-content-field="fields.hover_content.field"
                  :game-title-field="fields.game_title.field"
                  :play-money-button-field="fields.play_money_button.field"
                  :play-money-button-state="states.play_money_button"
                  :play-free-button-field="fields.play_free_button.field"
                  :play-free-button-state="states.play_free_button"
                  :cards-elements-field="fields.game_cover.field"
                  :promo-tag-field="fields.promo_tag.field"
                  :promo-tag-state="states.promo_tag"
                  :favorite-icon-field="fields.favorite_icon.field"
                  :is-hover-content="isHoverContent"
                />

                <WidgetField
                  v-if="fields.game_name.active"
                  :widget="widget"
                  :field="fields.game_name.field"
                >
                  <BasicTitleField
                    :field="fields.game_name.field"
                    value="Game Name"
                  />
                </WidgetField>
              </BasicDesignWrapperField>
            </WidgetField>
            <WidgetField
              v-if="fields.all_games_card.active"
              :widget="widget"
              :field="fields.all_games_card.field"
              :style="allGamesCardWrapperStyles"
            >
              <BasicDesignWrapperField
                :widget="widget"
                :field="fields.all_games_card.field"
                :style="allGamesCardStyles"
              >
                <WidgetField
                  :widget="widget"
                  :field="fields.games_button.field"
                >
                  <BasicButtonField
                    :field="fields.games_button.field"
                    :current-state="states.games_button"
                  />
                </WidgetField>
              </BasicDesignWrapperField>
              <div v-if="fields.game_name.active">&nbsp;</div>
            </WidgetField>
          </div>

          <template v-else>
            <BasicTitleField :field="fields.nogames_title.field" />

            <BasicTitleField
              v-if="fields.nogames_title.field.options.description._active"
              :field="fields.nogames_title.field"
              :value="fields.nogames_title.field.options.description.value"
              :options="fields.nogames_title.field.options.description"
            />
          </template>
        </BasicDesignWrapperField>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useInit } from "~~/composables/widgets/casino/smart-games/useCasinoSmartGamesInit";
import { useStates } from "~~/composables/widgets/common/useStates";
import { getPxValueFromNumber } from "~~/assets/utils";
import { ElementStyle } from "~~/models/common";
import { getDisplayGridStyles } from "~~/assets/utils/styles";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { ContainerMode, State } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const { init, fields, states, DEFAULT_STATES } = useInit(toRef(props.widget));

const widgetSettingsStore = useWidgetSettingsStore();
const { selectedField } = storeToRefs(widgetSettingsStore);

const widgetWrapperStyles = computed<ElementStyle>(() => {
  const settings = props.widget.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(settings.display.distance),
  };
});

const cardsWrapperStyles = computed<ElementStyle>(() => {
  const settings = fields.value.game_cards.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(settings.distance),
  };
});

const allGamesCardWrapperStyles = computed<ElementStyle>(() => {
  const settings = fields.value.all_games_card.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    flexDirection: "column",
    gap: getPxValueFromNumber(settings.distance),
  };
});

const allGamesCardStyles = computed<ElementStyle>(() => {
  const settings = fields.value.all_games_card.field.options;

  if (!settings) {
    return {};
  }

  return {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: settings.alignment,
    gap: getPxValueFromNumber(settings.distance),
  };
});

const gamesListWrapper = computed<ElementStyle>(() => {
  const settings = fields.value.games_list.field.options;

  if (!settings) {
    return {};
  }

  switch (settings.mode) {
    case ContainerMode.GRID:
      return getDisplayGridStyles(settings.gridSettings);
    case ContainerMode.CAROUSEL:
      return {
        display: "grid",
        gap: getPxValueFromNumber(settings.gridSettings.gap.column),
        gridAutoColumns: getPxValueFromNumber(
          settings.gridSettings.minCardWidth
        ),
        gridAutoFlow: "column",
        overflowX: "auto",
      };
    default:
      return {};
  }
});

const isHoverContent = computed<boolean>(() => {
  return (
    selectedField.value?.name === fields.value.hover_content.field.name ||
    selectedField.value?.name === fields.value.game_title.field.name ||
    selectedField.value?.name === fields.value.play_money_button.field.name ||
    selectedField.value?.name === fields.value.play_free_button.field.name
  );
});

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
  resetConditionHandler: (stateName: string) => {
    const skip = [""];
    if (skip.includes(stateName)) return false;
    return true;
  },
});

init();
</script>

<style lang="scss">
.casino-smart-games-widget {
  position: relative;
  &__header {
    flex-wrap: nowrap !important;
  }
}
</style>
