<template>
  <div class="grid-add-block-controls">
    <CommonIcon
      name="ant-design:plus-square-filled"
      class="grid-add-block-controls__add-icon-container"
      :class="addIconContainerClasses"
      @click="toggleAddBlock"
    />
    <div
      v-if="filteredColumnIcons.length && showAddBlockContainer"
      class="grid-add-block-controls__columns-icons-container"
      :class="{
        'grid-add-block-controls__columns-icons-container--bottom':
          props.position === InsertionRule.AFTER,
        'grid-add-block-controls__columns-icons-container--top':
          props.position === InsertionRule.BEFORE,
      }"
    >
      <button
        v-for="(icon, index) in filteredColumnIcons"
        :key="index"
        class="grid-add-block-controls__icon pointer"
        @click.stop="addBlock(index + 1)"
      >
        <span class="anticon">
          <CommonIcon :name="icon" />
        </span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGridConfig } from "~~/store/grid";
import { useUiStore } from "~~/store/ui";
import { Breakpoint } from "~~/models/breakpoints.model";
import {
  NestingLevel,
  InsertionRule,
  ICell,
  IParentData,
} from "~~/models/grid.interface";
import { findCell } from "~~/assets/utils/grid/grid-tree";
import { useFilteredColumnsIcons } from "~~/components/composables/grid/useFilteredColumnsIcons";

const props = defineProps<{
  position: InsertionRule;
}>();

const gridStore = useGridConfig();
const uiStore = useUiStore();

const { selectedCell, currentLayout } = storeToRefs(gridStore);
const { activeScreen } = storeToRefs(uiStore);

const { addCell } = gridStore;

const showAddBlockContainer = ref<boolean>(false);

const filteredColumnIcons = useFilteredColumnsIcons.value;

const addIconContainerClasses = computed<string[]>(() => {
  const selectedCellVal = selectedCell.value;
  if (!selectedCellVal) return [];

  const level = selectedCellVal.settings.level;
  const isActive = showAddBlockContainer.value;
  const position = props.position === InsertionRule.AFTER ? "bottom" : "top";
  const baseClass = "grid-add-block-controls__add-icon-container";

  const classSuffix = `${
    level === NestingLevel.PARENT
      ? `--parent-${position}`
      : `--child-${position}`
  }`;
  const classes = [`${baseClass}${classSuffix}`];

  if (isActive)
    classes.push(
      `${baseClass}--active${
        level === NestingLevel.PARENT ? "-parent" : "-child"
      }`
    );

  return classes;
});

const toggleAddBlock = () => {
  if (
    activeScreen.value === Breakpoint.MOBILE ||
    activeScreen.value === Breakpoint.MOBILE_APP ||
    activeScreen.value === Breakpoint.TG_BOT
  ) {
    addBlock(1);
  } else {
    showAddBlockContainer.value = !showAddBlockContainer.value;
  }
};

const addBlock = (columns: number): void => {
  const selectedCellVal = selectedCell.value;
  if (!selectedCellVal) return;

  const { level } = selectedCellVal.settings;
  let parentCell: ICell | null = null;
  let layoutToAddCell: ICell[] = [];
  if (selectedCellVal.parentId === null) {
    layoutToAddCell = currentLayout.value;
  } else {
    parentCell = findCell(currentLayout.value, selectedCellVal.parentId);
    if (parentCell) {
      layoutToAddCell = parentCell.children;
    } else return;
  }

  const index = layoutToAddCell.indexOf(selectedCellVal) + props.position;
  const parent: IParentData = { parentId: selectedCellVal.parentId };
  /* Pass a parent cell exlicitly to avoid unnecessary call of `findCell` inside `addCell` */
  if (parentCell) parent.parentCell = parentCell;
  addCell(columns, level!, index, parent);
  showAddBlockContainer.value = false;
};
</script>

<style lang="scss">
.grid-add-block-controls {
  &__add-icon-container {
    position: absolute;
    font-size: 24px;
    border-radius: 4px;
    background-color: $c-white;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    cursor: pointer;

    &--parent-top,
    &--child-top {
      top: -13px;
    }
    &--parent-bottom,
    &--child-bottom {
      bottom: -13px;
    }

    &--parent-top,
    &--parent-bottom {
      color: $c-primary-base;
    }

    &--child-top,
    &--child-bottom {
      color: $c-violet;
    }

    &--active-parent {
      color: $c-primary-base-active;
    }

    &--active-child {
      color: $c-violet-active;
    }

    svg {
      border-radius: 8px;
    }
  }

  &__columns-icons-container {
    @include flex(center, space-around, 16px);
    position: absolute;
    height: $space-xl;
    padding: 0 $space-s;
    max-width: 120px;
    background-color: $c-white;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    left: 50%;
    transform: translateX(15px);
    z-index: 1;
    cursor: pointer;

    &--bottom {
      bottom: -18px;
    }

    &--top {
      top: -18px;
    }
  }

  &__icon {
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: background-color 0.3s ease;
    border-radius: 4px;

    &:hover {
      background-color: $c-grey-06;
    }
  }
}
</style>
