import type { Rule } from "ant-design-vue/es/form";

import { REPLACE_STR, widthValidations } from "~~/constants/width-validations";
import { IGridWidthParams, Sizing } from "~~/models/grid.interface";
import { Breakpoint, breakpoints } from "~~/models/breakpoints.model";
import { useUiStore } from "~~/store/ui";
import { useGridConfig } from "~~/store/grid";

export const useContainerWidthValidation = () => {
  const widthParams = reactive<IGridWidthParams>({
    sizing: useGridConfig().selectedCell?.settings.sizing ?? Sizing.ADAPTIVE,
    width: useGridConfig().selectedCell?.settings.width,
  });

  const uiStore = useUiStore();
  const { activeScreen } = storeToRefs(uiStore);

  const minWidth = computed<number>(() => {
    return breakpoints[activeScreen.value].screenSize;
  });

  const maxWidth = computed<number | null>(() => {
    switch (activeScreen.value) {
      case Breakpoint.TABLET:
        return breakpoints[Breakpoint.DESKTOP].screenSize - 1;
      case Breakpoint.MOBILE:
        return breakpoints[Breakpoint.TABLET].screenSize - 1;
      case Breakpoint.MOBILE_APP:
        return breakpoints[Breakpoint.TABLET].screenSize - 1;
      case Breakpoint.TG_BOT:
        return breakpoints[Breakpoint.TABLET].screenSize - 1;

      default:
        return null;
    }
  });

  const validateWidth = async (_rule: Rule, value: string) => {
    if (widthParams.sizing === Sizing.FIXED) {
      if (Number(value) < minWidth.value) {
        const errorMsg = widthValidations.MIN_CONTAINER_WIDTH.replace(
          REPLACE_STR,
          minWidth.value.toString()
        );
        return Promise.reject(errorMsg);
      }
      if (maxWidth.value && Number(value) > maxWidth.value) {
        const errorMsg = widthValidations.MAX_CONTAINER_WIDTH.replace(
          REPLACE_STR,
          maxWidth.value.toString()
        );
        return Promise.reject(errorMsg);
      }
    }
    return Promise.resolve();
  };

  const validationRules: Record<string, Rule[]> = {
    width: [{ validator: validateWidth, trigger: "change" }],
  };
  return {
    widthParams,
    validateWidth,
    validationRules,
  };
};
