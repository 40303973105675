import { useNotification } from "@kyvg/vue3-notification";

import successSound from "~~/assets/sounds/success-sound.mp3";
import { playSound } from "~~/composables/useSounds";

const notification = useNotification();

export function handleActionSuccess(
  text: string,
  successCallback?: () => void
): void {
  notification.notify({
    text,
    type: "success",
  });

  playSound(successSound);

  if (successCallback) successCallback();
}
