import type { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import {
  ContainerMode,
  Display,
  FlexDirection,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useFlexibleImageControls } from "~~/composables/widgets/common/useFlexibleCardWidgetControls";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { useSuccessScreenConfig } from "~~/composables/widgets/top-match-fenix/useSuccessScrenConfig";
import { useEventInfoConfig } from "~~/composables/widgets/top-match-fenix/useEventInfoConfig";
import { useBetsConfig } from "~~/composables/widgets/top-match-fenix/useBetsConfig";
import { useArrowsConfig } from "~~/entities/carousel/composables/useArrowsConfig";
import { usePaginationConfig } from "~~/entities/carousel/composables/usePaginationConfig";
import { useCarouselConfig } from "~~/entities/carousel/composables/useCarouselConfig";
import type { StateController } from "~~/composables/widgets/common/useStates";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any,
  stateController: StateController
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(useWidgetSettingsStore());

  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Arrow settings", "arrows"),
    generateDropdownItem("Pagination settings", "pagination"),
    generateDropdownItem("Match cards", "match_cards"),
    generateDropdownItem("Card title", "title"),
    generateDropdownItem("Image", "image"),
    generateDropdownItem("Content", "content"),
    generateDropdownItem("Timer", "timer"),
    generateDropdownItem("Timer header", "timer_header"),
    generateDropdownItem("Counter", "timer_counter"),
    generateDropdownItem("Days/hours/mins", "timer_value"),
    generateDropdownItem("Separator", "timer_separator"),
    generateDropdownItem("Event info", "event_info"),
    generateDropdownItem("Team / player", "team"),
    generateDropdownItem("Date / time", "date"),
    generateDropdownItem("Markets", "markets"),
    generateDropdownItem("Outcomes", "outcomes"),
    generateDropdownItem("Bets", "bets"),
    generateDropdownItem("Input", "input"),
    generateDropdownItem("Bet amounts", "bet_amounts"),
    generateDropdownItem("Not enough funds", "not_enough_container"),
    generateDropdownItem("Buttons group", "button_group"),
    generateDropdownItem("Place bet button", "button_1"),
    generateDropdownItem("See more bets", "button_2"),
    generateDropdownItem("Success message", "success_message"),
    generateDropdownItem("Bet card", "bet_card"),
    generateDropdownItem("Bets type", "bets_type"),
    generateDropdownItem("Event container", "event_container"),
    generateDropdownItem("Event name", "event_name"),
    generateDropdownItem("Bet market", "bet_market"),
    generateDropdownItem("Bet name", "bet_name"),
    generateDropdownItem("Coeffecient", "coefficent"),
    generateDropdownItem("Bet amounts", "result_bet_amounts"),
    generateDropdownItem("Continue button", "continue_button"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    arrows: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    pagination: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    match_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    content: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    timer: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    timer_header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    timer_counter: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    timer_value: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    timer_separator: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    markets: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    outcomes: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    bets: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    input: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    bet_amounts: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    not_enough_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    button_group: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    button_1: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    button_2: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    success_message: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bet_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bets_type: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    event_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    coefficent: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    continue_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const defaultDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const { carousel } = useCarouselConfig(widget);

  const carouselControls = computed<ControlProp[]>(() => {
    if (states.value.widget === "success") {
      return [];
    }

    if (widget.value.options.display.mode === ContainerMode.FLEX) {
      return [];
    }

    return [...carousel.value];
  });

  const widgetContentConfigItems = computed(() => {
    if (states.value.widget === "top_match") {
      return [
        {
          value: "pagination",
          label: "Pagination",
          isActiveValueSource: fields.value.pagination.field.options,
        },
        {
          value: "arrows",
          label: "Arrows",
          isActiveValueSource: fields.value.arrows.field.options,
        },
        {
          value: "match_cards",
          label: "Match cards",
        },
      ];
    }

    return [
      {
        value: "success_message",
        label: "Success message",
      },
      {
        value: "bet_card",
        label: "Bet card",
      },
      {
        value: "continue_button",
        label: "Continue button",
      },
    ];
  });

  const widgetContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.widget,
        options: {
          items: [
            {
              label: "Top match",
              value: "top_match",
            },
            {
              label: "Result",
              value: "result",
            },
          ],
        },
        onUpdate(value: State) {
          states.value.widget = value;
        },
      },
      ...carouselControls.value,
      {
        section: "Display mode",
        visible: states.value.widget === "result",
        controls: [
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.success.gap",
            valueSource: widget.value,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: widgetContentConfigItems.value,
        },
      },
    ];
  });

  const widgetDesignControls = computed<ControlProp[]>(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const topMatchContentControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigDimension",
        valuePath: "options.size.height",
        valueSource: fields.value.match_cards.field,
        options: {
          placeholder: "Enter text",
          type: ColorPickerType.TEXT,
          label: "Height",
          icon: "ant-design:column-height-outlined",
        },
      },
      {
        section: "Display mode",
        controls: [
          {
            componentPath: "ConfigAlignmentAdvanced",
            valuePath: "options.display.align",
            valueSource: selectedField.value,
            options: {
              label: "Align elements",
              display: Display.FLEX,
              direction: FlexDirection.COLUMN,
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: selectedField.value,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "title",
              label: "Card title",
              isActiveValueSource: fields.value.title.field.options,
            },
            {
              value: "image",
              label: "Image",
              isActiveValueSource: fields.value.image.field.options,
            },
            {
              value: "content",
              label: "Content",
            },
          ],
        },
      },
    ];
  });

  const topMatchStatePath = computed<string>(() => {
    if (states.value.match_cards === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.match_cards}`;
  });

  const topMatchStatesControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.match_cards,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          emit("update-state", { state: "match_cards", value });
        },
      },
      ...getInitialDesignList({
        source: fields.value.match_cards.field,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
        basePath: topMatchStatePath.value,
      }),
      {
        section: "Team / Player",
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${topMatchStatePath.value}.color`,
            valueSource: fields.value.team.field,
            options: {
              placeholder: "None",
              label: "Color",
              isBold: false,
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const titleControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.title.field,
            className: "group-control-element",
            options: {
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: fields.value.title.field,
            className: "border-bottom-none",
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
      typography({
        source: fields.value.title.field,
        except: ["ConfigAlignment"],
      }),
      {
        section: generateDesignSectionName("Icon"),
        valueSource: fields.value.title.field.options.icon,
        toggleable: true,
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.title.field,
            options: {},
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.title.field,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: fields.value.title.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "common/ConfigRadioIcon",
            valuePath: "options.icon.position",
            valueSource: fields.value.title.field,
            className: "group-control-element",
            options: {
              label: "Position",
              row: true,
              items: [
                { icon: "custom:unordered-list-outlined", value: "left" },
                {
                  icon: "custom:unordered-list-outlined",
                  value: "right",
                  iconClassName: "r-180",
                },
              ],
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.gap",
            valueSource: fields.value.title.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Spacing between text and icon",
              minValue: 12,
              maxValue: 32,
            },
          },
        ],
      },
    ];
  });

  const { flexibleImageControls } = useFlexibleImageControls(
    toRef(fields.value.image.field)
  );

  const contentContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: "Display mode",
        controls: [
          {
            componentPath: "ConfigAlignmentAdvanced",
            valuePath: "options.display.align",
            valueSource: fields.value.content.field,
            options: {
              label: "Align elements",
              display: Display.FLEX,
              direction: FlexDirection.COLUMN,
            },
          },
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.alignContent",
            valueSource: fields.value.content.field,
            options: {
              label: "Align content",
              items: [
                { value: "start", label: "Start" },
                { value: "end", label: "End" },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.content.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "timer",
              label: "Timer",
              isActiveValueSource: fields.value.timer.field.options,
            },
            {
              value: "event_info",
              label: "Events info",
              isActiveValueSource: fields.value.event_info.field.options,
            },
            {
              value: "markets",
              label: "Markets",
            },
            {
              value: "bets",
              label: "Bets",
            },
            {
              value: "not_enough_container",
              label: "Not enough funds message",
            },
            {
              value: "button_group",
              label: "Button Group",
            },
          ],
        },
      },
    ];
  });

  const timerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.timer.field,
            className: "group-control-element",
            options: {
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: fields.value.timer.field,
            className: "border-bottom-none",
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.timer.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "timer_header",
              label: "Timer header",
              isActiveValueSource: fields.value.timer_header.field.options,
            },
            {
              value: "timer_counter",
              label: "Counter",
              isActiveValueSource: fields.value.timer_counter.field.options,
            },
          ],
        },
      },
    ];
  });

  const timerHeaderControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.timer_header.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Display mode",
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: fields.value.timer_header.field,
            className: "border-bottom-none",
            options: {
              isTextContentIcons: false,
            },
          },
        ],
      },
      typography({
        source: fields.value.timer_header.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const timerCounterContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.timer_counter.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Display mode",
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          // {
          //   componentPath: "ConfigAlignment",
          //   valuePath: "options.display.alignment",
          //   valueSource: fields.value.timer_counter.field,
          //   className: "border-bottom-none",
          //   options: {
          //     isTextContentIcons: false,
          //   },
          // },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.timer_counter.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "timer_value",
              label: "Days/hours/mins",
              isActiveValueSource: fields.value.timer_value.field.options,
            },
            {
              value: "timer_separator",
              label: "Separator",
              isActiveValueSource: fields.value.timer_separator.field.options,
            },
          ],
        },
      },
    ];
  });

  const timerValueControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.timer_value.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Display mode",
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            valueSource: fields.value.timer_value.field,
            className: "border-bottom-none",
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.timer_value.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.fontFamily",
        valueSource: fields.value.timer_value.field,
        options: {
          placeholder: "Select",
          label: "Font family",
          isBold: true,
        },
      },
      typography(
        {
          source: fields.value.timer_value.field.options.numbers,
          except: ["ConfigAlignment"],
          isOptionPath: false,
        },
        "Numbers"
      ),
      typography(
        {
          source: fields.value.timer_value.field.options.caption,
          except: ["ConfigAlignment"],
          isOptionPath: false,
        },
        "Caption"
      ),
    ];
  });

  const timerSeparatorControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.timer_separator.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Display mode",
              noTextWrap: true,
              items: [
                {
                  label: "Fill container",
                  value: ResizingType.FILL,
                },
                {
                  label: "Hug content",
                  value: ResizingType.HUG,
                },
              ],
            },
          },
          {
            componentPath: "ConfigVerticalAlignment",
            valuePath: "options.display.alignment",
            valueSource: fields.value.timer_separator.field,
            className: "border-bottom-none",
            options: {},
          },
        ],
      },
      typography({
        source: fields.value.timer_separator.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const { eventInfoContentControls, teamControls, dateControls } =
    useEventInfoConfig(widget);

  /* 
    Markets
  */

  const {
    marketsContentControls,
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
    betsContentControls,
    betsAmountContentControls,
    betAmountsStatesControls,
    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,
    notEnoughFundsContentControls,
    buttonsGroupContentControls,
  } = useBetsConfig(widget, states, emit);

  const {
    buttonDesignControls: placeButtonDesignControls,
    buttonContentControls: placeButtonContentControlsWithoutLink,
    buttonStatesControls: placeButtonStatesControls,
  } = useButtonControls(
    {
      state: toRef(() => states.value.button_1),
      stateHandler: (state: State) => {
        emit("update-state", { state: "button_1", value: state });
      },
      valueSource: toRef(fields.value.button_1.field),
      exclude: {
        content: ["ConfigLinkType", "content/ConfigTextInputWithToggle"],
      },
    },
    emit
  );

  const {
    buttonDesignControls: seeMoreBetsButtonDesignControls,
    buttonContentControls: seeMoreBetsButtonContentControls,
    buttonStatesControls: seeMoreBetsButtonStatesControls,
  } = useButtonControls(
    {
      state: toRef(() => states.value.button_2),
      stateHandler: (state: State) => {
        emit("update-state", { state: "button_2", value: state });
      },
      valueSource: toRef(fields.value.button_2.field),
      exclude: {
        content: ["ConfigLinkType", "content/ConfigTextInputWithToggle"],
      },
    },
    emit
  );

  const placeButtonContentControls = computed(() => {
    return [
      ...placeButtonContentControlsWithoutLink.value,
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: fields.value.button_1.field,
        options: {
          label: "Login page URL",
          shouldShowStyleSettings: false,
        },
      },
    ];
  });

  const {
    successContentControls,
    betCardContentControls,
    betsTypeContentControls,
    eventContainerContentControls,
    textWithPaddingControls,
    coefContentControls,
    betAmountsControls,
    continueButtonDesignControls,
    continueButtonContentControls,
    continueButtonStatesControls,
  } = useSuccessScreenConfig(widget, states, emit);

  const { arrowsDesignControls, arrowsContentControls, arrowsStatesControls } =
    useArrowsConfig(widget, stateController);

  const {
    paginationDesignControls,
    paginationContentControls,
    paginationStatesControls,
  } = usePaginationConfig(widget, stateController);

  return {
    TABS,
    DEFAULT_DROPDOWN_ITEMS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    arrowsDesignControls,
    arrowsContentControls,
    arrowsStatesControls,

    paginationDesignControls,
    paginationContentControls,
    paginationStatesControls,

    defaultDesignControls,

    titleControls,
    topMatchContentControls,
    topMatchStatesControls,
    flexibleImageControls,
    contentContentControls,
    timerContentControls,
    timerHeaderControls,
    timerCounterContentControls,
    timerValueControls,
    timerSeparatorControls,
    eventInfoContentControls,
    teamControls,
    dateControls,
    marketsContentControls,
    betItemsDesignControls,
    betItemsContentControls,
    betItemsStateControls,
    betsContentControls,
    betAmountsStatesControls,
    notEnoughFundsContentControls,
    buttonsGroupContentControls,
    placeButtonDesignControls,
    placeButtonContentControls,
    placeButtonStatesControls,
    seeMoreBetsButtonDesignControls,
    seeMoreBetsButtonContentControls,
    seeMoreBetsButtonStatesControls,
    betsAmountContentControls,
    formFieldDesignStyles,
    formFieldContentStyles,
    formFieldStates,

    /* 
      Success screen
    */
    successContentControls,
    betCardContentControls,
    betsTypeContentControls,
    eventContainerContentControls,
    textWithPaddingControls,
    coefContentControls,
    betAmountsControls,
    continueButtonDesignControls,
    continueButtonContentControls,
    continueButtonStatesControls,
  };
};
