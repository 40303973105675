import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

export const CHECKBOX_INITIAL = (
  fieldDetails?: {
    placeholder: string;
    label: string;
    options?: IWidgetOptions;
  },
  formName = "form"
) => {
  if (fieldDetails?.options && Object.hasOwn(fieldDetails.options, "label")) {
    return {
      options: fieldDetails.options,
    };
  }

  return {
    options: {
      _active: true,
      _parent: formName,
      // title: {
      //   _active: true,
      //   value: fieldDetails?.label,
      // },
      label: {
        _active: true,
        value: fieldDetails?.label,
      },
      // tooltip: {
      //   _active: true,
      //   value: "Tooltip text",
      // },
    },
  };
};
