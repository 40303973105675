import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { FormDropdownItem } from "~~/models/widgets/form.model";

export const DROPDOWN_INITIAL = (
  fieldDetails?: {
    placeholder: string;
    label: string;
    options?: IWidgetOptions;
    items?: FormDropdownItem[] | null;
  },
  formName = "form"
) => {
  const items =
    (fieldDetails?.options?.items?.length
      ? fieldDetails?.options?.items
      : fieldDetails?.items) || [];

  const defaultValue = {
    label: {
      _active: true,
      value: fieldDetails?.label,
    },
    tooltip: {
      _active: true,
      value: "Tooltip text",
    },
    placeholder: {
      _active: true,
      value: fieldDetails?.placeholder || "Placeholder",
    },
  };

  if (fieldDetails?.options && Object.hasOwn(fieldDetails.options, "label")) {
    return {
      options: {
        ...fieldDetails.options,
        items: items as FormDropdownItem[] | null,
        label: fieldDetails.options.label || defaultValue.label,
        tooltip: fieldDetails.options.tooltip || defaultValue.tooltip,
        placeholder:
          fieldDetails.options.placeholder || defaultValue.placeholder,
      },
    };
  }

  return {
    options: {
      _active: true,
      _parent: formName,
      items: items as FormDropdownItem[] | null,
      ...defaultValue,
    },
  };
};
