import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";

export const DATE_INITIAL = (
  fieldDetails?: {
    placeholder: string;
    label: string;
    options?: IWidgetOptions;
  },
  formName = "form"
) => {
  if (fieldDetails?.options && Object.hasOwn(fieldDetails.options, "label")) {
    return {
      options: fieldDetails.options,
    };
  }

  return {
    options: {
      _active: true,
      _parent: formName,
      label: {
        _active: true,
        value: fieldDetails?.label,
      },
      tooltip: {
        _active: true,
        value: "Tooltip text",
      },
      placeholder: {
        _active: true,
        value: fieldDetails?.placeholder || "Placeholder",
      },
    },
  };
};
