<template>
  <WidgetField
    :widget="widget"
    :field="fields.button_group.field"
    class="top-match-fenix__buttons"
    :class="`top-match-fenix__buttons--${fields.button_group.field.options.display.layout}`"
  >
    <BasicDesignWrapperField
      :widget="widget"
      :field="fields.button_group.field"
      class="top-match-fenix__buttons-content"
    >
      <WidgetField
        :widget="widget"
        :field="fields.button_1.field"
      >
        <BasicButtonField
          :field="fields.button_1.field"
          :current-state="states.button_1"
          custom-value="Place bet"
        />
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.button_2.field"
      >
        <BasicButtonField
          :field="fields.button_2.field"
          :current-state="states.button_2"
          custom-value="See more bets"
        />
      </WidgetField>
    </BasicDesignWrapperField>
  </WidgetField>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getPxValueFromNumber } from "~~/assets/utils";
import { DisplayOrientation } from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
}>();

const fields = useWidgetFields(props.widget);

const gap = computed<string>(() => {
  return getPxValueFromNumber(
    fields.value.button_group.field.options.display.gap
  );
});

const dir = computed<string>(() => {
  const dir = fields.value.button_group.field.options.display.layout;

  if (dir === DisplayOrientation.HORIZONTAL) {
    return "row";
  }

  return "column";
});
</script>

<style lang="scss">
.top-match-fenix__buttons {
  &-content {
    display: flex;
    gap: v-bind(gap);
    flex-direction: v-bind(dir);
    align-items: stretch;

    & > * {
      flex: 1;
    }
  }
}
</style>
