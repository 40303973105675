import { usePagesStore } from "~~/store/pages";

import { PageEditor } from "../types";
import * as api from "../api";

export const usePageEditors = () => {
  const MAX_VISIBLE_EDITORS = 4;

  const pageId = usePagesStore().selectedPageId as string;

  const currentEditor = ref<PageEditor | null>(null);
  const allEditors = ref<PageEditor[] | null>(null);

  const visibleEditors = computed<PageEditor[] | null>(() => {
    return (
      allEditors.value?.reduce((acc: PageEditor[], editor: PageEditor) => {
        if (
          editor.sessionId !== currentEditor.value?.sessionId &&
          acc.length < MAX_VISIBLE_EDITORS
        ) {
          acc.push(editor);
        }
        return acc;
      }, []) || []
    );
  });

  const hiddenEditorsCount = computed<number>(() => {
    const totalHidden =
      Number(allEditors.value?.length) - MAX_VISIBLE_EDITORS - 1;

    return totalHidden > -1 ? totalHidden : 0;
  });

  const fetchEditors = async () => {
    if (!pageId) {
      return;
    }

    try {
      const { data } = await api.fetchPageEditors(pageId);
      allEditors.value = data;
    } catch (error) {
      console.error(error);
    }
  };

  const createEditor = async () => {
    if (!pageId) {
      return;
    }

    try {
      const { data } = await api.createPageEditor(pageId);
      currentEditor.value = data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateEditor = async () => {
    if (!currentEditor.value?.sessionId) {
      return;
    }

    try {
      const { data } = await api.updatePageEditor(
        currentEditor.value?.sessionId
      );
      currentEditor.value = data;
    } catch (error) {
      console.error(error);
    }
  };

  const removeEditor = async () => {
    if (!currentEditor.value?.sessionId) {
      return;
    }

    try {
      await api.removePageEditor(currentEditor.value?.sessionId);
      currentEditor.value = null;
      allEditors.value = null;
    } catch (error) {
      console.error(error);
    }
  };

  const removeEditorBeacon = () => {
    if (!currentEditor.value?.sessionId) {
      return;
    }

    try {
      api.removePageEditorBeacon(currentEditor.value?.sessionId);
      currentEditor.value = null;
      allEditors.value = null;
    } catch (error) {
      console.error(error);
    }
  };

  const executeAbortControllers = async () => {
    try {
      api.executeAbortControllers();
    } catch (error) {
      console.error(error);
    }
  };

  return {
    currentEditor,
    visibleEditors,
    hiddenEditorsCount,

    fetchEditors,
    createEditor,
    updateEditor,
    removeEditor,
    removeEditorBeacon,
    executeAbortControllers,
  };
};
