import { useNotification } from "@kyvg/vue3-notification";
import { AxiosError } from "axios";

import errorSound from "~~/assets/sounds/error-sound.mp3";
import { playSound } from "~~/composables/useSounds";

const notification = useNotification();

export function handleActionError(
  text: string,
  error?: AxiosError,
  errorCallback?: () => void
): void {
  if (error?.response && error.response.status === 422) {
    const responseData = error.response.data as { name: string };
    notification.notify({
      text: responseData.name,
      type: "error",
    });
  } else {
    notification.notify({
      text: text,
      type: "error",
    });
  }

  playSound(errorSound);

  if (errorCallback) errorCallback();
}
