import { computed, type Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { State } from "~~/models/widgets/widget-controls.model";
import { useItems } from "~~/composables/widgets/languages-list/useLangItems";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { stateConfig } from "~~/constants/configs/common/state-config";
import { useAllowDisableDependentFieldContentItems } from "~~/composables/widgets/useAllowDisableDependentFieldContentItems";
import { FieldContentItem } from "~~/models/widgets/widget.core/field-content-item.enum";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(widgetSettingsStore);

  const { DROPDOWN_ITEMS, TABS, SETTINGS_ITEMS } = useItems(widget);

  /*
    Widget config
  */
  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "ConfigButtonDisplaySettings",
        valuePath: "options.display",
        valueSource: widget.value,
        options: {
          label: "Display mode",
          showTitle: false,
          isBold: true,
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: SETTINGS_ITEMS,
        },
      },
    ];
  });

  /*
    Container
  */

  const dropdownContainerDesignConfig = computed(() => {
    return getInitialDesignList({
      source: fields.value.dropdown_container.field,
    });
  });

  const { allowDisableFieldsItem } = useAllowDisableDependentFieldContentItems(
    selectedField,
    [FieldContentItem.TEXT, FieldContentItem.ICON, FieldContentItem.ARROW]
  );

  const dropdownContainerContentConfig = computed(() => {
    return [
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options.text._active",
        valueSource: fields.value.dropdown_container.field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Text",
          size: "small",
          allowDisable: allowDisableFieldsItem(FieldContentItem.TEXT).value,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.text.color",
        valueSource: fields.value.dropdown_container.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Text color",
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "ConfigThemeSelect",
        valuePath: "options.text.theme",
        valueSource: fields.value.dropdown_container.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Text color",
        },
      },
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.text.fontFamily",
        valueSource: fields.value.dropdown_container.field,
        className: "group-control-element",
        options: {
          placeholder: "Select",
          label: "Font family",
        },
      },
      {
        componentPath: "ConfigDecoration",
        valuePath: "options.text.decoration",
        valueSource: fields.value.dropdown_container.field,
        options: {
          placeholder: "Select",
          label: "Decoration",
        },
      },
      {
        section: generateDesignSectionName("Icon"),
        toggleable: true,
        valueSource: fields.value.dropdown_container.field.options?.icon,
        allowDisable: allowDisableFieldsItem(FieldContentItem.ICON).value,
        controls: [
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.iconSize",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: fields.value.dropdown_container.field,
            visible: fields.value.dropdown_container.field.options.icon._active,
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            className: "widget-control-container p-l-16 p-r-16 p-b-16",
            valuePath: "options.icon.gap",
            valueSource: fields.value.dropdown_container.field,
            visible: fields.value.dropdown_container.field.options.icon._active,
            options: {
              label: "Spacing between text and icon",
              minValue: 0,
              maxValue: 20,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Arrow"),
        toggleable: true,
        valueSource: fields.value.dropdown_container.field.options?.arrow,
        allowDisable: allowDisableFieldsItem(FieldContentItem.ARROW).value,
        controls: [
          {
            componentPath: "ConfigArrowSettings",
            valuePath: "options.arrow",
            valueSource: fields.value.dropdown_container.field,
            className: "p-t-0",
            options: {
              hideSimpleIconPicker: true,
              label: "Color",
              isBold: true,
              allowDisable: true,
              showOptions: false,
              meta: {
                hideToggle: true,
              },
            },
          },
        ],
      },
    ];
  });

  const stateBasePath = computed<string>(() => {
    if (states.value.dropdown_container === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.dropdown_container}`;
  });

  const dropdownContainerStatesConfig = computed(() => {
    return [
      stateConfig({
        source: states.value.dropdown_container,
        onUpdate: value => {
          emit("update-state", { state: "dropdown_container", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.dropdown_container.field,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
        basePath: stateBasePath.value,
        elements: [
          [
            3,
            {
              section: generateDesignSectionName("Text"),
              controls: [
                {
                  componentPath: "ConfigColorPickerInput",
                  valuePath: `${stateBasePath.value}.text.color`,
                  valueSource: fields.value.dropdown_container.field,
                  options: {
                    placeholder: "None",
                    label: "Color",
                    type: ColorPickerType.TEXT,
                  },
                },
              ],
            },
          ],
          [
            4,
            {
              section: generateDesignSectionName("Arrow"),
              controls: [
                {
                  componentPath: "ConfigColorPickerInput",
                  valuePath: `${stateBasePath.value}.arrow.textColor`,
                  valueSource: fields.value.dropdown_container.field,
                  options: {
                    placeholder: "None",
                    label: "Color",
                    type: ColorPickerType.TEXT,
                  },
                },
              ],
            },
          ],
        ],
      }),
    ];
  });

  /*
    Menu
  */

  const dropdownMenuControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.dropdown_menu.field,
    });
  });

  /*
    Menu items
  */

  const menuItemsDesignControls = computed(() => {
    return getInitialDesignList({
      source: fields.value.menu_items.field,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.padding",
            valueSource: fields.value.menu_items.field,
            options: {
              label: "Padding",
              meta: {
                isBold: true,
              },
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.distance",
            valueSource: fields.value.menu_items.field,
            options: {
              isBold: true,
              label: "Distance between items",
            },
          },
        ],
      ],
    });
  });

  const menuItemsContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options.text._active",
        valueSource: fields.value.menu_items.field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Text",
          size: "small",
          allowDisable: allowDisableFieldsItem(FieldContentItem.TEXT).value,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.text.color",
        valueSource: fields.value.menu_items.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Text color",
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "ConfigThemeSelect",
        valuePath: "options.text.theme",
        valueSource: fields.value.menu_items.field,
        className: "group-control-element",
        options: {
          placeholder: "Enter text",
          label: "Text color",
        },
      },
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.text.fontFamily",
        valueSource: fields.value.menu_items.field,
        className: "group-control-element",
        options: {
          placeholder: "Select",
          label: "Font family",
        },
      },
      {
        componentPath: "ConfigDecoration",
        valuePath: "options.text.decoration",
        valueSource: fields.value.menu_items.field,
        options: {
          placeholder: "Select",
          label: "Decoration",
        },
      },
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options.icon._active",
        valueSource: fields.value.menu_items.field,
        className: "p-l-16 p-r-16 p-t-16",
        options: {
          label: "Icon",
          size: "small",
          allowDisable: allowDisableFieldsItem(FieldContentItem.ICON).value,
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.icon.iconSize",
        className: "p-l-16 p-r-16 p-b-16 p-t-16",
        valueSource: fields.value.menu_items.field,
        visible: fields.value.menu_items.field.options.icon._active,
        options: {
          label: "Size",
          minValue: 12,
          maxValue: 32,
        },
      },
      {
        componentPath: "common/ConfigRangeSlider",
        className: "p-l-16 p-r-16",
        valuePath: "options.icon.gap",
        valueSource: fields.value.menu_items.field,
        visible: fields.value.menu_items.field.options.icon._active,
        options: {
          label: "Spacing between text and icon",
          minValue: 0,
          maxValue: 20,
        },
      },
    ];
  });

  const menuItemsStateBasePath = computed<string>(() => {
    if (states.value.menu_items === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value.menu_items}`;
  });

  const menuItemsStatesControls = computed(() => {
    return [
      stateConfig({
        source: states.value.menu_items,
        onUpdate: value => {
          emit("update-state", { state: "menu_items", value });
        },
      }),
      ...getInitialDesignList({
        source: fields.value.menu_items.field,
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
        basePath: menuItemsStateBasePath.value,
        elements: [
          [
            3,
            {
              section: generateDesignSectionName("Text"),
              controls: [
                {
                  componentPath: "ConfigColorPickerInput",
                  valuePath: `${menuItemsStateBasePath.value}.color`,
                  valueSource: fields.value.menu_items.field,
                  options: {
                    placeholder: "None",
                    label: "Color",
                    type: ColorPickerType.TEXT,
                  },
                },
              ],
            },
          ],
        ],
      }),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    dropdownContainerDesignConfig,
    dropdownContainerContentConfig,
    dropdownContainerStatesConfig,

    dropdownMenuControls,

    menuItemsDesignControls,
    menuItemsContentControls,
    menuItemsStatesControls,
  };
};
