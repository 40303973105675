import {
  getColorValue,
  getDecorationValue,
  getFontFamilyStyle,
  getFontSizeStyle,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";
import {
  clearObject,
  useDesignInitialStyles,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";

export const useBonusContentStyles = (bonusContentOptions: any = {}) => {
  const theme = bonusContentOptions.theme
    ? getFontSizeStyle(bonusContentOptions.theme)
    : {};
  const fontFamily = bonusContentOptions.fontFamily
    ? getFontFamilyStyle(bonusContentOptions.fontFamily)
    : {};
  const decoration = bonusContentOptions.decoration
    ? getDecorationValue(bonusContentOptions.decoration)
    : {};
  const alignment = bonusContentOptions.alignment
    ? getTextAlignStyle(bonusContentOptions.alignment)
    : {};

  const styles = {
    ...useDesignInitialStyles(bonusContentOptions),

    color: bonusContentOptions.color
      ? getColorValue(bonusContentOptions.color)
      : undefined,
    ...theme,
    ...fontFamily,
    ...decoration,
    ...alignment,
  };
  return clearObject(styles);
};
