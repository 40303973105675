import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import {
  DisplayOrientation,
  FillType,
  Grid,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCustomStyles,
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateStageButtonCssString,
  generateCardCssString,
  generateFlexibleImageCssString,
} from "../utils/form-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateBorderStyle,
  generateFlex,
  generateFlexAlignment,
  generateGap,
  generateTextAlignStyle,
  generateWidth,
} from "../helpers";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import { getPxValueFromNumber } from "../..";
import { getFlexAlign, getFlexPosition } from "../../widget-settings";

export const generateCardFullAlignment =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const { layoutPosition } = element.options;

    const flexAlign = getFlexAlign(layoutPosition.alignment);

    const alignmentStyles = `align-items:${getFlexPosition(
      flexAlign["justify-content"]
    )} !important;justify-content:${getFlexPosition(
      flexAlign["align-items"]
    )} !important;`;

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: alignmentStyles,
    });
    // TODO - is it necessary?
    // cssString += generateCssClassWithContent({
    //   className: element.options._cssClass,
    //   pseudoClassName: " .card-element",
    //   content: "flex-grow:unset !important;",
    // });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: " .card-element__text",
      content: alignmentStyles,
    });

    return cssString;
  };

export const generatePromoButtonsGroupCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const { layout, gap, alignment } = element.options;

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: `${generateFlex({
        flex: "flex",
        align:
          layout === DisplayOrientation.HORIZONTAL
            ? "start"
            : generateFlexAlignment(alignment),
        justify: alignment,
        direction: layout === DisplayOrientation.VERTICAL ? "column" : "row",
        gap: gap,
      })}${generateDefaultStyles(element.options)}${generateWidth(100, "%")}`,
    });

    if (element.options.spacing.margin.top === 0) {
      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        content: "margin-top: auto;",
      });
    }

    return cssString;
  };

const generatePromotionsTabsAdditionalStyles =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const tabsFlex = generateFlex({
      flex: "flex",
      direction: "row",
      align: "center",
      justify: element.options.display.alignment,
      gap: element.options.gap,
    });

    const tabsTextStyles = generateDefaultStyles(element.options.text);

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: tabsFlex + tabsTextStyles,
    });

    // Generating styles for items inside tabs container
    const tabItemFlexValue =
      element.options.display.resizing === ResizingType.FILL ? "flex:1;" : "";

    cssString += generateCssClassWithContent({
      className: element.options.text._cssClass,
      content:
        tabItemFlexValue +
        generateTextAlignStyle(element.options.text.alignment) +
        generateBorderStyle(element.options.itemBorder),
    });

    for (const state in element.options.states) {
      const tabsTextStyles = generateDefaultStyles(
        element.options.states[state].text
      );

      if (state === State.ACTIVE) {
        cssString += generateCssClassWithContent({
          className: `${element.options.text._cssClass}.router-link-active`,
          content: `${generateDefaultStyles(
            element.options.states.active.text
          )}${generateBorderStyle(element.options.states.active.itemBorder)}`,
        });

        continue;
      }

      cssString += generateCssClassWithContent({
        className: element.options.text._cssClass,
        pseudoClassName: `:${state}`,
        content:
          tabsTextStyles +
          generateBorderStyle(element.options.states[state].itemBorder),
      });
    }

    return cssString;
  };

export const generatePromoButtonCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    cssString += generateStageButtonCssString(element)(cssString);

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: "max-width:-webkit-fill-available;",
    });

    return cssString;
  };

export const generatePromoListCssString =
  (element: IWidgetField) =>
  (cssString: string): string => {
    const { options } = element;

    const flexString = generateFlex({
      flex: "flex",
      align: "initial",
      justify: "initial",
      direction: "column",
      gap: options.gap,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flexString + generateDefaultStyles(options),
    });

    return cssString;
  };

const generateCardImageAlignmentCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: " .card-element",
      content: "align-items:center;",
    });

    return cssString;
  };

export const generateGridLayoutCssString =
  (
    element: IWidgetOptions,
    withAutoRowsSetting = true,
    additionalStyle?: string,
    className?: string,
    dynamicWidth = false
  ) =>
  (cssString: string): string => {
    const gridSettings = element.options.gridSettings;

    const minCardWidth = getPxValueFromNumber(gridSettings.minCardWidth);
    const gap = getPxValueFromNumber(
      gridSettings.gap?.column ?? gridSettings.gap
    );
    const gapNumber = Number(gridSettings.gap?.column ?? gridSettings.gap);
    const rowGap = getPxValueFromNumber(
      gridSettings.gap?.row ?? gridSettings.rowGap
    );

    const fixedGridType =
      gridSettings.gridFixedType === Grid.FILL ? "auto-fill" : "auto-fit";
    const cardWidth = dynamicWidth
      ? `calc((100% - ${gapNumber * (gridSettings.minCardsInRow - 1)}px) / ${
          gridSettings.minCardsInRow
        })`
      : "1fr";

    const getGridTemplateColumns = (): string => {
      switch (gridSettings.grid || gridSettings.gridFixedType) {
        case Grid.FIT:
        case ResizingType.FIT:
          return `repeat(auto-fit, minmax(${minCardWidth}, 1fr))`;

        case Grid.FILL:
        case ResizingType.FILL:
          return `repeat(auto-fill, minmax(${minCardWidth}, 1fr))`;

        case Grid.FIXED:
        case ResizingType.FIXED:
          return `repeat(${fixedGridType}, minmax(min((100% / ${gridSettings.minCardsInRow}) - ${gap}, max(${minCardWidth}, (100% / ${gridSettings.maxCardsInRow}) - ${gap})), ${cardWidth}))`;

        default:
          return "";
      }
    };

    const gridStylesContent = [
      "display:grid;",
      `grid-gap:${gap};`,
      `gap:${gap};`,
      `grid-row-gap:${rowGap};`,
      `row-gap:${rowGap};`,
      `grid-template-columns:${getGridTemplateColumns()};`,
    ];

    if (withAutoRowsSetting) {
      gridStylesContent.push("grid-auto-rows:1fr;");
    }

    if (additionalStyle) {
      gridStylesContent.push(additionalStyle);
    }

    cssString += generateCssClassWithContent({
      className: className || element.options._cssClass,
      content: gridStylesContent.join(""),
    });

    return cssString;
  };

export const generatePromotionsListCss = (widget: IWidgetOptions): string => {
  widget.options._cssClass = generateClassName(widget.name);
  const prefilled = prefillWithClasses(widget.content);

  const cardsGapStyles = `.${widget.options._cssClass} .${
    prefilled.promo_cards.options._cssClass
  }{${generateGap(prefilled.promo_cards.options.gap)}}`;

  const cardsTextGapStyles = `.${widget.options._cssClass} .${
    prefilled.promo_cards.options._cssClass
  } .card-element__text{${generateGap(prefilled.promo_cards.options.gap)}}`;

  let cardHeightValue = "height:auto;";

  if (
    prefilled.promo_cards.options.containerSize.height.type ===
    ResizingType.FIXED
  ) {
    cardHeightValue = `height:${getPxValueFromNumber(
      prefilled.promo_cards.options.containerSize.height.value
    )};`;
  }

  const cardHeightStyles = `.${widget.options._cssClass} .${prefilled.promo_cards.options._cssClass}{${cardHeightValue}}`;

  prefilled.promo_button_1.options.iconSettings._cssClass =
    generateClassName("promo_button-icon");

  prefilled.promo_button_2.options.iconSettings._cssClass = generateClassName(
    "promo_button_2-icon"
  );

  prefilled.tabs.options.text._cssClass = generateClassName("tabs_text");

  let cardBackgroundOptions: IWidgetOptions = {};

  if (
    prefilled.promo_cards.options.fillImageColor?.fillType === FillType.COLOR
  ) {
    cardBackgroundOptions = prefilled.promo_cards.options.fillImageColor;
  } else {
    cardBackgroundOptions = {
      ...prefilled.promo_cards.options.fillImageColor,
      value: null,
    };
  }

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringDefault(prefilled.page_title),
    generateStringWithStates(prefilled.tabs, false),
    generatePromotionsTabsAdditionalStyles(prefilled.tabs as IWidgetField),
    generatePromoListCssString(prefilled.promo_list as IWidgetField),
    generatePromoListCssString(prefilled.promo_group as IWidgetField),
    generateStringDefault(prefilled.promo_group_title),
    generateStringDefault(prefilled.no_promo_title),

    generateStringDefault(prefilled.promo_group_list),
    generateGridLayoutCssString(prefilled.promo_group_list),

    generateFlexibleImageCssString(prefilled.promo_image as IWidgetField),
    generateStringDefault(prefilled.promo_title),
    generateCustomStyles(prefilled.promo_title.options._cssClass, {
      width: "fit-content",
    }),

    generateStringDefault(prefilled.promo_primary_description),
    generateCustomStyles(
      prefilled.promo_primary_description.options._cssClass,
      {
        width: "fit-content",
      }
    ),

    generateStringDefault(prefilled.promo_secondary_description),
    generateCustomStyles(
      prefilled.promo_secondary_description.options._cssClass,
      {
        width: "fit-content",
      }
    ),

    generatePromoButtonCssString(prefilled.promo_button_1 as IWidgetField),
    generatePromoButtonCssString(prefilled.promo_button_2 as IWidgetField),
    generatePromoButtonsGroupCssString(
      prefilled.promo_button_group as IWidgetField
    ),

    generateCardCssString(
      {
        ...prefilled.promo_cards,
        options: {
          ...prefilled.promo_cards.options,
          fillImageColor: cardBackgroundOptions,
        },
      },
      cardsGapStyles + cardsTextGapStyles + cardHeightStyles
    ),
    generateCardFullAlignment(prefilled.promo_cards as IWidgetField),
    generateCardImageAlignmentCssString(prefilled.promo_cards)
  )("");
};
