import {
  getColorValue,
  getDecorationValue,
  getFontFamilyStyle,
  getFontSizeStyle,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";
import { clearObject } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";

export const useNoBonusTitleStyles = (noBonusTitleOptions: any = {}) => {
  const theme = noBonusTitleOptions.theme
    ? getFontSizeStyle(noBonusTitleOptions.theme)
    : {};
  const fontFamily = noBonusTitleOptions.fontFamily
    ? getFontFamilyStyle(noBonusTitleOptions.fontFamily)
    : {};
  const decoration = noBonusTitleOptions.decoration
    ? getDecorationValue(noBonusTitleOptions.decoration)
    : {};
  const alignment = noBonusTitleOptions.alignment
    ? getTextAlignStyle(noBonusTitleOptions.alignment)
    : {};

  const styles = {
    color: noBonusTitleOptions.color
      ? getColorValue(noBonusTitleOptions.color)
      : undefined,
    ...theme,
    ...fontFamily,
    ...decoration,
    ...alignment,
  };
  return clearObject(styles);
};
