import http from "~~/services/http";
import { BASE_API_URL } from "~~/constants";

import { PageEditor } from "../types";

let abortControllers: AbortController[] = [];

export const fetchPageEditors = (pageUuid: string) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  abortControllers.push(abortController);

  return http.get<PageEditor[]>(`/pages/editors/${pageUuid}`, {
    signal,
  });
};

export const createPageEditor = (pageUuid: string) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  abortControllers.push(abortController);

  return http.post<PageEditor>(`/pages/editors/${pageUuid}`, undefined, {
    signal,
  });
};

export const updatePageEditor = (sessionId: string) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  abortControllers.push(abortController);

  return http.patch(`/pages/editors/${sessionId}`, undefined, {
    signal,
  });
};

export const removePageEditor = (sessionId: string) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  abortControllers.push(abortController);

  return http.post(`/pages/editors/delete/${sessionId}`, undefined, {
    signal,
  });
};

export const removePageEditorBeacon = (sessionId: string) => {
  return navigator.sendBeacon(
    `${BASE_API_URL()}/pages/editors/delete/${sessionId}`
  );
};

export const executeAbortControllers = () => {
  abortControllers.forEach((abortController: AbortController) => {
    abortController.abort();
  });

  abortControllers = [];
};
