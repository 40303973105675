import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 101;

const v101WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (widget.name === "CasinoMenuWidget") {
    if (widget.content.menu_level_2.options?.itemIcon._active === true) {
      widget.content.menu_level_2.options.itemIcon._active = false;
    }
  }

  return widget;
};
export default v101WidgetMigration;
