import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import {
  Align,
  DisplayOrientation,
  State,
  ContainerMode,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import {
  getDefaultFillValue,
  getDefaultSize,
} from "~~/constants/configs/common/widget-initial";
import { useCarouselInit } from "~~/entities/carousel/composables/useCarouselInit";
import { useArrowsInit } from "~~/entities/carousel/composables/useArrowsInit";
import { usePaginationInit } from "~~/entities/carousel/composables/usePaginationInit";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const { initFields, initWidgetOptions, addFieldInitialOptions } =
    useCreateFields(widget.value);

  const DEFAULT_STATES: Record<string, string> = {
    arrows: State.DEFAULT,
  };

  const states = ref({
    ...DEFAULT_STATES,
  });

  const widgetOptions = initWidgetOptions(
    [],
    {
      displayMode: ContainerMode.FLEX,
      display: {
        layout: DisplayOrientation.HORIZONTAL,
        verticalGap: 0,
        horizontalGap: 0,
        alignment: `${Align.MIDDLE} ${Align.MIDDLE}`,
        horizontalOverflow: "visible",
        verticalOverflow: "visible",
        scrolling: "",
        offset: 0,
      },
      ...useCarouselInit(),
    },
    {
      fill: getDefaultFillValue("#ffffff"),
      size: {
        width: {
          ...getDefaultSize().width,
          minMax: {
            _active: false,
            min: null,
            max: null,
            mode: "min",
          },
        },
        height: {
          ...getDefaultSize().height,
          minMax: {
            _active: false,
            min: null,
            max: null,
            mode: "min",
          },
        },
      },
    }
  );

  const init = (): void => {
    initFields();

    addFieldInitialOptions("arrows", useArrowsInit());
    addFieldInitialOptions("pagination", usePaginationInit());

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,
    states,
    DEFAULT_STATES,

    init,
  };
};
