<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SportsMenuWidgetSettings
      v-if="selectedWidget?.id === widget.id"
      :widget="widget"
      :states="states"
      :menu-fields="menuFields"
      :is-live="false"
      :is-fenix="true"
      @reset-states="resetStates"
    />

    <div class="sports-menu-prematch-fenix-widget">
      <WidgetField
        v-if="fields.title.active"
        :widget="widget"
        :field="fields.title.field"
      >
        <BasicTitleField :field="fields.title.field" />
      </WidgetField>

      <div :style="itemsStyles">
        <WidgetField
          :widget="widget"
          :field="fields.menu_level_1.field"
          class="sports-menu-prematch-fenix-widget__field-menu-1"
        >
          <a-menu
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
            mode="inline"
          >
            <div class="sports-menu-prematch-fenix-widget__container_1">
              <div class="sports-menu-prematch-fenix-widget__element_1">
                <a-sub-menu
                  key="sub1"
                  class="sports-menu-prematch-fenix-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Sport</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      class="sports-menu-prematch-fenix-widget__expand-icon"
                      :name="itemFirstLevelIcon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-prematch-fenix-widget__container_1">
              <div class="sports-menu-prematch-fenix-widget__element_1">
                <a-sub-menu
                  key="sub2"
                  class="sports-menu-prematch-fenix-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Sport</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="sports-menu-prematch-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-prematch-fenix-widget__container_1">
              <div class="sports-menu-prematch-fenix-widget__element_1">
                <a-sub-menu
                  key="sub3"
                  :disabled="true"
                  class="sports-menu-prematch-fenix-widget__menu_level_1"
                >
                  <template #icon>
                    <CommonIcon
                      v-if="fields.menu_level_1.field.options.itemIcon._active"
                      name="custom:sport-icon"
                    />
                  </template>
                  <template #title>Sport</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_1.field.options.arrowSettings._active
                      "
                      :name="itemFirstLevelIcon"
                      class="sports-menu-prematch-fenix-widget__expand-icon--last-item"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>
          </a-menu>
        </WidgetField>

        <WidgetField
          v-if="fields.menu_level_2.field.options._active"
          :widget="widget"
          :field="fields.menu_level_2.field"
          class="sports-menu-prematch-fenix-widget__field-menu-2"
        >
          <a-menu
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
            mode="inline"
          >
            <div class="sports-menu-prematch-fenix-widget__container_2">
              <div class="sports-menu-prematch-fenix-widget__element_2">
                <a-sub-menu
                  key="sub3-1"
                  class="sports-menu-prematch-fenix-widget__menu_level_2"
                >
                  <template #title>Country</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_2.field.options.arrowSettings._active
                      "
                      :name="itemSecondLevelIcon"
                      class="sports-menu-prematch-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-prematch-fenix-widget__container_2">
              <div class="sports-menu-prematch-fenix-widget__element_2">
                <a-sub-menu
                  key="sub3-2"
                  class="sports-menu-prematch-fenix-widget__menu_level_2"
                >
                  <template #title>Country</template>
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_2.field.options.arrowSettings._active
                      "
                      :name="itemSecondLevelIcon"
                      class="sports-menu-prematch-fenix-widget__expand-icon"
                    />
                  </template>
                </a-sub-menu>
              </div>
            </div>

            <div class="sports-menu-prematch-fenix-widget__container_2">
              <div class="sports-menu-prematch-fenix-widget__element_2">
                <a-sub-menu
                  key="sub3-3"
                  :disabled="true"
                  class="sports-menu-prematch-fenix-widget__menu_level_2"
                >
                  <template #expandIcon>
                    <CommonIcon
                      v-if="
                        fields.menu_level_2.field.options.arrowSettings._active
                      "
                      :name="itemSecondLevelIcon"
                      class="sports-menu-prematch-fenix-widget__expand-icon--last-item"
                    />
                  </template>
                  <template #title>Country</template>
                </a-sub-menu>
              </div>
            </div>
          </a-menu>
        </WidgetField>

        <WidgetField
          v-if="fields.menu_level_3.field.options._active"
          :widget="widget"
          :field="fields.menu_level_3.field"
          class="sports-menu-prematch-fenix-widget__field-menu-3"
        >
          <a-menu
            v-model:open-keys="openKeys"
            v-model:selected-keys="selectedKeys"
            mode="inline"
          >
            <div class="sports-menu-prematch-fenix-widget__container_3">
              <div class="sports-menu-prematch-fenix-widget__element_3">
                <a-menu-item
                  key="sub3-3-1"
                  class="sports-menu-prematch-fenix-widget__menu_level_3"
                >
                  <span
                    class="sports-menu-prematch-fenix-widget__menu_level_3__text"
                    >League</span
                  ></a-menu-item
                >
              </div>
            </div>

            <div class="sports-menu-prematch-fenix-widget__container_3">
              <div class="sports-menu-prematch-fenix-widget__element_3">
                <a-menu-item
                  key="sub3-3-2"
                  class="sports-menu-prematch-fenix-widget__menu_level_3"
                >
                  <span
                    class="sports-menu-prematch-fenix-widget__menu_level_3__text"
                    >League</span
                  ></a-menu-item
                >
              </div>
            </div>

            <div class="sports-menu-prematch-fenix-widget__container_3">
              <div class="sports-menu-prematch-fenix-widget__element_3">
                <a-menu-item
                  key="sub3-3-2"
                  class="sports-menu-prematch-fenix-widget__menu_level_3"
                >
                  <span
                    class="sports-menu-prematch-fenix-widget__menu_level_3__text"
                    >League</span
                  ></a-menu-item
                >
              </div>
            </div>
          </a-menu>
        </WidgetField>
      </div>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useSportsMenuStyles } from "~~/composables/widgets/sports-menu/useSportsMenuStyles";
import { useSportsMenuSharedLogic } from "~~/composables/widgets/sports-menu/useSportsMenuSharedLogic";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const DEFAULT_STATES = {
  menu_level_1: State.DEFAULT,
  menu_level_2: State.DEFAULT,
  menu_level_3: State.DEFAULT,
};

const states = ref({
  ...DEFAULT_STATES,
});

const { resetStates } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const { itemsStyles } = useSportsMenuStyles(props.widget, states, false, true);
const widgetSettingsStore = useWidgetSettingsStore();

const selectedKeys = ref<string[]>([""]);
const openKeys = ref<string[]>(["sub3", "sub3-3"]);
const menuFields = ["menu_level_1", "menu_level_2", "menu_level_3"];
const { selectedWidget } = storeToRefs(widgetSettingsStore);

const { itemFirstLevelIcon, itemSecondLevelIcon, fields } =
  useSportsMenuSharedLogic(ref(props.widget), states, false, true);
</script>

<style lang="scss">
.sports-menu-prematch-fenix-widget {
  &__field-menu-1 {
    & > .ant-menu-root {
      display: flex !important;
      flex-direction: var(--menu_level_1-flexDirection) !important;
      gap: var(--menu_level_1-marginBottom) !important;
    }
  }

  &__field-menu-2 {
    & > .ant-menu-root {
      display: flex !important;
      flex-direction: var(--menu_level_2-flexDirection) !important;
      gap: var(--menu_level_2-marginBottom) !important;
    }
  }

  &__field-menu-3 {
    & > .ant-menu-root {
      display: flex !important;
      flex-direction: var(--menu_level_3-flexDirection) !important;
      gap: var(--menu_level_3-marginBottom) !important;
    }
  }

  .ant-menu::before,
  .ant-menu::after {
    display: none !important;
  }

  .ant-menu,
  .ant-sub-menu {
    background-color: transparent !important;
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    max-width: 100% !important;
    height: initial !important;
    line-height: 1 !important;
  }

  .ant-menu-submenu {
    margin: 0 !important;
    padding: 0 !important;
  }

  .ant-menu {
    border: none !important;
    overflow-x: auto !important;
  }

  .ant-menu-item {
    &::after {
      display: none !important;
    }
  }

  .ant-menu-submenu-arrow {
    display: none !important;
  }

  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled,
  .ant-menu-submenu-title {
    cursor: pointer !important;
    transition: all 0s !important;
    line-height: 1 !important;
    flex-shrink: 0 !important;
  }

  .ant-menu-title-content {
    margin: 0 !important;
  }

  &__menu_level_1 {
    display: flex !important;
    align-items: var(--menu_level_1-alignItems) !important;

    & > .ant-menu-submenu-title {
      width: var(--menu_level_1-width) !important;
      height: var(--menu_level_1-height) !important;
      background-color: var(--menu_level_1-backgroundColor) !important;
      padding-top: var(--menu_level_1-padding-top) !important;
      padding-left: var(--menu_level_1-padding-left) !important;
      padding-bottom: var(--menu_level_1-padding-bottom) !important;
      padding-right: var(--menu_level_1-padding-right) !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-radius: var(--menu_level_1-borderRadius) !important;
      border-width: var(--menu_level_1-borderWidth) !important;
      border-color: var(--menu_level_1-borderColor) !important;
      border-style: var(--menu_level_1-borderStyle) !important;
      box-shadow: var(--menu_level_1-boxShadow) !important;
      flex-direction: var(--menu_level_1-elementsFlexDirection) !important;

      /* Content */
      color: var(--menu_level_1-color) !important;
      font-size: var(--menu_level_1-fontSize) !important;
      font-family: var(--menu_level_1-fontFamily) !important;
      font-weight: var(--menu_level_1-fontWeight) !important;
      font-style: var(--menu_level_1-fontStyle) !important;
      text-decoration: var(--menu_level_1-textDecoration) !important;
      gap: var(--menu_level_1-spaceBetweenTextAndIcon) !important;
      text-align: var(--menu_level_1-textAlign) !important;

      svg {
        fill: var(--menu_level_1-iconColor) !important;
        width: var(--menu_level_1-iconSize) !important;
        height: var(--menu_level_1-iconSize) !important;
      }

      & > .sports-menu-prematch-fenix-widget__expand-icon {
        font-size: var(--menu_level_1-fontSize) !important;
        right: var(--menu_level_1-padding-right) !important;
        transform: rotate(-90deg) !important;
        color: var(--menu_level_1-arrowIconColor) !important;

        &--last-item {
          transform: translateY(0) !important;
        }
      }
    }
  }

  &__menu_level_2 {
    display: flex !important;
    align-items: var(--menu_level_2-alignItems) !important;

    & > .ant-menu-submenu-title {
      width: var(--menu_level_2-width) !important;
      height: var(--menu_level_2-height) !important;
      background-color: var(--menu_level_2-backgroundColor) !important;
      padding-top: var(--menu_level_2-padding-top) !important;
      padding-left: var(--menu_level_2-padding-left) !important;
      padding-bottom: var(--menu_level_2-padding-bottom) !important;
      padding-right: var(--menu_level_2-padding-right) !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-radius: var(--menu_level_2-borderRadius) !important;
      border-width: var(--menu_level_2-borderWidth) !important;
      border-color: var(--menu_level_2-borderColor) !important;
      border-style: var(--menu_level_2-borderStyle) !important;
      box-shadow: var(--menu_level_2-boxShadow) !important;

      /* Content */
      color: var(--menu_level_2-color) !important;
      font-size: var(--menu_level_2-fontSize) !important;
      font-family: var(--menu_level_2-fontFamily) !important;
      font-weight: var(--menu_level_2-fontWeight) !important;
      font-style: var(--menu_level_2-fontStyle) !important;
      text-decoration: var(--menu_level_2-textDecoration) !important;
      gap: var(--menu_level_2-spaceBetweenTextAndIcon) !important;
      text-align: var(--menu_level_2-textAlign) !important;

      svg {
        fill: var(--menu_level_2-iconColor) !important;
        width: var(--menu_level_2-iconSize) !important;
        height: var(--menu_level_2-iconSize) !important;
      }

      & > .sports-menu-prematch-fenix-widget__expand-icon {
        font-size: var(--menu_level_2-fontSize) !important;
        right: var(--menu_level_2-padding-right) !important;
        transform: rotate(-90deg) !important;
        color: var(--menu_level_2-arrowIconColor) !important;

        &--last-item {
          transform: translateY(0) !important;
        }
      }
    }
  }

  &__menu_level_3 {
    display: flex !important;
    align-items: var(--menu_level_3-alignItems) !important;

    padding: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-radius: var(--menu_level_3-borderRadius) !important;
    border-width: var(--menu_level_3-borderWidth) !important;
    border-color: var(--menu_level_3-borderColor) !important;
    border-style: var(--menu_level_3-borderStyle) !important;
    box-shadow: var(--menu_level_3-boxShadow) !important;
    background-color: transparent !important;

    & > .ant-menu-title-content {
      width: var(--menu_level_3-width) !important;
      height: var(--menu_level_3-height) !important;
      background-color: var(--menu_level_3-backgroundColor) !important;
      padding-top: var(--menu_level_3-padding-top) !important;
      padding-left: var(--menu_level_3-padding-left) !important;
      padding-bottom: var(--menu_level_3-padding-bottom) !important;
      padding-right: var(--menu_level_3-padding-right) !important;

      /* Content */
      color: var(--menu_level_3-color) !important;
      font-size: var(--menu_level_3-fontSize) !important;
      font-family: var(--menu_level_3-fontFamily) !important;
      font-weight: var(--menu_level_3-fontWeight) !important;
      font-style: var(--menu_level_3-fontStyle) !important;
      text-decoration: var(--menu_level_3-textDecoration) !important;
      justify-content: var(--menu_level_3-textAlign) !important;
    }

    .ant-menu-title-content {
      @include flex(center, flex-start, 0);
    }

    &__icon {
      display: inline-block;
      line-height: var(--menu_level_3-iconSize) !important;
      height: var(--menu_level_3-iconSize) !important;
      width: var(--menu_level_3-iconSize) !important;

      svg {
        fill: var(--menu_level_3-iconColor) !important;
        width: var(--menu_level_3-iconSize) !important;
        height: var(--menu_level_3-iconSize) !important;
      }
    }

    &__text {
      display: inline-block;
    }
  }
}
</style>
