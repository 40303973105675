<template>
  <div
    v-if="hasDesignPermissions"
    class="p-l-16 p-r-16 p-t-16 p-b-16 config-alignment widget-control-container"
  >
    <p
      v-if="!hideLabel"
      :class="{
        'sub-heading': isBold,
      }"
      class="m-b-4"
    >
      {{ label || "Alignment" }}
    </p>
    <div class="config-alignment__menu">
      <button
        class="config-alignment__button pointer"
        :class="{
          'config-alignment__button--active':
            currentAlignment ===
            (isSnapAlign
              ? SnapAlignment.START
              : isAlign
              ? Align.START
              : Alignment.LEFT),
        }"
        @click="
          handleAlignmentClick(
            isSnapAlign
              ? SnapAlignment.START
              : isAlign
              ? Align.START
              : Alignment.LEFT
          )
        "
      >
        <CommonIcon
          v-if="isTextContentIcons"
          name="ant-design:align-left-outlined"
        />
        <CommonIcon
          v-else
          name="custom:alignment-left"
        />
      </button>
      <button
        class="config-alignment__button pointer"
        :class="{
          'config-alignment__button--active':
            currentAlignment ===
            (isSnapAlign
              ? SnapAlignment.CENTER
              : isAlign
              ? Align.MIDDLE
              : Alignment.CENTER),
        }"
        @click="
          handleAlignmentClick(
            isSnapAlign
              ? SnapAlignment.CENTER
              : isAlign
              ? Align.MIDDLE
              : Alignment.CENTER
          )
        "
      >
        <CommonIcon
          v-if="isTextContentIcons"
          name="ant-design:align-center-outlined"
        />
        <CommonIcon
          v-else
          name="custom:alignment-center"
        />
      </button>
      <button
        class="config-alignment__button pointer"
        :class="{
          'config-alignment__button--active':
            currentAlignment ===
            (isSnapAlign
              ? SnapAlignment.END
              : isAlign
              ? Align.END
              : Alignment.RIGHT),
        }"
        @click="
          handleAlignmentClick(
            isSnapAlign
              ? SnapAlignment.END
              : isAlign
              ? Align.END
              : Alignment.RIGHT
          )
        "
      >
        <CommonIcon
          v-if="isTextContentIcons"
          name="ant-design:align-right-outlined"
        />
        <CommonIcon
          v-else
          name="custom:alignment-right"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePermissions } from "~~/composables/permissions/usePermissions";
import {
  Align,
  Alignment,
  SnapAlignment,
} from "~~/models/widgets/widget-controls.model";

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    isTextContentIcons?: boolean;
    isAlign?: boolean;
    isSnapAlign?: boolean;
    isBold?: boolean;
    hideLabel?: boolean;
    label?: string;
  }>(),
  {
    isTextContentIcons: true,
    label: "",
    modelValue() {
      return "";
    },
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const currentAlignment = computed<string>(() => {
  return props.modelValue;
});

const handleAlignmentClick = (value: string): void => {
  emit("update:modelValue", value);
};
</script>

<style lang="scss">
@import "~~/assets/styles/mixins/controls-menu.scss";

.config-alignment {
  @include controls-menu(33%);
}
</style>
