import { CSSProperties } from "vue";

import {
  clearObject,
  useDesignInitialStyles,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getFlexAlign, getTextPosition } from "~~/assets/utils/widget-settings";
import { textPositionToDisplayOrientation } from "~~/constants/dictionaries";
import { TextPosition } from "~~/models/widgets/widget-controls.model";

export const useBonusHeaderStyles = (
  bonusHeaderOptions: any = {}
): CSSProperties => {
  const layoutPosition = bonusHeaderOptions.layoutPosition?.position
    ? getTextPosition(bonusHeaderOptions.layoutPosition?.position)
    : {};

  const flexAlign = getFlexAlign(
    bonusHeaderOptions.layoutAlignment,
    textPositionToDisplayOrientation[
      bonusHeaderOptions.layoutPosition?.position as TextPosition
    ]
  );

  const styles = {
    ...useDesignInitialStyles(bonusHeaderOptions),

    ...layoutPosition,
    alignItems: flexAlign["align-items"],
    justifyContent: flexAlign["justify-content"],
    gap: bonusHeaderOptions.gap
      ? getPxValueFromNumber(bonusHeaderOptions.gap)
      : undefined,
    cursor: "pointer",
  };

  return clearObject(styles);
};
