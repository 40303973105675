import { Ref } from "vue";

import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import {
  ResizingType,
  State,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { useFlexibleImageControls } from "~~/composables/widgets/common/useFlexibleCardWidgetControls";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import type { StateController } from "~~/composables/widgets/common/useStates";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { textPositionToCellLayout } from "~~/constants/dictionaries";
import { useWidgetsStore } from "~~/store/widgets";

export const useWelcomeBonusFenixWidgetConfig = (
  widget: Ref<IWidgetWithFields>,
  stateController: StateController
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Bonus block", "bonus_block"),
    generateDropdownItem("Bonuses", "bonus", "bonus"),
    generateDropdownItem("Bonus header", "bonus_header", "bonus"),
    generateDropdownItem("Image", "bonus_image", "bonus"),
    generateDropdownItem("Title", "bonus_title", "bonus"),
    generateDropdownItem("Bonus content", "bonus_content", "bonus"),
    generateDropdownItem("No Bonus", "no_bonus", "no_bonus"),
    generateDropdownItem("Image", "no_bonus_image", "no_bonus"),
    generateDropdownItem("Title", "no_bonus_title", "no_bonus"),
    generateDropdownItem("Description", "description"),
    generateDropdownItem("Button", "button"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    bonus_block: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bonus: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bonus_header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    bonus_content: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    no_bonus: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
    });
  });
  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
        except: ["ConfigVerticalAlignment"],
      }),
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.gap",
            valueSource: widget.value,
            options: {
              label: "Gap",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        className: "p-b-16",
        options: {
          items: [
            {
              label: "Bonus block",
              value: "bonus_block",
            },
            {
              label: "Description",
              value: "description",
              isActiveValueSource: fields.value.description.field.options,
            },
            {
              label: "Button",
              value: "button",
            },
          ],
        },
      },
    ];
  });

  const bonusBlockContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            options: {
              label: "Gap",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        className: "p-b-16",
        options: {
          items: [
            {
              label: "Bonuses",
              value: "bonus",
            },
            {
              label: "No bonus",
              value: "no_bonus",
            },
          ],
        },
      },
    ];
  });

  const bonusContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            options: {
              label: "Gap",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        className: "p-b-16",
        options: {
          items: [
            {
              label: "Bonus header",
              value: "bonus_header",
            },
            {
              label: "Bonus content",
              value: "bonus_content",
            },
          ],
        },
      },
    ];
  });

  const headerContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigLayoutPosition",
            valuePath: "options.layoutPosition",
            valueSource: selectedField.value,
            options: {
              showAlignment: false,
            },
          },
          {
            componentPath: "ConfigAlignmentFull",
            valuePath: "options.layoutAlignment",
            valueSource: selectedField.value,
            options: {
              label: "Alignment",
              layout:
                textPositionToCellLayout[
                  selectedField.value?.options?.layoutPosition
                    ?.position as TextPosition
                ] || "",
            },
          },
          {
            componentPath: "common/ConfigSpacing",
            valuePath: "options.gap",
            valueSource: selectedField.value,
            options: {
              label: "Gap",
            },
          },
        ],
      },
    ];
  });

  const bonusHeaderContentControls = computed(() => {
    return [
      ...headerContentControls.value,
      {
        componentPath: "form/ConfigTabs",
        className: "p-b-16",
        options: {
          items: [
            {
              label: "Image",
              value: "bonus_image",
              isActiveValueSource: fields.value.bonus_image.field.options,
            },
            {
              label: "Title",
              value: "bonus_title",
            },
          ],
        },
      },
    ];
  });

  const headerStateControls = (fieldName: string, childName: string) => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateController.states[fieldName],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          stateController.handleStateUpdate({
            state: fieldName,
            value,
          });
          stateController.handleStateUpdate({
            state: childName,
            value,
          });
        },
      },
      ...getInitialDesignList({
        source: selectedField.value,
        basePath: stateController.getOptionsValuePath(
          selectedField.value?.name
        ),
        except: ["ConfigCornerRadius", "ConfigSpacingInputs"],
      }),
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${stateController.getOptionsValuePath(
              childName
            )}.color`,
            valueSource: fields.value[childName].field,
            options: {
              placeholder: "Enter text",
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  };

  const bonusHeaderStatesControls = computed(() => {
    return headerStateControls("bonus_header", "bonus_title");
  });

  const { flexibleImageControls: bonusImageControls } =
    useFlexibleImageControls(toRef(fields.value.bonus_image.field));

  const bonusTitleControls = computed(() => {
    return [
      typography({
        source: selectedField.value,
      }),
    ];
  });

  const bonusContentContentControls = computed(() => {
    return [
      typography({
        source: selectedField.value,
      }),
    ];
  });

  // noBonus

  const noBonusContentControls = computed(() => {
    return [
      ...headerContentControls.value,
      {
        componentPath: "form/ConfigTabs",
        className: "p-b-16",
        options: {
          items: [
            {
              label: "Image",
              value: "no_bonus_image",
              isActiveValueSource: fields.value.no_bonus_image.field.options,
            },
            {
              label: "Title",
              value: "no_bonus_title",
            },
          ],
        },
      },
    ];
  });

  const noBonusStatesControls = computed(() => {
    return headerStateControls("no_bonus", "no_bonus_title");
  });

  const noBonusImageControls = computed(() => {
    const { flexibleImageControls } = useFlexibleImageControls(
      toRef(fields.value.no_bonus_image.field)
    );

    return [
      {
        componentPath: "ConfigImageUpload",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          active: selectedField.value?.options._active,
          attribute: "no_bonus_image",
        },
      },
      ...flexibleImageControls.value,
    ];
  });

  const noBonusTitleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter text",
        },
      },
      typography({
        source: selectedField.value,
      }),
    ];
  });

  const descriptionControls = computed(() => {
    return [
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
      {
        componentPath: "common/ConfigToggle",
        valuePath: "options.withTranslations",
        valueSource: selectedField.value,
        className: "widget-control-container p-l-16 p-t-16 p-r-16 p-b-16",
        options: {
          label: "With Translations",
          size: "small",
          allowDisable: true,
          "onUpdate:modelValue": (value: boolean) => {
            useWidgetsStore().updateFieldOptionValue(
              value,
              "withTranslations",
              selectedField.value?.id
            );
          },
        },
      },
    ];
  });

  const designControls = computed(() => {
    return getInitialDesignList({
      source: selectedField.value,
    });
  });

  const buttonContentControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigDimension",
            valuePath: "options.buttonDisplaySettings.resizing",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Layout",
              exclude: [ResizingType.FIXED],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.buttonDisplaySettings.alignment",
            valueSource: selectedField.value,
            className: "p-t-0",
            options: {
              label: "Alignment",
              isTextContentIcons: false,
            },
          },
        ],
      },
      {
        section: generateContentSectionName("Typography"),
        controls: [
          {
            componentPath: "ConfigButtonTextSettings",
            valuePath: "options.buttonTextSettings",
            valueSource: selectedField.value,
            options: {
              placeholder: "Enter text",
              label: "Text color",
            },
          },
        ],
      },
      {
        componentPath: "ConfigIconSettings",
        valuePath: "options.iconSettings",
        valueSource: selectedField.value,
      },
    ];
  });
  const buttonStatesControls = computed(() => {
    const basePath = stateController.getOptionsValuePath(
      selectedField.value?.name
    );

    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateController.states.button,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
            {
              label: STATE_KEY_LABELS.disabled,
              value: State.DISABLED,
            },
          ],
        },
        onUpdate(value: State) {
          stateController.handleStateUpdate({
            state: "button",
            value,
          });
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${basePath}.fill`,
        valueSource: selectedField.value,
        options: {
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${basePath}.border`,
        valueSource: selectedField.value,
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${basePath}.shadow`,
        valueSource: selectedField.value,
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${basePath}.buttonTextSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${basePath}.iconSettings.textColor`,
            valueSource: selectedField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    widgetDesignControls,
    widgetContentControls,

    bonusBlockContentControls,

    bonusContentControls,

    bonusHeaderContentControls,
    bonusHeaderStatesControls,

    bonusImageControls,

    bonusTitleControls,

    bonusContentContentControls,

    noBonusContentControls,
    noBonusStatesControls,

    noBonusImageControls,

    noBonusTitleControls,

    descriptionControls,

    designControls,

    buttonContentControls,
    buttonStatesControls,
  };
};
