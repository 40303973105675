import type { Ref } from "vue";

import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { generateStyleDropdownItems } from "~~/assets/utils/widget/form";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { FORM_ELEMENTS_STYLE_TABS } from "~~/constants/widget-details/form/dynamic-fields/styling";
import { IFields } from "~~/composables/widgets/useWidgetFields";

export const useItems = (
  widget: Ref<IWidgetWithFields>,
  fields: Ref<IFields>
) => {
  const SETTINGS_ITEMS = [
    {
      label: "Header",
      value: "header",
      isActiveValueSource: fields.value.header.field.options,
    },
    {
      label: "Games list",
      value: "games_list",
    },
  ];

  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("Games List", "games_list"),
    generateDropdownItem("Header", "header", "header"),
    generateDropdownItem("Title", "title", "header"),
    generateDropdownItem("Button", "header_button", "header"),
    // generateDropdownItem("Search Result", "search_result", "header"),

    generateDropdownItem("Game Cards", "game_cards", "cardsElements"),
    generateDropdownItem("Game Cover", "game_cover", "cardsElements"),
    generateDropdownItem("Game Name", "game_name", "cardsElements"),
    generateDropdownItem("All games card", "all_games_card", "cardsElements"),
    generateDropdownItem("Games button", "games_button", "cardsElements"),

    generateDropdownItem("Hover Content", "hover_content", "hoverContent"),
    generateDropdownItem("Favourite Icon", "favorite_icon", "hoverContent"),
    generateDropdownItem("Promo Tag", "promo_tag", "hoverContent"),
    generateDropdownItem("Game Name Hover", "game_title", "hoverContent"),
    generateDropdownItem(
      "Play for money button",
      "play_money_button",
      "hoverContent"
    ),
    generateDropdownItem(
      "Play for free button",
      "play_free_button",
      "hoverContent"
    ),
  ];

  const TABS = {
    ...FORM_ELEMENTS_STYLE_TABS,
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    // search_result: [
    //   generateTabItem("Design", "design"),
    //   generateTabItem("Content", "content"),
    // ],
    games_list: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    game_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    all_games_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    game_cover: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    promo_tag: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    play_money_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    play_free_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    games_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    header_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    return [
      ...DEFAULT_DROPDOWN_ITEMS,
      ...generateStyleDropdownItems(widget.value.fields),
    ];
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
