import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { State } from "~~/models/widgets/widget-controls.model";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { useWidgetFields } from "../useWidgetFields";

import { useBreadcrumbsItems } from "./useBreadcrumbsItems";

export const useBreadcrumbsConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>,
  emit: any
) => {
  const widgetSettingsStore = useWidgetSettingsStore();

  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField, selectedWidget } = storeToRefs(widgetSettingsStore);
  const { DROPDOWN_ITEMS, TABS, WIDGET_CONTENT_ITEMS } = useBreadcrumbsItems();

  const linkValue = computed<IWidgetField>(() => {
    return fields.value.links.field;
  });

  const titleLinkSettingsValuePath = computed(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.link.states.${states.value[selectedField.value!.name]}`;
  });

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigSpacingInputs"],
      elements: [
        [
          4,
          {
            componentPath: "ConfigPaddingInputs",
            valuePath: "options.spacing.padding",
            valueSource: selectedWidget.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Padding",
            },
          },
        ],
        [
          5,
          {
            componentPath: "ConfigMarginInputs",
            valuePath: "options.spacing.margin",
            valueSource: selectedWidget.value,
            options: {
              meta: {
                isBold: true,
              },
              label: "Margin",
              isAllSideSetting: true,
              showTooltip: true,
            },
          },
        ],
      ],
    });
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: selectedWidget.value,
      }),
      {
        section: generateDesignSectionName("Display settings"),
        controls: [
          {
            componentPath: "ConfigColumnWidth",
            valuePath: "options.gap",
            valueSource: selectedWidget.value,
            className: "group-control-element",
            options: {
              label: "Gap",
              showIconPrefix: true,
              showSuffix: true,
              suffix: "px",
            },
          },
          {
            componentPath: "ConfigAlignmentFull",
            valuePath: "options.display.alignment",
            valueSource: selectedWidget.value,
            className: "group-control-element--child",
            options: {
              label: "Alignment",
            },
          },
        ],
      },

      {
        componentPath: "form/ConfigTabs",
        options: {
          items: WIDGET_CONTENT_ITEMS,
        },
      },
    ];
  });

  const widgetDataControls = computed(() => {
    return [
      {
        componentPath: "ConfigBreadcrumbsLinks",
        valuePath: "options.breadcrumbs",
        valueSource: selectedWidget.value,
        options: {
          widget: selectedWidget.value,
        },
      },
    ];
  });

  const separatorsControls = computed(() => {
    return [
      {
        componentPath: "ConfigIconPicker",
        valuePath: "options.value",
        className: "p-l-16 p-r-16 p-t-16",
        valueSource: selectedField.value,
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.color",
        valueSource: selectedField.value,
        options: {
          placeholder: "Color",
          label: "Icon color",
          type: ColorPickerType.TEXT,
        },
        className: "border-bottom-none",
      },
      {
        componentPath: "common/ConfigRangeSlider",
        valuePath: "options.iconSize",
        valueSource: selectedField.value,
        className: "wconfig-item",
        options: {
          label: "Size",
          minValue: 12,
          maxValue: 32,
        },
      },
    ];
  });

  const linkControls = computed(() => {
    return [
      typography({ source: selectedField.value, except: ["ConfigAlignment"] }),
      {
        componentPath: "ConfigLinkType",
        valuePath: "options.link",
        valueSource: selectedField.value,
        options: {
          label: "Link",
          shouldShowTypeChanging: false,
          "onShow-states-settings": () => {
            widgetSettingsStore.setDropdownItem({ name: "linkStates" });
          },
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const titleLinkStatesControls = computed(() => [
    {
      componentPath: "common/ConfigStatesList",
      valuePath: "",
      valueSource: states.value[selectedField.value!.name],
      options: {
        items: [
          {
            label: STATE_KEY_LABELS.default,
            value: State.DEFAULT,
          },
          {
            label: STATE_KEY_LABELS.hover,
            value: State.HOVER,
          },
          {
            label: STATE_KEY_LABELS.active_current_page,
            value: State.ACTIVE,
          },
          {
            label: STATE_KEY_LABELS.disabled,
            value: State.DISABLED,
          },
        ],
      },
      onUpdate(value: State) {
        emit("update-state", {
          state: "links",
          value,
        });
      },
    },
    {
      componentPath: "ConfigColorPickerInput",
      valuePath: `${titleLinkSettingsValuePath.value}.color`,
      valueSource: linkValue.value,
      options: {
        label: "Text color",
        type: ColorPickerType.TEXT,
      },
    },
    {
      componentPath: "ConfigDecoration",
      valuePath: `${titleLinkSettingsValuePath.value}.decoration`,
      valueSource: linkValue.value,
      options: {
        label: "Decoration",
      },
    },
  ]);

  return {
    DROPDOWN_ITEMS,
    TABS,

    widgetDesignControls,
    widgetContentControls,
    widgetDataControls,

    separatorsControls,

    linkControls,
    titleLinkStatesControls,
  };
};
