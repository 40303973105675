import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 94;

const v83WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (widget.name === "ContainerWidget") {
    if (widget.options.display) {
      widget.options.display.horizontalOverflow = "visible";
      widget.options.display.verticalOverflow = "visible";
    }
  }

  return widget;
};
export default v83WidgetMigration;
