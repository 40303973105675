<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <CasinoPromotedCategoriesWidgetSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div
      :style="wrapperStyle"
      class="casino-promoted-categories"
    >
      <WidgetField
        :widget="widget"
        :field="fields.dropdown_container.field"
      >
        <LanguagesDropdown
          :field="fields.dropdown_container.field"
          :state="states.dropdown_container"
          :widget="widget"
          dropdown-trigger-label="Category"
        />
      </WidgetField>

      <WidgetField
        v-if="isWidgetSelected"
        :widget="widget"
        :field="fields.dropdown_menu.field"
      >
        <DropdownMenuField :field="fields.dropdown_menu.field">
          <div
            :style="itemsContainerStyle"
            class="casino-promoted-categories__items-container"
          >
            <WidgetField
              v-for="(item, index) in MENU_ITEMS"
              :key="index"
              :field="fields.menu_items.field"
              :widget="widget"
            >
              <LanguagesItem
                :field="fields.menu_items.field"
                :widget="widget"
                :state="states.menu_items"
                :value="item.label"
              />
            </WidgetField>
          </div>
        </DropdownMenuField>
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/casino-promoted-categories/useCasinoPromotedCategoriesInit";
import { ResizingType } from "~~/models/widgets/widget-controls.model";
import { getFlexPosition } from "~~/assets/utils/widget-settings";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";
import { useStates } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const MENU_ITEMS = [
  {
    label: "Sub category",
    value: "subcategory",
  },
  {
    label: "Sub category",
    value: "subcategory",
  },
  {
    label: "Sub category",
    value: "subcategory",
  },
];

const fields = useWidgetFields(props.widget);
const widgetSettingsStore = useWidgetSettingsStore();

const { selectedWidget } = storeToRefs(widgetSettingsStore);

const { states, DEFAULT_STATES, init } = useInit(toRef(props.widget));

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const isWidgetSelected = computed<boolean>(() => {
  return props.widget.id === selectedWidget.value?.id;
});

const displaySettings = computed<IWidgetOptions>(() => {
  return props.widget.options.display;
});

const wrapperStyle = computed<ElementStyle>(() => {
  if (displaySettings.value.resizing === ResizingType.HUG) {
    return {
      alignItems: getFlexPosition(displaySettings.value.alignment),
    };
  }

  return {
    alignItems: "stretch",
  };
});

const itemsContainerStyle = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(fields.value.menu_items.field.options.distance),
  };
});

init();
</script>

<style lang="scss">
.casino-promoted-categories {
  @include flex(flex-start, flex-start, 0px);
  flex-direction: column;

  &__items-container {
    @include flex(stretch, flex-start, 0px);
    flex-direction: column;
  }
}
</style>
