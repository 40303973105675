<template>
  <div v-if="hasDesignPermissions">
    <ConfigCheckbox
      :model-value="modelValue?.fixed"
      label="Fix position when scrolling"
      :class="{ 'group-control-element': modelValue?.fixed }"
      @update:model-value="
        handleUpdate({
          fixed: $event,
          position: modelValue?.position || CellPosition.TOP,
          distance: modelValue?.distance || 0,
        })
      "
    />

    <template v-if="modelValue?.fixed">
      <ConfigRadioTextElements
        :model-value="modelValue.position"
        class="border-bottom-none"
        :items="[
          { label: 'Top', value: 'top' },
          { label: 'Bottom', value: 'bottom' },
        ]"
        @update:model-value="
          handleUpdate({
            fixed: true,
            position: $event,
            distance: modelValue?.distance || 0,
          })
        "
      />

      <!-- TODO: Delete if no sense FECO-4421. Made non-visible for unknown term -->
      <ConfigVerticalSpace
        label="Distance"
        class="non-visible p-t-0"
        :model-value="modelValue.distance"
        @update:model-value="
          handleUpdate({
            fixed: true,
            position: modelValue?.position || CellPosition.TOP,
            distance: $event ?? 0,
          })
        "
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { usePermissions } from "~~/composables/permissions/usePermissions";
import { CellPosition, ICellPosition } from "~~/models/grid.interface";

defineProps<{
  modelValue?: ICellPosition;
}>();

const emit = defineEmits<{
  (e: "update:model-value", event: ICellPosition): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const handleUpdate = (value: ICellPosition) => {
  emit("update:model-value", value);
};
</script>
