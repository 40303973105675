import { CSSProperties } from "vue";
import kebabCase from "lodash/kebabCase";

import { Breakpoint } from "~~/models/breakpoints.model";
import { ElementStyle } from "~~/models/common";
import {
  Dimension,
  Grid,
  ICell,
  ICellDimensionValue,
} from "~~/models/grid.interface";
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { Align } from "~~/models/widgets/widget-controls.model";

import { deepCopyWidgetWithFields } from "./widget";

export function throttle<T extends Function>(
  func: T,
  delay: number
): (...args: Array<any>) => void {
  let timeout: ReturnType<typeof setTimeout> | null;
  let inThrottle: boolean;

  return function (...args: Array<any>) {
    if (!inThrottle) {
      func.call(null, ...args);
      inThrottle = true;
    }

    if (timeout) {
      return;
    }

    const later = function (): void {
      timeout = null;
      func.call(null, ...args);
      inThrottle = false;
    };
    timeout = setTimeout(later, delay);
  } as any;
}

export function debounce<F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced;
}

function getID(): () => number {
  let id = -1;
  return function () {
    return id--;
  };
}

export const generateId = getID();

export const parseId = (id?: string | number): string | number | null => {
  if (!id) return null;

  /*
      We need to distinguish numeric and
      string id values
    */
  if (isNaN(+id)) {
    return id;
  }

  return +id;
};
export const getCurrentDatasetElementId = (
  el: HTMLElement | null
): string | number | null => {
  if (!el) {
    return null;
  }

  const id = el.dataset.elementId;

  if (id) {
    return parseId(id);
  }

  return getCurrentDatasetElementId(el.parentElement || null);
};

export const deepCopy = <T>(target: T): T => {
  if (target === null) {
    return target;
  }

  if (target instanceof Date) {
    return new Date(target.getTime()) as any;
  }

  if (target instanceof Array) {
    const cp = [] as any[];
    (target as any[]).forEach(v => {
      cp.push(v);
    });
    return cp.map((n: any) => deepCopy<any>(n)) as any;
  }

  if (typeof target === "object") {
    const cp: { [key: string]: any } = {
      ...(target as { [key: string]: any }),
    };
    Object.keys(cp).forEach(k => {
      cp[k] = deepCopy<any>(cp[k]);
    });
    return cp as T;
  }

  return target;
};

export const generateErrors = (errors: {
  [key: string]: string[];
}): string[] => {
  const result: string[] = [];

  Object.keys(errors).forEach(errorKey => {
    result.push(errors[errorKey].join("\n"));
  });

  return result;
};

export const objectListToArray = <T>(
  objectList: {
    [key: string]: T;
  } | null
): Array<T & { value: string }> => {
  const result: Array<T & { value: string }> = [];

  for (const key in objectList) {
    const item = objectList[key];

    result.push({
      ...item,
      value: key,
    });
  }

  return result;
};

export const sortByKeyname = <T>(
  list: Array<T>,
  keyName: keyof T
): Array<T> => {
  const result = [...list];

  result.sort((a, b) => {
    return (a[keyName] as number) - (b[keyName] as number);
  });

  return result;
};

export function getEqualFraction(totalCount: number, totalSum = 100): number {
  if (!totalCount) return 0;
  return Math.round((totalSum / totalCount) * 100) / 100;
}

export const getPxValueFromNumber = (
  value: string | number | undefined | null
): string => {
  if (value) {
    return `${value}px`;
  }

  return "0px";
};

export function getClosestPossibleDragTargetElement(t?: HTMLElement | null) {
  return t?.closest<HTMLElement>("[data-element-id]") || null;
}

export const joinStrings = (...strings: string[]) => strings.join("");

export const styleObjectToString = (source: ElementStyle): string => {
  let result: string = "";

  for (const key in source) {
    result += `${key}:${source[key]};`;
  }

  return result;
};

export const styleObjectToStringAdvanced = (
  styles: CSSProperties,
  { newLine = false } = {}
): string => {
  return Object.entries(styles)
    .map(([key, value]) => {
      const cssProperty = key.includes("-") ? key : kebabCase(key);

      const cssRule = `${cssProperty}:${value};`;
      return newLine ? `${cssRule}\n` : cssRule;
    })
    .join(newLine ? "" : " ");
};

export const getSizeValue = (dimension?: ICellDimensionValue): string => {
  if (!dimension) {
    return "initial";
  }

  if (dimension.type === Dimension.CALC) {
    return `calc(${dimension.value})`;
  }

  if (dimension.type === Dimension.CUSTOM) {
    return dimension.value;
  }

  return `${dimension.value}${dimension.type}`;
};

export const getWidgetsFromLayout = (cells: ICell[]): IWidgetWithFields[] => {
  const result: IWidgetWithFields[] = [];

  cells.forEach(cell => {
    if (cell.widgets && cell.widgets.length) {
      result.push(...cell.widgets);
    }

    if (cell.children) {
      const innerWidgets = getWidgetsFromLayout(cell.children);

      result.push(...innerWidgets);
    }
  });

  return result;
};

export const findWidgetByTag = (
  widgets: IWidgetWithFields[],
  tag: string
): IWidgetWithFields | undefined => {
  for (const widget of widgets) {
    if (widget.options._tag === tag) {
      return widget;
    }

    if (widget.options._children) {
      const foundWidget = findWidgetByTag(widget.options._children, tag);
      if (foundWidget) {
        return foundWidget;
      }
    }
  }

  return undefined;
};

const gatherWidgetTags = (widget: IWidgetWithFields): string[] => {
  const tags: string[] = [];

  const collectTags = (currentWidget: IWidgetWithFields) => {
    if (currentWidget.options._tag) {
      tags.push(currentWidget.options._tag);
    }

    if (currentWidget.options._children) {
      currentWidget.options._children.forEach(childrenWidget => {
        collectTags(childrenWidget);
      });
    }
  };

  collectTags(widget);

  return tags;
};

export const gatherWidgetsTags = (grid: Grid): string[] => {
  const result: string[] = [];

  for (const key in grid) {
    const cells = grid[key as Breakpoint]!;

    const widgets = getWidgetsFromLayout(cells);

    result.push(
      ...widgets.flatMap(widget => {
        return gatherWidgetTags(widget);
      })
    );
  }

  return result;
};

export const updateWidgetCopyChildrensIds = (
  widgets: IWidgetWithFields[] | undefined,
  parentId?: string
): IWidgetWithFields[] | undefined => {
  if (!widgets) {
    return undefined;
  }

  return widgets.map(child => {
    const current = deepCopyWidgetWithFields(child, parentId);

    if (current.options._children) {
      current.options._children = updateWidgetCopyChildrensIds(
        current.options._children,
        current.id as string
      );
    }

    return current;
  });
};

export const getGridAlignValue = (align: Align): string => {
  return align.replace("flex-", "");
};
