import { State } from "~~/models/widgets/widget-controls.model";
import {
  StateController,
  useStates,
} from "~~/composables/widgets/common/useStates";

export const usePromoBetsFenixWidgetStateController = () => {
  const DEFAULT_STATES = {
    arrows: State.DEFAULT,
    pagination: State.DEFAULT,
    cards: State.DEFAULT,
    team_player: State.DEFAULT,
    outcomes: State.DEFAULT,
    button: State.DEFAULT,
  };

  const states = ref<Record<string, State>>({
    ...DEFAULT_STATES,
  });

  const { resetStates, handleStateUpdate } = useStates({
    defaultStates: DEFAULT_STATES,
    states,
    resetConditionHandler: (stateName: string) => {
      const skip = [""];
      if (skip.includes(stateName)) return false;
      return true;
    },
  });

  const stateController = reactive<StateController>({
    states: states.value,
    resetStates,
    handleStateUpdate,
    getOptionsValuePath: (fieldName?: string, stateName?: string): string => {
      if (!fieldName && stateName) {
        if (stateName === State.DEFAULT) {
          return "options";
        }
        return `options.states.${stateName}`;
      }

      if (!fieldName || states.value[fieldName] === State.DEFAULT) {
        return "options";
      }
      return `options.states.${states.value[fieldName]}`;
    },
  });

  return {
    stateController,
  };
};
