<template>
  <template
    v-for="(item, index) in ITEMS"
    :key="index"
  >
    <WidgetField
      :widget="widget"
      :field="fields.timer_value.field"
      :class="`top-match-fenix__timer-value--${fields.timer_value.field.options.display.layout}`"
      :style="timerValueStyles"
      class="top-match-fenix__timer-value"
    >
      <BasicDesignWrapperField
        :widget="widget"
        :field="fields.timer_value.field"
        :options="timerValueOptions"
        class="top-match-fenix__timer-value-content"
      >
        <BasicTitleField
          :field="fields.timer_value.field"
          :options="fields.timer_value.field.options.numbers"
          class="top-match-fenix__timer-date"
        >
          00
        </BasicTitleField>

        <BasicTitleField
          v-if="fields.timer_value.field.options._active"
          :field="fields.timer_value.field"
          :options="fields.timer_value.field.options.caption"
          class="top-match-fenix__timer-date"
        >
          {{ item.label }}
        </BasicTitleField>
      </BasicDesignWrapperField>
    </WidgetField>

    <WidgetField
      v-if="item.dots && fields.timer_separator.field.options._active"
      :widget="widget"
      :field="fields.timer_separator.field"
      :class="`top-match-fenix__timer-separator--${fields.timer_separator.field.options.display.layout}`"
      :style="timerSeparatorStyles"
      class="top-match-fenix__timer-separator"
    >
      <BasicDesignWrapperField
        :widget="widget"
        :field="fields.timer_separator.field"
        :options="timerSeparatorOptions"
        class="top-match-fenix__timer-separator-content"
      >
        <BasicTitleField
          :field="fields.timer_separator.field"
          :options="fields.timer_separator.field.options.numbers"
          class="top-match-fenix__timer-separator"
        >
          :
        </BasicTitleField>
      </BasicDesignWrapperField>
    </WidgetField>
  </template>
</template>

<script lang="ts" setup>
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getPxValueFromNumber } from "~~/assets/utils";
import { getFlexPosition } from "~~/assets/utils/widget-settings";
import { getCommonStyles } from "~~/assets/utils/styles";
import { ElementStyle } from "~~/models/common";

const props = defineProps<{
  widget: IWidgetWithFields;
}>();

const fields = useWidgetFields(props.widget);

const ITEMS = [
  {
    label: "Days",
    dots: true,
  },
  {
    label: "Hours",
    dots: true,
  },
  {
    label: "Mins",
    dots: false,
  },
];

const timerValueOptions = computed<IWidgetOptions>(() => {
  return {
    ...fields.value.timer_value.field.options,
    spacing: {
      padding: fields.value.timer_value.field.options.spacing.padding,
      margin: null,
    },
  };
});

const timerSeparatorOptions = computed<IWidgetOptions>(() => {
  return {
    ...fields.value.timer_separator.field.options,
    spacing: {
      padding: fields.value.timer_separator.field.options.spacing.padding,
      margin: null,
    },
  };
});

const timerValueStyles = computed<ElementStyle>(() => {
  return getCommonStyles({
    margin: fields.value.timer_value.field.options.spacing.margin,
  });
});

const timerSeparatorStyles = computed<ElementStyle>(() => {
  return getCommonStyles({
    margin: fields.value.timer_separator.field.options.spacing.margin,
  });
});

const timerValueAlign = computed<string>(() => {
  return getFlexPosition(
    fields.value.timer_value.field.options.display.alignment
  );
});

const timerValueGap = computed<string>(() => {
  return getPxValueFromNumber(
    fields.value.timer_value.field.options.display.gap
  );
});

const separatorAlign = computed<string>(() => {
  return getFlexPosition(
    fields.value.timer_separator.field.options.display.alignment
  );
});
</script>

<style lang="scss">
.top-match-fenix {
  &__timer-value,
  &__timer-value-content {
    align-items: v-bind(timerValueAlign);
    display: flex;
    flex-direction: column;
    gap: v-bind(timerValueGap);
  }

  &__timer-value--fill {
    flex: 1;

    .top-match-fenix__timer-value-content {
      align-self: stretch;
    }
  }

  &__timer-value--hug {
    display: inline-flex;
  }

  &__timer-date {
    line-height: 1;
  }

  &__timer-separator {
    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: v-bind(separatorAlign);
      align-items: center;
      height: 100%;

      .basic-title-widget__content {
        line-height: 1;
      }
    }

    &--fill {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
