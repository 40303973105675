import { type Ref } from "vue";

import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  getSpacing,
  getColorFromHex,
  getDecorationValue,
  getBorderStyle,
  getBoxShadowStyle,
  getFontSizeStyle,
  getWidthStyles,
  getHeightStyles,
  getCornerRadiusStyle,
} from "~~/assets/utils/widget-settings";
import {
  DisplayOrientation,
  IBorderControl,
  IFillControl,
  IShadowControl,
  SpacingKeyName,
  State,
  TextStyle,
} from "~~/models/widgets/widget-controls.model";
import { getStylesAsVars } from "~~/assets/utils/widget/form";
import { ElementStyle } from "~~/models/common";
import { getPxValueFromNumber } from "~~/assets/utils";

const getColorValue = (colorValue: IFillControl | null): string => {
  const color = getColorFromHex({
    opacity: colorValue?.opacity || 100,
    color: colorValue?.color || "",
  });

  return color || "";
};

export const useSportsMenuStyles = (
  widget: IWidgetWithFields,
  states: Ref<Record<string, State>>,
  isLive: boolean,
  isFenix?: boolean | undefined
) => {
  const fields = useWidgetFields(widget);

  const getStateStyle = (
    options: IWidgetOptions,
    propertyName: string,
    state = State.DEFAULT
  ): unknown => {
    if (state === State.DEFAULT) {
      return options[propertyName];
    }

    return options.states[state][propertyName];
  };

  const getItemStyles = (itemName: string, state?: State): ElementStyle => {
    const fieldValue = fields.value[itemName]?.field;

    if (!fieldValue) return {};

    const color = getColorValue(
      getStateStyle(fieldValue.options, "color", state) as IFillControl
    );
    const backgroundColor = getColorValue(
      getStateStyle(fieldValue.options, "fill", state) as IFillControl
    );

    const padding = getSpacing(
      fieldValue.options.padding,
      SpacingKeyName.PADDING
    );

    const marginBottom = getPxValueFromNumber(fieldValue.options.verticalSpace);
    const borderRadius = getCornerRadiusStyle(fieldValue.options.cornerRadius);

    const border = getBorderStyle(
      getStateStyle(fieldValue.options, "border", state) as IBorderControl
    );
    const shadow = getBoxShadowStyle(
      getStateStyle(fieldValue.options, "shadow", state) as IShadowControl
    );

    const fontFamily = fieldValue.options.fontFamily;
    const decoration = getDecorationValue(
      getStateStyle(fieldValue.options, "decoration", state) as TextStyle[]
    );

    const iconSize = getPxValueFromNumber(fieldValue.options.itemIcon?.size);

    const stateIconOptions = getStateStyle(
      fieldValue.options,
      "itemIcon",
      state
    ) as { color: IFillControl };

    const iconColor = getColorValue(stateIconOptions?.color);

    const spaceBetweenTextAndIcon = getPxValueFromNumber(
      fieldValue.options.itemIcon?.spaceBetween
    );

    const stateArrowOptions = getStateStyle(
      fieldValue.options,
      "arrowSettings",
      state
    ) as { textColor: IFillControl };

    const arrowIconColor = getColorValue(stateArrowOptions?.textColor || null);
    const fontSize = getFontSizeStyle(fieldValue.options.theme);

    let flexDirection = "";
    let elementsFlexDirection = "";
    if (isFenix) {
      flexDirection =
        fieldValue.options.displayMode === DisplayOrientation.VERTICAL
          ? "column"
          : "row";

      elementsFlexDirection =
        fieldValue.options.displayMode === DisplayOrientation.VERTICAL
          ? "column"
          : "row";
    }

    if (
      isFenix &&
      (itemName === "menu_level_1" || itemName === "menu_level_2")
    ) {
      elementsFlexDirection =
        fieldValue.options.elementsDisplayMode === DisplayOrientation.VERTICAL
          ? "column"
          : "row";
    }

    const widthStyles = fieldValue.options.size
      ? getWidthStyles(fieldValue.options.size?.width)
      : {};
    const heightStyles = fieldValue.options.size
      ? getHeightStyles(fieldValue.options.size?.height)
      : {};

    const data = {
      width: widthStyles.width,
      height: heightStyles.height,
      alignItems: fieldValue.options.baseDisplaySettings?.alignment || "center",
      textAlign: fieldValue.options.alignment,
      color,
      backgroundColor,
      marginBottom,
      fontFamily,

      ...borderRadius,
      ...padding,
      ...border,
      ...shadow,
      ...decoration,
      ...fontSize,
      iconSize,
      iconColor,
      spaceBetweenTextAndIcon,
      arrowIconColor,
      flexDirection,
      elementsFlexDirection,
    };

    return getStylesAsVars(data, itemName);
  };

  const firstLevelStyles = computed<ElementStyle>(() => {
    return getItemStyles("menu_level_1", states.value.menu_level_1);
  });

  const secondLevelStyles = computed<ElementStyle>(() => {
    return getItemStyles("menu_level_2", states.value.menu_level_2);
  });

  const thirdLevelStyles = computed<ElementStyle>(() => {
    if (isLive) {
      if (isFenix) {
        return getItemStyles("event_container");
      }
      return {};
    }

    return getItemStyles("menu_level_3", states.value.menu_level_3);
  });

  const itemsStyles = computed(() => {
    let styles: Record<string, any> = {
      ...firstLevelStyles.value,
      ...secondLevelStyles.value,
    };

    if (!isLive || (isFenix && isLive)) {
      styles = {
        ...styles,
        ...thirdLevelStyles.value,
      };
    }

    return styles;
  });

  return { itemsStyles };
};
