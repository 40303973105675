<template>
  <div class="page-editors">
    <ul class="page-editors__list">
      <li
        v-for="editor in visibleEditors"
        :key="editor.sessionId"
        class="page-editors__list-item"
      >
        <PageEditor
          :name="editor.user.name"
          :avatar="editor.user.avatar"
          :abbr="editor.user.abbr"
        />
      </li>
      <li
        v-if="currentEditor"
        :key="currentEditor.sessionId"
        class="page-editors__list-item"
      >
        <PageEditor
          :name="currentEditor.user.name"
          :avatar="currentEditor.user.avatar"
          :abbr="currentEditor.user.abbr"
          :active="true"
        />
      </li>
    </ul>

    <div
      v-if="hiddenEditorsCount"
      class="page-editors__count"
    >
      +{{ hiddenEditorsCount }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { useTimer } from "~~/composables/useTimer";

import { usePageEditors } from "../composables/usePageEditors";

import PageEditor from "./PageEditor.vue";

const pageEditors = usePageEditors();
const { currentEditor, visibleEditors, hiddenEditorsCount } = pageEditors;

const timer = useTimer({
  callback: () => {
    Promise.all([pageEditors.updateEditor(), pageEditors.fetchEditors()]);
  },
  timeout: 30_000,
});

const init = async () => {
  await Promise.all([pageEditors.createEditor(), pageEditors.fetchEditors()]);

  timer.init();
  timer.start();

  window.addEventListener("beforeunload", onBeforeunload);
};

const destroy = () => {
  pageEditors.executeAbortControllers();
  pageEditors.removeEditor();
  timer.stop();

  window.removeEventListener("beforeunload", onBeforeunload);
};

const onBeforeunload = () => {
  pageEditors.removeEditorBeacon();
};

onMounted(() => {
  init();
});

onBeforeUnmount(() => {
  destroy();
});
</script>
<style lang="scss">
.page-editors {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 2px 0;

  &__list {
    display: flex;
    margin: 0;

    &-item {
      &:not(:first-child) {
        margin-left: -8px;
      }
    }
  }

  &__count {
    margin-right: 6px;
    color: #b1b4b9;
    font-size: 12px;
  }
}
</style>
