import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import {
  DisplayOrientation,
  ResizingType,
  WidgetDimension,
  WidgetSize,
} from "~~/models/widgets/widget-controls.model";
import { CellSizeType, Dimension } from "~~/models/grid.interface";

import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateBorderStyle,
  generateDecorationStyle,
  generateFillColorStyle,
  generateFlex,
  generateFlexAlignment,
  generateFontFamily,
  generateFontSize,
  generateGap,
  generateGrid,
  generatePaddingStyle,
  generateTextColor,
  generateWidth,
} from "../helpers";
import { getColorFromHex } from "../../widget-settings";

import { CssGenerator } from "./css-generator";

export const generateTabWidth = (width?: WidgetDimension): string => {
  if (!width) {
    return "";
  }

  if (width.type === CellSizeType.HUG) {
    return "width:fit-content !important;";
  }

  if (width.type === CellSizeType.FILL) {
    return "width: 100%;";
  }

  return `width: ${width.value?.value}${width.value?.type};max-width: ${width.value?.value}${width.value?.type};`;
};

export const generateHeight = (height?: WidgetDimension): string => {
  if (!height) {
    return "";
  }

  if (height.type === CellSizeType.FILL) {
    return "flex: 1;";
  }

  if (height.type === CellSizeType.HUG) {
    return "height: fit-content !important;";
  }

  return `height: ${height.value?.value}${height.value?.type};`;
};

export const generateSize = (size: WidgetSize): string => {
  if (!size) {
    return "";
  }

  return generateTabWidth(size.width) + generateHeight(size.height);
};

export const generateSuccessMessageCssString =
  (element: IWidgetOptions, wrapperStyles?: string) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const customContent = generateFlex({
      flex: "flex",
      justify:
        element.options.layout === DisplayOrientation.HORIZONTAL
          ? "center"
          : "space-between",
      align:
        element.options.layout === DisplayOrientation.HORIZONTAL
          ? "center"
          : element.options.alignment,
      direction:
        element.options.layout === DisplayOrientation.HORIZONTAL
          ? "row"
          : "column",
      gap: "6",
    });
    `justify-content:space-between;`;

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: customContent,
    });

    const iconSettingsContent = [
      generateGap(element.options.iconSettings.gap),
      generateFontSize(element.options.iconSettings.iconSize),
      generateTextColor(element.options.iconSettings.textColor),
    ];

    cssString += generateCssClassWithContent({
      className: element.options.iconSettings._cssClass,
      content: iconSettingsContent.join(""),
    });

    cssString += generateCssClassWithContent({
      className: element.options.printSettings._cssClass,
      content: element.options.printSettings,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options._wrapperCssClass,
      content: wrapperStyles || "",
    });

    return cssString;
  };

export const generateMessageCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const iconSettingsContent = [
      generateGap(element.options.iconSettings.gap),
      generateFontSize(element.options.iconSettings.iconSize),
      generateTextColor(element.options.iconSettings.textColor),
    ];

    cssString += generateCssClassWithContent({
      className: element.options.iconSettings._cssClass,
      content: iconSettingsContent.join(""),
    });

    return cssString;
  };

export const generateResultTotalAmountsCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const isHorizontal =
      element.options.position === DisplayOrientation.HORIZONTAL;

    const rootCssContent = [
      generateFlex({
        flex: "flex",
        align: isHorizontal ? "unset" : "center",
        justify: isHorizontal ? "unset" : "space-between",
        direction: isHorizontal ? "column" : "",
        gap: element.options.gap || "",
      }),
      generateWidth(100, "%"),
      "margin-left:auto;margin-right:auto;",
      generatePaddingStyle(element.options.padding),
    ];

    cssString += generateCssClassWithContent({
      className: element.options._rootCssClass,
      content: rootCssContent.join(""),
    });

    const elementCssContent = [
      generateFlex({
        flex: "flex",
        align: "unset",
        justify: isHorizontal ? "space-between" : "unset",
        direction: isHorizontal ? "" : "column",
      }),
      generateWidth(100, "%"),
      "margin-left:auto;margin-right:auto;",
    ];

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: elementCssContent.join(""),
    });

    cssString += generateDefaultStyles(element.options);

    const elementContent = generateFontFamily(element.options.fontFamily);

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: elementContent,
    });

    cssString += generateCssClassWithContent({
      className: element.options.labelSettings._cssClass,
      content: element.options.labelSettings,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options.valueSettings._cssClass,
      content: element.options.valueSettings,
      customFunction: generateDefaultStyles,
    });

    return cssString;
  };

export const generateHeaderTabsCssString =
  (
    element: IWidgetOptions,
    counterElement?: IWidgetOptions,
    headerLabelField?: IWidgetOptions,
    widgetName?: string
  ) =>
  (cssString: string): string => {
    const headerElemCssClass = element.options.headerElementsSettings._cssClass;

    if (widgetName !== "betslipStatusFenixWidget") {
      cssString += generateCssClassWithContent({
        className: headerElemCssClass,
        content: generateFlex({
          flex: "flex",
          align: "stretch",
          direction: "column",
          justify: element.options.alignment,
          gap: "0",
        }),
      });
    } else {
      // FIXME: delete this logic after this comment resolved https://fedevco.youtrack.cloud/issue/FECO-4053/FE-BAGBetslip-status-perekryvaetsya-koef.#focus=Comments-4-23271.0-0
      cssString += generateCssClassWithContent({
        className: headerElemCssClass,
        content: generateFlex({
          flex: "flex",
          align: "center",
          direction: "row",
          justify: element.options.alignment,
          gap: element.options.gapBetweenTextAndCounter,
        }),
      });
    }

    const isDisplayFill =
      element.options.buttonDisplaySettings.resizing === ResizingType.FILL;

    const widthValue = isDisplayFill ? "100%" : "unset";

    const justifyContent = isDisplayFill
      ? element.options.buttonDisplaySettings.alignment
      : "unset";

    const alignSelf = isDisplayFill
      ? "unset"
      : generateFlexAlignment(element.options.buttonDisplaySettings.alignment);

    const buttonDisplayCssString = [
      generateWidth(widthValue, null),
      `align-self: ${alignSelf}`,
    ].join("");

    cssString += generateCssClassWithContent({
      className: headerElemCssClass,
      content: buttonDisplayCssString,
    });

    cssString += generateCssClassWithContent({
      className: headerElemCssClass,
      childClassName: "betslip-header__cash-out-tab-label-container",
      content: buttonDisplayCssString,
    });

    cssString += generateCssClassWithContent({
      className: headerElemCssClass,
      childClassName: "betslip-header__cash-out-tab-container",
      content:
        generateFlex({
          flex: "flex",
          direction: "column",
          align: "center",
          justify: "flex-start",
          gap: "4",
        }) + generateWidth(100, "%"),
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content:
        generateDefaultStyles({
          ...element.options,
          color: null,
          decoration: null,
        }) + `justify-content:space-around !important;`,
    });

    if (element.options?.decoration) {
      cssString += generateCssClassWithContent({
        className: headerElemCssClass,
        childClassName: "betslip-header-label",
        content: element.options?.decoration,
        customFunction: generateDecorationStyle,
      });
    }

    if (!headerLabelField) {
      cssString += generateCssClassWithContent({
        className: headerElemCssClass,
        childClassName: "betslip-header-label",
        content: element.options.decoration,
        customFunction: generateDecorationStyle,
      });

      cssString += generateCssClassWithContent({
        className: headerElemCssClass,
        childClassName: "betslip-header-label",
        content: "text-align:left;",
      });
    }

    cssString += generateCssClassWithContent({
      className: headerElemCssClass,
      content: {
        ...element.options.headerElementsSettings,
        color: element.options.color,
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: ` button.base-tabs__trigger`,
      content: generateSize(element.options.size),
    });

    // if (!isDisplayFill) {
    //   cssString += generateCssClassWithContent({
    //     className: element.options._cssClass,
    //     content: `justify-content:${element.options.buttonDisplaySettings.alignment};`,
    //   });
    // }

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: ` button.base-tabs__trigger:hover .${headerElemCssClass}`,
      content: {
        ...element.options.states.hover.headerElementsSettings,
        color: element.options.states.hover.color,
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cssClass} button.base-tabs__trigger[data-active="true"] .${headerElemCssClass}`,
      content: {
        ...element.options.states.active.headerElementsSettings,
        color: element.options.states.active.color,
      },
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: `${element.options._cssClass} button.base-tabs__trigger[data-disabled] .${headerElemCssClass}`,
      content: {
        ...element.options.states.disabled.headerElementsSettings,
        color: element.options.states.disabled.color,
      },
      customFunction: generateDefaultStyles,
    });

    if (counterElement) {
      cssString += generateCssClassWithContent({
        className: counterElement.options._cssClass,
        content: `width:fit-content;justify-self:${element.options.buttonDisplaySettings.alignment};`,
      });

      cssString += generateCssClassWithContent({
        className: counterElement.options._cssClass,
        content: counterElement.options,
        customFunction: generateDefaultStyles,
      });

      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        pseudoClassName: ` button.base-tabs__trigger:hover .${counterElement.options._cssClass}`,
        content: counterElement.options.states.hover,
        customFunction: generateDefaultStyles,
      });

      cssString += generateCssClassWithContent({
        className: `${element.options._cssClass} button.base-tabs__trigger[data-active="true"] .${counterElement.options._cssClass}`,
        content: counterElement.options.states.active,
        customFunction: generateDefaultStyles,
      });

      cssString += generateCssClassWithContent({
        className: `${element.options._cssClass} button.base-tabs__trigger[data-disabled] .${counterElement.options._cssClass}`,
        content: counterElement.options.states.disabled,
        customFunction: generateDefaultStyles,
      });
    }

    if (headerLabelField) {
      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        pseudoClassName: ` .betslip-header-label`,
        content: headerLabelField.options,
        customFunction: generateDefaultStyles,
      });

      cssString += generateCssClassWithContent({
        className: `${element.options._cssClass} button.base-tabs__trigger[data-active="true"] .betslip-header-label`,
        content: headerLabelField.options.states.active,
        customFunction: generateDefaultStyles,
      });

      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        pseudoClassName: ` button.base-tabs__trigger:hover .betslip-header-label`,
        content: headerLabelField.options.states.hover,
        customFunction: generateDefaultStyles,
      });

      cssString += generateCssClassWithContent({
        className: `${element.options._cssClass} button.base-tabs__trigger[data-disabled] .betslip-header-label`,
        content: headerLabelField.options.states.disabled,
        customFunction: generateDefaultStyles,
      });
    }

    let templateColumns = "";
    // let templateRows =
    //   "grid-template-rows:min-content min-content;height:fit-content;";
    let templateAreas = "";
    const gap = element.options.gapBetweenTextAndCounter
      ? element.options.gapBetweenTextAndCounter
      : 0;

    if (element.options.layout === 1) {
      templateColumns = "max-content max-content";
      templateAreas = `"label count";`;
      // templateRows = "min-content";

      cssString += generateCssClassWithContent({
        className: element.options._contentCssClass,
        pseudoClassName: " *:nth-child(3)",
        content: "grid-column: span 2 !important;",
      });

      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        pseudoClassName: ` button:last-child .${element.options._contentWithCashoutCssClass}`,
        content: `grid-template-areas: "label count" "cashout ." !important;`,
      });
    }

    if (element.options.layout === 2) {
      templateColumns = "1fr";
      templateAreas = `"label" "count";`;

      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        pseudoClassName: ` button:last-child .${element.options._contentWithCashoutCssClass}`,
        content: `grid-template-areas:"label" "cashout" "count" !important;`,
      });
    }

    if (element.options.layout === 3) {
      templateColumns = "1fr";
      templateAreas = `"label" "count";`;

      cssString += generateCssClassWithContent({
        className: element.options._cssClass,
        pseudoClassName: ` button:last-child .${element.options._contentWithCashoutCssClass}`,
        content: `grid-template-areas:"label" "count" "cashout" !important;`,
      });
    }

    cssString += generateCssClassWithContent({
      className: element.options._contentCssClass,
      content:
        generateGrid({
          templateColumns,
          rowGap: gap,
          columnGap: gap,
        }) +
        `align-items:center;justify-content:${justifyContent};height:100%;` +
        `grid-template-rows:min-content;height:fit-content;`,
    });

    cssString += generateCssClassWithContent({
      className: element.options._contentCssClass,
      content:
        generateGrid({
          templateColumns,
          rowGap: gap,
          columnGap: gap,
        }) +
        `align-items:center;justify-content:${justifyContent};height:100%;` +
        `grid-template-rows:min-content;height:fit-content;`,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      pseudoClassName: ` button:last-child .${element.options._contentCssClass}`,
      content:
        `grid-template-areas: ${templateAreas};` +
        `grid-template-rows:min-content;`,
    });

    return cssString;
  };

export const generateCoefficientCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const downStyles = [
      generateDefaultStyles(element.options, ["fill", "border", "color"]),
      generateBorderStyle(element.options.states.down.border, true),
      generateFillColorStyle(element.options.states.down.fill, true),
      generateTextColor(element.options.states.down.color, true),
    ];
    cssString += generateCssClassWithContent({
      className: element.options._downCssClass,
      content: downStyles.join(""),
    });

    const upStyles = [
      generateDefaultStyles(element.options, ["fill", "border", "color"]),
      generateBorderStyle(element.options.states.up.border, true),
      generateFillColorStyle(element.options.states.up.fill, true),
      generateTextColor(element.options.states.up.color, true),
    ];
    cssString += generateCssClassWithContent({
      className: element.options._upCssClass,
      content: upStyles.join(""),
    });

    cssString += CssGenerator.init()
      .className(`${element.options._upCssClass}-triangle--up`)
      .openClassBody()
      .property("position", "relative")
      .property("overflow", "hidden")
      .closeClassBody()
      .toString();

    cssString += CssGenerator.init()
      .className(`${element.options._upCssClass}-triangle--up`)
      .pseudoSelector("after")
      .openClassBody()
      .property("content", "''")
      .property("display", "block")
      .property("position", "absolute")
      .property("right", "-10px")
      .property("top", "-7px")
      .property("width", "22px")
      .property("height", "15px")
      .property(
        "background-color",
        `${getColorFromHex(element.options.states.up.arrow?.color)!} !important`
      )
      .property("z-index", "2")
      .property("transform", "rotate(45deg)")
      .closeClassBody()
      .toString();

    cssString += CssGenerator.init()
      .className(`${element.options._downCssClass}-triangle--down`)
      .openClassBody()
      .property("position", "relative")
      .property("overflow", "hidden")
      .closeClassBody()
      .toString();

    cssString += CssGenerator.init()
      .className(`${element.options._downCssClass}-triangle--down`)
      .pseudoSelector("after")
      .openClassBody()
      .property("content", "''")
      .property("display", "block")
      .property("position", "absolute")
      .property("right", "-10px")
      .property("top", "initial")
      .property("bottom", "-7px")
      .property("width", "22px")
      .property("height", "15px")
      .property(
        "background-color",
        `${getColorFromHex(
          element.options.states.down.arrow?.color
        )!} !important`
      )
      .property("z-index", "2")
      .property("transform", "rotate(-45deg)")
      .closeClassBody()
      .toString();

    return cssString;
  };

export const generateCloseIconCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const content = [
      generateTextColor(element.options.color),
      generateFontSize(element.options.iconSize),
    ];

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: content.join(""),
    });

    return cssString;
  };

export const generateLabelCopyIconCssString =
  (customFields: IWidgetOptions, form: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: customFields.fields_styling.options.iconRight._cssClass,
      content: generateFontSize(customFields.labels_styling.options.theme),
    });

    cssString += generateCssClassWithContent({
      className: `${form.options._cssClass} .formkit-label-wrapper-override`,
      content: customFields.fields_styling.options.iconRight.gap,
      customFunction: generateGap,
    });

    return cssString;
  };

export const generateBetslipFormCssString =
  (form: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: `${form.options._cssClass} .formkit-outer-override:not(:last-child)`,
      content: "margin-bottom: 8px;",
    });

    return cssString;
  };

export const generateResultContainerCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const resultContainerDisplayContent = [
      generateFlex({
        flex: "flex !important",
        align: "unset",
        justify: "unset",
        direction: "column",
      }),
    ].join("");

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: resultContainerDisplayContent,
    });

    return cssString;
  };

export const generateBetsContainerCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const betsContainerDisplayContent = generateFlex({
      flex: "flex !important",
      align: "unset",
      justify: "unset",
      direction: "column",
      gap: element.options.spaceBetween || element.options.distance,
    });

    let heightStyles;
    const heightOptions = element.options.size?.height;

    if (heightOptions) {
      if (heightOptions.type === CellSizeType.HUG) {
        heightStyles = "height:initial;";
      } else {
        if (heightOptions.value?.type === Dimension.CALC) {
          heightStyles = `max-height:calc(${heightOptions.value?.value});`;
        } else if (heightOptions.value?.type === Dimension.CUSTOM) {
          heightStyles = heightOptions.value?.value;
        } else {
          heightStyles = `max-height:${heightOptions.value?.value}${heightOptions.value?.type};`;
        }

        heightStyles += "overflow-y:auto;flex-grow:1;";
      }
    }

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: betsContainerDisplayContent + heightStyles,
    });

    return cssString;
  };

export const generateBetsTypeTitleCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: element.options,
      customFunction: generateDefaultStyles,
    });

    const defaultTypeStyles =
      generateFontSize(element.options.theme) +
      generatePaddingStyle(element.options.padding) +
      generateDecorationStyle(element.options.decoration);

    cssString += generateCssClassWithContent({
      className: element.options._systemCssClass,
      content: element.options.states.system_bets,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options._multiCssClass,
      content: element.options.states.multi_bets,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options._systemCssClass,
      content: defaultTypeStyles,
    });

    cssString += generateCssClassWithContent({
      className: element.options._multiCssClass,
      content: defaultTypeStyles,
    });

    return cssString;
  };
