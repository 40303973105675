<template>
  <BaseWidgetNew
    :widget="widget"
    :cell="cell"
  >
    <SportsbookLiveFenixSettings
      :widget="widget"
      :states="states"
      @update-state="handleStateUpdate"
      @reset-states="resetStates"
    />

    <div class="sportbook-live-mobile-fenix">
      <WidgetField
        :widget="widget"
        :field="fields.sports_container.field"
      >
        <BasicDesignWrapperField
          v-if="states.widget === State.ACTIVE_EVENTS"
          :widget="widget"
          :field="fields.sports_container.field"
          :state="states.sports_container"
        >
          <div
            :style="sportsContainerStyles"
            class="sportbook-live-mobile-fenix__sports-container"
          >
            <div>
              <WidgetField
                :widget="widget"
                :field="fields.title.field"
              >
                <SportsbookTitle
                  :widget="widget"
                  :field="fields.title.field"
                  :state="states.title"
                  :button-field="fields.title_button.field"
                  :button-state="states.title_button"
                  icon="mdi:basketball"
                >
                  Sport
                </SportsbookTitle>
              </WidgetField>

              <div
                v-for="(card, index) in CARDS"
                :key="index"
                :style="leagueCardElementStyle"
                class="sportbook-live-mobile-fenix__card-item"
              >
                <WidgetField
                  v-if="fields.leagues_titles.field.options._active"
                  :widget="widget"
                  :field="fields.leagues_titles.field"
                >
                  <BasicDesignWrapperField
                    :widget="widget"
                    :field="fields.leagues_titles.field"
                  >
                    <BasicTitleField
                      :field="fields.leagues_titles.field"
                      value="League name"
                    />
                  </BasicDesignWrapperField>
                </WidgetField>

                <WidgetField
                  :widget="widget"
                  :field="fields.sport_events_container.field"
                >
                  <SportsbookLiveFixesSportsContainer
                    :field="fields.sport_events_container.field"
                    :widget="widget"
                    :count="card.cards"
                  >
                    <WidgetField
                      :widget="widget"
                      :field="fields.event_cards.field"
                    >
                      <SportsbookLiveFenixEventCard
                        :widget="widget"
                        :field="fields.event_cards.field"
                        :state="states.event_cards"
                      >
                        <WidgetField
                          :widget="widget"
                          :field="fields.event_header.field"
                        >
                          <SportsbookLiveFenixEventCardHeader
                            :widget="widget"
                            :field="fields.event_header.field"
                            :league-name-field="fields.event_league.field"
                            :favorite-icon-field="
                              fields.favourites_button.field
                            "
                            :stream-icon-field="fields.stream_icon.field"
                            :time-icon-field="fields.event_time.field"
                            :period-field="fields.event_period.field"
                          />
                        </WidgetField>

                        <div
                          :style="cardBottomStyles"
                          :class="{
                            'sportbook-live-mobile-fenix__card-bottom--second':
                              fields.event_cards.field.options.display
                                .layout === 2,
                          }"
                          class="sportbook-live-mobile-fenix__card-bottom"
                        >
                          <div>
                            <WidgetField
                              :widget="widget"
                              :field="fields.event_info.field"
                            >
                              <SportsbookLiveFenixEventInfo
                                :widget="widget"
                                :field="fields.event_info.field"
                                :event-name-field="fields.event_name.field"
                                :event-score-field="fields.event_score.field"
                              />
                            </WidgetField>
                          </div>

                          <WidgetField
                            :widget="widget"
                            :field="fields.bet_items.field"
                            class="sportbook-live-mobile-fenix__bet-items-container"
                          >
                            <div
                              :style="betItemsStyle"
                              class="sportbook-live-mobile-fenix__bet-items"
                            >
                              <template v-if="states.bet_items !== 'suspended'">
                                <SportsbookBetItem
                                  v-for="(item, index2) in BET_ITEMS"
                                  :key="index2"
                                  :bet-item-field="fields.bet_items.field"
                                  :item-value="item"
                                  :state-options="betItemsOptions"
                                  :is-up="states.bet_items === State.UP"
                                  :is-down="states.bet_items === State.DOWN"
                                  :show-both-arrows-states="[
                                    State.ACTIVE,
                                    State.HOVER,
                                  ]"
                                  :state="states.bet_items"
                                  :style="betItemWidthStyles"
                                  class="sportbook-live-mobile-fenix__bet-item"
                                />
                              </template>

                              <BasicDesignWrapperField
                                v-else
                                :field="fields.bet_items.field"
                                :widget="widget"
                                state="suspended"
                                class="sportbook-live-mobile-fenix__bet-item--disabled"
                              >
                                <BasicTitleField
                                  :field="fields.bet_items.field"
                                  :options="{
                                    ...fields.bet_items.field.options.states
                                      .suspended.name,
                                    alignment: Alignment.CENTER,
                                  }"
                                  value="Suspended"
                                />
                              </BasicDesignWrapperField>
                            </div>
                          </WidgetField>

                          <WidgetField
                            v-if="fields.event_button.active"
                            :widget="widget"
                            :field="fields.event_button.field"
                            :class="{
                              'sportbook-live-mobile-fenix__event-button--stretch':
                                fields.event_button.field.options
                                  .buttonDisplaySettings.resizing ===
                                ResizingType.FILL,
                            }"
                            class="sportbook-live-mobile-fenix__event-button"
                          >
                            <BasicButtonField
                              :field="fields.event_button.field"
                              :current-state="states.event_button"
                            />
                          </WidgetField>
                        </div>
                      </SportsbookLiveFenixEventCard>
                    </WidgetField>
                  </SportsbookLiveFixesSportsContainer>
                </WidgetField>
              </div>
            </div>

            <div>
              <WidgetField
                :widget="widget"
                :field="fields.title.field"
              >
                <SportsbookTitle
                  :widget="widget"
                  :field="fields.title.field"
                  :state="states.title"
                  :button-field="fields.title_button.field"
                  :button-state="states.title_button"
                  icon="mdi:basketball"
                >
                  Horses or greyhounds
                </SportsbookTitle>
              </WidgetField>

              <WidgetField
                :widget="widget"
                :field="fields.racing_events_container.field"
              >
                <SportsbookLiveFixesSportsContainer
                  :field="fields.racing_events_container.field"
                  :widget="widget"
                  :count="2"
                >
                  <WidgetField
                    :widget="widget"
                    :field="fields.racing_event_cards.field"
                  >
                    <SportsbookLiveFenixEventCard
                      :widget="widget"
                      :field="fields.racing_event_cards.field"
                      :state="states.racing_event_cards"
                    >
                      <SportsbookLiveFenixRacingEventContent
                        :widget="widget"
                        :field="fields.racing_event_cards.field"
                        :league-name-field="fields.racing_event_name.field"
                        :favorite-icon-field="
                          fields.racing_event_favourites_button.field
                        "
                        :stream-icon-field="
                          fields.racing_event_stream_icon.field
                        "
                        :status-field="fields.racing_event_status.field"
                        :button-field="fields.racing_event_button.field"
                        :button-state="states.racing_event_button"
                      />
                    </SportsbookLiveFenixEventCard>
                  </WidgetField>
                </SportsbookLiveFixesSportsContainer>
              </WidgetField>
            </div>
          </div>
        </BasicDesignWrapperField>

        <BasicTitleField
          v-else
          :field="fields.no_events_title.field"
        />
      </WidgetField>
    </div>
  </BaseWidgetNew>
</template>

<script lang="ts" setup>
import { ICell } from "~~/models/grid.interface";
import { ElementStyle } from "~~/models/common";
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { useInit } from "~~/composables/widgets/sportbook-live-fenix-mobile/useSportsbookLiveFenixMobileInit";
import { useStates } from "~~/composables/widgets/common/useStates";
import {
  Alignment,
  DisplayOrientation,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";

const props = defineProps<{
  widget: IWidgetWithFields;
  cell: ICell;
}>();

const fields = useWidgetFields(props.widget);

const {
  states,
  DEFAULT_STATES,

  init,
} = useInit(props.widget);

const { resetStates, handleStateUpdate } = useStates({
  defaultStates: DEFAULT_STATES,
  states,
});

const BET_ITEMS = [
  { name: "1", coef: "0.00" },
  { name: "X", coef: "0.00" },
  { name: "2", coef: "0.00" },
];

const CARDS = [{ cards: 2 }, { cards: 1 }];

const betItemsOptions = computed<IWidgetOptions>(() => {
  if (states.value.bet_items === State.DEFAULT) {
    return fields.value.bet_items.field.options;
  }

  return fields.value.bet_items.field.options.states[states.value.bet_items];
});

const betItemsStyle = computed<ElementStyle>(() => {
  const options = fields.value.bet_items.field.options;

  if (options.displayMode === DisplayOrientation.HORIZONTAL) {
    return {
      flexDirection: "row",
      gap: getPxValueFromNumber(options.distance),
    };
  }

  return {
    flexDirection: "column",
    gap: getPxValueFromNumber(options.distance),
  };
});

const cardBottomStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(
      fields.value.event_cards.field.options.display.distance
    ),
  };
});

const leagueCardElementStyle = computed<ElementStyle>(() => {
  if (!fields.value.leagues_titles.active) {
    return {
      marginBottom: getPxValueFromNumber(
        fields.value.event_cards.field.options.display.distance
      ),
    };
  }

  return {
    marginBottom: getPxValueFromNumber(
      fields.value.sports_container.field.options.display
        .distanceBetweenLeagueEvents
    ),
  };
});

const sportsContainerStyles = computed<ElementStyle>(() => {
  return {
    gap: getPxValueFromNumber(
      fields.value.sports_container.field.options.display.distanceBetweenSports
    ),
  };
});

const betItemWidthStyles = computed<ElementStyle>(() => {
  const field = fields.value.bet_items.field;

  if (field.options.width.type === "auto") {
    return {};
  }

  return {
    width: `${field.options.width.value.value}${field.options.width.value.type}`,
  };
});

init();
</script>

<style lang="scss">
.sportbook-live-mobile-fenix {
  &__sports-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__card-item {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &__bet-items {
    display: flex;
  }

  &__bet-item {
    flex-direction: column;
    align-items: center;
    flex: 1;

    &--disabled {
      width: 100%;
    }

    span,
    div {
      padding: 0;
      min-width: 0;
    }
  }

  &__card-bottom {
    display: flex;
    align-items: center;

    .sportbook-live-mobile-fenix__bet-items-container {
      flex: 1;
    }

    .sportbook-live-mobile-fenix__event-button {
      &--stretch {
        align-self: stretch;
      }
    }

    &--second {
      flex-direction: column;
      align-items: stretch;

      .sportbook-live-mobile-fenix__event-button {
        align-self: flex-start;
      }
    }

    .sportbook-live-mobile-fenix__event-button {
      &--stretch {
        align-self: stretch;
      }
    }
  }
}
</style>
