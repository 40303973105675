import { CSSProperties, Ref } from "vue";
import _get from "lodash/get";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { IFields } from "~~/composables/widgets/useWidgetFields";
import { useArrowsStyles } from "~~/entities/carousel/composables/styles/useArrowsStyles";
import { usePaginationStyles } from "~~/entities/carousel/composables/styles/usePaginationStyles";
import type { StateController } from "~~/composables/widgets/common/useStates";

import { useWrapperStyles } from "./styles/useWrapperStyles";
import { useGridCellStyles } from "./styles/useGridCellStyles";
import { useCardsStyles } from "./styles/useCardsStyles";
import { useTeamPlayerStyles } from "./styles/useTeamPlayerStyles";
import { useContentStyles } from "./styles/useContentStyles";
import { useEventInfoStyles } from "./styles/useEventInfoStyles";
import { useDateTimeStyles } from "./styles/useDateTimeStyles";
import { useMarketsStyles } from "./styles/useMarketsStyles";
import { useOutcomesStyles } from "./styles/useOutcomesStyles";
import { useButtonStyles } from "./styles/useButtonStyles";

export const usePromoBetsFenixWidgetStyles = (
  widget: Ref<IWidgetWithFields>,
  fields: Ref<IFields>,
  stateController: StateController
) => {
  const wrapperStyles = computed(() => {
    return useWrapperStyles(widget.value);
  });

  const gridCellStyles = computed<CSSProperties>(() => {
    return useGridCellStyles(widget.value);
  });

  const cardsStyles = computed<CSSProperties>(() => {
    const cards = fields.value.cards.field;

    const cardsDefaultOptions = cards.options;
    const cardsStatesOptions =
      _get(cards, `${stateController.getOptionsValuePath("cards")}`) || {};

    return {
      ...useCardsStyles(cardsDefaultOptions),
      ...useCardsStyles(cardsStatesOptions),
    };
  });

  const contentStyles = computed<CSSProperties>(() => {
    const contentOptions = fields.value.content?.field.options || {};
    return useContentStyles(contentOptions);
  });

  const eventInfoStyles = computed<CSSProperties>(() => {
    const eventInfoOptions = fields.value.event_info?.field.options || {};
    return useEventInfoStyles(eventInfoOptions);
  });

  const teamPlayerStyles = computed<CSSProperties>(() => {
    const teamPlayerOptions = fields.value.team_player?.field.options || {};
    const _active = Boolean(fields.value.event_info?.field.options._active);
    return useTeamPlayerStyles(teamPlayerOptions, _active).teamPlayerStyles();
  });

  const teamPlayerTextStyles = computed<CSSProperties>(() => {
    const teamPlayer = fields.value.team_player.field;

    const teamPlayerDefaultOptions = teamPlayer.options;
    const teamPlayerStatesOptions =
      _get(
        teamPlayer,
        `${stateController.getOptionsValuePath("team_player")}`
      ) || {};

    const _active = Boolean(fields.value.event_info?.field.options._active);
    return {
      ...useTeamPlayerStyles(
        teamPlayerDefaultOptions,
        _active
      ).teamPlayerTextStyles(),
      ...useTeamPlayerStyles(
        teamPlayerStatesOptions,
        _active
      ).teamPlayerTextStyles(),
    };
  });

  const teamPlayerIconContainerStyles = computed<CSSProperties>(() => {
    const teamPlayerOptions = fields.value.team_player?.field.options || {};
    const _active = Boolean(
      fields.value.event_info?.field.options._active &&
        teamPlayerOptions.icon._active
    );
    return useTeamPlayerStyles(
      teamPlayerOptions,
      _active
    ).teamPlayerIconContainerStyles();
  });

  const teamPlayerIconStyles = computed<CSSProperties>(() => {
    const teamPlayerOptions = fields.value.team_player?.field.options || {};
    const _active = Boolean(
      fields.value.event_info?.field.options._active &&
        teamPlayerOptions.icon._active
    );
    return useTeamPlayerStyles(
      teamPlayerOptions,
      _active
    ).teamPlayerIconStyles();
  });

  const dateTimeStyles = computed<CSSProperties>(() => {
    const dateTimeOptions = fields.value.date_time?.field.options || {};
    const eventInfoOptions = fields.value.event_info?.field.options || {};
    const _active = Boolean(fields.value.event_info?.field.options._active);
    return useDateTimeStyles(dateTimeOptions, _active).dateTimeStyles(
      eventInfoOptions
    );
  });

  const dateTimeElementStyles = computed<CSSProperties>(() => {
    const dateTimeOptions = fields.value.date_time?.field.options || {};
    const _active = Boolean(fields.value.event_info?.field.options._active);
    return useDateTimeStyles(dateTimeOptions, _active).dateTimeElementStyles();
  });

  const dateTimeIconStyles = computed<CSSProperties>(() => {
    const dateTimeOptions = fields.value.date_time?.field.options || {};
    const _active = Boolean(fields.value.event_info?.field.options._active);
    return useDateTimeStyles(dateTimeOptions, _active).dateTimeIconStyles();
  });

  const dateTimeTextStyles = computed<CSSProperties>(() => {
    const dateTimeOptions = fields.value.date_time?.field.options || {};
    const _active = Boolean(fields.value.event_info?.field.options._active);
    return useDateTimeStyles(dateTimeOptions, _active).dateTimeTextStyles();
  });

  const marketsStyles = computed<CSSProperties>(() => {
    const marketsOptions = fields.value.markets?.field.options || {};
    const contentOptions = fields.value.content?.field.options || {};
    return useMarketsStyles(marketsOptions, contentOptions);
  });

  const outcomesStyles = computed<CSSProperties>(() => {
    const outcomes = fields.value.outcomes.field;
    const outcomesDefaultOptions = outcomes.options;
    const outcomesStatesOptions =
      _get(outcomes, `${stateController.getOptionsValuePath("outcomes")}`) ||
      {};

    return {
      ...useOutcomesStyles(outcomesDefaultOptions).outcomesStyles(),
      ...useOutcomesStyles(outcomesStatesOptions).outcomesStyles(),
    };
  });

  const outcomesTextNameStyles = computed<CSSProperties>(() => {
    const outcomes = fields.value.outcomes.field;
    const outcomesDefaultOptions = outcomes.options;
    const outcomesStatesOptions =
      _get(outcomes, `${stateController.getOptionsValuePath("outcomes")}`) ||
      {};

    return {
      ...useOutcomesStyles(outcomesDefaultOptions).outcomesTextNameStyles(),
      ...useOutcomesStyles(outcomesStatesOptions).outcomesTextNameStyles(),
    };
  });

  const outcomesTextCoefficientStyles = computed<CSSProperties>(() => {
    const outcomes = fields.value.outcomes.field;
    const outcomesDefaultOptions = outcomes.options;
    const outcomesStatesOptions =
      _get(outcomes, `${stateController.getOptionsValuePath("outcomes")}`) ||
      {};

    return {
      ...useOutcomesStyles(
        outcomesDefaultOptions
      ).outcomesTextCoefficientStyles(),
      ...useOutcomesStyles(
        outcomesStatesOptions
      ).outcomesTextCoefficientStyles(),
    };
  });

  const buttonContainerStyles = computed<CSSProperties>(() => {
    const buttonOptions = fields.value.button?.field.options || {};
    return useButtonStyles(
      buttonOptions,
      Boolean(buttonOptions._active)
    ).buttonContainerStyles();
  });

  const buttonStyles = computed<CSSProperties>(() => {
    const button = fields.value.button.field;
    const buttonDefaultOptions = button.options;
    const buttonStatesOptions =
      _get(button, `${stateController.getOptionsValuePath("button")}`) || {};

    return {
      ...useButtonStyles(
        buttonDefaultOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonStyles(),
      ...useButtonStyles(
        buttonStatesOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonStyles(),
    };
  });

  const buttonTextStyles = computed<CSSProperties>(() => {
    const button = fields.value.button.field;
    const buttonDefaultOptions = button.options;
    const buttonStatesOptions =
      _get(button, `${stateController.getOptionsValuePath("button")}`) || {};

    return {
      ...useButtonStyles(
        buttonDefaultOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonTextStyles(),
      ...useButtonStyles(
        buttonStatesOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonTextStyles(),
    };
  });

  const buttonIconStyles = computed<CSSProperties>(() => {
    const button = fields.value.button.field;
    const buttonDefaultOptions = button.options;
    const buttonStatesOptions =
      _get(button, `${stateController.getOptionsValuePath("button")}`) || {};

    return {
      ...useButtonStyles(
        buttonDefaultOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonIconStyles(),
      ...useButtonStyles(
        buttonStatesOptions,
        Boolean(buttonDefaultOptions._active)
      ).buttonIconStyles(),
    };
  });

  const arrowsStyles = computed<CSSProperties>(() => {
    return useArrowsStyles().arrowsStyles();
  });

  const arrowsContainerStyles = computed<CSSProperties>(() => {
    const arrowsOptions = fields.value.arrows?.field.options || {};
    return useArrowsStyles(arrowsOptions).arrowsContainerStyles();
  });

  const arrowsButtonStyles = computed(() => {
    const arrows = fields.value.arrows.field;
    const arrowsDefaultOptions = arrows.options;
    const arrowsStatesOptions =
      _get(arrows, `${stateController.getOptionsValuePath("arrows")}`) || {};

    return {
      ...useArrowsStyles(arrowsDefaultOptions).arrowsButtonStyles(),
      ...useArrowsStyles(arrowsStatesOptions).arrowsButtonStyles(),
    };
  });

  const paginationStyles = computed<CSSProperties>(() => {
    const paginationOptions = fields.value.pagination?.field.options || {};
    return usePaginationStyles(paginationOptions).paginationStyles();
  });

  const paginationDotsStyles = computed<CSSProperties>(() => {
    const pagination = fields.value.pagination.field;
    const paginationDefaultOptions = pagination.options;
    const paginationStatesOptions =
      _get(
        pagination,
        `${stateController.getOptionsValuePath("pagination")}`
      ) || {};

    paginationStatesOptions.isStroke = paginationDefaultOptions.isStroke;

    return {
      ...usePaginationStyles(paginationDefaultOptions).paginationDotsStyles(),
      ...usePaginationStyles(paginationStatesOptions).paginationDotsStyles(),
    };
  });

  return {
    wrapperStyles,
    gridCellStyles,

    cardsStyles,

    contentStyles,

    eventInfoStyles,

    teamPlayerStyles,
    teamPlayerTextStyles,
    teamPlayerIconContainerStyles,
    teamPlayerIconStyles,

    dateTimeStyles,
    dateTimeElementStyles,
    dateTimeIconStyles,
    dateTimeTextStyles,

    marketsStyles,

    outcomesStyles,
    outcomesTextNameStyles,
    outcomesTextCoefficientStyles,

    buttonContainerStyles,
    buttonStyles,
    buttonTextStyles,
    buttonIconStyles,

    arrowsStyles,
    arrowsContainerStyles,
    arrowsButtonStyles,

    paginationStyles,
    paginationDotsStyles,
  };
};
