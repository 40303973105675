import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import { IWidgetOptions } from "~~/models/widgets/widget.core/widget.model";
import { ResizingType } from "~~/models/widgets/widget-controls.model";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCssClassWithContent,
  generateDefaultStylesWithStates,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateMarginStyle,
  generateFontSize,
  generateTextColor,
  generateTextAlignStyle,
  generateHeight,
} from "../helpers";
import { getFlexPosition } from "../../widget-settings";

export const generateDropdownContainerCssString =
  (items: IWidgetOptions, widget: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    const boxCssContent = generateFlex({
      flex: "flex",
      align: "center",
      justify: "flex-start",
      gap: options.icon.gap,
    });

    const iconCssContent =
      generateFontSize(options.icon.iconSize) +
      generateHeight(options.icon.iconSize);

    cssString += generateDefaultStylesWithStates(items, false);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: boxCssContent + "cursor: pointer;",
    });

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options.text._cssClass,
      content: generateDefaultStyles(options.text) + "flex: 1",
    });

    const isFill = widget.options.display.resizing === ResizingType.FILL;

    if (isFill) {
      cssString += generateCssClassWithContent({
        className: options.text._cssClass,
        content: generateTextAlignStyle(widget.options.display.alignment),
      });
    }

    cssString += generateCssClassWithContent({
      className: options.arrow._cssClass,
      content: `${generateFontSize(options.arrow.arrowSize)}${generateTextColor(
        options.arrow.textColor
      )}${generateMarginStyle({ left: options.arrow.gap })}`,
    });

    for (const state in options.states) {
      const currentState = options.states[state];

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.arrow?._cssClass,
        content: currentState.arrow?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: `:${state}`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.text?._cssClass,
        content: currentState.text,
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }

    return cssString;
  };

export const generateMenuCssString =
  (items: IWidgetOptions, item: IWidgetOptions, widget: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    const flex = generateFlex({
      flex: "flex",
      align: "stretch",
      justify: "flex-start",
      direction: "column",
      gap: item.options.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options) + "z-index: 2;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      childClassName: "dropdown-element__container-override",
      content: flex,
    });

    cssString += generateCssClassWithContent({
      className: widget.options._cssClass,
      pseudoClassName: " div[data-radix-popper-content-wrapper]",
      content: "z-index: 5 !important;",
    });

    if (widget.options.display.resizing === ResizingType.FILL) {
      cssString += generateCssClassWithContent({
        className: widget.options._cssClass,
        pseudoClassName: " [data-radix-popper-content-wrapper]",
        content: "position: absolute !important; width: 100%;",
      });
    }

    return cssString;
  };

export const generateMenuItemsCssString =
  (items: IWidgetOptions, widget: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = items;

    const isFill = widget.options.display.resizing === ResizingType.FILL;

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: isFill
        ? getFlexPosition(widget.options.display.alignment)
        : "flex-start",
      gap: options.icon.gap,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex + "cursor: pointer;",
    });

    cssString += generateDefaultStylesWithStates(items, false);

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content:
        generateFontSize(options.icon.iconSize) +
        generateHeight(options.icon.iconSize),
    });

    cssString += generateCssClassWithContent({
      className: options.text._cssClass,
      content: generateDefaultStyles(options.text),
    });

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: "color: initial !important;",
    });

    for (const state in options.states) {
      const currentState = options.states[state];

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.text?._cssClass,
        content: currentState,
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }
    return cssString;
  };

export const generateWidgetCssString =
  (widget: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = widget;

    const display = options.display;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options),
    });

    const flex = generateFlex({
      flex: "flex",
      align: display.resizing === ResizingType.HUG ? "flex-start" : "stretch",
      direction: display.resizing === ResizingType.HUG ? "row" : "column",
      justify: getFlexPosition(display.alignment),
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: flex,
    });

    return cssString;
  };

export const generateLanguagesCssString = (
  widget: IPageContentWidget
): string => {
  const dropdown_container = widget.content["dropdown_container"];
  const dropdown_menu = widget.content["dropdown_menu"];
  const menu_items = widget.content["menu_items"];

  widget.options._cssClass = generateClassName("LanguagesWidget");
  dropdown_container.options._cssClass =
    generateClassName("dropdown_container");

  dropdown_menu.options._cssClass = generateClassName("dropdown_menu");
  menu_items.options._cssClass = generateClassName("menu_items");
  menu_items.options.icon._cssClass = generateClassName("menu_items_icon");
  menu_items.options.text._cssClass = generateClassName("menu_items_text");

  dropdown_container.options.icon._cssClass = generateClassName(
    "dropdown_container_icon"
  );

  dropdown_container.options.text._cssClass = generateClassName(
    "dropdown_container_text"
  );

  dropdown_container.options.arrow._cssClass = generateClassName(
    "dropdown_container_arrow"
  );

  return pipeSync<string>(
    generateWidgetCssString(widget),
    generateDropdownContainerCssString(dropdown_container, widget),
    generateMenuCssString(dropdown_menu, menu_items, widget),
    generateMenuItemsCssString(menu_items, widget)
  )("");
};
