import type { IWidgetMigration } from "../runWidgetMigrations";

const WIDGET_VERSION = 93;

const generateNoBetsDescriptionField = () => ({
  "name": "no_bets_description_mybets",
  "type": "TextInputField",
  "value": "No bets description",
  "options": {
    "_active": true,
    "displaySettings": 1,
    "padding": {
      "top": 0,
      "bottom": 0,
      "left": 0,
      "right": 0,
    },
  },
});

const v82WidgetMigration: IWidgetMigration = widget => {
  if (widget.options?.__VERSION >= WIDGET_VERSION) {
    return widget;
  }

  widget.options.__VERSION = WIDGET_VERSION;

  if (
    widget.name === "BetslipFenixWidget" &&
    (!widget.content.no_bets_description_mybets ||
      widget.content.no_bets_description_mybets.type === "UnknownField")
  ) {
    widget.content.no_bets_description_mybets =
      generateNoBetsDescriptionField();
  }

  return widget;
};
export default v82WidgetMigration;
