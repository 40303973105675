<template>
  <WidgetField
    :widget="widget"
    :field="fields.event_info.field"
    class="top-match-fenix__event-info"
    :class="`top-match-fenix__event-info--${fields.event_info.field.options.display.layout}`"
    :style="eventInfoStyle"
  >
    <BasicDesignWrapperField
      :widget="widget"
      :field="fields.event_info.field"
      :style="containerStyles"
      class="top-match-fenix__event-info"
    >
      <WidgetField
        :widget="widget"
        :field="fields.team.field"
        :style="teamStyles"
        :class="`top-match-fenix__event-info-team--${fields.team.field.options.display.layout}`"
        class="top-match-fenix__event-info-team"
      >
        <div
          v-if="fields.team.field.options.icon._active"
          class="top-match-fenix__event-info-image"
        >
          <img
            src="/widgets/PromoBetsFenixWidget/logos/1.png"
            alt="1"
          />
        </div>

        <BasicTitleField
          :field="fields.team.field"
          :state="states.match_cards"
        >
          Team / player 1
        </BasicTitleField>
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.team.field"
        :style="teamStyles"
        :class="`top-match-fenix__event-info-team--${fields.team.field.options.display.layout}`"
        class="top-match-fenix__event-info-team"
      >
        <div
          v-if="fields.team.field.options.icon._active"
          class="top-match-fenix__event-info-image"
        >
          <img
            src="/widgets/PromoBetsFenixWidget/logos/2.png"
            alt="2"
          />
        </div>

        <BasicTitleField
          :field="fields.team.field"
          :state="states.match_cards"
        >
          Team / player 2
        </BasicTitleField>
      </WidgetField>

      <WidgetField
        :widget="widget"
        :field="fields.date.field"
        :style="dateStyles"
        class="top-match-fenix__event-info-time-container"
      >
        <BasicTitleField
          :field="fields.date.field"
          class="top-match-fenix__event-info-time"
        >
          <CommonIcon
            v-if="fields.date.field.options.icon._active"
            :name="fields.date.field.options.icon.dateValue"
            :size="fields.date.field.options.icon.size"
            :color="getColorFromHex(fields.date.field.options.icon.color)"
          />

          <span> 00-00-0000 </span>
        </BasicTitleField>

        <BasicTitleField
          :field="fields.date.field"
          class="top-match-fenix__event-info-time"
        >
          <CommonIcon
            v-if="fields.date.field.options.icon._active"
            :name="fields.date.field.options.icon.timeValue"
            :size="fields.date.field.options.icon.size"
            :color="getColorFromHex(fields.date.field.options.icon.color)"
          />

          <span> 00:00 </span>
        </BasicTitleField>
      </WidgetField>
    </BasicDesignWrapperField>
  </WidgetField>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  getColorFromHex,
  getFlexPosition,
  getResizingHeightStyles,
} from "~~/assets/utils/widget-settings";
import {
  DisplayOrientation,
  ResizingType,
} from "~~/models/widgets/widget-controls.model";
import { ElementStyle } from "~~/models/common";
import { getEventInfoVariantStyles } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetHelpers";
import { EventInfoDirection } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget/PromoBetsFenixWidgetTypes";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
}>();

const fields = useWidgetFields(props.widget);

const heightStyles = computed(() => {
  const height = fields.value.event_info.field.options.size.height;

  if (height.type === ResizingType.FILL) {
    return {
      height: "100%",
    };
  }

  return {
    height: "initial",
  };
});

const widthStyles = computed(() => {
  const width = fields.value.event_info.field.options.size.width;

  if (width.type === ResizingType.FILL) {
    return {
      alignSelf: "stretch",
    };
  }

  return {
    width: "initial",
    display: "inline-flex",
  };
});

const eventInfoStyle = computed(() => {
  return {
    ...heightStyles.value,
    ...widthStyles.value,
  };
});

const teamIconGap = computed<string>(() => {
  return getPxValueFromNumber(fields.value.team.field.options.icon.gap);
});

const teamIcoSize = computed<string>(() => {
  return getPxValueFromNumber(fields.value.team.field.options.icon.size);
});

const teamStyles = computed<ElementStyle>(() => {
  const layout = fields.value.team.field.options.icon.position;
  const height = getResizingHeightStyles(
    fields.value.team.field.options.size.height
  );

  if (layout === "top") {
    return {
      ...height,
      flexDirection: "column",
      alignItems: getFlexPosition(
        fields.value.team.field.options.display.alignment
      ),
    };
  }

  return {
    ...height,
    justifyContent: getFlexPosition(
      fields.value.team.field.options.display.alignment
    ),
  };
});

const timeGap = computed<string>(() => {
  return getPxValueFromNumber(fields.value.date.field.options.display.gap);
});

const timeTextGap = computed<string>(() => {
  return getPxValueFromNumber(fields.value.date.field.options.icon.gap);
});

const timeDirection = computed<string>(() => {
  const dir = fields.value.date.field.options.display.layout;

  if (dir === DisplayOrientation.HORIZONTAL) {
    return "row";
  }

  return "column";
});

const eventInfoStyles = computed<Record<EventInfoDirection, any>>(() => {
  return getEventInfoVariantStyles(
    fields.value.event_info.field.options.display.layout
  );
});

const dir = computed<EventInfoDirection>(() => {
  return fields.value.event_info.field.options.display.direction;
});

const containerStyles = computed<ElementStyle>(() => {
  return {
    ...eventInfoStyles.value[dir.value].eventInfoStyles,
    ...fields.value.event_info.field.options.display.layoutAlignment,
    gap: getPxValueFromNumber(
      fields.value.event_info.field.options.display.gap
    ),
  };
});

const dateStyles = computed<ElementStyle>(() => {
  return eventInfoStyles.value[dir.value].dateTimeStyles;
});
</script>

<style lang="scss">
.top-match-fenix__event-info {
  &-team {
    display: flex;
    gap: v-bind(teamIconGap);

    &--hug {
      display: inline-flex;
    }

    &--fill {
      width: 100%;
    }
  }

  &-image {
    img {
      width: v-bind(teamIcoSize);
      height: v-bind(teamIcoSize);
    }
  }

  &-time-container {
    display: inline-flex;
    gap: v-bind(timeGap);
    flex-direction: v-bind(timeDirection);
    align-items: center;
  }

  &-time {
    .basic-title-widget__content {
      display: flex;
      align-items: center;
      gap: v-bind(timeTextGap);
    }
  }
}
</style>
