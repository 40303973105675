import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { TITLE_INITIAL } from "~~/constants/configs/common/title-initial";
import { useWidgetsStore } from "~~/store/widgets";
import { useCreateFields } from "~~/composables/widgets/common/useCreateFields";
import { PAYMENT_PROVIDERS_WIDGET_INITIAL } from "~~/constants/configs/payment-providers-list-fenix/initial-constants";
import { FLEXIBLE_IMAGE_INITIAL } from "~~/constants/configs/common/flexible-image-initial";
import {
  PROMO_BUTTON_INITIAL,
  PROMO_CARD_INITIAL,
  PROMO_GROUP_LIST_INITIAL,
  PROMO_LIST_INITIAL,
  TABS_INITIAL,
} from "~~/constants/configs/promotions-list/initial-constants";
import {
  Alignment,
  DisplayOrientation,
} from "~~/models/widgets/widget-controls.model";
import {
  getDefaultBorderValue,
  getDefaultCornerRadiusValue,
} from "~~/constants/configs/common/widget-initial";

export const useInit = (widget: Ref<IWidgetWithFields>) => {
  const widgetsStore = useWidgetsStore();

  const fields = useWidgetFields(widget.value);

  const {
    addFieldInitialOptions,

    initFields,
    initWidgetOptions,
  } = useCreateFields(widget.value);

  const widgetOptions = initWidgetOptions(["fill"], {
    ...PAYMENT_PROVIDERS_WIDGET_INITIAL().options,
  });

  const init = (): void => {
    initFields();

    addFieldInitialOptions("page_title", {
      data: TITLE_INITIAL(),
      exclude: ["link"],
      values: {
        padding: {
          top: 0,
          right: 0,
          bottom: 16,
          left: 0,
        },
      },
    });

    addFieldInitialOptions("tabs", {
      data: TABS_INITIAL(),
    });

    addFieldInitialOptions("no_promo_title", {
      data: TITLE_INITIAL(),
      exclude: ["link", "margins", "padding"],
      values: {
        theme: 14,
      },
    });

    addFieldInitialOptions("promo_list", {
      data: PROMO_LIST_INITIAL(),
    });

    addFieldInitialOptions("promo_group", {
      data: PROMO_LIST_INITIAL(),
      exclude: ["fillImageColor"],
      additionalData: {
        options: {
          fill: {
            color: "",
            opacity: 100,
          },
        },
      },
    });

    addFieldInitialOptions("promo_group_title", {
      data: TITLE_INITIAL(),
      exclude: ["link", "margins"],
      additionalData: {
        options: {
          fill: {
            color: "",
            opacity: 100,
          },
          border: getDefaultBorderValue(),
          shadow: {
            fill: {
              color: null,
              opacity: 100,
            },
            x: 0,
            y: 0,
            blur: 0,
            spread: 0,
          },
          cornerRadius: getDefaultCornerRadiusValue(0),
        },
      },
    });

    addFieldInitialOptions("promo_group_list", {
      data: PROMO_GROUP_LIST_INITIAL(),
    });

    addFieldInitialOptions("promo_cards", {
      data: PROMO_CARD_INITIAL(),
    });

    addFieldInitialOptions("promo_image", {
      data: FLEXIBLE_IMAGE_INITIAL(),
    });

    addFieldInitialOptions("promo_title", {
      data: TITLE_INITIAL(),
      exclude: ["link", "margins"],
      values: {
        padding: {
          top: 16,
          right: 16,
          bottom: 4,
          left: 16,
        },
      },
    });

    addFieldInitialOptions("promo_primary_description", {
      data: TITLE_INITIAL(),
      exclude: ["link", "margins"],
      values: {
        theme: 12,
        padding: {
          top: 0,
          right: 16,
          bottom: 0,
          left: 16,
        },
      },
    });

    addFieldInitialOptions("promo_secondary_description", {
      data: TITLE_INITIAL(),
      exclude: ["link", "margins"],
      values: {
        theme: 12,
        padding: {
          top: 0,
          right: 16,
          bottom: 0,
          left: 16,
        },
      },
    });

    addFieldInitialOptions("promo_button_group", {
      data: PROMO_LIST_INITIAL(),
      exclude: ["fillImageColor"],
      additionalData: {
        options: {
          fill: {
            color: "#ffffff",
            opacity: 100,
          },
          layout: DisplayOrientation.HORIZONTAL,
          alignment: Alignment.CENTER,
        },
      },
    });

    addFieldInitialOptions("promo_button_1", {
      data: PROMO_BUTTON_INITIAL(),
    });

    addFieldInitialOptions("promo_button_2", {
      data: PROMO_BUTTON_INITIAL(),
    });

    widgetsStore.updateWidgetOptions(widget.value, widgetOptions);
  };

  return {
    fields,

    init,
  };
};
