import { CSSProperties } from "vue";

import {
  getColorValue,
  getDecorationValue,
  getFlexPosition,
  getFontFamilyStyle,
  getFontSizeStyle,
  getResizingHeightStyles,
  getResizingWidthStyles,
  getTextAlignStyle,
} from "~~/assets/utils/widget-settings";
import {
  Display,
  FlexDirection,
  TextPosition,
} from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";

import { clearObject } from "../../PromoBetsFenixWidgetHelpers";

export const useTeamPlayerStyles = (
  teamPlayerOptions: any = {},
  _active: boolean = true
) => {
  const teamPlayerStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    const width = teamPlayerOptions.layout
      ? getResizingWidthStyles(teamPlayerOptions.layout)
      : {};
    const size = teamPlayerOptions.size?.height
      ? getResizingHeightStyles(teamPlayerOptions.size?.height)
      : {};

    const styles = {
      display: Display.FLEX,
      ...width,
      flexDirection: teamPlayerOptions.icon?.position
        ? teamPlayerOptions.icon.position === TextPosition.BOTTOM
          ? FlexDirection.COLUMN
          : FlexDirection.ROW
        : undefined,
      alignItems: teamPlayerOptions.icon?.position
        ? teamPlayerOptions.icon.position === TextPosition.BOTTOM
          ? getFlexPosition(teamPlayerOptions.horizontalAlignment)
          : "center"
        : undefined,
      justifyContent: teamPlayerOptions.icon?.position
        ? teamPlayerOptions.icon.position == TextPosition.BOTTOM
          ? "center"
          : getFlexPosition(teamPlayerOptions.horizontalAlignment)
        : undefined,
      gap: getPxValueFromNumber(teamPlayerOptions.icon.gap),
      ...size,
    };

    return clearObject(styles);
  };

  const teamPlayerTextStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    const theme = teamPlayerOptions.theme
      ? getFontSizeStyle(teamPlayerOptions.theme)
      : {};
    const fontFamily = teamPlayerOptions.fontFamily
      ? getFontFamilyStyle(teamPlayerOptions.fontFamily)
      : {};
    const decoration = teamPlayerOptions.decoration
      ? getDecorationValue(teamPlayerOptions.decoration)
      : {};
    const alignment = teamPlayerOptions.alignment
      ? getTextAlignStyle(teamPlayerOptions.alignment)
      : {};

    const styles = {
      color: teamPlayerOptions.color
        ? getColorValue(teamPlayerOptions.color)
        : undefined,
      ...theme,
      ...fontFamily,
      ...decoration,
      ...alignment,
    };
    return clearObject(styles);
  };

  const teamPlayerIconContainerStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    return {
      display: Display.FLEX,
    };
  };

  const teamPlayerIconStyles = (): CSSProperties => {
    if (!_active) {
      return {};
    }

    return {
      objectFit: "contain",
      width: "1em",
      height: "1em",
      ...getFontSizeStyle(teamPlayerOptions.icon.size),
    };
  };

  return {
    teamPlayerStyles,
    teamPlayerTextStyles,
    teamPlayerIconContainerStyles,
    teamPlayerIconStyles,
  };
};
