<template>
  <div class="pages-tab">
    <div
      class="pages-tab__add-new-page-container pointer"
      @click.stop="addNewPage"
    >
      <CommonIcon name="ant-design:plus-outlined" />
      Add new page
    </div>

    <div class="pages-tab__search-container">
      <a-input
        v-model:value="searchValue"
        placeholder="Search page"
        class="pages-tab__search-input ant-input--has-suffix-clickable"
      >
        <template #suffix>
          <CommonIcon name="ant-design:search-outlined" />
        </template>
      </a-input>
    </div>

    <div class="pages-tab__list">
      <PagesList
        :items="searchPages"
        :search-value="searchValue"
        @copy="handleCopyClick"
        @select-page="$emit('select-page')"
      >
        <template #list:prepend>
          <CreateNewPage
            v-if="isCreatingNewPage"
            ref="createNewPageComponent"
            :copy-page-data="copyPageData"
            @close="copyPageData = null"
          />
        </template>
      </PagesList>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { uuidv7 } from "uuidv7";

import { usePagesStore } from "~~/store/pages";
import { useListSearch } from "~~/composables/widgets-config/useListSearch";
import createNewPage from "~~/components/widgets/CreateNewPage.vue";
import { IPage } from "~~/models/stores/pages-store.model";
import {
  IWidgetWithFields,
  PageIdType,
} from "~~/models/widgets/widget.core/widget.model";
import { handleActionSuccess } from "~~/helpers/handle-action-success";
import {
  COPYING_PAGE_ERROR,
  COPYING_PAGE_SUCCESS,
} from "~~/constants/notification-messages";
import { useIframePostMessage } from "~~/composables/useIframePostMessage";
import { handleActionError } from "~~/helpers/handle-action-error";
import { useGridConfig } from "~~/store/grid";
import { deepCopy } from "~~/assets/utils";
import { Grid, ICell } from "~~/models/grid.interface";

defineEmits<{
  (e: "select-page"): void;
}>();

const pagesStore = usePagesStore();
const { widgetsGridLayout } = storeToRefs(useGridConfig());

const { searchValue, getSearchHighlightedItem } = useListSearch();

const { pages, isCreatingNewPage, selectedPageId } = storeToRefs(pagesStore);

const copyPageData = ref<{ id: PageIdType; name: string } | null>(null);

const createNewPageComponent = ref<InstanceType<typeof createNewPage>>();

const searchPages = computed<IPage[]>(() => {
  return getSearchHighlightedItem(pages.value);
});

const generateNewWidgetsIds = (
  widgets: IWidgetWithFields[],
  parentId?: string
): IWidgetWithFields[] => {
  return widgets.map(widget => {
    const newWidget = deepCopy(widget);
    const newId = uuidv7();
    newWidget.id = newId;

    if (newWidget.options._children) {
      newWidget.options._children = generateNewWidgetsIds(
        newWidget.options._children,
        newId
      );
    }

    if (parentId) {
      newWidget.parentId = parentId;
    }

    return newWidget;
  });
};

const generateCellsData = (cells: ICell[]): ICell[] => {
  return cells.map(cell => {
    if (cell.children) {
      cell.children = generateCellsData(cell.children);
    }

    if (cell.widgets) {
      cell.widgets = generateNewWidgetsIds(cell.widgets);
    }

    return cell;
  });
};

const generatePageCopyData = () => {
  const copy = deepCopy(widgetsGridLayout.value);

  for (const screen in copy) {
    const cells = copy[screen as keyof Grid];

    copy[screen as keyof Grid] = generateCellsData(cells as ICell[]);
  }

  return copy;
};

const handleCopyClick = (data: { id: PageIdType; name: string }): void => {
  pagesStore.setIsWidgetsTemplateLoading(true);
  /* 
    Generate widgets grid with new Ids
  */
  const newWidgetsGrid = generatePageCopyData();

  pagesStore
    .copyPage(data.id, newWidgetsGrid)
    .then(() => {
      handleActionSuccess(COPYING_PAGE_SUCCESS, () => {
        pagesStore.fetchPageContent();
      });

      useIframePostMessage().sendFullRedirect(
        `/pages/${selectedPageId.value}/setup`
      );
    })
    .catch(error => {
      pagesStore.setIsWidgetsTemplateLoading(false);

      handleActionError(COPYING_PAGE_ERROR, error);
    });
};

const addNewPage = (): void => {
  if (isCreatingNewPage.value) copyPageData.value = null;
  pagesStore.setIsCreatingNewPage(true);
};
</script>

<style lang="scss">
.pages-tab {
  width: $propertybar-width;

  &__add-new-page-container {
    @include flex(center, flex-start, 8px);
    color: $c-primary-base;
    padding: $space-m $space-m 0 $space-m;

    &:hover {
      color: $c-primary-base-hover;
    }
  }

  &__search-container {
    padding: 0 $space-m;
  }

  &__search-input {
    margin: $space-m 0;
  }

  &__list {
    overflow: auto;
    height: calc(100vh - 255px);
    width: 100%;
    padding: 0 $space-m;
  }

  .ant-input-affix-wrapper {
    border-radius: 6px;
  }
}
</style>
