import type { Ref } from "vue";

import { ResizingType, State } from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { TabItem, generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";

import { usePromoGroupControls } from "../common/usePromoGroupControls";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Page Title", "page_title", "title"),
    generateDropdownItem("Tabs", "tabs", "tabs"),
    generateDropdownItem("Promo list", "promo_list", "promo_list"),
    generateDropdownItem("No promo title", "no_promo_title", "promo_list"),
    generateDropdownItem("Promo group", "promo_group", "promo_group"),
    generateDropdownItem("Group title", "promo_group_title", "promo_group"),
    generateDropdownItem("Promo group list", "promo_group_list", "promo_group"),
    generateDropdownItem("Promo cards", "promo_cards", "promo_group"),
    generateDropdownItem("Image", "promo_image", "promo_group"),
    generateDropdownItem("Title", "promo_title", "promo_group"),
    generateDropdownItem(
      "Primary Description",
      "promo_primary_description",
      "promo_group"
    ),
    generateDropdownItem(
      "Secondary Description",
      "promo_secondary_description",
      "promo_group"
    ),
    generateDropdownItem("Button group", "promo_button_group", "promo_group"),
    generateDropdownItem("Button", "promo_button_1", "promo_group"),
    generateDropdownItem("Button", "promo_button_2", "promo_group"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    tabs: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    promo_list: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    promo_group: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    promo_group_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    promo_group_list: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    promo_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    promo_button_group: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    promo_button_1: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    promo_button_2: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  } as Record<string, TabItem[]>;

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const configItems = computed<ConfigTabItem[]>(() => [
    {
      label: "Page Title",
      value: "page_title",
      isActiveValueSource: fields.value.page_title.field.options,
    },
    {
      label: "Tabs",
      value: "tabs",
    },
    {
      label: "Promo List",
      value: "promo_list",
    },
  ]);

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.widget,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.all_groups,
              value: State.PROMOTIONS_ALL_GROUPS,
            },
            {
              label: STATE_KEY_LABELS.one_group,
              value: State.PROMOTIONS_ONE_GROUP,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.widget = value;
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: configItems.value,
        },
      },
    ];
  });

  const pageTitleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter text",
        },
      },
      typography({ source: selectedField.value! }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const promoListDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigFillImageColor",
        valuePath: "options.fillImageColor",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const PROMO_EXIST_CONTROLS = [
    {
      componentPath: "content/ConfigNumberInput",
      valuePath: "options.gap",
      valueSource: fields.value.promo_list.field,
      options: {
        label: "Distance between cards",
        suffixContent: "px",
      },
    },
    {
      componentPath: "form/ConfigTabs",
      options: {
        items: [
          {
            label: "Promo Group",
            value: "promo_group",
          },
        ],
      },
    },
  ];

  const PROMO_NO_PROMO_CONTROLS = [
    {
      section: generateDesignSectionName("Description"),
      controls: [
        {
          componentPath: "content/ConfigTextInput",
          valuePath: "value",
          valueSource: fields.value.no_promo_title.field,
          options: {
            placeholder: "None",
            showOptions: false,
          },
        },
      ],
    },
    typography({ source: fields.value.no_promo_title.field }),
  ];

  const promoListContentControls = computed(() => {
    const result: Array<Record<string, any>> = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value.promo_list,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.promo_exist,
              value: State.PROMO_EXIST,
            },
            {
              label: STATE_KEY_LABELS.promo_not_exist,
              value: State.PROMO_NOT_EXIST,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.promo_list = value;
        },
      },
    ];

    if (states.value.promo_list === State.PROMO_EXIST) {
      result.push(...PROMO_EXIST_CONTROLS);

      return result;
    }

    if (states.value.promo_list === State.PROMO_NOT_EXIST) {
      result.push(...PROMO_NO_PROMO_CONTROLS);

      return result;
    }

    return result;
  });

  const promoGroupDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const promoGroupContentControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigNumberInput",
        valuePath: "options.gap",
        valueSource: selectedField.value,
        options: {
          label: "Distance between elements",
          suffixContent: "px",
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              label: "Promo Group Title",
              value: "promo_group_title",
              isActiveValueSource: fields.value.promo_group_title.field.options,
            },
            {
              label: "Promo Group List",
              value: "promo_group_list",
            },
          ],
        },
      },
    ];
  });

  const promoGroupTitleDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const promoGroupTitleContentControls = computed(() => {
    return [typography({ source: selectedField.value! })];
  });

  const {
    promoGroupListDesignControls,
    promoGroupListContentControls,

    promoCardsDesignControls,
    promoCardsContentControls,
    promoCardsStatesControls,

    promoImageControls,

    promoTitleControls,
    promoDescriptionControls,

    promoButtonGroupDesignControls,
    promoButtonGroupContentControls,
    promoButtonDesignControls,
    promoButtonContentControls,
    promoButtonStatesControls,
  } = usePromoGroupControls(widget, states);

  const tabsDesignControls = computed(() => {
    return [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigSpacingInputs",
        valuePath: "options.spacing",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Spacing",
        },
      },
    ];
  });

  const tabsContentControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigRadioTextElements",
        valuePath: "options.display.resizing",
        valueSource: selectedField.value,
        className: "group-control-element",
        options: {
          isBold: true,
          label: "Display mode",
          noTextWrap: true,
          items: [
            {
              label: "Fill container",
              value: ResizingType.FILL,
            },
            {
              label: "Hug content",
              value: ResizingType.HUG,
            },
          ],
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.display.alignment",
        valueSource: selectedField.value,
        visible:
          selectedField.value?.options.display.resizing === ResizingType.HUG,
        options: {
          label: "Alignment",
          isTextContentIcons: false,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.itemBorder",
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Item border",
          isBold: true,
        },
      },
      {
        componentPath: "content/ConfigNumberInput",
        valuePath: "options.gap",
        valueSource: selectedField.value,
        options: {
          label: "Distance between tabs",
          suffixContent: "px",
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.text.color",
        valueSource: selectedField.value,
        options: {
          placeholder: "Enter text",
          label: "Text color",
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "ConfigThemeSelect",
        valuePath: "options.text.theme",
        valueSource: selectedField.value,
        options: {
          label: "Font size",
        },
      },
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.text.fontFamily",
        valueSource: selectedField.value,
        options: {
          placeholder: "Select",
          label: "Font family",
        },
      },
      {
        componentPath: "ConfigDecoration",
        valuePath: "options.text.decoration",
        valueSource: selectedField.value,
        options: {
          placeholder: "Select",
          label: "Decoration",
        },
      },
      {
        componentPath: "ConfigAlignment",
        valuePath: "options.text.alignment",
        valueSource: selectedField.value,
        options: {
          label: "Alignment",
        },
      },
    ];
  });

  const tabsStateValuePath = computed<string>(() => {
    if (states.value[selectedField.value!.name] === State.DEFAULT) {
      return "options";
    }

    return `options.states.${states.value[selectedField.value!.name]}`;
  });

  const tabsStatesControls = computed(() => {
    return [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: states.value[selectedField.value!.name],
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          states.value.tabs = value;
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${tabsStateValuePath.value}.fill`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${tabsStateValuePath.value}.border`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${tabsStateValuePath.value}.shadow`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${tabsStateValuePath.value}.text.color`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Text color",
          isBold: true,
          type: ColorPickerType.TEXT,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${tabsStateValuePath.value}.itemBorder`,
        valueSource: selectedField.value,
        options: {
          placeholder: "None",
          label: "Item border",
          isBold: true,
        },
      },
    ];
  });
  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    pageTitleControls,

    promoListDesignControls,
    promoListContentControls,

    promoGroupDesignControls,
    promoGroupContentControls,
    promoGroupTitleDesignControls,
    promoGroupTitleContentControls,
    promoGroupListDesignControls,
    promoGroupListContentControls,

    promoCardsDesignControls,
    promoCardsContentControls,
    promoCardsStatesControls,

    promoImageControls,

    promoTitleControls,
    promoDescriptionControls,

    promoButtonGroupDesignControls,
    promoButtonGroupContentControls,
    promoButtonDesignControls,
    promoButtonContentControls,
    promoButtonStatesControls,

    tabsDesignControls,
    tabsContentControls,
    tabsStatesControls,
  };
};
