import { createApp } from "vue";
import { createPinia } from "pinia";
import notifications from "@kyvg/vue3-notification";
import draggable from "vuedraggable";
import { createI18n, useI18n } from "vue-i18n";

import App from "./App.vue";
import router from "./router";
import VDraggable from "./directives/vDraggable";
import vClickOutside from "./directives/vClickOutside";
import vTranslate from "./directives/vTranslate";
import VNumber from "./directives/VNumber";
import CustomIcons from "./plugins/custom-icons";
import ReleaseVersion from "./plugins/release-version";

import "./assets/styles/styles.scss";

const pinia = createPinia();
const app = createApp(App, {
  setup() {
    const { t } = useI18n();
    return { t };
  },
});

const i18n = createI18n({
  legacy: false,
  silentFallbackWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  silentTranslationWarn: true,
});

app
  .directive("draggable", VDraggable)
  .directive("click-outside", vClickOutside)
  .directive("translate", vTranslate)
  .directive("number", VNumber)
  .use(pinia)
  .use(router)
  .use(notifications)
  .use(CustomIcons)
  .use(ReleaseVersion)
  .use(i18n)
  .component("DraggableList", draggable)
  .mount("#app");
