<script lang="ts" setup>
import { VITE_DASHBOARD_BASE_URL } from "~~/constants";
import { useLanguagesStore } from "~~/store/languages";
import { ILanguage } from "~~/models/widgets/languages.interface";
import { useContentPageReplace } from "~~/components/config/composables/useContentPageReplace";
import { useGridConfig } from "~~/store/grid";
import { useContentAllPagesReplace } from "~~/components/config/composables/useContentAllPagesReplace";
import { handleActionSuccess } from "~~/helpers/handle-action-success";
import { handleActionError } from "~~/helpers/handle-action-error";
import { SUCCESSFULLY_UPDATED } from "~~/constants/notification-messages";
import { useMetaStore } from "~~/store/meta";

const emit = defineEmits<{
  (e: "close"): void;
}>();

const languagesStore = useLanguagesStore();
const gridStore = useGridConfig();
const metaStore = useMetaStore();

const { applyChangesToPage } = useContentPageReplace();
const {
  findAllPagesSimilarities,
  applyChangesToAllPages,
  similarPages,
  listLoading,
  pagesWithErrors,
} = useContentAllPagesReplace();

const ignoredPagesWithSimilarWidgets = computed<string[]>(() => {
  return Object.keys(pagesWithErrors.value).filter(pageId => {
    return similarPages.value.find(page => page.id === pageId);
  });
});

const mode = ref("single");
const loading = ref<boolean>(false);
const isWorkingTab = ref<boolean>(false);
const selected = ref<string[]>([]);

const onSelectChange = (changableRowKeys: string[]): void => {
  selected.value = changableRowKeys;
};

const rowSelection = computed<any>(() => {
  return {
    selectedRowKeys: unref(selected),
    onChange: onSelectChange,
    hideDefaultSelections: true,
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };
});
type SearchLangValue = {
  search: string;
  change: string;
  searchValid: string | boolean;
  changeValid: string | boolean;
};
const langsSearchData = ref<Record<string, SearchLangValue>>({});

const cols = [
  {
    key: "lang",
    dataIndex: "lang",
    title: "Language",
  },
  {
    key: "search",
    dataIndex: "search",
    title: "Search",
  },
  {
    key: "change",
    dataIndex: "change",
    title: "Change to",
  },
];

const similarTableColumns = [
  {
    title: "Page",
    dataIndex: "name",
    key: "name",
  },
];

const availableLangs = computed<ILanguage>(() => {
  return languagesStore.availableLanguages;
});

const tableData = computed(() => {
  return Object.keys(langsSearchData.value).map(langName => {
    const langData = availableLangs.value[langName];

    return {
      lang: langData.name,
      langKey: langName,
    };
  });
});

const handleFetchAllClick = async () => {
  if (!isWorkingTab.value) {
    const globalStatus = await metaStore.fetchGlobalStatus();
    metaStore.setGlobalProcessState(globalStatus);

    if (metaStore.globalProcessIsRunning) {
      loading.value = false;
      handleActionError("Global proccess is busy");

      return false;
    }

    metaStore.setGlobalProcessState(true);
    metaStore.updateGlobalStatus(true);
    metaStore.runUpdateWorkingStatusInterval();
    isWorkingTab.value = true;
  }

  const similar = await findAllPagesSimilarities(langsSearchData.value);

  selected.value = similar.map(page => page.id);
};

const handleApplyAllClick = async () => {
  loading.value = true;

  try {
    const selectedPages = similarPages.value.filter(page =>
      selected.value.includes(page.id)
    );

    await applyChangesToAllPages(langsSearchData.value, selectedPages);
    handleActionSuccess(SUCCESSFULLY_UPDATED);
    emit("close");
  } catch (e) {
    handleActionError("Something went wrong");
  }

  loading.value = false;
};

const handleApplyToPageClick = async () => {
  loading.value = true;

  const widgetsCount = applyChangesToPage(
    gridStore.pageWidgets,
    langsSearchData.value
  );

  handleActionSuccess(
    `Successfully updated. ${widgetsCount} widgets were affected`
  );
  emit("close");

  loading.value = false;
};

const validateValue = (value?: string): string | boolean => {
  const edited = (value || "").trim();

  if (!edited) {
    return true;
  }

  if (!edited.startsWith("/")) {
    return 'Value should start with "/"';
  }

  if (edited.includes(":")) {
    return "Value should not include dynamic params";
  }

  return true;
};

const handleInputUpdate = (
  value: string,
  key: "search" | "change",
  langKey: string
): void => {
  langsSearchData.value[langKey][key] = value;
  langsSearchData.value[langKey][`${key}Valid`] = validateValue(value);
};

const submitDisabled = computed<boolean>(() => {
  const withErrors = Object.keys(langsSearchData.value).filter(langKey => {
    if (typeof langsSearchData.value[langKey].changeValid === "string") {
      return true;
    }

    if (typeof langsSearchData.value[langKey].searchValid === "string") {
      return true;
    }

    return false;
  });

  return Boolean(withErrors.length);
});

onMounted(() => {
  langsSearchData.value = Object.keys(availableLangs.value).reduce(
    (result, langName) => {
      return {
        ...result,
        [langName]: {
          search: "",
          change: "",
          searchValid: true,
          changeValid: true,
        },
      };
    },
    {}
  );
});

onBeforeUnmount(() => {
  if (isWorkingTab.value) {
    metaStore.updateGlobalStatus(false);

    metaStore.stopWorkingStatusInterval();
  }
});
</script>

<template>
  <a-typography-title :level="5">Change url:</a-typography-title>
  <a-radio-group
    v-model:value="mode"
    :style="{ marginBottom: '8px' }"
  >
    <a-radio-button value="single">Change on current page</a-radio-button>
    <a-radio-button value="all">Change on all pages</a-radio-button>
  </a-radio-group>

  <a-table
    :data-source="tableData"
    :columns="cols"
    :pagination="false"
    row-key="key"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'search'">
        <a-form-item
          :validate-status="
            langsSearchData[record.langKey].searchValid === true ? '' : 'error'
          "
          :help="langsSearchData[record.langKey].searchValid"
        >
          <a-input
            :value="langsSearchData[record.langKey].search"
            :status="
              langsSearchData[record.langKey].searchValid === true
                ? ''
                : 'error'
            "
            class="content-tools__input"
            placeholder="Search"
            @update:value="handleInputUpdate($event, 'search', record.langKey)"
          />
        </a-form-item>
      </template>

      <template v-if="column.dataIndex === 'change'">
        <a-form-item
          :validate-status="
            langsSearchData[record.langKey].changeValid === true ? '' : 'error'
          "
          :help="langsSearchData[record.langKey].changeValid"
        >
          <a-input
            :value="langsSearchData[record.langKey].change"
            :status="
              langsSearchData[record.langKey].changeValid === true
                ? ''
                : 'error'
            "
            placeholder="Change to"
            class="content-tools__input"
            @update:value="handleInputUpdate($event, 'change', record.langKey)"
          />
        </a-form-item>
      </template>
    </template>
  </a-table>

  <div
    v-if="mode === 'all' && similarPages.length"
    class="m-t-8"
  >
    <a-typography-title :level="5"
      >Similar pages list({{ similarPages.length }}):</a-typography-title
    >

    <a-table
      :data-source="similarPages"
      :columns="similarTableColumns"
      :row-selection="rowSelection"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <a
            :href="`${VITE_DASHBOARD_BASE_URL()}/pages/${record.id}/setup`"
            target="_blank"
          >
            {{ record.name }}
          </a>
        </template>
      </template>
    </a-table>
  </div>

  <div
    v-if="ignoredPagesWithSimilarWidgets.length"
    class="m-t-16"
  >
    <p class="m-b-8">These pages were ignored. Please update them manually</p>
    <div>
      <p
        v-for="pageId in ignoredPagesWithSimilarWidgets"
        :key="pageId"
        class="m-b-0"
      >
        <a
          :href="`${VITE_DASHBOARD_BASE_URL()}/pages/${pageId}/setup`"
          target="_blank"
        >
          {{ pagesWithErrors[pageId].route }}
        </a>
      </p>
    </div>
  </div>

  <div class="m-t-16 content-tools__apply">
    <a-button
      v-if="mode === 'single'"
      :disabled="submitDisabled"
      type="primary"
      @click="handleApplyToPageClick"
    >
      Apply changes
    </a-button>
    <template v-else>
      <a-button
        type="primary"
        class="m-r-4"
        :disabled="submitDisabled"
        :loading="listLoading"
        @click="handleFetchAllClick"
      >
        Fetch pages
      </a-button>

      <a-button
        type="secondary"
        :disabled="!similarPages.length || submitDisabled"
        :loading="loading"
        @click="handleApplyAllClick"
      >
        Apply changes to selected pages
      </a-button>
    </template>
  </div>
</template>

<style lang="scss">
.content-tools {
  &__apply {
    display: flex;
    justify-content: flex-end;
  }

  &__input {
    &[status="error"] {
      border-color: $c-icon-font-red;
    }
  }

  &__error {
    font-size: 14px;
    color: $c-icon-font-red;
  }
}
</style>
