<template>
  <div>
    <!-- 
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DEFAULT_DROPDOWN_ITEMS"
        :tabs="TABS"
        class="container-widget__settings"
      >
        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #arrows-design>
          <ControlsList
            :widget="widget"
            :controls="arrowsDesignControls"
          />
        </template>

        <template #arrows-content>
          <ControlsList
            :widget="widget"
            :controls="arrowsContentControls"
          />
        </template>

        <template #arrows-states>
          <ControlsList
            :widget="widget"
            :controls="arrowsStatesControls"
          />
        </template>

        <template #pagination-design>
          <ControlsList
            :widget="widget"
            :controls="paginationDesignControls"
          />
        </template>

        <template #pagination-content>
          <ControlsList
            :widget="widget"
            :controls="paginationContentControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { useConfig } from "~~/composables/widgets/container-widget/useContainerWidgetConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { State } from "~~/models/widgets/widget-controls.model";
import type { StateController } from "~~/composables/widgets/common/useStates";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
  stateController: StateController;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsStore);

const {
  TABS,
  DEFAULT_DROPDOWN_ITEMS,

  widgetDesignControls,
  widgetContentControls,

  arrowsDesignControls,
  arrowsContentControls,
  arrowsStatesControls,

  paginationDesignControls,
  paginationContentControls,
} = useConfig(
  ref(props.widget),
  toRef(props.states),
  emit,
  props.stateController
);
</script>

<style lang="scss">
.container-widget {
  &__layout-config {
    .common-icon {
      font-size: 14px;
    }
  }

  &__arrows-settings {
    .config-radio-icons__icon-wrapper {
      padding: 8px;
      flex: 1;
    }

    .config-radio-icons__icon {
      background-color: $c-light-blue;
      color: $c-primary-base-hover;
      border-radius: 4px;
      width: 100%;
      justify-content: center;
      font-size: 16px;
      padding: 8px 0;
    }
  }

  &__settings {
    .config-dimension-input {
      margin-top: 8px;
      .config-radio-text {
        margin-bottom: 0;
      }

      .config-radio-text__elements {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
