import { State } from "~~/models/widgets/widget-controls.model";

type Args = {
  states: Ref<Record<string, State | string>>;
  defaultStates: Record<string, State | string>;
  resetConditionHandler?: (stateName: string) => boolean;
};

export type StateController = {
  states: Record<string, State | string>;
  resetStates: () => void;
  handleStateUpdate: (stateDetails: { state: string; value: State }) => void;
  getOptionsValuePath: (fieldName?: string, stateName?: string) => string;
};

export const useStates = ({
  states,
  defaultStates,
  resetConditionHandler,
}: Args) => {
  const resetStates = (): void => {
    for (const key in states.value) {
      if (resetConditionHandler) {
        const result = resetConditionHandler(key);

        if (!result) {
          continue;
        }
      }
      states.value[key] = defaultStates[key];
    }
  };

  const handleStateUpdate = (stateDetails: {
    state: string;
    value: State;
  }): void => {
    states.value[stateDetails.state] = stateDetails.value;
  };

  const stateController = reactive<StateController>({
    states: states.value,
    resetStates,
    handleStateUpdate,
    getOptionsValuePath: (fieldName?: string, stateName?: string): string => {
      if (!fieldName && stateName) {
        if (stateName === State.DEFAULT) {
          return "options";
        }
        return `options.states.${stateName}`;
      }

      if (!fieldName || states.value[fieldName] === State.DEFAULT) {
        return "options";
      }
      return `options.states.${states.value[fieldName]}`;
    },
  });

  return {
    resetStates,
    handleStateUpdate,
    stateController,
  };
};
