import { CSSProperties } from "vue";
import cloneDeep from "lodash/cloneDeep";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";

import { Display } from "~~/models/widgets/widget-controls.model";

import {
  EventInfoDirection,
  EventInfoVariant,
} from "./PromoBetsFenixWidgetTypes";

type VariantProperties = {
  eventInfoStyles: CSSProperties;
  dateTimeStyles: CSSProperties;
};
export const getEventInfoVariantStyles = (
  variant: EventInfoVariant
): {
  [EventInfoDirection.Default]: VariantProperties;
  [EventInfoDirection.Reverse]: VariantProperties;
} => {
  const stylesByVariantDefaultDirection: Record<string, VariantProperties> = {
    [EventInfoVariant.Variant1]: {
      eventInfoStyles: {
        display: Display.GRID,
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "auto auto",
      },
      dateTimeStyles: {
        gridColumn: "span 2",
      },
    },
    [EventInfoVariant.Variant2]: {
      eventInfoStyles: {
        display: Display.GRID,
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto auto",
      },
      dateTimeStyles: {},
    },
    [EventInfoVariant.Variant3]: {
      eventInfoStyles: {
        display: Display.GRID,
        gridTemplateColumns: "1fr 1fr auto",
        gridTemplateRows: "auto",
      },
      dateTimeStyles: {},
    },
    [EventInfoVariant.Variant4]: {
      eventInfoStyles: {
        display: Display.GRID,
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "auto auto",
      },
      dateTimeStyles: {
        gridColumn: "2",
        gridRow: "1 / 3",
      },
    },
    [EventInfoVariant.Variant5]: {
      eventInfoStyles: {
        display: Display.GRID,
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "auto",
      },
      dateTimeStyles: {
        gridColumn: "2",
        gridRow: "1",
      },
    },
  };

  const stylesByVariantReverseDirection = cloneDeep(
    stylesByVariantDefaultDirection
  );

  stylesByVariantReverseDirection[
    EventInfoVariant.Variant1
  ].dateTimeStyles.gridRow = "1";
  stylesByVariantReverseDirection[
    EventInfoVariant.Variant2
  ].dateTimeStyles.gridRow = "1";
  stylesByVariantReverseDirection[
    EventInfoVariant.Variant3
  ].eventInfoStyles.gridTemplateColumns = "auto 1fr 1fr";
  stylesByVariantReverseDirection[EventInfoVariant.Variant3].dateTimeStyles = {
    gridRow: "1",
    gridColumn: "1",
  };
  stylesByVariantReverseDirection[
    EventInfoVariant.Variant4
  ].dateTimeStyles.gridColumn = "1";

  return {
    [EventInfoDirection.Default]: stylesByVariantDefaultDirection[variant],
    [EventInfoDirection.Reverse]: stylesByVariantReverseDirection[variant],
  };
};

export const clearObject = (object: any) => {
  return omitBy(object, isUndefined);
};
