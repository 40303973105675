import { breakpoints as resp } from "~~/assets/utils/scss-variables";

export enum Breakpoint {
  MOBILE = "mob",
  TABLET = "tab",
  DESKTOP = "desktop",
  MOBILE_APP = "mob-app",
  TG_BOT = "bot",
}

export interface IBreakpointOption {
  screenSize: number;
  label: string;
  icon: {
    name: string;
    isCustom: boolean;
  };
}

export const breakpoints: { [key in Breakpoint]: IBreakpointOption } = {
  [Breakpoint.TG_BOT]: {
    screenSize: resp.sm,
    label: "Telegram bot",
    icon: {
      name: "custom:telegram",
      isCustom: false,
    },
  },
  [Breakpoint.MOBILE_APP]: {
    screenSize: resp.sm,
    label: "Mobile app",
    icon: {
      name: "custom:mobile-app",
      isCustom: false,
    },
  },

  [Breakpoint.MOBILE]: {
    screenSize: resp.sm,
    label: "Mobile",
    icon: {
      name: "ant-design:mobile-outlined",
      isCustom: false,
    },
  },
  [Breakpoint.TABLET]: {
    screenSize: resp.md,
    label: "Tablet",
    icon: {
      name: "fluent:tablet-24-regular",
      isCustom: true,
    },
  },
  [Breakpoint.DESKTOP]: {
    screenSize: resp.lg,
    label: "Desktop",
    icon: {
      name: "ant-design:desktop-outlined",
      isCustom: false,
    },
  },
};
