import type { Ref } from "vue";

import { State } from "~~/models/widgets/widget-controls.model";
import {
  ColorPickerType,
  IWidgetField,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { ConfigTabItem } from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetsStore } from "~~/store/widgets";
import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { TabItem, generateTabItem } from "~~/helpers/configs/generate-tab-item";
import { STATE_KEY_LABELS } from "~~/constants/widget-details/state-key-labels";
import { getInitialContentControlsList } from "~~/constants/configs/common/widget-content-config";
import { generateDesignSectionName } from "~~/helpers/configs/generate-section-name";
import { getInitialDesignList } from "~~/constants/configs/common/design-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";

export const useConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, State>>
) => {
  const widgetSettingsStore = useWidgetSettingsStore();
  const widgetsStore = useWidgetsStore();
  const fields = useWidgetFields(widget.value as IWidgetWithFields);

  const { selectedField } = storeToRefs(widgetSettingsStore);

  const DROPDOWN_ITEMS = [
    generateDropdownItem("Title", "title"),
    generateDropdownItem("Category items", "menu_level_1"),
    generateDropdownItem("Subcategory items", "menu_level_2"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    menu_level_1: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    menu_level_2: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
  } as Record<string, TabItem[]>;

  const widgetDesignControls = computed(() => {
    return getInitialDesignList({
      source: widget.value,
      except: ["ConfigColorPickerInput"],
      elements: [
        [
          0,
          {
            componentPath: "ConfigFillImageColor",
            valuePath: "options.fillImageColor",
            valueSource: widget.value,
            options: {
              placeholder: "None",
              label: "Fill",
              isBold: true,
            },
          },
        ],
      ],
    });
  });

  const configItems = computed<ConfigTabItem[]>(() => {
    return [
      {
        label: "Category items",
        value: "menu_level_1",
      },
      {
        label: "Subcategory items",
        value: "menu_level_2",
        isActiveValueSource: fields.value.menu_level_2.field.options,
      },
    ];
  });

  const widgetContentControls = computed(() => {
    return [
      ...getInitialContentControlsList({
        source: widget.value,
      }),
      {
        componentPath: "content/ConfigTextInputWithToggle",
        valuePath: "value",
        valueSource: fields.value.title?.field,
        visible: !!fields.value.title,
        options: {
          placeholder: "Enter button text",
          label: "Title",
          allowDisable: true,
          active: fields.value.title?.field.options._active,
          showOptions: fields.value.title?.field.options._active,
          "onUpdate:active": (value: boolean) => {
            widgetsStore.updateFieldOptionValue(
              value,
              "_active",
              fields.value.title?.field.id
            );
          },
          "onShow-options": () => {
            widgetSettingsStore.setActiveElement(
              widget.value,
              fields.value.title?.field
            );
          },
        },
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: configItems.value,
        },
      },
    ];
  });

  const titleControls = computed(() => {
    return [
      {
        componentPath: "content/ConfigTextInput",
        valuePath: "value",
        valueSource: fields.value.title.field,
        options: {
          placeholder: "Enter text",
        },
      },
      typography({ source: fields.value.title.field! }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.title.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  /*
    menu_level_1
    menu_level_2
  */

  const currentField = computed<IWidgetField | null>(() => {
    if (!selectedField.value) {
      return null;
    }

    return fields.value?.[selectedField.value.name].field;
  });

  const menuItemContentIconControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateDesignSectionName("Icon"),
        toggleable: true,
        valueSource: currentField.value?.options.itemIcon,
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.itemIcon.color",
            valueSource: currentField.value,
            visible: currentField.value?.options.itemIcon._active,
            options: {
              placeholder: "None",
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.itemIcon.size",
            className: "p-l-16 p-r-16 p-b-16 p-t-16",
            valueSource: currentField.value,
            visible: currentField.value?.options.itemIcon._active,
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 64,
            },
          },
        ],
      },
    ];
  });

  const showMenuItemsArrowSettings = computed<boolean>(() => {
    return !(
      currentField.value?.name === "menu_level_2" ||
      currentField.value?.name === "menu_level_3"
    );
  });

  const menuItemContentArrowControls = computed<ControlProp[]>(() => {
    return [
      {
        componentPath: "ConfigArrowSettings",
        valuePath: "options.arrowSettings",
        valueSource: currentField.value,
        visible: showMenuItemsArrowSettings.value,
        options: {
          label: "Fill",
          isBold: true,
          allowDisable: true,
          showOptions: false,
        },
      },
    ];
  });

  const menuItemDesignControls = computed(() => {
    const result = [
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: "options.fill",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Fill",
          isBold: true,
          type: ColorPickerType.BACKGROUND,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: "options.border",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: "options.shadow",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Shadow",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigCornerRadius",
        valuePath: "options.cornerRadius",
        valueSource: currentField.value,
        options: {
          placeholder: "None",
          label: "Corner radius",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: currentField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];

    return result;
  });

  const menuItemContentDisplayModeControls = computed(() => {
    const result: Array<Record<string, any>> = [
      {
        section: generateDesignSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.displayMode",
            valueSource: currentField.value,
            options: {
              label: "Items layout",
              items: [
                {
                  label: "Vertical",
                  value: "vertical",
                },
                {
                  label: "Horizontal",
                  value: "horizontal",
                },
              ],
            },
          },
          {
            componentPath: "ConfigVerticalSpace",
            valuePath: "options.verticalSpace",
            valueSource: currentField.value,
            options: {
              placeholder: "None",
              label: "Distance between items",
            },
          },
        ],
      },
    ];

    if (
      currentField.value?.name === "menu_level_1" ||
      currentField.value?.name === "menu_level_2"
    ) {
      result[0].controls.push(
        {
          componentPath: "common/ConfigRadioTextElements",
          valuePath: "options.elementsDisplayMode",
          valueSource: currentField.value,
          className: "group-control-element",
          options: {
            label: "Items elements layout",
            items: [
              {
                label: "Vertical",
                value: "vertical",
              },
              {
                label: "Horizontal",
                value: "horizontal",
              },
            ],
          },
        },
        {
          componentPath: "ConfigVerticalSpace",
          valuePath: "options.itemIcon.spaceBetween",
          valueSource: currentField.value,
          className: "group-control-element",
          options: {
            placeholder: "None",
            label: "Distance between item elements",
          },
        }
      );
    }

    return result;
  });

  const menuItemContentControls = computed<ControlProp[]>(() => {
    return [
      ...getInitialContentControlsList({
        source: currentField.value,
      }),
      ...menuItemContentDisplayModeControls.value,
      typography(
        {
          source: currentField.value,
        },
        "Text"
      ),
      ...menuItemContentIconControls.value,
      ...menuItemContentArrowControls.value,
    ];
  });

  const currentStatePath = computed(() => {
    if (!selectedField.value) {
      return "";
    }

    const currState = states.value?.[selectedField.value.name];

    if (currState === State.DEFAULT) {
      return `options`;
    }

    return `options.states.${currState}`;
  });

  const stateValue = computed<State | null>(() => {
    if (!selectedField.value) {
      return null;
    }

    return states.value[selectedField.value.name];
  });

  const arrowStateControls = computed(() => {
    if (currentField.value?.name === "menu_level_2") {
      return [];
    }

    return [
      {
        section: generateDesignSectionName("Arrow"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.arrowSettings.textColor`,
            valueSource: currentField.value,
            visible: currentField.value?.name !== "menu_level_3",
            options: {
              label: "Arrow color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const iconStateControls = computed(() => {
    return [
      {
        section: generateDesignSectionName("Icon"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.itemIcon.color`,
            valueSource: currentField.value,
            options: {
              label: "Icon color",
              type: ColorPickerType.TEXT,
            },
          },
        ],
      },
    ];
  });

  const menuItemStatesControls = computed(() => {
    const result: Array<Record<string, any>> = [
      {
        componentPath: "common/ConfigStatesList",
        valuePath: "",
        valueSource: stateValue.value,
        options: {
          items: [
            {
              label: STATE_KEY_LABELS.default,
              value: State.DEFAULT,
            },
            {
              label: STATE_KEY_LABELS.hover,
              value: State.HOVER,
            },
            {
              label: STATE_KEY_LABELS.active,
              value: State.ACTIVE,
            },
          ],
        },
        onUpdate(value: State) {
          states.value[selectedField.value!.name] = value;
        },
      },
      {
        componentPath: "ConfigColorPickerInput",
        valuePath: `${currentStatePath.value}.fill`,
        valueSource: currentField.value,
        options: {
          label: "Fill",
          type: ColorPickerType.BACKGROUND,
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBorder",
        valuePath: `${currentStatePath.value}.border`,
        valueSource: currentField.value,
        options: {
          label: "Border",
          isBold: true,
        },
      },
      {
        componentPath: "ConfigBoxShadow",
        valuePath: `${currentStatePath.value}.shadow`,
        valueSource: currentField.value,
      },
      {
        section: generateDesignSectionName("Text"),
        controls: [
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: `${currentStatePath.value}.color`,
            valueSource: currentField.value,
            options: {
              label: "Text color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "ConfigDecoration",
            valuePath: `${currentStatePath.value}.decoration`,
            valueSource: currentField.value,
            options: {
              label: "Decoration",
            },
          },
        ],
      },
      ...iconStateControls.value,
      ...arrowStateControls.value,
    ];

    return result;
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    fields,

    /*
      Controls
    */
    widgetDesignControls,
    widgetContentControls,

    titleControls,

    menuItemDesignControls,
    menuItemContentControls,
    menuItemStatesControls,
  };
};
