import { type Ref } from "vue";

import {
  IWidgetWithFields,
  ColorPickerType,
} from "~~/models/widgets/widget.core/widget.model";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import {
  generateContentSectionName,
  generateDesignSectionName,
} from "~~/helpers/configs/generate-section-name";
import { useButtonControls } from "~~/composables/widgets/card/useButtonControls";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { typography } from "~~/constants/configs/text-common/typography-config";
import { ControlProp } from "~~/models/settings/settings-sidebar.model";

export const useSuccessScreenConfig = (
  widget: Ref<IWidgetWithFields>,
  states: Ref<Record<string, string>>,
  emit: any
) => {
  const fields = useWidgetFields(widget.value as IWidgetWithFields);
  const { selectedField } = storeToRefs(useWidgetSettingsStore());

  const successContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.success_message.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigAlignment",
            valuePath: "options.display.alignment",
            visible:
              fields.value.success_message.field.options.display.layout ===
              DisplayOrientation.VERTICAL,
            valueSource: fields.value.success_message.field,
            className: "border-bottom-none",
            options: {
              isTextContentIcons: false,
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.success_message.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      typography(
        {
          source: fields.value.success_message.field,
          except: ["ConfigAlignment"],
        },
        "Text"
      ),
      {
        section: generateDesignSectionName("Icon"),
        valueSource: fields.value.success_message.field.options.icon,
        toggleable: true,
        controls: [
          {
            componentPath: "ConfigIconPicker",
            valuePath: "options.icon.value",
            className: "p-l-16 p-r-16 p-t-16",
            valueSource: fields.value.success_message.field,
            options: {},
          },
          {
            componentPath: "ConfigColorPickerInput",
            valuePath: "options.icon.color",
            valueSource: fields.value.success_message.field,
            options: {
              label: "Color",
              type: ColorPickerType.TEXT,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.size",
            valueSource: fields.value.success_message.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Size",
              minValue: 12,
              maxValue: 32,
            },
          },
          {
            componentPath: "common/ConfigRangeSlider",
            valuePath: "options.icon.gap",
            valueSource: fields.value.success_message.field,
            className: "p-l-16 p-r-16 p-t-16",
            options: {
              label: "Spacing between text and icon",
              minValue: 12,
              maxValue: 32,
            },
          },
        ],
      },
      typography(
        {
          source: fields.value.success_message.field.options.print,
          except: ["ConfigAlignment"],
          isOptionPath: false,
        },
        "Print"
      ),
    ];
  });

  const betCardContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.bet_card.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "bets_type",
              label: "Bets type",
            },
            {
              value: "event_container",
              label: "Event container",
            },
            {
              value: "result_bet_amounts",
              label: "Bet amounts",
            },
          ],
        },
      },
    ];
  });

  const betsTypeContentControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: fields.value.bets_type.field,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const eventContainerContentControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.event_container.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "form/ConfigTabs",
        options: {
          items: [
            {
              value: "event_name",
              label: "Event name",
            },
            {
              value: "bet_market",
              label: "Bet market",
            },
            {
              value: "bet_name",
              label: "Bet name",
            },
            {
              value: "coefficent",
              label: "Coefficient",
            },
          ],
        },
      },
    ];
  });

  const textWithPaddingControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: selectedField.value,
        except: ["ConfigAlignment"],
      }),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: selectedField.value,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const coefContentControls = computed<ControlProp[]>(() => {
    return [
      typography({
        source: selectedField.value,
        except: ["ConfigAlignment"],
      }),
    ];
  });

  const betAmountsControls = computed<ControlProp[]>(() => {
    return [
      {
        section: generateContentSectionName("Display mode"),
        controls: [
          {
            componentPath: "common/ConfigRadioTextElements",
            valuePath: "options.display.layout",
            valueSource: fields.value.result_bet_amounts.field,
            className: "group-control-element",
            options: {
              isBold: true,
              label: "Layout",
              noTextWrap: true,
              items: [
                {
                  label: "Vertical",
                  value: DisplayOrientation.VERTICAL,
                },
                {
                  label: "Horizontal",
                  value: DisplayOrientation.HORIZONTAL,
                },
              ],
            },
          },
          {
            componentPath: "ConfigHorizontalSpace",
            valuePath: "options.display.gap",
            valueSource: fields.value.result_bet_amounts.field,
            className: "group-control-element",
            options: {
              label: "Gap",
              suffix: "px",
            },
          },
        ],
      },
      {
        componentPath: "ConfigFontFamilySelect",
        valuePath: "options.fontFamily",
        valueSource: fields.value.result_bet_amounts.field,
        options: {
          placeholder: "Select",
          label: "Font family",
          isBold: true,
        },
      },
      typography(
        {
          source: fields.value.result_bet_amounts.field.options.label,
          except: ["ConfigAlignment"],
          isOptionPath: false,
        },
        "Label text style"
      ),
      typography(
        {
          source: fields.value.result_bet_amounts.field.options.value,
          except: ["ConfigAlignment"],
          isOptionPath: false,
        },
        "Value text style"
      ),
      {
        componentPath: "ConfigPaddingInputs",
        valuePath: "options.padding",
        valueSource: fields.value.result_bet_amounts.field,
        options: {
          label: "Padding",
          meta: {
            isBold: true,
          },
        },
      },
    ];
  });

  const {
    buttonDesignControls: continueButtonDesignControls,
    buttonContentControls: continueButtonContentControls,
    buttonStatesControls: continueButtonStatesControls,
  } = useButtonControls(
    {
      state: toRef(() => states.value.continue_button),
      stateHandler: (state: State) => {
        emit("update-state", { state: "continue_button", value: state });
      },
      valueSource: toRef(fields.value.continue_button.field),
      exclude: {
        content: ["ConfigLinkType", "content/ConfigTextInputWithToggle"],
      },
    },
    emit
  );

  return {
    successContentControls,
    betCardContentControls,
    betsTypeContentControls,
    eventContainerContentControls,
    textWithPaddingControls,
    coefContentControls,
    betAmountsControls,
    continueButtonDesignControls,
    continueButtonContentControls,
    continueButtonStatesControls,
  };
};
