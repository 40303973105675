import { Breakpoint } from "~~/models/breakpoints.model";
import { useUiStore } from "~~/store/ui";

export const useLayoutOption = (columns: number) => {
  const store = useUiStore();
  const { activeScreen } = storeToRefs(store);

  const isMobileScreen = computed<boolean>(() => {
    return (
      activeScreen.value === Breakpoint.MOBILE ||
      activeScreen.value === Breakpoint.MOBILE_APP ||
      activeScreen.value === Breakpoint.TG_BOT
    );
  });

  const isLayoutAvailable = computed<Boolean>(() => {
    if (isMobileScreen.value && columns === 1) {
      return true;
    } else if (activeScreen.value === Breakpoint.TABLET && columns < 3) {
      return true;
    } else if (activeScreen.value === Breakpoint.DESKTOP) {
      return true;
    }
    return false;
  });

  return { isLayoutAvailable };
};
