import { CSSProperties } from "vue";

import { clearObject } from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  Display,
  FlexDirection,
} from "~~/models/widgets/widget-controls.model";

export const useContentStyles = (widgetOptions: any = {}): CSSProperties => {
  const styles = {
    display: Display.FLEX,
    flexDirection: FlexDirection.COLUMN,
    width: "100%",
    gap: widgetOptions.gap
      ? getPxValueFromNumber(widgetOptions.gap)
      : undefined,
  };

  return clearObject(styles);
};
