import { TickWorker } from "~~/workers/tick/TickWorker";

export const useTimer = ({
  callback,
  timeout = 1000,
}: {
  callback: (currentTimestamp: number) => void;
  timeout?: number;
}) => {
  let tick: TickWorker | null = null;
  let lastTimestamp: number | null = null;

  const onMessage = (event: MessageEvent) => {
    const data = event.data;
    const currentTimestamp = data.timestamp;

    if (lastTimestamp) {
      const diff = data.timestamp - lastTimestamp;

      if (diff > timeout) {
        callback(currentTimestamp);
        lastTimestamp = data.timestamp;
      }
    }
  };

  const init = () => {
    tick = new TickWorker();
    tick.worker?.addEventListener("message", onMessage);
  };

  const start = () => {
    lastTimestamp = new Date().getTime();
  };

  const pause = () => {
    lastTimestamp = null;
  };

  const stop = () => {
    tick?.worker?.removeEventListener("message", onMessage);
  };

  const destroy = () => {
    tick?.worker?.removeEventListener("message", onMessage);
    tick?.destroy();
  };

  return {
    init,
    start,
    pause,
    stop,
    destroy,
  };
};
