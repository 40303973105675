import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { generateCheckboxCssString as generateTableCheckboxCssString } from "~~/assets/utils/widget-css/widgets/betslip-fenix-widget-css";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateFormCssString,
  generateIconCssString,
  generateDropdownMenuCssString,
  generateFieldsWithStatesCssString,
  generateErrorCssString,
  generateTooltipStyling,
  generateDropdownMenuItemStyling,
  generateCalendarWrapperCssString,
  generateCalendarCellCssString,
  generateCalendarNavCssString,
  generateCalendarHeaderWrapperCssString,
  generateCalendarWeekdayCssString,
  generateIconRightCssString,
  generateCheckboxCssString,
  generateRadioCssString,
  generateStageButtonCssString,
} from "../utils/form-helper-functions";
import { prefillWithClasses } from "../utils/prefill-with-classes";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
} from "../compiler/default-css-compiler";
import {
  generateCornerRadiusStyle,
  generateFlex,
  generateFontSize,
  generateGrid,
  generateTextColor,
  generateWidgetWidth,
} from "../helpers";
import { getPxValueFromNumber } from "../..";
import { getFlexPosition } from "../../widget-settings";

import {
  generateEmptyTextStyles,
  generateFilterItemsStyles,
  generateFilterStyles,
  // generateTableStyles,
} from "./transaction-history-widget-css";
import { generateStatusMessageCssString } from "./payment-provider-form-fenix-widget-css";

const generateFilterInnerStyles =
  (field: IWidgetOptions, filterOptions?: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options) + generateWidgetWidth(options.width),
    });

    const direction = filterOptions?.displayMode === 3 ? "column" : "row";

    const flex = generateFlex({
      flex: "flex",
      align: "flex-start",
      justify: "flex-start",
      gap: options.distance,
      direction,
    });

    cssString += generateCssClassWithContent({
      className: options._itemsCssClass,
      content: flex,
    });

    return cssString;
  };

const generateTableStyles =
  (
    field: IWidgetOptions,
    headerField: IWidgetOptions,
    rowField: IWidgetOptions,
    dataField: IWidgetOptions,
    paramsField: IWidgetOptions,
    checkboxField: IWidgetOptions
  ) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options),
    });

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "stretch",
          justify: "flex-start",
          gap: options.displayMode.distanceBetweenCards,
          direction: "column",
        }) + "overflow:hidden;",
    });

    const mode = field.options.mode;
    const gap = field.options.displayMode.distanceBetweenItems;
    const gapInPx = getPxValueFromNumber(gap);

    const dataWidthOptions = dataField.options.width;
    const paramsWidthOptions = paramsField.options.width;

    const dataWidth = `${dataWidthOptions.value?.value}${dataWidthOptions.value?.type}`;
    const paramsWidth = `${paramsWidthOptions.value?.value}${paramsWidthOptions.value?.type}`;
    const checkboxSize = getPxValueFromNumber(checkboxField.options.size);
    const dataWidthWithoutCheckbox = `(${dataWidth} - ${checkboxSize})`;

    if (mode === "table") {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: "overflow-x: auto;",
      });

      cssString += generateCssClassWithContent({
        className: options._contentCssClass,
        content: "min-width:fit-content;",
      });

      /*
        Transaction
      */
      cssString += generateCssClassWithContent({
        className: `${headerField.options._cssClass}--transaction`,
        content:
          `grid-template-areas: "createdDate transactionId description typeDescription toWin gameStatus";` +
          `grid-template-columns: calc(${dataWidth} * 0.35) calc(${dataWidth} * 0.65 - ${gapInPx}) 1fr 
        calc((${paramsWidth} - ${gapInPx} * 2) * 0.33) calc((${paramsWidth}) * 0.495 - ${gapInPx})
        calc((${paramsWidth}) * 0.165)`,
      });

      cssString += generateCssClassWithContent({
        className: `${rowField.options._cssClass}--transaction`,
        content:
          `grid-template-areas: "table_data description table_parameters";` +
          `grid-template-columns: ${dataWidth} 1fr ${paramsWidth};`,
      });

      cssString += generateCssClassWithContent({
        className: `${dataField.options._cssClass}--transaction`,
        content:
          `grid-template-areas: "table_date table_reference";` +
          `grid-template-columns: calc(${dataWidth} * 0.35) calc(${dataWidth} * 0.65);`,
      });

      cssString += generateCssClassWithContent({
        className: `${paramsField.options._cssClass}--transaction`,
        content:
          `grid-template-areas: "table_transaction_type table_amount table_status";` +
          "grid-template-columns: 1fr 1.5fr 0.5fr;",
      });

      /*
        Betting

        createdDate transactionId description gameStatus toWin credit
      */
      cssString += generateCssClassWithContent({
        className: `${headerField.options._cssClass}--betting`,
        content:
          `grid-template-areas: "createdDate transactionId description gameStatus toWin credit";` +
          `grid-template-columns: calc(${dataWidth} * 0.35) calc(${dataWidth} * 0.65 - ${gapInPx}) 1fr 
          calc((${paramsWidth} - ${gapInPx} * 2) * 0.33) calc((${paramsWidth}) * 0.33 - ${gapInPx})
          calc((${paramsWidth}) * 0.33)`,
      });

      cssString += generateCssClassWithContent({
        className: `${rowField.options._cssClass}--betting`,
        content:
          `grid-template-areas: "table_data description table_parameters";` +
          `grid-template-columns: ${dataWidth} 1fr ${paramsWidth};`,
      });

      cssString += generateCssClassWithContent({
        className: `${dataField.options._cssClass}--betting`,
        content:
          `grid-template-areas: "createdDate transactionId";` +
          `grid-template-columns: calc(${dataWidth} * 0.35) calc(${dataWidth} * 0.65);`,
      });

      cssString += generateCssClassWithContent({
        className: `${paramsField.options._cssClass}--betting`,
        content:
          `grid-template-areas: "gameStatus toWin credit";` +
          "grid-template-columns: 1fr 1fr 1fr;",
      });

      /*
        Pending

        noticed_at id provider_name amount
      */
      cssString += generateCssClassWithContent({
        className: `${headerField.options._cssClass}--pending`,
        content:
          `grid-template-areas: "checkbox noticed_at id provider_name amount";` +
          `grid-template-columns: ${checkboxSize} calc( ${dataWidthWithoutCheckbox} * 0.35) calc(${dataWidthWithoutCheckbox} * 0.65 - ${gapInPx} * 2) 1fr 
          ${paramsWidth}`,
      });

      cssString += generateCssClassWithContent({
        className: `${rowField.options._cssClass}--pending`,
        content:
          `grid-template-areas: "table_data provider_name table_parameters";` +
          `grid-template-columns: ${dataWidth} 1fr ${paramsWidth};`,
      });

      cssString += generateCssClassWithContent({
        className: `${dataField.options._cssClass}--pending`,
        content:
          `grid-template-areas: "checkbox noticed_at id";` +
          `grid-template-columns: ${checkboxSize} calc( ${dataWidthWithoutCheckbox} * 0.35) calc(${dataWidthWithoutCheckbox} * 0.65);`,
      });

      cssString += generateCssClassWithContent({
        className: `${paramsField.options._cssClass}--pending`,
        content:
          `grid-template-areas: "amount";` + "grid-template-columns: 1fr;",
      });
    }

    if (mode === "rows") {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: "overflow-x: auto;",
      });

      cssString += generateCssClassWithContent({
        className: options._contentCssClass,
        content: "min-width:fit-content;",
      });

      /*
        Transaction
      */
      cssString += generateCssClassWithContent({
        className: `${headerField.options._cssClass}--transaction`,
        content:
          `grid-template-areas: "createdDate  description typeDescription";` +
          `grid-template-columns: ${dataWidth} 1fr ${paramsWidth};`,
      });

      cssString += generateCssClassWithContent({
        className: `${rowField.options._cssClass}--transaction`,
        content:
          `grid-template-areas: "table_data description table_parameters";` +
          `grid-template-columns: ${dataWidth} 1fr ${paramsWidth};`,
      });

      cssString += generateCssClassWithContent({
        className: `${dataField.options._cssClass}--transaction`,
        content: `grid-template-areas: "table_date" "table_reference";`,
      });

      cssString += generateCssClassWithContent({
        className: `${paramsField.options._cssClass}--transaction`,
        content: `grid-template-areas: "table_transaction_type" "table_amount" "table_status";`,
      });

      /*
        =========
       */

      /*
        Betting

        createdDate transactionId description gameStatus toWin credit
      */
      cssString += generateCssClassWithContent({
        className: `${headerField.options._cssClass}--betting`,
        content:
          `grid-template-areas: "createdDate  description gameStatus";` +
          `grid-template-columns: ${dataWidth} 1fr ${paramsWidth};`,
      });

      cssString += generateCssClassWithContent({
        className: `${rowField.options._cssClass}--betting`,
        content:
          `grid-template-areas: "table_data description table_parameters";` +
          `grid-template-columns: ${dataWidth} 1fr ${paramsWidth};`,
      });

      cssString += generateCssClassWithContent({
        className: `${dataField.options._cssClass}--betting`,
        content:
          `grid-template-areas: "createdDate" "transactionId";` +
          `grid-template-columns: 1fr 1fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${paramsField.options._cssClass}--betting`,
        content: `grid-template-areas: "gameStatus" "toWin" "credit";`,
      });

      /*
        Pending

        noticed_at id provider_name amount
      */

      cssString += generateCssClassWithContent({
        className: `${headerField.options._cssClass}--pending`,
        content:
          `grid-template-areas: "checkbox noticed_at id provider_name amount";` +
          `grid-template-columns: ${checkboxSize} calc(${dataWidthWithoutCheckbox} * 0.35) calc(${dataWidthWithoutCheckbox} * 0.65 - ${gapInPx} * 2) 1fr ${paramsWidth}`,
      });

      cssString += generateCssClassWithContent({
        className: `${rowField.options._cssClass}--pending`,
        content:
          `grid-template-areas: "table_data provider_name table_parameters";` +
          `grid-template-columns: ${dataWidth} 1fr ${paramsWidth};`,
      });

      cssString += generateCssClassWithContent({
        className: `${dataField.options._cssClass}--pending`,
        content:
          `grid-template-areas: "checkbox noticed_at" ". id";` +
          `grid-template-columns: ${checkboxSize} 1fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${paramsField.options._cssClass}--pending`,
        content: `grid-template-areas: "amount";`,
      });
    }

    /*
      =========
    */

    if (mode === "wrap") {
      /*
        Transaction
      */
      cssString += generateCssClassWithContent({
        className: `${headerField.options._cssClass}--transaction`,
        content:
          `grid-template-areas: "createdDate . . transactionId" "description . . ." "typeDescription toWin gameStatus .";` +
          `grid-template-columns: 1fr 1fr 1fr 1fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${headerField.options._cssClass}`,
        pseudoClassName: ' div[data-name="transactionId"]',
        content: `text-align: right;`,
      });

      cssString += generateCssClassWithContent({
        className: `${rowField.options._cssClass}--transaction`,
        content:
          `grid-template-areas: "table_data" "description" "table_parameters";` +
          `grid-template-columns: 1fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${dataField.options._cssClass}--transaction`,
        content:
          `grid-template-areas: "table_date table_reference";` +
          `grid-template-columns: 1fr 2fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${paramsField.options._cssClass}--transaction`,
        content: `grid-template-areas: "table_transaction_type table_amount table_status";`,
      });

      /*
        Betting

        createdDate transactionId description gameStatus toWin credit
      */

      cssString += generateCssClassWithContent({
        className: `${headerField.options._cssClass}--betting`,
        content:
          `grid-template-areas: "createdDate . . transactionId" "description . . ." "gameStatus toWin credit .";` +
          `grid-template-columns: 1fr 1fr 1fr 1fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${rowField.options._cssClass}--betting`,
        content:
          `grid-template-areas: "table_data" "description" "table_parameters";` +
          `grid-template-columns: 1fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${dataField.options._cssClass}--betting`,
        content:
          `grid-template-areas: "createdDate transactionId";` +
          `grid-template-columns: 1fr 2fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${paramsField.options._cssClass}--betting`,
        content: `grid-template-areas: "gameStatus toWin credit";`,
      });

      /*
        Pending
      */

      cssString += generateCssClassWithContent({
        className: `${headerField.options._cssClass}--pending`,
        content:
          `grid-template-areas: "checkbox noticed_at . . id" ". provider_name . . ." ". amount . . .";` +
          `grid-template-columns: ${checkboxSize} 1fr 1fr 1fr 1fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${rowField.options._cssClass}--pending`,
        content:
          `grid-template-areas: "table_data" "provider_name" "table_parameters";` +
          `grid-template-columns: 1fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${dataField.options._cssClass}--pending`,
        content:
          `grid-template-areas: "checkbox noticed_at id";` +
          `grid-template-columns: ${checkboxSize} calc(40% - ${checkboxSize} * 2) 1fr;`,
      });

      cssString += generateCssClassWithContent({
        className: `${paramsField.options._cssClass}--pending`,
        content: `grid-template-areas: "amount";`,
      });

      cssString += generateCssClassWithContent({
        className: `${rowField.options._cssClass}--pending`,
        pseudoClassName: " .transaction-id-cell__text",
        content: "min-width:0;overflow:hidden;text-overflow:ellipsis;",
      });
    }

    return cssString;
  };

const generateTableHeaderStyles =
  (field: IWidgetOptions, tableField: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;
    const gap = tableField.options.displayMode.distanceBetweenItems;

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}--betting`,
      content:
        generateDefaultStyles(options) +
        generateGrid({
          rowGap: gap,
          columnGap: gap,
          gridAutoFlow: "column",
        }),
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}--pending`,
      content:
        generateDefaultStyles(options) +
        generateGrid({
          rowGap: gap,
          columnGap: gap,
          gridAutoFlow: "column",
        }),
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}--transaction`,
      content:
        generateDefaultStyles(options) +
        generateGrid({
          rowGap: gap,
          columnGap: gap,
          gridAutoFlow: "column",
        }),
    });

    return cssString;
  };

const generateTableRowStyles =
  (
    field: IWidgetOptions,
    tableField: IWidgetOptions,
    dataField: IWidgetOptions,
    paramsField: IWidgetOptions
  ) =>
  (cssString: string): string => {
    const { options } = field;
    const gap = tableField.options.displayMode.distanceBetweenItems;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options) +
        generateGrid({
          rowGap: gap,
          columnGap: gap,
          gridAutoFlow: "column",
        }) +
        "align-items:center;",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " > div",
      content: "overflow:hidden;",
    });

    cssString += generateCssClassWithContent({
      className: dataField.options._cssClass,
      content:
        generateDefaultStyles(dataField.options) +
        generateGrid({
          rowGap: gap,
          columnGap: gap,
          gridAutoFlow: "column",
        }) +
        "grid-template-rows:min-content;" +
        "align-items:center;",
    });

    cssString += generateCssClassWithContent({
      className: dataField.options._cssClass,
      pseudoClassName: " > div",
      content: "overflow:hidden;",
    });

    cssString += generateCssClassWithContent({
      className: paramsField.options._cssClass,
      content:
        generateDefaultStyles(paramsField.options) +
        generateGrid({
          rowGap: gap,
          columnGap: gap,
          gridAutoFlow: "column",
        }) +
        "grid-template-rows:min-content;" +
        "align-items:center;",
    });

    cssString += generateCssClassWithContent({
      className: paramsField.options._cssClass,
      pseudoClassName: " > div",
      content: "overflow:hidden;",
    });

    return cssString;
  };

const generateAmountStyles =
  (amountField: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = amountField;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: getFlexPosition(options.display.alignment),
        gap: options.display.distance,
      }),
    });

    cssString += generateCssClassWithContent({
      className: options._labelCssClass,
      content: generateDefaultStyles(options.label),
    });

    cssString += generateCssClassWithContent({
      className: options._valueCssClass,
      content: generateDefaultStyles(options.value),
    });

    return cssString;
  };

const generateReferenceStyles =
  (amountField: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = amountField;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(options) +
        generateFlex({
          flex: "flex",
          align: "flex-start",
          justify: "flex-start",
          gap: "0",
        }),
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " button",
      content: `justify-content: ${getFlexPosition(options.alignment)}`,
    });

    cssString += generateCssClassWithContent({
      className: options._iconCssClass,
      content:
        generateFontSize(options.icon.size) +
        generateTextColor(options.icon.color),
    });

    return cssString;
  };

const generateTableCustomCheckboxCssString =
  (amountField: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = amountField;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: " .base-checkbox",
      content: generateCornerRadiusStyle(options.cornerRadius, true),
    });
    return cssString;
  };

const generateCustomStatusStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: `display:flex;justify-content: ${getFlexPosition(
        options.alignment
      )};`,
    });

    return cssString;
  };

export const generateTransactionHistoryFenixCssString = (
  widget: IPageContentWidget
): string => {
  widget.options._cssClass = generateClassName(widget.name);
  const prefilled = prefillWithClasses(widget.content);
  const prefilledCustom = prefillWithClasses(widget.options._customFields);

  prefilled.table.options._noContentRowCssClass =
    generateClassName("no_content_row");

  prefilled.table.options._containerCssClass =
    generateClassName("table_container");

  prefilled.table.options._contentCssClass = generateClassName("table_content");

  prefilled.date_filter.options._itemsCssClass = generateClassName(
    "date_filter_controls"
  );
  prefilled.transactions_filter.options._itemsCssClass = generateClassName(
    "transactions_filter_controls"
  );

  prefilled.pending_error_text.options.icon._cssClass = generateClassName(
    "pending_error_text_icon"
  );
  prefilled.pending_error_text.options._textCssClass = generateClassName(
    "pending_error_text_description"
  );

  prefilled.table_amount.options._labelCssClass =
    generateClassName("table_amount_label");
  prefilled.table_amount.options._valueCssClass =
    generateClassName("table_amount_value");

  prefilled.table_reference.options._iconCssClass = generateClassName(
    "table_reference_icon"
  );

  prefilled.action_button.options.iconSettings._cssClass =
    generateClassName("action_button_icon");

  // Calendar class names
  prefilledCustom.calendar_styling.options._wrapperCssClass =
    generateClassName("calendar_wrapper");
  prefilledCustom.calendar_styling.options._dayCellCssClass =
    generateClassName("day_cell");

  prefilledCustom.calendar_styling.options._navCssClass =
    generateClassName("calendarNav");

  prefilledCustom.datepicker_styling.options._datepickerCssClass =
    generateClassName("datepicker");
  // Icons class names
  prefilledCustom.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");

  if (prefilledCustom.dropdowns_styling) {
    prefilledCustom.dropdowns_styling.options.icon._cssClass =
      generateClassName("dropdown_icon");
  }

  prefilledCustom.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("text_choose_icon");

  if (prefilledCustom.calendar_styling) {
    // Calendar class names
    prefilledCustom.calendar_styling.options._wrapperCssClass =
      generateClassName("calendar_wrapper");
    prefilledCustom.calendar_styling.options._headerWrapperCssClass =
      generateClassName("calendarHeaderWrapper");
    prefilledCustom.calendar_styling.options._weekDayCssClass =
      generateClassName("calendarWeekDay");
    prefilledCustom.calendar_styling.options._cellCssClass =
      generateClassName("cell");
    prefilledCustom.calendar_styling.options._cellWrapperCssClass =
      generateClassName("cellWrapperCssClass");
    prefilledCustom.calendar_styling.options._navCssClass =
      generateClassName("calendarNav");

    prefilledCustom.datepicker_styling.options._datepickerCssClass =
      generateClassName("datepicker");
  }

  const dropdowns = prefilledCustom.dropdowns_styling
    ? [
        generateDropdownMenuCssString(
          prefilledCustom.dropdown_menu_styling,
          prefilledCustom.dropdown_menu_items_styling
        ),
        generateDropdownMenuItemStyling(
          prefilledCustom.dropdown_menu_items_styling
        ),
      ]
    : [];

  const calendar = prefilledCustom.calendar_styling
    ? [
        generateCalendarWrapperCssString(prefilledCustom.calendar_styling),
        generateCalendarHeaderWrapperCssString(
          prefilledCustom.calendar_styling
        ),
        generateCalendarCellCssString(prefilledCustom.calendar_styling),
        generateCalendarWeekdayCssString(prefilledCustom.calendar_styling),
        generateCalendarNavCssString(prefilledCustom.calendar_styling),
      ]
    : [];

  if (prefilledCustom.checkboxes_styling) {
    prefilledCustom.checkboxes_styling.options._checkboxOptionsCssClass =
      generateClassName("checkbox_options");

    prefilledCustom.checkboxes_styling.options._checkboxContainerCssClass =
      generateClassName("checkbox_container");

    prefilledCustom.checkboxes_styling.options._checkoxCssClass =
      generateClassName("checkbox");
    prefilledCustom.checkboxes_styling.options._checkboxLabelCssClass =
      generateClassName("checkboxLabel");

    if (prefilledCustom.checkboxes_styling.options.check.icon) {
      prefilledCustom.checkboxes_styling.options.check.icon._cssClass =
        generateClassName("checkIcon");
    }
  }

  if (prefilledCustom.radio_group_styling) {
    prefilledCustom.radio_group_styling.options._radioOptionsCssClass =
      generateClassName("radio_options");

    prefilledCustom.radio_group_styling.options._radioContainerCssClass =
      generateClassName("radio_container");

    prefilledCustom.radio_group_styling.options._radioCssClass =
      generateClassName("radio");

    prefilledCustom.radio_group_styling.options._radioLabelCssClass =
      generateClassName("radioLabel");

    if (prefilledCustom.radio_group_styling.options.radio.icon) {
      prefilledCustom.radio_group_styling.options.radio.icon._cssClass =
        generateClassName("radioIcon");
    }
  }

  const checkboxes =
    prefilledCustom.checkboxes_styling &&
    prefilledCustom.checkboxes_styling.options.check.icon
      ? generateCheckboxCssString(
          prefilledCustom.checkboxes_styling,
          prefilledCustom.checkboxes_styling?.options.check.icon
        )
      : generateCheckboxCssString(prefilledCustom.checkboxes_styling);

  return pipeSync<string>(
    generateStringDefault(widget),
    generateStringWithStates(prefilled.title, true),
    generateStringDefault(prefilled.filter),
    generateStringDefault(prefilled.filter_title),
    generateFilterInnerStyles(prefilled.date_filter),
    generateFilterInnerStyles(
      prefilled.transactions_filter,
      prefilled.form.options
    ),
    generateStringDefault(prefilled.date_filter_title),
    generateStringDefault(prefilled.transactions_filter_title),
    generateStageButtonCssString(prefilled.action_button as IWidgetField),
    generateTableStyles(
      prefilled.table,
      prefilled.table_header_settings,
      prefilled.table_row_settings,
      prefilled.table_data,
      prefilled.table_parameters,
      prefilled.table_checkboxes_settings
    ),
    generateTableHeaderStyles(prefilled.table_header_settings, prefilled.table),
    generateTableRowStyles(
      prefilled.table_row_settings,
      prefilled.table,
      prefilled.table_data,
      prefilled.table_parameters
    ),
    generateStringDefault(prefilled.pending_description),
    generateEmptyTextStyles(prefilled.no_events_title),
    generateTableCheckboxCssString(prefilled.table_checkboxes_settings),
    generateTableCustomCheckboxCssString(prefilled.table_checkboxes_settings),

    generateStatusMessageCssString(prefilled.pending_error_text),

    generateStringDefault(prefilled.table_date),
    generateStringDefault(prefilled.table_transaction_type),
    generateStringDefault(prefilled.table_status),
    generateStringDefault(prefilled.table_description),
    generateAmountStyles(prefilled.table_amount),
    generateReferenceStyles(prefilled.table_reference),
    generateStringDefault(prefilled.table_transaction_type),

    generateCustomStatusStyles(prefilled.table_status),
    /*
      Form
    */
    checkboxes,
    generateRadioCssString(prefilledCustom.radio_group_styling),
    generateFormCssString(prefilled.form, widget),
    generateFilterStyles(prefilled.form.options),
    generateFilterItemsStyles(prefilled.form.options),
    generateTooltipStyling(prefilledCustom.tooltips_styling),
    generateIconCssString(
      prefilledCustom.tooltips_styling.options.textChooseIcon
    ),
    generateErrorCssString(prefilledCustom.errors_styling),
    generateStringDefault(prefilledCustom.labels_styling),
    generateFieldsWithStatesCssString(
      prefilledCustom.fields_styling,
      prefilledCustom.errors_styling,
      widget,
      prefilledCustom.labels_styling,
      prefilledCustom.field_success_styling
    ),
    generateIconRightCssString(prefilledCustom.fields_styling.options),
    ...dropdowns,
    ...calendar
  )("");
};
