import { CSSProperties } from "vue";

import { getDisplayOrientationStyles } from "~~/assets/utils/widget-settings";
import { AlignContent } from "~~/models/widgets/widget-controls.model";
import { getPxValueFromNumber } from "~~/assets/utils";

import { useDesignInitialStyles } from "./useDesignInitialStyles";

export const useMarketsStyles = (
  marketsOptions: any = {},
  contentOptions: any = {}
): CSSProperties => {
  const displayOrientation = getDisplayOrientationStyles(
    marketsOptions.displayOrientation
  );

  return {
    ...useDesignInitialStyles(marketsOptions),

    ...displayOrientation,
    width: "100%",
    gap: getPxValueFromNumber(marketsOptions.gap),
    ...(contentOptions.alignContent === AlignContent.END
      ? { marginTop: "auto" }
      : {}),
  };
};
