import { generateDropdownItem } from "~~/helpers/configs/generate-dropdown-item";
import { generateTabItem } from "~~/helpers/configs/generate-tab-item";

const SETTINGS_ITEMS = [
  {
    label: "Title settings",
    value: "title",
  },
  {
    label: "Tabs settings",
    value: "tabs",
  },
  {
    label: "Cards settings",
    value: "cards",
  },
];

const PREMATCH_DROPDOWN_ITEMS = [
  generateDropdownItem("Prematch banner", "prematch_banner"),
  generateDropdownItem("Iframe", "betting_constructor_iframe"),
  generateDropdownItem("Betting constructor", "betting_constructor"),
  generateDropdownItem("Constructor Header", "betting_constructor_header"),
  generateDropdownItem("Content", "betting_constructor_content"),
  generateDropdownItem("Description", "betting_constructor_description"),
  generateDropdownItem("Iframe", "betting_constructor_iframe"),
  generateDropdownItem("Markets", "markets_container"),
  generateDropdownItem("Market cards", "markets_cards"),
  generateDropdownItem("Market Name", "markets_market_name"),
  generateDropdownItem("Status", "markets_status"),
  generateDropdownItem("Pin Icon", "markets_pin_icon"),
  generateDropdownItem("Bet Items", "bet_items"),
  generateDropdownItem("Event time", "prematch_banner_event_time"),
  generateDropdownItem("Event title", "prematch_banner_event_title"),
];

const LIVE_DROPDOWN_ITEMS = [
  generateDropdownItem("Iframe", "betting_constructor_iframe"),
  generateDropdownItem("Betting constructor", "betting_constructor"),
  generateDropdownItem("Constructor Header", "betting_constructor_header"),
  generateDropdownItem("Content", "betting_constructor_content"),
  generateDropdownItem("Description", "betting_constructor_description"),
  generateDropdownItem("Iframe", "betting_constructor_iframe"),
  generateDropdownItem("Live banner", "live_banner"),
  generateDropdownItem("Event info", "live_banner_event_info"),
  generateDropdownItem("Header", "live_banner_header"),
  generateDropdownItem("Rows", "live_banner_rows"),
  generateDropdownItem("Team / Player", "live_banner_rows_team_player"),
  generateDropdownItem("Info point", "live_banner_rows_team_player_info_point"),
  generateDropdownItem("Footer", "live_banner_footer"),
  generateDropdownItem("Login text", "live_banner_login_text"),
  generateDropdownItem("Login button", "live_banner_login_button"),
  generateDropdownItem("Header statistics", "live_banner_header_statistics"),
  generateDropdownItem("Titles", "live_banner_header_titles"),
  generateDropdownItem("Value titles", "live_banner_header_value_titles"),
  generateDropdownItem(
    "Team / Player info",
    "live_banner_rows_team_player_info"
  ),
  generateDropdownItem("Info name", "live_banner_event_info_name"),
  generateDropdownItem("Info score", "live_banner_event_info_score"),
  generateDropdownItem(
    "Team / Player Values",
    "live_banner_rows_team_player_info_values"
  ),
  generateDropdownItem("Point", "live_banner_header_point_title"),
  generateDropdownItem("Footer time", "live_banner_footer_time"),
  generateDropdownItem("Footer period", "live_banner_footer_period"),
  generateDropdownItem("Live header", "live_header"),
  generateDropdownItem("Back button", "live_header_back_button"),
  generateDropdownItem("Sport / League Name", "live_header_sport_league_name"),
  generateDropdownItem("View mode switch", "live_header_view_mode_switch"),
  generateDropdownItem("Markets", "markets_container"),
  generateDropdownItem("Market cards", "markets_cards"),
  generateDropdownItem("Market Name", "markets_market_name"),
  generateDropdownItem("Status", "markets_status"),
  generateDropdownItem("Pin Icon", "markets_pin_icon"),
  generateDropdownItem("Bet Items", "bet_items"),
];

const RACING_DROPDOWN_ITEMS = [
  generateDropdownItem("Racing banner", "racing_banner"),
  generateDropdownItem("Event description", "racing_banner_event_description"),
  generateDropdownItem("Live header", "live_header"),
  generateDropdownItem("Back button", "live_header_back_button"),
  generateDropdownItem("Sport / League Name", "live_header_sport_league_name"),
  generateDropdownItem("View mode switch", "live_header_view_mode_switch"),
  generateDropdownItem("Event Info", "racing_event_info"),
  generateDropdownItem("Race distance and venue", "racing_title"),
  generateDropdownItem("Group stage and conditions", "racing_description"),
  generateDropdownItem("Players Info", "racing_players_info"),
  generateDropdownItem("Header Card", "racing_header_card"),
  generateDropdownItem("Player Cards", "racing_player_cards"),
  generateDropdownItem("№", "racing_number"),
  generateDropdownItem("Horse or greyhound", "racing_player_name"),
  generateDropdownItem("Last 5 races", "racing_last_5_races"),
  generateDropdownItem("Previous odds", "racing_prev_odds"),
  generateDropdownItem("Odds", "racing_odds"),
  generateDropdownItem("Login button", "racing_banner_login_button"),
  generateDropdownItem("Login text", "racing_banner_login_text"),
];

export const useItems = (states: Ref<Record<string, string>>) => {
  const DEFAULT_DROPDOWN_ITEMS = [
    generateDropdownItem("No events title", "no_events_title", "no_events"),
  ];

  const TABS = {
    widgetSettings: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("Data", "data"),
    ],
    live_header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_header_view_mode_switch: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    betting_constructor: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    betting_constructor_header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    betting_constructor_content: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    markets_container: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    markets_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    markets_status: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    bet_items: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],

    racing_event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    racing_players_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    racing_header_card: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    racing_player_cards: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    racing_odds: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    live_banner: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_event_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_event_info_score: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_header: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_header_statistics: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_header_titles: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_header_value_titles: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_header_point_title: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_rows: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_rows_team_player: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_rows_team_player_info_point: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    live_banner_rows_team_player_info: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_rows_team_player_info_values: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateTabItem("States", "states"),
    ],
    live_banner_footer: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    live_banner_login_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateDropdownItem("States", "states"),
    ],
    prematch_banner: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    racing_banner: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
    ],
    racing_banner_login_button: [
      generateTabItem("Design", "design"),
      generateTabItem("Content", "content"),
      generateDropdownItem("States", "states"),
    ],
  };

  const DROPDOWN_ITEMS = computed(() => {
    if (states.value.widget === "prematch_event") {
      return PREMATCH_DROPDOWN_ITEMS;
    }

    if (states.value.widget === "live_event") {
      return LIVE_DROPDOWN_ITEMS;
    }

    if (states.value.widget === "racing_event") {
      return RACING_DROPDOWN_ITEMS;
    }

    return DEFAULT_DROPDOWN_ITEMS;
  });

  return {
    DROPDOWN_ITEMS,
    TABS,
    SETTINGS_ITEMS,
  };
};
