import {
  ICustomField,
  IWidgetField,
  IWidgetOptions,
} from "~~/models/widgets/widget.core/widget.model";
import { pipeSync } from "~~/helpers/pipe";
import { IPageContentWidget } from "~~/models/page.model";
import {
  DisplayOrientation,
  ResizingType,
  State,
} from "~~/models/widgets/widget-controls.model";
import { Sizing } from "~~/models/grid.interface";

import { generateClassName } from "../utils/generate-class-name";
import {
  generateCustomStyles,
  generateDefaultStylesWithStateClassName,
  generateStringDefault,
  generateStringWithStates,
} from "../utils/pipe-helper-functions";
import {
  generateCssClassWithContent,
  generateDefaultStyles,
  generateDefaultStylesWithStates,
} from "../compiler/default-css-compiler";
import {
  generateFlex,
  generateGrid,
  generateGap,
  generateFlexDirection,
  generateWrap,
  generateAlignItems,
  generateWidth,
  generateMaxWidth,
  generateTextColor,
  generateFontSize,
  generateMarginStyle,
  generateJustifyContent,
  generatePaddingStyle,
  generateSpacingStyle,
  generateMinWidth,
  generateWidgetWidth,
} from "../helpers";
import {
  generateFormCssString,
  generateIconCssString,
  generateDropdownMenuCssString,
  generateFieldsWithStatesCssString,
  generateErrorCssString,
  generateTooltipStyling,
  generateDropdownMenuItemStyling,
  generateCalendarWrapperCssString,
  generateCalendarCellCssString,
  generateCalendarNavCssString,
  generateCalendarHeaderWrapperCssString,
  generateCalendarWeekdayCssString,
  generateIconRightCssString,
} from "../utils/form-helper-functions";
import { getPxValueFromNumber } from "../..";
import { getFlexPosition } from "../../widget-settings";
import { itemsGroupClassNameGenerator } from "../compiler/items-css-compiler";

import { generateGridLayoutCssString } from "./promotions-list-widet-css";
import { generateDayLabelCssString } from "./sportbook-bet9-widget";
import { generateButtonCssString } from "./card-widget-css";

export const generateHeaderCssString =
  (header: IWidgetField) =>
  (cssString: string): string => {
    const { options } = header;

    const alignment = options.alignment;

    const widgetPositionCssContent = generateFlex({
      flex: "flex",
      align: alignment,
      justify: "space-between",
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetPositionCssContent + generateDefaultStyles(header.options),
    });

    return cssString;
  };

export const generateLeagueWinnerCardCssString =
  (leagueCard: IWidgetField) =>
  (cssString: string): string => {
    const { options } = leagueCard;

    const widgetPositionCssContent = generateGrid({
      templateColumns: `repeat(${options.displaySettings}, minmax(0, 1fr))`,
      rowGap: options.verticalSpacing,
      columnGap: options.verticalSpacing,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        widgetPositionCssContent +
        generateMinWidth("unset") +
        generateDefaultStyles(leagueCard.options),
    });

    return cssString;
  };

const generateLeagueCardMinWidthCssString =
  (leagueCard: IWidgetField) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: leagueCard.options._cssClass,
      content: generateMinWidth("unset"),
    });

    return cssString;
  };

/*
  To prevent case when bet bet name flows coef
*/
export const betDefaultSpacingCssString = (): string => {
  return "padding-left:8px;";
};

export const generateLeagueBetItemCssString =
  (leagueBetItem: IWidgetField, allowItemWidth?: boolean) =>
  (cssString: string): string => {
    const { options } = leagueBetItem;

    const nameCssContent = generateDefaultStyles(options.name);

    cssString += generateCssClassWithContent({
      className: options.name._cssClass,
      content:
        nameCssContent +
        "overflow:hidden;text-overflow:ellipsis;white-space:nowrap;",
    });

    const coefCssContent = generateDefaultStyles(options.coef);

    cssString += generateCssClassWithContent({
      className: options.coef._cssClass,
      content: coefCssContent + betDefaultSpacingCssString(),
    });

    const flexString = generateFlex({
      flex: "flex",
      align: "center",
      justify: "space-between",
    });

    const itemWidth = allowItemWidth ? "min-width: 0" : "";

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(leagueBetItem.options) +
        flexString +
        "flex: 1; cursor: pointer;" +
        itemWidth,
    });

    cssString += generateDefaultStylesWithStates(leagueBetItem, false);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      pseudoClassName: "--selected",
      content: generateDefaultStyles(leagueBetItem.options.states.active),
    });

    for (const state in leagueBetItem.options.states) {
      const pseudoClassName = `:${state}`;

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName,
        childClassName: leagueBetItem.options.name._cssClass,
        content: generateDefaultStyles(
          leagueBetItem.options.states[state].name
        ),
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        pseudoClassName,
        childClassName: leagueBetItem.options.coef._cssClass,
        content: generateDefaultStyles(
          leagueBetItem.options.states[state].coef
        ),
      });
    }

    if (options._itemSelectedCssClass) {
      cssString += generateCssClassWithContent({
        className: options._itemSelectedCssClass,
        content: generateDefaultStyles(leagueBetItem.options.states.active),
      });
    }

    return cssString;
  };

export const generateListContainerCssString =
  (
    eventsCard: IWidgetField,
    eventsContainer: IWidgetField,
    itemsContainerOptions: IWidgetOptions,
    itemsContainerCssClass: string,
    itemsContainerVerticalCssClass: string,
    eventNameCssClass: string,
    timeOptions: IWidgetOptions,
    dateOptions: IWidgetOptions
  ) =>
  (cssString: string): string => {
    const { options } = eventsCard;

    for (const state in options.states) {
      /* Card */
      cssString += generateCssClassWithContent({
        className: options.card._cssClass,
        pseudoClassName: `:${state}`,
        content: options.states[state].card,
        customFunction: generateDefaultStyles,
      });
      /* Name */
      cssString += generateCssClassWithContent({
        className: options.card._cssClass,
        pseudoClassName: `:${state}`,
        childClassName: eventNameCssClass,
        content: options.states[state].name.color,
        customFunction: generateTextColor,
      });
      /* Time */
      cssString += generateCssClassWithContent({
        className: options.card._cssClass,
        pseudoClassName: `:${state}`,
        childClassName: timeOptions._cssClass,
        content: options.states[state].time.color,
        customFunction: generateTextColor,
      });

      cssString += generateCssClassWithContent({
        className: options.card._cssClass,
        pseudoClassName: `:${state}`,
        childClassName: timeOptions.icon._cssClass,
        content: options.states[state].time.icon.color,
        customFunction: generateTextColor,
      });

      /* Date */
      cssString += generateCssClassWithContent({
        className: options.card._cssClass,
        pseudoClassName: `:${state}`,
        childClassName: dateOptions._cssClass,
        content: options.states[state].date.color,
        customFunction: generateTextColor,
      });

      cssString += generateCssClassWithContent({
        className: options.card._cssClass,
        pseudoClassName: `:${state}`,
        childClassName: dateOptions.icon._cssClass,
        content: options.states[state].date.icon.color,
        customFunction: generateTextColor,
      });
    }

    const widgetPositionCssContent = generateGrid({
      templateColumns: `repeat(${options.columns}, minmax(0,1fr))`,
      rowGap: options.distanceBetweenCards,
      columnGap: options.distanceBetweenCards,
    });

    cssString += generateCssClassWithContent({
      className: itemsContainerCssClass,
      content: generateDefaultStyles(itemsContainerOptions),
    });

    cssString += generateCssClassWithContent({
      className: itemsContainerVerticalCssClass,
      content: generateDefaultStyles(itemsContainerOptions),
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetPositionCssContent,
    });

    const isHorizontal =
      options.items.displayMode === DisplayOrientation.HORIZONTAL;

    cssString += generateCssClassWithContent({
      className: options.card._cssClass,
      content:
        generateDefaultStyles(options.card) +
        "display:flex;flex-direction: column;",
    });

    cssString += generateCssClassWithContent({
      className: options.card._newLeagueItemCssClass,
      content: `margin-bottom: ${getPxValueFromNumber(
        eventsContainer.options.distanceBetweenLeagueEvents
      )}`,
    });

    cssString += generateCssClassWithContent({
      className: options.card._newDayItemCssClass,
      content: `margin-bottom: ${getPxValueFromNumber(
        eventsContainer.options.distanceBetweenDateEvents
      )}`,
    });

    cssString += generateCssClassWithContent({
      className: options.card._headerCssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: "space-between",
        gap: "4",
      }),
    });

    const itemsCssContent = generateFlex({
      flex: "flex",
      direction: isHorizontal ? "row" : "column",
      align: isHorizontal ? "center" : "flex-start",
      justify: isHorizontal ? "flex-start" : "flex-end",
      gap: isHorizontal ? "0" : options.items.distanceBetweenItems,
    });

    const { distanceBetweenItems } = options.items;

    const betItemsCssHorizontalContent = generateGrid({
      rowGap: distanceBetweenItems,
      columnGap: distanceBetweenItems,
      templateColumns: `repeat(auto-fit, minmax(calc(33.33334% - ${getPxValueFromNumber(
        distanceBetweenItems
      )}), 1fr))`,
    });

    const betItemsCssVerticalContent = generateFlex({
      flex: "flex",
      direction: "column",
      align: "stretch",
      justify: "flex-start",
      gap: distanceBetweenItems,
    });

    const itemsContainerWidth = generateWidth(100, "%");
    const grow = isHorizontal ? "" : "flex-grow: 1;";
    const marginTop = isHorizontal ? "margin-top: auto;" : "";

    cssString += generateCssClassWithContent({
      className: options.items._cssClass,
      content: itemsCssContent + itemsContainerWidth + grow + marginTop,
    });

    cssString += generateCssClassWithContent({
      className: options.bet_items._cssClass,
      content: betItemsCssHorizontalContent + itemsContainerWidth,
    });

    cssString += generateCssClassWithContent({
      className: options.bet_items._cssClass,
      pseudoClassName: "> *:only-child",
      content: `max-width: 50%;`,
    });

    cssString += generateCssClassWithContent({
      className: options.bet_items._verticalCssClass,
      content: betItemsCssVerticalContent + itemsContainerWidth,
    });

    cssString += generateCssClassWithContent({
      className: options._itemHeaderCssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: "space-between",
        gap: "0",
      }),
    });
    return cssString;
  };

export const generatEventInfoContainerCssString =
  (field: IWidgetField, startTimeField: IWidgetField) =>
  (cssString: string): string => {
    const { options } = field;

    const isHorizontal =
      options.display.layout === DisplayOrientation.HORIZONTAL;

    const flex = generateFlex({
      flex: "flex",
      align: "center",
      justify: "space-between",
      gap: options.display.distance,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options) + flex,
    });

    cssString += generateCssClassWithContent({
      className: startTimeField.options._containerCssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: "flex-start",
        gap: options.display.distance,
      }),
    });

    cssString += generateCssClassWithContent({
      className: options._detailsCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: isHorizontal ? "center" : "stretch",
          justify: "flex-start",
          gap: options.display.distance,
          direction: isHorizontal ? "row" : "column",
        }) +
        generateWidth(100, "%") +
        "overflow:hidden;text-overflow:ellipsis;white-space:nowrap;",
    });

    cssString += generateCssClassWithContent({
      className: options._buttonsCssClass,
      content: generateFlex({
        flex: "flex",
        align: "center",
        justify: "flex-start",
        gap: options.display.distance,
      }),
    });

    return cssString;
  };

export const generateListStartTimeCssString =
  (startTime: IWidgetField, gap = "4") =>
  (cssString: string): string => {
    const { options } = startTime;

    const iconFontCssContent =
      generateTextColor(options.icon.color) +
      generateFontSize(options.icon.size);

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content: iconFontCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateDefaultStyles(startTime.options, ["spaceBetween", "padding"]) +
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "space-between",
          gap: gap,
        }) +
        generateMarginStyle({ right: options.spaceBetween || 0 }),
    });

    cssString += generateCssClassWithContent({
      className: options._containerCssClass,
      content:
        generateFlex({
          flex: "flex",
          align: "center",
          justify: "flex-start",
          gap: "0",
        }) + generatePaddingStyle(options.padding),
    });

    return cssString;
  };

export const generateWidgetStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    let widgetStyles = "";

    if (options.contentWidth.type === Sizing.FIXED) {
      widgetStyles = generateWidth(options.contentWidth.width, "px");
    } else {
      widgetStyles += generateWidth(100, "%");
    }

    widgetStyles += generateMaxWidth(`100%`);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: widgetStyles + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateItemMoreStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateDefaultStylesWithStates(
      {
        options: options,
      },
      false,
      [],
      ["size"]
    );

    const paddingStylesString = generateDefaultStyles({
      spacing: options.spacing,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateWidgetWidth(options.width) +
        `${paddingStylesString}overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align:center;`,
    });

    return cssString;
  };

export const generateFilterStyles =
  (filterOptions: IWidgetOptions) =>
  (cssString: string): string => {
    const isHorizontal =
      filterOptions.displayMode === DisplayOrientation.HORIZONTAL;

    const alignment = getFlexPosition(filterOptions.alignment);
    const visibility = filterOptions.visibility;

    const wrap = isHorizontal ? "nowrap" : "wrap";

    cssString += generateCssClassWithContent({
      className: filterOptions._cssClass,
      content:
        generateGap(filterOptions.distance) +
        generateWrap(wrap) +
        generateAlignItems("flex-end") +
        generateFlexDirection("row") +
        generateJustifyContent("flex-start"),
    });

    cssString += generateCssClassWithContent({
      className: filterOptions._cssClass,
      pseudoClassName: " li",
      content: "cursor: pointer",
    });

    /*
      Align Options
    */

    if (isHorizontal) {
      cssString += generateCssClassWithContent({
        className: filterOptions._cssClass,
        content: generateJustifyContent(alignment),
      });

      cssString += generateCssClassWithContent({
        className: filterOptions._cssClass,
        pseudoClassName: " div[data-type='datepicker']",
        content: "max-width: 25%;",
      });

      cssString += generateCssClassWithContent({
        className: filterOptions._cssClass,
        pseudoClassName: " div[data-type='dropdown']",
        content: "max-width: 50%;",
      });
    }

    if (visibility === "period") {
      cssString += generateCssClassWithContent({
        className: filterOptions._cssClass,
        pseudoClassName: " div[data-type='dropdown']",
        content: "display: none;",
      });
    }

    if (visibility === "market") {
      cssString += generateCssClassWithContent({
        className: filterOptions._cssClass,
        pseudoClassName: " div[data-type='datepicker']",
        content: "display: none;",
      });
    }

    return cssString;
  };

export const generateFilterItemsStyles =
  (filterOptions: IWidgetOptions) =>
  (cssString: string): string => {
    cssString +=
      `.${filterOptions._cssClass} > ` +
      `div:not(:nth-child(3)) {flex:1; width:initial;}`;

    cssString +=
      `.${filterOptions._cssClass} > ` +
      `div:nth-child(3) {flex:2; width:initial;}`;

    if (filterOptions.displayMode === DisplayOrientation.VERTICAL) {
      cssString +=
        `.${filterOptions._cssClass} > ` +
        `div:not(:nth-child(3)) {flex:1; width:calc(50% - (${filterOptions.distance}px / 2));}`;

      cssString +=
        `.${filterOptions._cssClass} > ` +
        `div:nth-child(3) {flex:2 1 100%; width:initial;}`;
    }

    return cssString;
  };

export const generateTitleAdditionalStyles =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: "flex: 1;",
    });

    return cssString;
  };

export const generateLeaguesContainerCss =
  (options: IWidgetOptions) =>
  (cssString: string): string => {
    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content:
        generateGrid({
          templateColumns: `repeat(${options.display.layout}, 1fr)`,
          rowGap: options.display.distance,
          columnGap: options.display.distance,
        }) + generateDefaultStyles(options),
    });

    return cssString;
  };

export const generateFavButtonStyles =
  (field: IWidgetOptions) =>
  (cssString: string): string => {
    const { options } = field;

    const iconFontCssContent =
      generateTextColor(options.color) + generateFontSize(options.size);

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: iconFontCssContent,
    });

    return cssString;
  };

const generateItemsCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const elementContent = generateFlex({
      flex: "flex",
      align: "stretch",
      justify: element.value[0].options.containerAlignment,
      direction: "row",
      gap: element.value[0].options.distance,
    });

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content: elementContent,
    });

    cssString += generateCssClassWithContent({
      className: element.options._rootCssClass,
      content: `overflow: auto;${generateWidth(100, "%")}${generateSpacingStyle(
        element.options.spacing
      )}`,
    });

    return cssString;
  };

export const generateItemsGroupCssString =
  (itemsGroup: ICustomField) =>
  (cssString: string): string => {
    const { options } = itemsGroup;
    const iconCssStringContent =
      generateTextColor(options.itemsIcon?.textColor) +
      generateFontSize(options.itemsIcon?.iconSize);

    const isDisplayFill = options.resizing === ResizingType.FILL;
    const itemsGroupAlignment = isDisplayFill
      ? options.alignment
      : "flex-start";

    const itemsGroupWidth = isDisplayFill ? `${generateWidth(100, "%")}` : "";

    cssString += generateDefaultStylesWithStates(itemsGroup, false);
    cssString += `.${itemsGroup.options._cssClass}{${generateFlex({
      flex: "flex",
      align: "center",
      justify: itemsGroupAlignment,
      direction: options.itemsIcon?.position === "left" ? "row-reverse" : "row",
      gap: options.itemsIcon?.gap,
    })}${itemsGroupWidth}}`;

    cssString += generateCssClassWithContent({
      className: itemsGroup.fields?.[0].options._cssClass || options._cssClass,
      content: options,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: options.itemsIcon?._cssClass,
      content: iconCssStringContent,
    });

    for (const state in itemsGroup.options.states) {
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        childClassName: options.itemsIcon?._cssClass,
        content: options.states[state].itemsIcon?.textColor,
        customFunction: generateTextColor,
        pseudoClassName: `:${state}`,
      });

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state],
        customFunction: generateDefaultStyles,
        pseudoClassName: `:${state}`,
      });
    }

    /*
      Styles for active route
    */

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-active="true"]`,
      content: options.states.active,
      customFunction: generateDefaultStyles,
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-active="true"]`,
      childClassName: options.itemsIcon?._cssClass,
      content: options.states.active.itemsIcon?.textColor,
      customFunction: generateTextColor,
    });

    cssString += generateCssClassWithContent({
      className: `${options._cssClass}[data-active="true"]`,
      content: options.states.active,
      customFunction: generateDefaultStyles,
    });

    return cssString;
  };

const generateSearchFieldContainerCssString =
  (fieldContainer: IWidgetField) =>
  (cssString: string): string => {
    const { options } = fieldContainer;

    const textCssContent = generateDefaultStyles(options.text);
    const placeholderCssContent = generateDefaultStyles(options.placeholder);
    const iconCssContent = generateDefaultStyles(options.icon);

    cssString += generateCssClassWithContent({
      className: options.text._cssClass,
      content: textCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options.text._cssClass,
      pseudoClassName: "::placeholder",
      content: placeholderCssContent,
    });

    cssString += generateCssClassWithContent({
      className: options.icon._cssClass,
      content:
        iconCssContent +
        generateMarginStyle({
          right: options.icon.gap,
        }) +
        generateFontSize(options.icon.iconSize),
    });

    for (const state in options.states) {
      let classNameString =
        state === State.ACTIVE ? `:has(input:focus)` : `:has(input:${state})`;

      classNameString =
        state === State.DISABLED ? `:has([disabled])` : classNameString;

      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state].text,
        childClassName: options.text._cssClass,
        customFunction: generateDefaultStyles,
        pseudoClassName: classNameString,
      });
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state],
        customFunction: generateDefaultStyles,
        pseudoClassName: classNameString,
      });
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state].icon,
        customFunction: generateDefaultStyles,
        pseudoClassName: `${classNameString} svg`,
      });
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state].placeholder,
        customFunction: generateDefaultStyles,
        pseudoClassName: `${classNameString} input::placeholder`,
      });
    }

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: generateDefaultStyles(options),
    });

    return cssString;
  };

const generateResultContainerCssString =
  (resultBox: IWidgetField) =>
  (cssString: string): string => {
    const { options } = resultBox;

    const textSettings = options.itemsText;

    cssString += generateCssClassWithContent({
      className: textSettings._cssClass,
      content: textSettings,
      customFunction: generateDefaultStyles,
    });

    const searchResultsBoxCssContent = generateDefaultStyles(options);

    const boxCssContent = generateFlex({
      flex: "flex",
      direction: "column",
      align: "initial",
      justify: "flex-start",
      gap: options.spaceBetween,
    });

    cssString += generateCssClassWithContent({
      className: options._cssClass,
      content: boxCssContent + searchResultsBoxCssContent,
    });

    for (const state in options.states) {
      cssString += generateCssClassWithContent({
        className: options.itemsText._cssClass,
        content: options.states[state].itemsText,
        pseudoClassName: `:${state}`,
        customFunction: generateDefaultStyles,
      });
      cssString += generateCssClassWithContent({
        className: options._cssClass,
        content: options.states[state],
        pseudoClassName: `:${state}`,
        customFunction: generateDefaultStyles,
      });
    }
    return cssString;
  };

const generateFilterContainerCssString =
  (filterContainer: IWidgetField) =>
  (cssString: string): string => {
    const { options } = filterContainer;
    let widthStyles = "";

    cssString += `.${options._cssClass} {${generateDefaultStyles(options)}}`;

    if (options.size.width.type === Sizing.FIXED) {
      widthStyles = generateWidth(
        options.size.width.value.value,
        options.size.width.value.type
      );
    } else {
      widthStyles += generateWidth(100, "%");
    }

    widthStyles += generateMaxWidth(`100%`);

    cssString += `.${options._cssClass} {${widthStyles}}`;

    return cssString;
  };

const generateEventNameCssString =
  (element: IWidgetOptions) =>
  (cssString: string): string => {
    const defaultStyles = generateDefaultStyles(element.options);

    cssString += generateCssClassWithContent({
      className: element.options._cssClass,
      content:
        defaultStyles +
        "overflow:hidden;text-overflow:ellipsis;white-space:nowrap;",
    });

    return cssString;
  };

export const generateSportsBookPreMatchFenixMobileWidgetCssString = (
  widget: IPageContentWidget
): string => {
  const header = widget.content["header"];
  const title = widget.content["title"];
  const live_button_title = widget.content["live_button_title"];
  const winner_league_title = widget.content["winner_league_title"];
  const winner_league_card = widget.content["winner_league_card"];
  const winner_league_bet_items = widget.content["winner_league_bet_items"];
  const list_title = widget.content["list_title"];
  const filter = widget.content["filter"];
  const list_container = widget.content["list_container"];
  const top_leagues_list_container =
    widget.content["top_leagues_list_container"];
  const list_start_time = widget.content["list_start_time"];
  const top_leagues_list_start_time =
    widget.content["top_leagues_list_start_time"];
  const league_title = widget.content["league_title"];
  const top_leagues_league_title = widget.content["top_leagues_league_title"];
  const list_event_name = widget.content["list_event_name"];
  const top_leagues_list_event_name =
    widget.content["top_leagues_list_event_name"];
  const list_bet_item = widget.content["list_bet_item"];
  const top_leagues_list_bet_item = widget.content["top_leagues_list_bet_item"];
  const list_item_more = widget.content["list_item_more"];
  const top_leagues_list_item_more =
    widget.content["top_leagues_list_item_more"];
  const no_events_title = widget.content["no_events_title"];
  const top_leagues_no_events_title =
    widget.content["top_leagues_no_events_title"];
  const betbuilder_button = widget.content["betbuilder_button"];
  const top_leagues_betbuilder_button =
    widget.content["top_leagues_betbuilder_button"];
  const customFields = widget.options._customFields;
  const tabs = widget.content["items"] as any;
  const search_title = widget.content["search_title"];
  const search_box = widget.content["search_box"];
  const search_container = widget.content["search_container"];
  const search_field = widget.content["search_field"];
  const search_result_items = widget.content["search_result_items"];

  /*
    New
  */
  const leagues_container = widget.content["leagues_container"];
  const league_card = widget.content["league_card"];
  const winner_container = widget.content["winner_container"];
  const filter_container = widget.content["filter_container"];
  const events_container = widget.content["events_container"];
  const top_leagues_events_container =
    widget.content["top_leagues_events_container"];
  const league_name = widget.content["league_name"];
  const top_leagues_league_name = widget.content["top_leagues_league_name"];
  const day_label = widget.content["day_label"];
  const top_leagues_day_label = widget.content["top_leagues_day_label"];
  const list_date = widget.content["list_date"];
  const top_leagues_list_date = widget.content["top_leagues_list_date"];

  const list_event_info = widget.content["list_event_info"];
  const top_leagues_list_event_info =
    widget.content["top_leagues_list_event_info"];
  const list_favourite_icon = widget.content["list_favourite_icon"];
  const top_leagues_list_favourite_icon =
    widget.content["top_leagues_list_favourite_icon"];

  widget.options._cssClass = generateClassName("Sportsbook");
  header.options._cssClass = generateClassName("header");
  title.options._cssClass = generateClassName("title");
  live_button_title.options._cssClass = generateClassName("live_button_title");
  live_button_title.options.iconSettings._cssClass =
    generateClassName("live_button_icon");

  winner_league_title.options._cssClass = generateClassName(
    "winner_league_title"
  );
  winner_league_card.options._cssClass =
    generateClassName("winner_league_card");

  betbuilder_button.options._cssClass = generateClassName("betbuilder_button");
  top_leagues_betbuilder_button.options._cssClass = generateClassName(
    "top_leagues_betbuilder_button"
  );

  /*
    Tabs
  */
  tabs.options._cssClass = generateClassName("tabs");
  tabs.options._rootCssClass = generateClassName("tabs-root");

  if (tabs.value) {
    tabs.value[0].options.itemsIcon._cssClass = generateClassName("tabs-icon");
    tabs.value[0].options._cssClass = generateClassName("tabs-text");
  }
  itemsGroupClassNameGenerator(tabs);

  /*
    Bet item
  */
  winner_league_bet_items.options._cssClass = generateClassName(
    "winner_league_bet_items"
  );
  winner_league_bet_items.options.name._cssClass = generateClassName(
    "winner_league_bet_items_name"
  );
  winner_league_bet_items.options.coef._cssClass = generateClassName(
    "winner_league_bet_items_coef"
  );

  list_title.options._cssClass = generateClassName("list_title");

  no_events_title.options._cssClass = generateClassName("no_events_title");
  top_leagues_no_events_title.options._cssClass = generateClassName(
    "top_leagues_no_events_title"
  );

  /*
    Search
  */
  search_title.options._cssClass = generateClassName("search_title");
  search_container.options._cssClass = generateClassName("search_container");
  search_box.options._cssClass = generateClassName("search_box");
  search_field.options._cssClass = generateClassName("search_field");
  search_field.options.text._cssClass = generateClassName("search_field--text");
  search_field.options.icon._cssClass = generateClassName("search_field--icon");

  search_result_items.options._cssClass = generateClassName(
    "search_result_items"
  );
  search_result_items.options.itemsText._cssClass = generateClassName(
    "search_result_items--text"
  );
  /*
    Form
  */
  filter.options._cssClass = generateClassName("filter");
  filter.options._sectionClass = generateClassName("filter_section");
  filter.options._itemClass = generateClassName("filter_item");
  filter.options._itemBottomClass = generateClassName("filter_item_bottom");

  customFields.labels_styling.options._cssClass = generateClassName("label");
  customFields.fields_styling.options._cssClass = generateClassName("field");
  customFields.errors_styling.options._cssClass = generateClassName("error");
  customFields.tooltips_styling.options._cssClass =
    generateClassName("tooltip");

  // Dropdown class names
  customFields.dropdowns_styling.options._cssClass =
    generateClassName("dropdown");
  customFields.dropdown_menu_styling.options._cssClass =
    generateClassName("dropdownMenu");
  customFields.dropdown_menu_items_styling.options._cssClass =
    generateClassName("dropdownItems");

  // Calendar class names
  customFields.calendar_styling.options._wrapperCssClass =
    generateClassName("calendarWrapper");
  customFields.calendar_styling.options._dayCellCssClass =
    generateClassName("dayCell");

  customFields.calendar_styling.options._navCssClass =
    generateClassName("calendarNav");

  customFields.datepicker_styling.options._datepickerCssClass =
    generateClassName("datepicker");
  // Icons class names
  customFields.fields_styling.options.iconRight._cssClass =
    generateClassName("fieldIcon");

  if (customFields.dropdowns_styling) {
    customFields.dropdowns_styling.options.icon._cssClass =
      generateClassName("dropdownIcon");
  }

  customFields.tooltips_styling.options.textChooseIcon._cssClass =
    generateClassName("textChooseIcon");

  if (customFields.calendar_styling) {
    // Calendar class names
    customFields.calendar_styling.options._wrapperCssClass =
      generateClassName("calendarWrapper");
    customFields.calendar_styling.options._headerWrapperCssClass =
      generateClassName("calendarHeaderWrapper");
    customFields.calendar_styling.options._weekDayCssClass =
      generateClassName("calendarWeekDay");
    customFields.calendar_styling.options._cellCssClass =
      generateClassName("cell");
    customFields.calendar_styling.options._cellWrapperCssClass =
      generateClassName("cellWrapperCssClass");

    customFields.calendar_styling.options._navCssClass =
      generateClassName("calendarNav");

    customFields.datepicker_styling.options._datepickerCssClass =
      generateClassName("datepicker");
  }

  const calendar = customFields.calendar_styling
    ? [
        generateCalendarWrapperCssString(customFields.calendar_styling),
        generateCalendarHeaderWrapperCssString(customFields.calendar_styling),
        generateCalendarCellCssString(customFields.calendar_styling),
        generateCalendarWeekdayCssString(customFields.calendar_styling),
        generateCalendarNavCssString(customFields.calendar_styling),
      ]
    : [];

  list_container.options._cssClass = generateClassName("list_container");
  list_container.options.card._cssClass = generateClassName("list_card");
  list_container.options.card._newLeagueItemCssClass = generateClassName(
    "list_card_new_league"
  );
  list_container.options.card._newDayItemCssClass =
    generateClassName("list_card_new_day");
  list_container.options.card._headerCssClass =
    generateClassName("list_card_header");
  list_container.options.items._cssClass = generateClassName("list_items");
  list_container.options.bet_items = {};
  list_container.options.bet_items._cssClass =
    generateClassName("list_bet_items");
  list_container.options.bet_items._verticalCssClass = generateClassName(
    "list_bet_items_vertical"
  );

  list_container.options._itemHeaderCssClass = generateClassName(
    "list_container_item_header"
  );

  top_leagues_list_container.options._cssClass = generateClassName(
    "top_leagues_list_container"
  );
  top_leagues_list_container.options.card._cssClass = generateClassName(
    "top_leagues_list_card"
  );
  top_leagues_list_container.options.card._newLeagueItemCssClass =
    generateClassName("top_leagues_list_card_new_league");
  top_leagues_list_container.options.card._newDayItemCssClass =
    generateClassName("top_leagues_list_card_new_day");
  top_leagues_list_container.options.card._headerCssClass = generateClassName(
    "top_leagues_list_card_header"
  );
  top_leagues_list_container.options.items._cssClass = generateClassName(
    "top_leagues_list_items"
  );
  top_leagues_list_container.options.bet_items = {};
  top_leagues_list_container.options.bet_items._cssClass = generateClassName(
    "top_leagues_list_bet_items"
  );
  top_leagues_list_container.options.bet_items._verticalCssClass =
    generateClassName("top_leagues_list_bet_items_vertical");

  top_leagues_list_container.options._itemHeaderCssClass = generateClassName(
    "top_leagues_list_container_item_header"
  );

  list_start_time.options._cssClass = generateClassName("list_start_time");
  list_start_time.options._containerCssClass = generateClassName(
    "list_start_time_container"
  );
  list_start_time.options.icon._cssClass = generateClassName(
    "list_start_time_icon"
  );

  top_leagues_list_start_time.options._cssClass = generateClassName(
    "top_leagues_list_start_time"
  );
  top_leagues_list_start_time.options._containerCssClass = generateClassName(
    "top_leagues_list_start_time_container"
  );
  top_leagues_list_start_time.options.icon._cssClass = generateClassName(
    "top_leagues_list_start_time_icon"
  );

  league_title.options._cssClass = generateClassName("league_title");
  top_leagues_league_title.options._cssClass = generateClassName(
    "top_leagues_league_title"
  );
  list_event_name.options._cssClass = generateClassName("list_event_name");
  top_leagues_list_event_name.options._cssClass = generateClassName(
    "top_leagues_list_event_name"
  );

  list_bet_item.options._cssClass = generateClassName("list_bet_item");
  list_bet_item.options.name._cssClass = generateClassName("list_bet_item");
  list_bet_item.options.coef._cssClass = generateClassName("list_bet_item");

  top_leagues_list_bet_item.options._cssClass = generateClassName(
    "top_leagues_list_bet_item"
  );
  top_leagues_list_bet_item.options.name._cssClass = generateClassName(
    "top_leagues_list_bet_item_name"
  );
  top_leagues_list_bet_item.options.coef._cssClass = generateClassName(
    "top_leagues_list_bet_item_coef"
  );

  list_item_more.options._cssClass = generateClassName("list_item_more");

  top_leagues_list_item_more.options._cssClass = generateClassName(
    "top_leagues_list_item_more"
  );

  /*
    New
  */

  leagues_container.options._cssClass = generateClassName("leagues_container");
  league_card.options._cssClass = generateClassName("league_card");
  winner_container.options._cssClass = generateClassName("winner_container");
  filter_container.options._cssClass = generateClassName("filter_container");
  events_container.options._cssClass = generateClassName("events_container");
  top_leagues_events_container.options._cssClass = generateClassName(
    "top_leagues_events_container"
  );
  league_name.options._cssClass = generateClassName("league_name");
  top_leagues_league_name.options._cssClass = generateClassName(
    "top_leagues_league_name"
  );
  day_label.options._cssClass = generateClassName("day_label");
  top_leagues_day_label.options._cssClass = generateClassName(
    "top_leagues_day_label"
  );
  list_event_info.options._cssClass = generateClassName("list_event_info");
  list_event_info.options._detailsCssClass = generateClassName(
    "list_event_info_details"
  );
  list_event_info.options._buttonsCssClass = generateClassName(
    "list_event_info_buttons"
  );

  top_leagues_list_event_info.options._cssClass = generateClassName(
    "top_leagues_list_event_info"
  );
  top_leagues_list_event_info.options._detailsCssClass = generateClassName(
    "top_leagues_list_event_info_details"
  );
  top_leagues_list_event_info.options._buttonsCssClass = generateClassName(
    "top_leagues_list_event_info_buttons"
  );

  list_date.options._cssClass = generateClassName("list_date");
  list_date.options._containerCssClass = generateClassName(
    "list_date_container"
  );
  list_date.options.icon._cssClass = generateClassName("list_date_time_icon");

  top_leagues_list_date.options._cssClass = generateClassName(
    "top_leagues_list_date"
  );
  top_leagues_list_date.options._containerCssClass = generateClassName(
    "top_leagues_list_date_container"
  );
  top_leagues_list_date.options.icon._cssClass = generateClassName(
    "top_leagues_list_date_time_icon"
  );

  list_favourite_icon.options._cssClass = generateClassName(
    "list_favourite_icon"
  );

  top_leagues_list_favourite_icon.options._cssClass = generateClassName(
    "top_leagues_list_favourite_icon"
  );

  const dropdowns = customFields.dropdowns_styling
    ? [
        generateDropdownMenuCssString(
          customFields.dropdown_menu_styling,
          customFields.dropdown_menu_items_styling,
          filter_container.options._cssClass
        ),
        generateDropdownMenuItemStyling(
          customFields.dropdown_menu_items_styling
        ),
      ]
    : [];

  return pipeSync<string>(
    generateWidgetStyles(widget.options),
    generateHeaderCssString(header as IWidgetField),
    generateButtonCssString(live_button_title as IWidgetField),
    generateCustomStyles(live_button_title.options._cssClass, {
      width: "auto",
    }),
    generateStringWithStates(betbuilder_button, false),
    generateStringWithStates(top_leagues_betbuilder_button, false),
    generateStringDefault(title),
    generateTitleAdditionalStyles(title.options),
    generateStringDefault(no_events_title),
    generateStringDefault(top_leagues_no_events_title),
    generateStringDefault(winner_league_title),
    generateLeagueWinnerCardCssString(winner_league_card as IWidgetField),
    generateLeagueBetItemCssString(winner_league_bet_items as IWidgetField),
    generateStringDefault(list_title),
    generateStringDefault(filter),
    generateListContainerCssString(
      list_container as IWidgetField,
      events_container as IWidgetField,
      list_bet_item.options.container,
      list_container.options.bet_items._cssClass,
      list_container.options.bet_items._verticalCssClass,
      list_event_name.options._cssClass,
      list_start_time.options,
      list_date.options
    ),
    generateListContainerCssString(
      top_leagues_list_container as IWidgetField,
      top_leagues_events_container as IWidgetField,
      top_leagues_list_bet_item.options.container,
      top_leagues_list_container.options.bet_items._cssClass,
      top_leagues_list_container.options.bet_items._verticalCssClass,
      top_leagues_list_event_name.options._cssClass,
      top_leagues_list_start_time.options,
      top_leagues_list_date.options
    ),
    generateListStartTimeCssString(list_start_time as IWidgetField),
    generateListStartTimeCssString(top_leagues_list_start_time as IWidgetField),
    generateStringDefault(league_title),
    generateStringDefault(top_leagues_league_title),
    generateEventNameCssString(list_event_name),
    generateEventNameCssString(top_leagues_list_event_name),
    generateLeagueBetItemCssString(list_bet_item as IWidgetField, true),
    generateLeagueBetItemCssString(
      top_leagues_list_bet_item as IWidgetField,
      true
    ),
    generateItemMoreStyles(list_item_more.options),
    generateItemMoreStyles(top_leagues_list_item_more.options),

    /*
      Tabs
    */
    generateItemsCssString(tabs),
    generateItemsGroupCssString(tabs.value[0]),

    /*
      Search
    */
    generateSearchFieldContainerCssString(search_field as IWidgetField),
    generateResultContainerCssString(search_result_items as IWidgetField),
    generateStringDefault(search_title),
    generateStringDefault(search_container),
    generateStringDefault(search_box),
    /*
      New
    */

    generateLeaguesContainerCss(leagues_container.options),
    generateStringWithStates(league_card, false),
    generateDefaultStylesWithStateClassName(
      league_card,
      State.ACTIVE,
      `${league_card.options._cssClass}.router-link-active`
    ),
    generateLeagueCardMinWidthCssString(league_card as IWidgetField),
    generateStringDefault(winner_container),
    generateFilterContainerCssString(filter_container as IWidgetField),
    generateStringDefault(events_container),
    generateStringDefault(top_leagues_events_container),
    generateStringDefault(league_name),
    generateStringDefault(top_leagues_league_name),
    generateDayLabelCssString(day_label as IWidgetField),
    generateStringDefault(top_leagues_day_label),
    generateListStartTimeCssString(list_date as IWidgetField),
    generateListStartTimeCssString(top_leagues_list_date as IWidgetField),
    generatEventInfoContainerCssString(
      list_event_info as IWidgetField,
      list_start_time as IWidgetField
    ),
    generatEventInfoContainerCssString(
      top_leagues_list_event_info as IWidgetField,
      top_leagues_list_start_time as IWidgetField
    ),
    generateFavButtonStyles(list_favourite_icon),
    generateFavButtonStyles(top_leagues_list_favourite_icon),

    /*
      Form
    */
    generateFormCssString(filter, widget),
    generateFilterStyles(filter.options),
    generateFilterItemsStyles(filter.options),
    generateTooltipStyling(customFields.tooltips_styling),
    generateIconCssString(customFields.tooltips_styling.options.textChooseIcon),
    generateErrorCssString(customFields.errors_styling),
    generateStringDefault(customFields.labels_styling),
    generateFieldsWithStatesCssString(
      customFields.fields_styling,
      customFields.errors_styling,
      widget,
      customFields.labels_styling,
      customFields.field_success_styling
    ),
    generateIconRightCssString(customFields.fields_styling.options),
    generateGridLayoutCssString(list_container, false, "align-items:end;"),
    generateGridLayoutCssString(
      top_leagues_list_container,
      false,
      "align-items:end;"
    ),

    ...dropdowns,
    ...calendar
  )("");
};
