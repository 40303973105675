<template>
  <div
    v-if="hasDesignPermissions"
    class="p-l-16 p-r-16 p-t-16 p-b-16 config-alignment-full widget-control-container"
  >
    <p
      v-if="label"
      :class="{
        'sub-heading': isBold,
      }"
      class="flex items-center gap-4 m-b-4"
    >
      {{ label }}
      <a-tooltip>
        <template #title>
          You can uncheck the alignment icon by clicking on it. Means
          <u>initial</u> value
        </template>

        <CommonIcon name="ant-design:question-circle-outlined" />
      </a-tooltip>
    </p>
    <div class="wconfig-common-radio__radio-group-container">
      <a-radio-group
        :value="modelValue"
        class="wconfig-common-radio__radio-group w-100"
      >
        <a-radio-button
          v-for="(item, idx) in alignValues"
          :key="idx"
          :value="item.value"
          :title="item.title"
          :checked="item.value === currentAlignment"
          @click="handleAlignmentClick(item.value)"
        >
          <CommonIcon
            class="config-alignment-full__dot"
            name="custom:dot-small"
          />

          <slot name="icon">
            <CommonIcon
              :name="customIconName || `align:${iconNamePrefix}${item.icon}`"
            />
          </slot>
        </a-radio-button>
      </a-radio-group>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Align } from "~~/models/widgets/widget-controls.model";
import { CellLayout } from "~~/models/grid.interface";
import { usePermissions } from "~~/composables/permissions/usePermissions";

type AlignValue = {
  title: string;
  value: string;
  icon?: string;
};

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    isBold?: boolean;
    label?: string;
    layout?: CellLayout;
    customAlignValues?: AlignValue[] | null;
    customIconName?: string;
  }>(),
  {
    label: "Alignment",
    modelValue() {
      return "";
    },
    layout: CellLayout.HORIZONTAL,
    customAlignValues: null,
    customIconName: "",
  }
);

const ALIGN_VALUES = [
  {
    title: "Top Left",
    icon: "lt",
    value: `${Align.START} ${Align.START}`,
  },
  {
    title: "Top Center",
    icon: "ct",
    value: `${Align.START} ${Align.MIDDLE}`,
  },
  {
    title: "Top Right",
    icon: "rt",
    value: `${Align.START} ${Align.END}`,
  },
  {
    title: "Center Left",
    icon: "lc",
    value: `${Align.MIDDLE} ${Align.START}`,
  },
  {
    title: "Center Center",
    icon: "cc",
    value: `${Align.MIDDLE} ${Align.MIDDLE}`,
  },
  {
    title: "Center Right",
    icon: "rc",
    value: `${Align.MIDDLE} ${Align.END}`,
  },
  {
    title: "Bottom Left",
    icon: "lb",
    value: `${Align.END} ${Align.START}`,
  },
  {
    title: "Bottom Center",
    icon: "cb",
    value: `${Align.END} ${Align.MIDDLE}`,
  },
  {
    title: "Bottom Right",
    icon: "rb",
    value: `${Align.END} ${Align.END}`,
  },
];

const emit = defineEmits<{
  (e: "update:modelValue", event: string): void;
}>();

const hasDesignPermissions = usePermissions().has(
  usePermissions().Permissions.DESIGN
);

const iconNamePrefix = computed<string>(() => {
  return props.layout.slice(0, 1).toLowerCase();
});

const currentAlignment = computed<string>(() => {
  return props.modelValue;
});

const alignValues = computed<AlignValue[]>(() => {
  if (props.customAlignValues) {
    return props.customAlignValues;
  }

  return ALIGN_VALUES;
});

const handleAlignmentClick = (value: string): void => {
  if (currentAlignment.value === value) {
    emit("update:modelValue", "");
  } else {
    emit("update:modelValue", value);
  }
};
</script>

<style lang="scss">
$config-prefix: "config-alignment-full";

.#{$config-prefix} {
  .wconfig-common-radio__radio-group-container {
    .wconfig-common-radio__radio {
      &-group {
        &-container {
          padding: 0.5rem 0;
          border: 1px solid $c-grey-15;
          border-radius: 6px;
        }
      }
    }

    .#{$config-prefix}__dot {
      position: relative;
      &::before {
        content: "";
        display: block;
        transition: all 0.2s ease;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 5px;
        height: 5px;
      }

      &:hover {
        svg {
          opacity: 0;
        }

        &::before {
          background-color: $c-grey-06;
          width: 20px;
          height: 20px;
        }
      }
    }

    .common-icon {
      font-size: 1.3em;
      &:not(.#{$config-prefix}__dot) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        visibility: hidden;
      }
    }
    .ant-radio {
      &-group {
        @include flex($gap: 0, $wrap: true);
      }
      &-button-wrapper {
        @include inline-flex(center, center, 0);
        flex: 1 0 33.33333%;
        border: none;
        outline: none;
        &:active,
        &:focus,
        &:focus-within,
        &::before {
          content: none;
          border: none;
          box-shadow: none;
        }
        &-checked {
          .common-icon {
            visibility: visible;
          }
        }
      }
    }
  }
}
</style>
