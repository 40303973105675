<template>
  <WidgetField
    :widget="widget"
    :field="fields.markets.field"
    class="top-match-fenix__markets"
    :class="`top-match-fenix__markets--${fields.markets.field.options.display.layout}`"
  >
    <BasicDesignWrapperField
      :widget="widget"
      :field="fields.markets.field"
      class="top-match-fenix__markets-content"
    >
      <WidgetField
        v-for="outcome in OUTCOMES"
        :key="outcome"
        :widget="widget"
        :field="fields.outcomes.field"
      >
        <SportsbookBetItem
          :item-value="{ name: outcome, coef: '0.00' }"
          :bet-item-field="fields.outcomes.field"
          :state-options="stateOptions"
        />
      </WidgetField>
    </BasicDesignWrapperField>
  </WidgetField>
</template>

<script lang="ts" setup>
import {
  IWidgetOptions,
  IWidgetWithFields,
} from "~~/models/widgets/widget.core/widget.model";
import { useWidgetFields } from "~~/composables/widgets/useWidgetFields";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  DisplayOrientation,
  State,
} from "~~/models/widgets/widget-controls.model";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, string>;
}>();

const OUTCOMES = ["1", "X", "2"];

const fields = useWidgetFields(props.widget);

const dir = computed<string>(() => {
  const display = fields.value.markets.field.options.display;

  if (display.layout === DisplayOrientation.HORIZONTAL) {
    return "row";
  }

  return "column";
});

const gap = computed<string>(() => {
  return getPxValueFromNumber(fields.value.markets.field.options.display.gap);
});

const state = computed<string>(() => {
  return props.states.outcomes;
});

const stateOptions = computed<IWidgetOptions>(() => {
  if (state.value === State.DEFAULT) {
    return fields.value.outcomes.field.options;
  }

  return fields.value.outcomes.field.options.states[state.value];
});
</script>

<style lang="scss">
.top-match-fenix {
  &__markets-content {
    display: flex;
    flex-direction: v-bind(dir);
    gap: v-bind(gap);
  }

  &__markets {
    &--vertical {
      align-items: stretch;
    }

    &--horizontal {
      .top-match-fenix__markets-content {
        & > div {
          flex: 1;
        }
      }
    }
  }
}
</style>
