<template>
  <article
    :class="['page-editor', { 'page-editor_active': active }]"
    :style="{ backgroundColor }"
  >
    <a-tooltip placement="bottom">
      <img
        v-if="avatar && !imageError"
        :src="`${VITE_DASHBOARD_BASE_URL()}/${avatar}`"
        :alt="name"
        class="page-editor__img"
        @load="handleImageLoad"
        @error="handleImageError"
      />
      <p
        v-if="!imageLoaded"
        class="page-editor__abbr"
      >
        {{ abbr }}
      </p>

      <template
        v-if="name"
        #title
      >
        {{ name }}
      </template>
    </a-tooltip>
  </article>
</template>
<script setup lang="ts">
import { VITE_DASHBOARD_BASE_URL } from "~~/constants";
import { getRandomIntInclusive } from "~~/helpers/get-random";

import { PageEditor } from "../types";

const {
  name = "User name",
  avatar,
  abbr = "UN",
} = defineProps<PageEditor["user"] & { active?: boolean }>();

const backgroundColors = [
  "#1677FF",
  "#722ED1",
  "#13C2C2",
  "#52C41A",
  "#EB2F96",
  "#FA8C16",
  "#F5222D",
  "#2F54EB",
  "#A0D911",
];

const backgroundColor = computed(() => {
  const randomNumber = getRandomIntInclusive(0, backgroundColors.length - 1);
  return backgroundColors[randomNumber];
});

const imageLoaded = ref(false);
const handleImageLoad = () => {
  imageLoaded.value = true;
};

const imageError = ref(false);
const handleImageError = () => {
  imageError.value = true;
};
</script>
<style lang="scss">
.page-editor {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  color: #fff;
  overflow: hidden;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  cursor: default;

  &_active {
    box-shadow: 0 0 0 1px #52c41a;
  }

  &__abbr {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
