import { CSSProperties } from "vue";

import {
  clearObject,
  useDesignInitialStyles,
} from "~~/components/widgets/widgets-entities/PromoBetsFenixWidget";
import { getPxValueFromNumber } from "~~/assets/utils";
import {
  Display,
  FlexDirection,
} from "~~/models/widgets/widget-controls.model";

export const useBonusStyles = (bonusOptions: any = {}): CSSProperties => {
  const styles = {
    ...useDesignInitialStyles(bonusOptions),

    display: Display.FLEX,
    flexDirection: FlexDirection.COLUMN,
    gap: bonusOptions.gap ? getPxValueFromNumber(bonusOptions.gap) : undefined,
  };

  return clearObject(styles);
};
