<template>
  <div>
    <!--
      TODO Think about how to remove current v-if
     -->
    <Teleport
      v-if="selectedWidget?.id === widget.id"
      to="#settings_sidebar"
    >
      <WidgetSettingsNew
        :dropdown-items="DROPDOWN_ITEMS"
        :tabs="TABS"
        @close="resetStates"
        @blur-tab="resetStates"
        @change="resetStates"
      >
        <template #widgetSettings-design>
          <ControlsList
            :widget="widget"
            :controls="widgetDesignControls"
          />
        </template>

        <template #widgetSettings-content>
          <ControlsList
            :widget="widget"
            :controls="widgetContentControls"
          />
        </template>

        <template #widgetSettings-data>
          <WidgetDynamicParams
            :widget="widget"
            :initial-value="bindingParams"
          />
        </template>

        <template #heading-design>
          <ControlsList
            :widget="widget"
            :controls="headingDesignControls"
          />
        </template>

        <template #heading-content>
          <ControlsList
            :widget="widget"
            :controls="headingContentControls"
          />
        </template>

        <template #heading-states>
          <ControlsList
            :widget="widget"
            :controls="headingStatesControls"
          />
        </template>

        <template #event_card-design>
          <ControlsList
            :widget="widget"
            :controls="sportEventCardsDesignControls"
          />
        </template>

        <template #event_card-content>
          <ControlsList
            :widget="widget"
            :controls="sportEventCardsContentControls"
          />
        </template>

        <template #event_card-states>
          <ControlsList
            :widget="widget"
            :controls="sportEventCardsStatesControls"
          />
        </template>

        <template #header-design>
          <ControlsList
            :widget="widget"
            :controls="headerDesignControls"
          />
        </template>

        <template #header-content>
          <ControlsList
            :widget="widget"
            :controls="headerContentControls"
          />
        </template>

        <template #footer-design>
          <ControlsList
            :widget="widget"
            :controls="footerDesignControls"
          />
        </template>

        <template #footer-content>
          <ControlsList
            :widget="widget"
            :controls="footerContentControls"
          />
        </template>

        <template #favourites_button>
          <ControlsList
            :widget="widget"
            :controls="favIconControls"
          />
        </template>

        <template #event_league>
          <ControlsList
            :widget="widget"
            :controls="leagueNameControls"
          />
        </template>

        <template #event_suspended_status-design>
          <ControlsList
            :widget="widget"
            :controls="eventSuspendedStatusDesignControls"
          />
        </template>

        <template #event_suspended_status-content>
          <ControlsList
            :widget="widget"
            :controls="eventSuspendedStatusContentControls"
          />
        </template>

        <template #stream_icon>
          <ControlsList
            :widget="widget"
            :controls="streamIconControls"
          />
        </template>

        <template #event_team_player>
          <ControlsList
            :widget="widget"
            :controls="eventNameControls"
          />
        </template>

        <template #event_time>
          <ControlsList
            :widget="widget"
            :controls="eventTimeControls"
          />
        </template>

        <template #event_period>
          <ControlsList
            :widget="widget"
            :controls="eventPeriodControls"
          />
        </template>

        <template #event_score-design>
          <ControlsList
            :widget="widget"
            :controls="eventScoreDesignControls"
          />
        </template>

        <template #event_score-content>
          <ControlsList
            :widget="widget"
            :controls="eventScoreContentControls"
          />
        </template>

        <template #racing_event_card-design>
          <ControlsList
            :widget="widget"
            :controls="racingEventCardsDesignControls"
          />
        </template>

        <template #racing_event_card-content>
          <ControlsList
            :widget="widget"
            :controls="racingEventCardsContentControls"
          />
        </template>

        <template #racing_event_card-states>
          <ControlsList
            :widget="widget"
            :controls="racingEventCardsStatesControls"
          />
        </template>

        <template #racing_stream_icon>
          <ControlsList
            :widget="widget"
            :controls="racingStreamIconControls"
          />
        </template>

        <template #racing_event_name>
          <ControlsList
            :widget="widget"
            :controls="racingEventNameControls"
          />
        </template>

        <template #racing_event_status-design>
          <ControlsList
            :widget="widget"
            :controls="racingEventStatusDesignControls"
          />
        </template>

        <template #racing_event_status-content>
          <ControlsList
            :widget="widget"
            :controls="racingEventStatusContentControls"
          />
        </template>

        <template #racing_favourites_icon>
          <ControlsList
            :widget="widget"
            :controls="racingFavIconControls"
          />
        </template>

        <template #event_container-design>
          <ControlsList
            :widget="widget"
            :controls="menuItemDesignControls"
          />
        </template>

        <template #event_container-content>
          <ControlsList
            :widget="widget"
            :controls="menuItemContentControls"
          />
        </template>
      </WidgetSettingsNew>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount } from "vue";

import { IWidgetWithFields } from "~~/models/widgets/widget.core/widget.model";
import { State } from "~~/models/widgets/widget-controls.model";
import { useFavoritesEventsLiveFenixConfig } from "~~/composables/widgets/favorites-events-live-fenix/useFavoritesEventsLiveFenixConfig";
import { useWidgetSettingsStore } from "~~/store/widget-settings";
import { useWidgetDynamicParams } from "~~/components/dynamic-params/useWidgetDynamicParams";

const props = defineProps<{
  widget: IWidgetWithFields;
  states: Record<string, State>;
}>();

const emit = defineEmits<{
  (e: "update-state", event: { state: string; value: State }): void;
  (e: "reset-states"): void;
}>();

const widgetsSettingStore = useWidgetSettingsStore();
const { selectedWidget } = storeToRefs(widgetsSettingStore);

const states = toRef(() => props.states);

const { bindingParams } = useWidgetDynamicParams(
  {
    "eventUrl": {
      label: "Event URL",
      sources: ["custom"],
      required: true,
    },
  },
  {
    values: props.widget.options.bindingParams || {},
  }
);

const {
  DROPDOWN_ITEMS,
  TABS,

  /*
      Controls
    */
  widgetDesignControls,
  widgetContentControls,

  headingContentControls,
  headingDesignControls,
  headingStatesControls,

  sportEventCardsDesignControls,
  sportEventCardsContentControls,
  sportEventCardsStatesControls,

  headerDesignControls,
  headerContentControls,

  footerDesignControls,
  footerContentControls,

  favIconControls,

  leagueNameControls,

  eventSuspendedStatusDesignControls,
  eventSuspendedStatusContentControls,

  streamIconControls,

  eventNameControls,

  eventTimeControls,

  eventPeriodControls,

  eventScoreDesignControls,
  eventScoreContentControls,

  racingEventCardsDesignControls,
  racingEventCardsContentControls,
  racingEventCardsStatesControls,

  racingStreamIconControls,

  racingEventNameControls,

  racingEventStatusDesignControls,
  racingEventStatusContentControls,

  racingFavIconControls,

  menuItemDesignControls,
  menuItemContentControls,
} = useFavoritesEventsLiveFenixConfig(ref(props.widget), states, emit);

const resetStates = () => {
  emit("reset-states");
};

onBeforeUnmount(() => {
  emit("reset-states");
});
</script>
